<template>
    <div class="container" v-if="$props.details.netAmount">
        <div class="content">
            <div class="page-actions" v-if="$props.details.payPeriod">
                <div class="action-left">
                    <a class="back" href="javascript:history.back()">
                        <svg class="icon-arrow-left" size="0 0 18 18" />
                        {{ displayDate($props.details.payPeriod) }}
                    </a>
                </div>
            </div>

             <div class="page-actions" v-else>
                <h2 style="text-align: center; margin-top: 20px">Deposit Summary</h2>
                <div class="action-left" style="margin-left: 20px" v-if="!$props.details.showRemarks">
                        Period Beginning: {{ displayDate($props.details.startDate) }}
                        <br><br>
                        Period Ending: {{ displayDate($props.details.endDate) }}
                </div>
            </div>
            <div class="earnings-grid deposit-summary-container margin-t-medium" id="pdfContent">
                <div>
                    <div class="flex label-group">
                        <!-- <p v-if="$props.details.checkDate" style="margin-right: 25px;">Check Date</p> -->
                        <p class="margin-l-30pc">Owner</p>
                    </div>
                    <div class="flex">
                        <!-- <p v-if="$props.details.checkDate" style="margin-right: 20px;">{{ displayDate($props.details.checkDate) }}</p> -->
                        <p class="margin-l-30pc">{{ $props.details.partner.name }}</p>
                    </div>
                </div>
                <div class="logo-and-line" v-if="$props.details.showRemarks">
                    <div class="remarks-icon-container">
                        <img class="deposit-remarks-img" src="@/assets/images/svg/Notes.svg" alt="Deposit Remarks" />
                    </div>
                    <div class="vertical-dotted-line"></div>
                    <div class="deposit-remarks">{{ $props.details.remarks }}</div>
                </div>
                <p class="net-income-heading">Earnings</p>
                <div class="gray-bg module-large total-amount">

                    <div v-if="$props.details.isAdhocPayment" class="detail-earning-main">
                        <div class="details-header">
                            <span style="display: inline-block; width:10%">QTY</span>
                            <span style="display: inline-block; width:25%" class="details-type-adhoc">TYPE</span>
                            <span style="display: inline-block; width:50%" class="details-type-adhoc">Remarks</span>
                            <span style="display: inline-block; width:15%">AMOUNT</span>
                        </div>

                        <div class="detail-earning" v-if="$props.details.credits.length > 0">
                            <ul v-for="(item, index) in $props.details.credits" :key="index">
                                <li :class="isTaxExempted(item)">
                                    <div class="detail-earning-title " style="width:85%">
                                        <span style="display: inline-block; width:10%">{{ item.count }}</span>
                                        <span style="display: inline-block; width:25%">{{ item.name }}</span>
                                        <span style="display: inline-block; padding:0 20px; width:50%">{{ item.remarks }}</span>
                                    </div>
                                    <div style="display: inline-block; width:10%">{{ formatCurrency(item.amount) }}</div>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div v-else class="detail-earning-main">
                        <div class="details-header">
                            <span>QTY</span>
                            <span class="details-type">TYPE</span>
                            <span>AMOUNT</span>
                        </div>

                        <div class="detail-earning" v-if="$props.details.credits.length > 0">
                            <ul v-for="(item, index) in $props.details.credits" :key="index">
                                <li>
                                    <div class="detail-earning-title ">
                                        <span>{{ item.count }}</span>
                                        <span style="padding-left:38px">{{ item.name }}</span>
                                    </div>
                                    <div>{{ formatCurrency(item.amount) }}</div>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div class="border-line"></div>
                    <h5 class="net-total bold-text">
                        Total
                        <span> {{ formatCurrency($props.details.netAmount) }}</span>
                    </h5>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { DateTime } from "luxon";
export default {
    name: "OneDaySummary",
    props: {
        details: {
            type: Object,
            required: false,
            default: () => { },
        },
    },
    methods: {
        displayDate(date) {
            return DateTime.fromISO(date.split("T")[0]).toFormat("d LLL y");
        },
        formatCurrency(amount, space) {
            amount == undefined || isNaN(amount) ? (amount = 0) : amount;
            if (!space) {
                return new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                }).format(amount);
            } else {
                const formattedMoney = new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                }).format(amount);
                return `${formattedMoney.substring(0, 1)} ${formattedMoney.substring(
                    1,
                    formattedMoney.length
                )}`;
            }
        },
        isTaxExempted(item) {
            return item.tax_exempted?.toLowerCase() === 'y' ? 'tax-exempted' : '';
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$emit("enableDownload");
        });
    },
};
</script>

<style scoped>
.deposit-summary-container .single-container .detail-prices {
    margin-left: 40px;
    width: 60%;
}

.net-income-heading {
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    margin-left: 16px;
}

.deposit-summary-container .single-container .detail-prices .wide {
    width: 20%;
}

.deposit-summary-container .single-container {
    margin-top: 30px;
}

h5 span {
    float: right;
}

.bold-text {
    font-weight: 600;
}

.pay-period {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #707070;
    margin-bottom: 32px;
}

.detail-earning-main {
    font-size: 16px;
    color: #3B4459;
}

.detail-earning li {
    display: flex;
    justify-content: space-between;
    color: #3B4459;
}

.details-header {
    display: flex;
    margin-bottom: 10px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-transform: uppercase;
    color: #8D95AA;
    ;
}

.details-type {
    padding-left: 17px;
    width: 100%;
}

.details-type-adhoc {
    padding-left: 17px;
    width: 100px;
}

.detail-earning .detail-earning-title {
    width: 100%;
}

.earnings-grid>div {
    padding: 16px 18px !important;
    margin-bottom: 40px;
}

.border-line {
    border: 1px solid #EBEBEb;
    margin-bottom: 24px;
}

ul {
    padding-left: 0 !important;
}

.logo-and-line {
  display: flex; 
  align-items: center;
  margin-left: 5px;
  margin-bottom: 20px;
}

.remarks-icon-container{
  width: 45px;
}

.deposit-remarks-img{
  height: 45px !important;
}

.vertical-dotted-line {
  border-left: 1px dotted #333333; 
  height: 40px;
  margin-left: 10px;
}

.deposit-remarks{
  max-width: 85%;
  margin-left: 5px;
  /* font-family: "Rubik"; */
  word-spacing: 1px;
  line-height: normal;
}

.tax-exempted {
  color: orange !important;
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
    .deposit-summary-container .single-container .detail-prices .wide {
        width: 50%;
    }

    .deposit-summary-container .single-container .detail-prices .right-para p {
        font-size: 15px;
    }

    .deposit-summary-container .single-container .detail-prices {
        margin-left: 0px;
        width: 100%;
    }

    .detail-earning .detail-earning-title {
        width: 70%;
    }
}
</style>
  