<template>
  <ValidationObserver v-slot="validation" slim>
    <main class="empty-area">
      <div class="container login">
        <div class="content grid">
          <div class="login-area col-5">
            <div class="login-form">
              <h2 class="text-center">Reset Password</h2>
              <span v-if="error">{{ error }}</span>
              <div class="module-large">
                <!-- <div class="field">
                  <label>
                    Username
                    <input v-model="username" name="username" disabled />
                  </label>
                </div> -->
                <div class="field">
                  <form-input v-model="credentials.password1" type="password" name="password" label="New Password" required :rules="`required|min`" />
                </div>
                <div class="field">
                  <form-input v-model="credentials.password2" type="password" name="confirm password" label="Re-type password" required :rules="`required|confirm`" />
                </div>
              </div>
              <div class="module-large">
                <button class="button block" :disabled="validation.invalid" @click="resetPassword">Reset</button>
              </div>
            </div>
          </div>
          <div class="logo-display col-7">
            <img src="@/assets/images/logos/walmart-logo.svg" alt="" />
          </div>
        </div>
      </div>
    </main>
  </ValidationObserver>
</template>
<script>
import {  ValidationObserver, extend } from "vee-validate";
import FormInput from "@/components/forms/fields/FormInput";
import { encodeWithParam} from "@/filters";
import axios from "axios";


export default {
  name: "Reset",
  components: { ValidationObserver,FormInput },
  data: () => {
    return {
      credentials: {
        password1: "",
        password2: "",
      },
      username: "",
      userID: "",
      error: "",
    };
  },
  mounted() {
    if (!this.$route.query.token) {
      this.$router.push("/");
    }
    extend('confirm', value => {
    if (value === this.credentials.password1) {
    return true;
    }
    return 'Password confirmation does not match';
    });

    extend('min', value => {
    if (value.length > 7) {
      return true;
    }
    return "Password required length is 8";
    });
  
  },
  methods: {
    async resetPassword() {
      let obj={
        password:this.credentials.password1
      }
      try {
      let axiosConfig={
        headers: {
          "Content-Type": "application/json",
           "Access-Control-Allow-Origin": "*", 
           Accept: "*/*",
          withCredentials: true,
          Authorization: this.$route.query.token,
          }
        }
      axios.post(`${config.VUE_APP_REST_API_BASE_URL || process.env.VUE_APP_REST_API_BASE_URL}/employee/changeForgottenPassword`,encodeWithParam(obj),axiosConfig).then((data)=>{
        console.log(data)
        if(data.data.success){
          return this.$router.push({
          name: "login",
        });
        }
      })

      } catch (error) {
        this.$log.error(error);
        this.error = "Password reset failed";
      }
    },
  },
};
</script>