<template>
  <fragment>
    <div class="grid">
      <div class="col-8">
        <h2>Documents</h2>
      </div>
      <div class="col-4 flex-right"><a class="button secondary" @click="addDocButton">Add Document</a></div>
    </div>
    <documents-table :no-locations="true" 
                     :tableid="'DocumentsTable'" 
                     :document-templates="templatesWithOverrides" 
                     @locationModified="getLocationDocuments"
                     :loading="loader"
                     />
    <add-document-modal :current-client="clientID" :selected-templates="templatesWithOverrides" :location="true" :name="clientName"  @documentModified="getLocationDocuments" />
  </fragment>
</template>
<script>
import micromodal from "micromodal";
import DocumentsTable from "@/modules/shared/documents/DocumentsTable";
import AddDocumentModal from "@/modules/shared/documents/AddDocumentModal";
import { Fragment } from "vue-fragment";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";

export default {
  name: "LocationDocuments",
  components: {
    AddDocumentModal,
    DocumentsTable,
    Fragment,
  },
  props: {
    locationName: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      documentTemplateUse: [],
      templates: [],
      overrides: [],
      loading:false
    };
  },
  computed: {
    loader () {
      return this.loading;
    },

    templatesWithOverrides() {
      return this.templates
    },
    clientID() {
      return config.CLIENT_ID;
    },
    clientName() {
      return config.CLIENT_NAME;
    }
  },
  mounted() {
    micromodal.init({
      onShow: function () {
        document.body.classList.add("no-scroll");
      },
      onClose: function () {
        document.body.classList.remove("no-scroll");
      },
    });
    if(this.$route.params.locationID)
    this.getLocationDocuments()
  },
  methods: {
    addDocButton() {
      micromodal.show("modal-add-document", {});
    },
    getLocationDocuments() {
      this.loading=true;
      restApi.post('/document/getLocationDocs',encodeWithParam({clientID:config.CLIENT_ID,locationID:this.$route.params.locationID})).then((data)=>{
        this.templates = data.data.result;
        this.loading=false;
      })
    }
  },
};
</script>
