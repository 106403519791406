<template >
  <base-modal :id="$props.id" :key="$props.modalKey" :class="$props.partnerPageInsuranceMicroModal ? 'insurance-preview-model-wrapper is-open' : 'insurance-preview-model-wrapper'">
    <span class="close-button" @click="cancel"><i class="icon-x"></i></span>
    <div class="preview-insurance-modal-content" :key="$props.modalKey">
      <div class="module">
        <h4>Validate Auto Insurance</h4>
        <p>Please verify the image is a valid insurance card and the expiration date in the submitted image matches the expiration date below.</p>
        <div v-if="showInsuranceImage">
          <div v-if="$props.data.insuranceImageURL !== null" class="viewer-wrapper">
            <div v-if="isInsuranceLoading" class="loading loader-container">
              <Loader />
            </div>
            <div v-else-if="$props.data.isPDF">
              <vue-pdf-embed :source="pdfPreview" />
            </div>
            <viewer v-else ref="viewer" :options="options" :images="images" class="viewer" @inited="initialized">
              <template >
                <img :class="!imageLoaded && 'hide-img-loading'" :src="$props.data.insuranceImageURL"  alt="Image Preview" @load="insuranceImageLoaded" />
                <div v-if="!imageLoaded">Loading the image...</div>
              </template>
            </viewer>
            <a class="button secondary" @click="removeImage">Remove Image</a>
          </div>
          <div v-else class="well upload-block">
            <file-upload label="Choose Image to Upload" :filename="'Insurance'" :uploaded-for="candidateUserId" @change="updateInsuranceImage" :data="this.$props.data"></file-upload>
          </div>
        </div>
        <div v-else class="show-image-cont">
          <button class="button" @click="previewInsuranceImage">Show Image</button>
        </div>
        
        <div style="padding: 10px 0px">
          <label>Auto Insurance Expiration</label>
          <Datepicker :disabled-dates="disabledDates" v-model="insuranceExpiry"></Datepicker>
        </div>
        <!-- <div class="input-inline valid-checkbox">
          <div class="checkbox-custom1">
            <input id="invoice-archive-3" type="checkbox" :disabled="!expirationDate || !imagePreview" @click="validateInsurance" />
            <label for="invoice-archive-3"> </label>
          </div>
          <div>
            <p>Valid</p>
            <label>The expiration date entered matches the one in the image</label>
          </div>
        </div> -->
      </div>
      <save-cancel emit-cancel save-label="Validate" cancel-label="Invalidate" @onSave="submit" @onCancel="invalidateInsurance" :saveEnabled="isValidateEnabled && !isInsuranceLoading" :cancelEnabled="!isInsuranceLoading && isValidateEnabled"/>
    </div>
  </base-modal>
</template>
<style>
.insurance-preview-model-wrapper .upload-block {
  margin-bottom: 15px;
}
.insurance-preview-model-wrapper .date-pick-expiry {
  margin-bottom: 15px;
}
</style>
<script>
import { BaseModal, SaveCancel, FileUpload } from "@/components";
import { DateTime } from "luxon";
import micromodal from "micromodal";
import Datepicker from "vuejs-datepicker";
// import DatePicker from 'vue2-datepicker';
import {FunnelStatus} from "@/util/funnelProgress";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/Base64Encoding";
import { component as Viewer } from "v-viewer";
import { mapGetters } from "vuex";
import { Loader } from "@/components";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';


export default {
  components: {
    FileUpload,
    SaveCancel,
    BaseModal,
    Viewer,
    Datepicker,
    Loader,
    VuePdfEmbed
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    id: {
      type: String,
      required: false, 
      default: "modal-insurance-expiration",
    },
    modalKey: {
      type: Number,
      required: false,
      default: 0
    },
    partnerPageInsuranceMicroModal: {
      type: Boolean,
      required: false,
      default: false
    },
    isInsuranceLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  }, 
  data() {
    return {
      imageLoaded:false,
      options: {},
      imageUrl:'',
      disabledDates: {
          to: new Date(Date.now() - 8640000)
        },
      tempInsuranceExpiry: null,
      tempInsuranceFileID: null,
      isValidateEnabled: false,
      insuranceExpiry: null,
      showInsuranceImage: false
    };
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler: function(newVal) {
        this.insuranceExpiry = newVal.insuranceExpiry;
        // this.insuranceExpiry=DateTime.fromISO(newVal.insuranceExpiry, {zone: 'America/Los_Angeles'}).toFormat("dd MMM yyyy")
        if(!this.tempInsuranceExpiry || this.tempInsuranceExpiry === 'Invalid DateTime') {
          this.tempInsuranceExpiry = newVal.insuranceExpiry;
        }
        if(!this.tempInsuranceFileID) {
          this.tempInsuranceFileID = newVal.insuranceFileId;
        }
        this.isValidateEnabled = (Boolean(this.insuranceExpiry) && Boolean(newVal.insuranceImageURL) && this.insuranceExpiry !== 'Invalid DateTime');
      }
    },

    insuranceExpiry: {
      handler: function(newVal) {
        this.$props.data.insuranceExpiry = this.insuranceExpiry;
        // let dt=new Date(this.insuranceExpiry)
        // this.$props.data.insuranceExpiry=DateTime.fromISO(this.insuranceExpiry, {zone: 'America/Los_Angeles'}).toFormat("dd MMM yyyy")
        // this.$props.data.insuranceExpiry = `${dt.getFullYear()}-${(dt.getMonth()+1).toString().padStart(2,'0')}-${dt.getDate().toString().padStart(2,'0')}`
        this.isValidateEnabled = (Boolean(this.$props.data.insuranceImageURL) && Boolean(newVal) && this.insuranceExpiry !== 'Invalid DateTime');
      }
    },

    isFlyoutShowing: {
      handler: function(newVal) {
        if(!newVal) {
          this.tempInsuranceExpiry =  null;
          this.tempInsuranceFileID =  null;
          this.isValidateEnabled =  false;
          this.insuranceExpiry =  null;
          this.showInsuranceImage = false;
        } 
      }
    }
  },
  computed: {
    ...mapGetters(["isFlyoutShowing"]),
    locationID() {
      return this.$props.data.locationID
    },
    images() {
      return this.imagePreview ? [this.imagePreview] : [];
    },
    imagePreview() {
      return this.$props.data.insuranceImageURL;
    },
    pdfPreview (){
      return this.$props.data.insuranceImageURL ?? null;
    },
    candidateUserId() {
      return this.$props.data.partnerUserID;
    },
    expirationDate() {
      return (this.$props.data.insuranceExpiry != "Invalid DateTime") ? this.$props.data.insuranceExpiry : null;
     // return this.$props.data.insuranceExpiry;
    },
  },

  mounted() {
    this.insuranceExpiry = this.$props.data.insuranceExpiry;
  },

  methods: {
    previewInsuranceImage(){
      this.showInsuranceImage = true;
    },
    candidateProgressObject(status) {
      return {
        assignedOS: this.$props.data.specialist ? this.$props.data.specialist : null,
        partnerID: this.$props.data.partnerID,
        stepStatus: status
      };
    },
    invalidateInsurance() {
      let obj = this.candidateProgressObject(FunnelStatus.INSURANCE_IMAGE_INVALID);
      this.updateCandidateProgress(obj).then(()=> {
        restApi.put('/partner/updateInsuranceInvalidated', encodeWithParam({ partnerID: this.$props.data.partnerID, insuranceExpiry: this.tempInsuranceExpiry, fileId: this.tempInsuranceFileID}));
        this.$toast.error("Insurance Invalidated");
        let customObj = {
          phone: this.$props.data.phone,
          text: encodeURIComponent(`Spark Driver app: We cannot accept the proof of auto insurance you uploaded. Acceptable proof of insurance is a clear image that includes your name, expiration date, and vehicle. You can log in to your Spark Driver profile to upload new proof of insurance here:https://bit.ly/3pAkw07`),
          partnerID: this.$props.data.partnerID,
          userID: this.$props.data.userID,
        };
      this.insuranceExpiry = this.tempInsuranceExpiry;
      this.$props.data.insuranceExpiry = this.tempInsuranceExpiry;
      restApi.post('/onboard/sendSMS',encodeWithParam(customObj))
       this.cancel()
      })
    },
    updateCandidateProgress(candidateProgress) {
      return restApi.post("/onboard/saveCandidateStatus", encodeWithParam(candidateProgress));
    },
    validateInsurance() {
      this.valid = !this.valid;
      return this.valid
    },
    updateDate(value) {
      this.expirationDate = value;
    },
    submit() {
      let textMsg;
      if(this.$props.data.externalID){
        textMsg = encodeURIComponent(`Spark Driver app: Thank you for uploading your proof of auto insurance. It was reviewed and accepted. Your information is now updated in our system. If Spark Now was disabled in your app, you will be able to turn it on in the next 24 hours.`)
      }
      else{
        textMsg = encodeURIComponent(`Spark Driver app: Thank you for uploading your proof of auto insurance. It was reviewed and accepted. Your information is now updated in our system`)
      }
        let customObj = {
          phone: this.$props.data.phone,
          text: textMsg,
          partnerID: this.$props.data.partnerID,
          userID: this.$props.data.userID,
        };
      this.tempInsuranceExpiry = this.formatDate(this.$props.data.insuranceExpiry);
      this.tempInsuranceFileID = this.$props.data.insuranceFileId;
      restApi.post('/onboard/sendSMS',encodeWithParam(customObj))
      this.$emit("onSave", { expirationDate: this.$props.data.insuranceExpiry });
    },
    cancel() {
      this.showInsuranceImage = false;
      micromodal.close("modal-insurance-expiration");
      this.$emit("refresh", {});
      this.$emit("onCancel");
    },
    parseDate(dateString, format) {
      return DateTime.fromFormat(dateString, format).toJSDate();
    },
    initialized(viewer) {
      this.$viewer = viewer;
    },
    removeImage() {
      this.insuranceExpiry = null;
      // TODO determine what needs to pass back on this event
      this.$emit("onRemove");
    },
    isPastDate(date) {
      return DateTime.fromJSDate(date) < DateTime.utc();
    },
    updateInsuranceImage(data) {
      this.$props.data.insuranceFileId=data.fileID;
      this.$props.data.insuranceImageURL=data.fileURL;
      this.$emit("imageChanged",data);
    },
    insuranceImageLoaded(){
      this.imageLoaded = true;
    },
    formatDate(date) {
      if (typeof date == "string" || !date) {
        return date;
      } else if(typeof date == "object"){
        return DateTime.fromISO(date.toISOString()).toFormat("dd MMM yyyy");
      }
      return date;
    }
  },
};
</script>
<style scoped>
  .hide-img-loading{
    opacity: 0;
  }
  .checkbox-custom1 {
    display: flex;
    margin-bottom: 24px;
    min-height: 18px;
    padding: 6px;
  }
  .close-button {
    position: relative; 
    font-size: 25px;
    right: -100%;
    bottom: 30px;
  }
  .show-image-cont{
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loader-container {
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>