<template>
  <base-modal id="modal-add-partner">
    <div class="module">
      <h4>Add Partner Modal</h4>
      <form class="module-form" @submit.prevent="submitForm">
        <div class="module">
          <form-input v-model="email" label="Candidate Email" name="Candidate Email" type="email" required />
        </div>
        <transition name="fade">
          <button v-if="success" class="button secondary">Sent!</button>
          <button v-else type="submit" class="button" :diabled="!validEmail">Submit</button>
        </transition>
      </form>
      <p></p>
    </div>
  </base-modal>
</template>

<script>
import { SEND_EMAIL } from "@/modules/shared/communications/graph/mutations";
import micromodal from "micromodal";
import { FormInput, BaseModal } from "@/components";

export default {
  name: "AddPartnerModal",
  components: { FormInput, BaseModal },
  props: {
    clientName: {
      type: String,
      required: true,
    },
    locationName: {
      type: String,
      required: true,
    },
    locationId: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      email: "",
      success: false,
      regex: new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?", "g"),
    };
  },
  computed: {
    validEmail() {
      const test = this.regex.test(this.email);
      return test;
    },
  },
  methods: {
    submitForm() {
      const userID = this.$store.getters.getUserID;
      //TODO: Update from email address
      const objects = {
        userID: userID,
        from: "mlanders@ddiwork.com",
        html: `Hi,<br><br> Thank you for your interest in signing up with DDI. Please use the following link to sign up.<br> <br><a href="${
          config.VUE_APP_PARTNER_PORTAL_BASE_URL || process.env.VUE_APP_PARTNER_PORTAL_BASE_URL
        }/signup?locationId=${this.locationId}">${this.clientName} - ${this.locationName} </a> <br><br>
        Delivery Drivers Inc.<br>`,
        subject: `DDI Partner Signup - ${this.clientName} `,
        to: this.email,
      };
      return this.$apollo
        .mutate({
          mutation: SEND_EMAIL,
          variables: {
            objects,
          },
        })
        .then(() => {
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 2000);
          setTimeout(() => {
            micromodal.close("modal-add-partner");
            this.email = "";
          }, 2500);
        });
    },
  },
};
</script>
