<template>
  <div>
    <h2>Text Messages</h2>
    <data-table
      :headers="headersText"
      :entries="communicationsText"
      :pagination-total-count="totalCount"
      :loading="textLoader"
      :pagination-per-page="perPage"
      :pagination-current-page="currentPage"
      @onPaginate="onPaginate($event)"
    >
      <template #to="{ item }">{{ item.to.toString() | formatPhone }}</template>
      <template #status="{ item }">
        <a v-if="item.externalIDFull" :href="`https://app.sendgrid.com/email_activity/${item.externalIDFull}`" target="_blank">{{ item.externalStatus }}</a>
        <span v-else>{{ item.externalStatus }} </span>
      </template>
      <template #createAt="{ item }">{{ item.created_at | formatDateTimePacific }}</template>
      <template #updatedAt="{ item }">{{ item.updated_at | formatDateTimePacific }}</template>
      <template #sentby="{ item }">{{ item.sentby }}</template>
    </data-table>
  </div>
</template>
<script>
import { DataTable, TableHeader } from "@/components";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";


export default {
  name: "TextCommunications",
  components: { DataTable },
  props: {
    partnerId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      communicationsText: [],
      totalCount: 0,
      currentPage: 0,
      offset: 0,
      perPage: 50,
      textLoader:true,
    };
  },
  computed: {
    headersText() {
      return [
        new TableHeader({
          label: "To",
          name: "to",
          sortable: true,
        }),
        new TableHeader({
          label: "Status",
          name: "status",
          sortable: true,
        }),
        new TableHeader({
          label: "Created At",
          name: "updatedAt",
          direction: "desc",
          sortable: true,
          sortFunction: () => (item1, item2) => {
            return new Date(item1.created_at) - new Date(item2.created_at);
          },
        }),
        new TableHeader({
          label: "Updated At",
          name: "updatedAt",
          sortable: true,
          sortFunction: () => (item1, item2) => {
            return new Date(item1.created_at) - new Date(item2.created_at);
          },
        }),
        new TableHeader({
          label: "Sent By",
          name: "sentby",
          sortable: true,
          cellContents: (item) => `${item.sentby}`,
        }),
      ];
    },
  },
  methods: {
    onPaginate(event) {
      this.currentPage = Number(event.pageNumber);
      this.offset = event.offset;
    },
    external(item) {
      return item.externalIDFull ? item.externalIDFull : item.externalID;
    },
    async textCall(){
      const obj = { 
        partnerID: this.partnerId,
        commType: 'text'
        }
        await restApi.post('onboard/getComms', encodeWithParam(obj))
        .then(data => {
          this.totalCount = data.data.rowCount
          this.communicationsText = data.data.result
          this.textLoader = false
        });
    }
  },
    mounted(){
      this.textCall();
  }

  
};
</script>
