<template>
  <fragment>
    <div class="page-header">
      <div class="header-contents">
        <h1>Onboarding &amp; Maintenance</h1>
        <pod-switcher :employees="employees" />
      </div>
      <div class="header-aside">
        <search />
      </div>
    </div>
    <tabs :tabs="onboardingTabs" :starting-tab="1">
      <template #locations><onboarding-locations /></template>
      <template #candidates><onboarding-candidates /></template>
      <template #partners><onboarding-partners /></template>
    </tabs>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import { Search, PodSwitcher, Tabs } from "@/components";
import { OnboardingLocations, OnboardingCandidates, OnboardingPartners } from "@/modules/admin/onboarding";
//import { GET_ONBOARDING_SPECIALISTS } from "@/modules/admin/onboarding/graph/subscriptions";
import { restApi } from "@/http/http.config";

export default {
  name: "OnboardingPage",
  components: { Tabs, Fragment, Search, PodSwitcher, OnboardingLocations, OnboardingCandidates, OnboardingPartners },
  data: () => ({ employees: [] }),
  mounted() {
    restApi.get('/onboard/getOS').then((data)=>{
      if(data.data.rowCount > 0) {
        this.employees = data.data.result.map((employee) => ({ id: employee.id, name: `${employee.firstName} ${employee.lastName}`.trim(), role: employee.role, status: employee.status }));
      }
    })
  },
  computed: {
    onboardingTabs() {
      return [
        { key: "locations", label: "Locations" },
        { key: "candidates", label: "Candidates" },
        { key: "partners", label: "Partners" },
      ];
    },
  },
  // apollo: {
  //   employees: {
  //     query: GET_ONBOARDING_SPECIALISTS,
  //     result({ data }) {
  //       this.employees = data.employees.map((employee) => ({ id: employee.user.id, name: `${employee.firstName} ${employee.lastName}`.trim(), role: employee.user.role }));
  //     },
  //   },
  // },
};
</script>
