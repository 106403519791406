<template>
  <add-edit-page>
    <template #header="{ validation }">
      <sticky-header title="New Client" classes = "margin-zero">
        <save-cancel :save-enabled="!validation.pristine && !validation.invalid && client.managers.length > 0" on-can @onSave="saveClient" />
      </sticky-header>
    </template>
    <client-form v-model="client" :managers="managers" @clientManagerChanged="updateClientManager" />
    <template #bottomContent="{ validation }">
      <p v-if="!validation.pristine && client.managers.length <= 0" class="error-message">You must assign a DM to create this Client</p>
      <save-cancel :save-enabled="!validation.pristine && !validation.invalid && client.managers.length > 0" on-can @onSave="saveClient" />
    </template>
  </add-edit-page>
</template>

<script>
import { timeZones } from "@/util/timeZones";
import { validatePhoneNumber } from "@/util/validator";
import ClientForm from "@/modules/admin/clients/components/ClientForm";
import { ADD_MANAGERS_TO_CLIENT, CREATE_CLIENT, CREATE_FINANCE_TEMPLATE, INSERT_RECRUITMENT_DAYS } from "@/modules/admin/clients/graph/mutations";
import { FIND_ALL_MANAGERS } from "@/modules/admin/clients/graph/queries";
import { AddEditPage, SaveCancel, StickyHeader } from "@/components";
import { mapGetters } from "vuex";
// import { CREATE_INVOICE_TEMPLATE } from "@/modules/shared/finances/graph/mutations";

export default {
  name: "AddClient",
  components: { ClientForm, AddEditPage, StickyHeader, SaveCancel },
  props: {
    timeZones: timeZones,
    isValid: Boolean,
  },
  data: () => {
    return {
      flyout: false,
      client: {
        name: "",
        address: {
          data: {
            address1: "",
            address2: "",
            address3: "",
            city: "",
            state: "",
            postalCode: "",
            country: "US",
          },
        },
        startDate: "",
        timeZone: "",
        phone: "",
        ageRequirement: "",
        federalTaxID: "",
        isRecruiting: "",
        taxProgram: "",
        businessType: "",
        insuranceSetting: "",
        managers: [],
      },
      managers: [],
    };
  },
  watch: {
    phoneNumber(value) {
      validatePhoneNumber(value);
    },
  },
  computed: {
    ...mapGetters(["getFullName"]),
  },
  methods: {
    saveClient() {
      const { managers, ...clientDetail } = this.client;
      const timePeriod = { startDay: clientDetail.startDay, endDay: clientDetail.endDay, timezone: clientDetail.timeZone, type: "RECRUITING" };
      let clientConfiguration = {};
      if (this.client.mvrPackageName && this.client.mvrPackageName.length > 0) {
        clientConfiguration.mvrPackageName = this.client.mvrPackageName;
      }
      if (this.client.screeningPackageName && this.client.screeningPackageName.length > 0) {
        clientConfiguration.screeningPackageName = this.client.screeningPackageName;
      }

      clientDetail.phone = clientDetail.phone.replace(/\D/g, "");
      clientDetail.clientConfiguration = clientConfiguration;
      delete clientDetail.mvrPackageName;
      delete clientDetail.screeningPackageName;
      delete clientDetail.startDay;
      delete clientDetail.endDay;
      this.$apollo
        .mutate({
          mutation: CREATE_CLIENT,
          variables: {
            client: clientDetail,
          },
        })
        .then(({ data }) => {
          const clientID = data.insert_clients_one.id;
          this.$apollo
            .mutate({
              mutation: CREATE_FINANCE_TEMPLATE,
              variables: {
                financeTemplate: { clientID: clientID },
              },
            })
            .then(() => {
              this.$log.info("Default Finance Template Created");
            });
          const clientManagers = managers && managers.length > 0 ? managers.map((manager) => ({ clientID, managerID: manager.id, assignedBy: this.getFullName })) : [];
          this.$apollo.mutate({ mutation: ADD_MANAGERS_TO_CLIENT, variables: { managers: clientManagers } }).then(({ data }) => {
            const rowsInserted = data.insert.affected_rows;
            this.$log.info(`Added ${rowsInserted} managers to client`);
          });
          return clientID;
        })
        .then(async (clientID) => {
          timePeriod.clientID = clientID;
          await this.$apollo.mutate({ mutation: INSERT_RECRUITMENT_DAYS, variables: { timeperiod: timePeriod } });
          this.$router.push({ name: "client", params: { id: clientID }, hash: "#clientSetup" });
        });
    },
    updateClientManager(itemChangedEvent) {
      this.client.managers = itemChangedEvent.items;
    },
  },
  apollo: {
    managers: {
      query: FIND_ALL_MANAGERS,
      update: (data) => data.employees.map((employee) => ({ id: employee.id, name: `${employee.firstName} ${employee.lastName}`.trim(), role: employee.user.role, userID: employee.user.id })),
    },
  },
};
</script>
