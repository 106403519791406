<template>
  <div class="spread">
    <div style="display:flex">
      <div class="module-form">
      <h3>Employee Info</h3>
      <div class="employee-form-row">
        <form-input :value="value.firstName" name="firstname" label="First Name" required rules="required" @input="updateEmployee('firstName', $event)" />
        <form-input :value="value.lastName" name="lastname" label="Last Name" required rules="required" @input="updateEmployee('lastName', $event)" />
      </div>
      <div class="employee-form-row">
        <form-input :value="value.phone" name="phone" label="Phone" :mask=true @input="updateEmployee('phone', $event)" />
        <form-input :value="value.email" name="email" label="Email" required :rules="`required|email|emailExists`" type="email" @input="updateEmployee('email', $event)" />
      </div>

      <div class="employee-form-role">
      <h3>Employee Role</h3>
      <form-select
        key="code"
        :value="value.role"
        label="Role"
        name="role"
        required
        default-choice="Choose Role"
        :options="roles"
        option-label="label"
        option-value="code"
        rules="required"
        @input="updateEmployee('role', $event)"
      />
    </div>
    </div>
      <div class="module-form-right" v-if="getPermission('preAssignZone')">
        <h3>Assigned Locations</h3>
        <div class="search-container-emp">
         <input
          v-model="searchTerm"
          @input="getLocations"
          type="search"
          placeholder="Type to search"
        />
    <div class="emp-loc-search">
        <button v-if="searchTerm.length === 0" disabled><span class="icon-search padding-top-4"></span></button>
        <button v-else @click.prevent="clearSearch"><span class="icon-x-circle padding-top-4"></span></button>
    </div>

    <div class="searchSubContainerEmp">
      <ul class="searchResult" v-if="searchTerm.length != 0" @click.prevent="clearSearch">
        <li v-for="result in locationsList" :key="result.id">
          <div class="searchResultRow">
              {{ result.name }} <span><a style="    display: flex; align-content: center" @click="assignLocation(result.name, result.id)"><span v-if="result.assigned" style="width: 20px; margin-top: -2px;"> <img src="@/assets/check.svg"> </span>Assign</a></span>
          </div>
        </li>
      </ul>
    </div>
  </div>
    <div style="display:flex; margin-bottom: 10px;" >
      <span style="margin-left: 15px">Locations: {{locationCount}} </span>
      <span style="margin-left: 68px;">Total Drivers: {{driverCount}}</span>
    </div>
      <data-table
      :headers="headers"
      :entries="selectedLocations"
    >
      <template #noContents>
        <p>No locations has been assigned for this employee yet .....</p>
      </template>
      <template #name="{ item }">
        {{ item.name }}
      </template>
      <template #drivers="{ item }">
        {{ item.drivers }}
      </template>
        <template #remove="{ item }">
        <a><span class="icon-x-circle padding-top-4" @click="removeItem(item.locationId)"></span></a>
      </template>
    </data-table>
    </div>
</div>
<delete-employee-location-modal :location="location" @removeLocation="removeLocation"/>
  </div>
  
</template>
<script>
import micromodal from "micromodal";
import DeleteEmployeeLocationModal from "@/modules/admin/employees/individuals/DeleteEmployeeLocationModal";
import FormInput from "@/components/forms/fields/FormInput";
import FormSelect from "@/components/forms/fields/FormSelect";
import * as Roles from "@/security/rolesHelper";
import { extend } from "vee-validate";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/Base64Encoding";
import { DataTable, TableHeader } from "@/components";
import { mapGetters } from "vuex";


let employeeEmail = '';

export default {
  name: "EmployeeForm",
  components: { 
    FormSelect, 
    FormInput,
    DataTable, 
    DeleteEmployeeLocationModal
    },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data(){
    return {
      searchTerm: "",
      locationsList: [],
      selectedLocations: [],
      location: [],
      allowedRoles: ["ACCOUNT_MANAGER", "ONBOARDING_SPECIALIST", "DEPARTMENT_MANAGER", "REGIONAL_MANAGER"]
      // assignedLocations: []
    }
  },
  computed: {
    ...mapGetters(["getPermission"]),
     headers() {
      return [
        new TableHeader({ headerClass: "cell50", cellContents: "name", name: "name", sortable: true }),
        new TableHeader({ headerClass: "cell50", cellContents: "drivers", name: "drivers", sortable: true }),
        new TableHeader({ headerClass: "cell30", cellContents: "remove", name: "remove", sortable: true })
        ]
    },

     driverCount(){
          let result = this.selectedLocations.map((item) => parseInt(item.drivers))
          return result.reduce((a, b) => a+b, 0)
      },

      locationCount(){
              return this.selectedLocations.length
      },
    roles() {
      return Roles.getRolesWithCodes();
    },
    userID() {
      if (this.$props.value && this.$props.value.userID) {
        return this.$props.value.userID;
      }
      return null;
    },
  },
  mounted() {
    // this.employeeMappingCount();
    employeeEmail = this.$props.value.email;
    extend("emailExists", {
      message: (field) => `${field} already exists`,
      validate: value => {
        if (employeeEmail === value) return Promise.resolve({valid: true})
        const param = { username: value }
        return restApi.post('employee/checkUserName', encodeWithParam(param))
          .then(data => {
            return Promise.resolve({
              valid: data.data.rowCount !== 1
            })
          });
      },
    });

     restApi.post('onboard/getEmpLocationsById', encodeWithParam({employeeID:this.$route.params.id}))
     .then((data)=>{
       this.selectedLocations = data.data.result;
      })
  },
  methods: {
    updateEmployee(key, value) {
      this.$emit("input", { ...this.$props.value, [key]: value });
    },
    getLocations(event){
    const locationParam = {searchTerm : `%${event.target.value}%`}
         restApi.post('onboard/locationSearch', encodeWithParam(locationParam))
        .then(data => {
          this.locationsList = data.data.result
       if(this.selectedLocations.length > 0){
       
           this.selectedLocations.map(item1 => {
            this.locationsList.map(item2 => {
                if(item1.locationId == item2.id){
                  item2.assigned = true
                }
              })
          })
       }
        });
    },
    assignLocation(name, id){
        const param = {id : id}
         restApi.post('onboard/locationParterCount', encodeWithParam(param))
        .then(data => {
          let obj = {locationId: id, name: name, drivers: data.data.result[0].count}
           let index = this.selectedLocations.findIndex(item => item.locationId == id)
              if(index == -1){
                this.selectedLocations.push(obj)
       
              }
        if(this.$route.params.id){
              let param = {
              employeeId: this.$route.params.id,
              locations: this.selectedLocations,
              role: this.$props.value.role
            }
            restApi.post('employee/addEmployeeLocations', encodeWithParam(param))
              .then(() => {
              });
      
        }

        else {
         this.$props.value.locations = this.selectedLocations
              this.$emit("assignedLocation", this.selectedLocations)
        }
        });

      },
      clearSearch(){
          this.searchTerm = ""
      },      
      removeItem(id){
         micromodal.show("modal-employee-delete-location", {});
         let locationObj = this.selectedLocations.filter( item => item.locationId == id)
         this.location = locationObj[0]
          // let index = this.selectedLocations.findIndex(item => item.locationId == id)
          // this.selectedLocations.splice(index,1)
          // this.$emit("assignedLocation", this.selectedLocations)
      },
      removeLocation(){
        let param = {
          locationId: this.location.locationId,
          employeeId: this.$route.params.id
        }

           restApi.post('employee/deleteEmployeeLocations', encodeWithParam(param))
        .then(() => {
           restApi.post('onboard/getEmpLocationsById', encodeWithParam({employeeID:this.$route.params.id}))
            .then((data)=>{
              this.selectedLocations = data.data.result;
      })
             micromodal.close("modal-employee-delete-location");
        });
      }
  },
};
</script>
<style scoped>
  .module-form {
    width: 500px;
  }

  .module-form-right{
    flex: none;
    margin-left: 100px;
    width: 350px;
  }
  

  .employee-form-row {
    display: flex;
  }

  .employee-form-row > .field {
    width: 340px;
    margin-right: 20px;
  }

  .employee-form-role {
    margin-top: 100px;
    width: 340px;
  }

button {
  height: 35px;
}
.searchResultNone {
  /* position: absolute; */
  right: 11px;
  max-height: 500px;
  overflow: scroll;
  z-index: 100;
  list-style: none;
  min-width: 216px;
  width: auto;
  padding: 10px;
  background-color: #fdfdfd;
  margin-top: 3px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  -webkit-box-shadow: 0 0 2px 1px rgba(189, 189, 189, 1);
  -moz-box-shadow: 0 0 2px 1px rgba(189, 189, 189, 1);
  box-shadow: 0 0 2px 1px rgba(189, 189, 189, 1);
}
.search-container-emp {
  padding: 5px;
    margin-bottom: 8px;
  position: relative;
}

.search-container-emp input {
     width: 343px;
     margin-left: -2px;
}

.search-container-emp button {
    right: 268px;
    margin-top: -36px;
    background: none;
    border: none;
    font-size: 20px;
    font-size: 1.25rem;
    color: var(--gray-40);
    transition: color 0.2s linear;
}
.searchSubContainerEmp {
  width: 340px;
}

.searchResult {
    right: 11px;
    max-height: 500px;
    overflow: scroll;
    z-index: 100;
    list-style: none;
    min-width: 280px;
    width: auto;
    padding-left: 0;
    background-color: #fdfdfd;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    box-shadow: 0 0 2px 1px rgb(189 189 189);
}
.searchResultRow {
  display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 8px;
}
.searchResult li {
  margin-bottom: 0;
}
.searchResult li a {
  width: 100%;
  padding: 10px 5px;
  text-decoration: none;
}
.searchResultRow:hover {
  background-color: #eaeaea;
}
.inline-header {
  background-color: #797979;
  color: white;
  padding: 5px;
}
.padding-top-4 {
  padding-top: 4px;
}
.link-color {
  color: var(--link);
}
.emp-loc-search{
  float: right;
  position: relative;
  margin-top: -25px;
}
</style>
