<template>
  <div class="module-form">
    <h3>Basic Info</h3>
    <form-input :value="value.name" :name="nameLabel" :label="nameLabel" required @input="update('name', $event)" />
    <form-select
      :value="value.timeZone"
      label="Time Zone"
      name="Time Zone"
      :options="timeZones"
      option-key="value"
      option-value="value"
      option-label="displayString"
      :required="true"
      @input="update('timeZone', $event)"
    />
    <div class="field">
      <date-pick :value="value.startDate" display-format="MM/DD/YYYY" :input-attributes="{ readonly: true }" @input="update('startDate', $event)">
        <template #default="{ inputValue, toggle }">
          <validation-provider v-slot="{ errors }" name="Start Date" mode="eager" rules="required">
            <label for="startDate">Start Date*</label>
            <div class="input-has-icon">
              <div class="input-icon" @click="toggle"><i class="icon-calendar"></i></div>
              <input id="startDate" :value="inputValue" type="text" name="Start Date" placeholder readonly required @click="toggle" />
              <p class="error-message">{{ errors[0] }}</p>
            </div>
          </validation-provider>
        </template>
      </date-pick>
    </div>
    <form-input :value="value.phone" :mask="true" name="Office Phone" label="Public/Office Phone" required rules="required|phone" type="phone" @input="update('phone', $event)" />
  </div>
</template>
<script>
import { timeZones } from "@/util/timeZones";
import { ValidationProvider } from "vee-validate";
import DatePick from "vue-date-pick";
import { FormSelect, FormInput } from "@/components";

export default {
  name: "BasicInfo",
  components: { FormInput, FormSelect, ValidationProvider, DatePick },
  props: {
    value: { type: Object, required: true, default: () => {} },
    nameLabel: { type: String, required: false, default: "Client Name" },
  },
  data() {
    return {
      timeZones: timeZones,
    };
  },
  methods: {
    update(key, value) {
      this.$emit("input", { ...this.$props.value, [key]: value });
    },
  },
};
</script>
