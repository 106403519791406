<template>
  <base-modal id="adoch-csv-modal" :full="true">
    <div class="adoch-header">
      <h2 style="color:#fff">{{ csvFileName }} - {{ csvUploadedDate }}</h2>
      <div class="button-group">
        <div style="display: flex;gap: 10px;">
          <button class="button-secondary" @click="cancel">Back</button>
          <button v-if="!loader && csvEntries.length > 0" class="button-secondary" :disabled="csvStatus !== 'Ready for Disbursement' && !isStatus" @click="initiatePaymentMenu()">Initiate Disbursement</button>
        </div>
      </div>
    </div>
     <!-- Error Menu -->
    <div v-if="errorMenu && errorMessage.length > 0" :style="{ width: '25%', position: 'absolute', top: `${errorMenuTop-100}px`, right:`180px`,zIndex:6, boxShadow: '2px 2px 9px 2px rgba(0,0,0,0.5)' }">
      <div class="table-content" style="maxHeight:350px">
        <button @click="hideErrorMenu" style="position: absolute; top: 4px; right: 0px; z-index:6">Close</button> 
        <table>
          <thead class="menu">
            <tr style="position:sticky;top:0px;background:#eee">
              <th  style="font-weight:900;padding-right: 35px;" colspan="4">Error Details for {{ partner_id }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in errorMessage" :key="item" style="background:#fff">
              <td class="box-size" style="text-align: center;color: red;">{{ item }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
     <!-- Popup -->
    <div v-show="isPopup" class="menu-card" style="height: auto; max-width: 600px; background:#eee; color: white; position: absolute;z-index: 6; border-radius: 8px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);"
        :style="{top:`90px`,left:`${500}px`,boxShadow: '2px 2px 9px 2px rgba(0,0,0,0.5)'}">
        <div style="padding: 15px;">
          <div style="font-size: 16px; font-weight: bold;color:black;margin-left:20px">{{PopupMessage}}</div>
          <div style="margin-top: 10px; margin-left:92px"><button @click="hidePopup" style="padding: 8px 15px; background: #fff; color: black; border: none; border-radius: 4px; cursor: pointer;">Okay</button></div>
        </div>
      </div>
      <!-- memo-message -->
      <div v-show="isMemo" class="menu-card" style="height: auto; max-width: 450px;padding: 10px ;background:#eee; color: white; position: absolute;z-index: 6; border-radius: 8px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);"
        :style="{top:`${errorMenuTop}px`,right:`${160}px`,boxShadow: '2px 2px 9px 2px rgba(0,0,0,0.5)'}">
        <div style="padding: 15px;">
          <div style="font-size: 16px; font-weight: bold;color:black;margin-left:20px">{{memoMessage}}</div>
          <!-- <div style="margin-top: 10px; margin-left:92px"><button @click="hidePopup" style="padding: 8px 15px; background: #fff; color: black; border: none; border-radius: 4px; cursor: pointer;">Okay</button></div> -->
        </div>
      </div>
       <!-- initiate payment -->
      <div v-if="isPayment" class="menu-card" style="height: auto; max-width: 600px; background:#eee; color: white; position: absolute;z-index: 6; border-radius: 8px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);"
        :style="{top:`90px`,left:`${500}px`,boxShadow: '2px 2px 9px 2px rgba(0,0,0,0.5)'}">
        <div style="padding: 40px;display: flex;flex-direction: column;gap: 15px;">
          <div style="font-size: 20px; font-weight: bold;color:black;margin-left:20px">{{paymentMessage}}</div>
          <div style="margin-top: 10px; margin-left:90px;display: flex;gap:15px">
            <button @click="initiatePayment" style="padding: 10px 25px; background: #fff; color: black; border: none; border-radius: 4px; cursor: pointer;">Disburse</button>
            <button @click="hidePaymentMenu" style="padding: 10px 25px; background: #fff; color: black; border: none; border-radius: 4px; cursor: pointer;">Cancel</button>
          </div>
        </div>
      </div>
      <!-- AMOUNT AND PARTNER COUNT -->
      <div class="main-container">
      <div v-if="!loader && csvEntries.length > 0" style="width: 50%;">
        <table class="partner-amount">
          <tr>
            <th class="head headings"><span class="head_cnt">Disbursement Details</span></th>
            <th class="head"><span class="head_cnt">ONE</span></th>
            <th class="head"><span class="head_cnt">ACH</span></th>
            <th class="head"><span class="head_cnt">BRANCH</span></th>
            <th class="head"><span class="head_cnt">HOLD</span></th>
            <th class="head"><span class="head_cnt">TOTAL</span></th>
          </tr>
          <tr>
            <th class="head headings">Disbursement</th>
              <td class="head">{{ payONE |formatMoney }}</td>
              <td class="head">{{ payACH | formatMoney }}</td>
              <td class="head">{{ payBRANCH |formatMoney}}</td>
              <td class="head">{{ totalHold | formatMoney }}</td>
              <td class="head">{{ totalToPay | formatMoney}}</td>
          </tr>
          <tr>
          <th class="head headings">Settlement</th>
            <td class="head">{{ paidONE | formatMoney}}</td>
            <td class="head">{{ paidACH |formatMoney}}</td>
            <td class="head">{{ paidBRANCH |formatMoney}}</td>
            <td class="head">{{ 0.00 | formatMoney}}</td>
            <td class="head">{{ totalPaid|formatMoney}}</td>
        </tr>
        </table>  
      </div>
      <div v-if="!loader && csvEntries.length > 0" style="width: 45%;">
        <table class="partner-count">
          <tr>
            <th class="head headings"><span class="head_cnt">Partner Details</span></th>
            <th class="head"><span class="head_cnt">ONE</span></th>
            <th class="head"><span class="head_cnt">ACH</span></th>
            <th class="head"><span class="head_cnt">BRANCH</span></th>
            <th class="head"><span class="head_cnt">HOLD</span></th>
            <th class="head"><span class="head_cnt">TOTAL</span></th>
          </tr>
          <tr>
          <th class="head headings" style="text-align: center;"><span class="content">Partner Count</span></th>
            <td class="head"><span class="content">{{ onePartner }}</span></td>
            <td class="head"><span class="content">{{ achPartner }}</span></td>
            <td class="head"><span class="content">{{ branchPartner }}</span></td>
            <td class="head"><span class="content">{{ holdPartner }}</span></td>
            <td class="head"><span class="content">{{ totalPartner }}</span></td>
          </tr>
        </table>         
      </div>
    </div>
    <div class="main-container">
    <div v-if="!loader && csvEntries.length > 0" style="width: 32%;margin-bottom: -60px;">
        <table style="margin-top: 20px; height: 90px;">
          <tr>
            <th class="head count-width"><span class="head_cnt">Disbursement</span></th>
            <th class="head count-width"><span class="head_cnt">Pending Disbursements</span></th>
            <th class="head count-width"><span class="head_cnt">Disbursed</span></th>
            <th class="head count-width"><span class="head_cnt">Held</span></th>
          </tr>
          <tr>
            <td class="head"><span class="content">{{ disburseCount }}</span></td>
            <td class="head"><span class="content">{{ pendingCount }}</span></td>
            <td class="head"><span class="content">{{ disbursedCount }}</span></td>
            <td class="head"><span class="content">{{ holdCount }}</span></td>
          </tr>
        </table>         
      </div>
    </div>
    <!-- csvStatus !== 'Fully Disbursed' && csvStatus !=='Partially Disbursed' -->
    <div style="position: relative;">
     <div class="searchbox" v-if="!loader && csvEntries.length > 0">
      <form style="padding-right: 30px;">
        <label>
          <input
            style="padding: 10px"
            v-model="searchTerm"
            @input="searchStr('searchItem')"
            type="search"
            placeholder="Partner Details"
            title="
              Partner: Partner ID,Name, Type, Action,Transaction ID, Tax Exempted, Primary Earnings Provider"
          />
        </label>
      </form>
      <div class="col-0.5 tooltip-i" style="padding: 0px;" @click="downloadAdhocCsv($event)">
        <a v-click-outside="hideMemo"><img src="@/assets/images/logos/download.svg" /></a>
        <span class="tooltip-text summary-tooltip-align">Click to download</span>
      </div>
    </div>
    <!-- ADHOC SUMMARY DATA TABLE -->
    <div class="table-container">
      <div class="sub-table" :class="getPagination()">
        <data-table :sticky-header="true" :entries="entriesToPass" :headers="csvDetailViewHeaders"
        :loading="loader" :remoteSort="true" :pagination-total-count="totalPageCount" :pagination-per-page="perPage" :pagination-current-page="currentPage"
        @onPaginate="fetchData($event)">
        <template #partner_id="{ item }">
          <table>
            <thead>
              <a @click="redirectProfile(item['partner_id'])"><tr>{{ item.partner_id}}</tr></a>
            </thead>
          </table>
        </template>
        <template #full_name="{ item }">
          <table>
            <thead>
            <tr>{{item.full_name}}</tr>
            </thead>
          </table>
        </template>
         <template #transaction_type="{ item }">
           <table>
            <thead>
              <tr>{{item.transaction_type}}</tr>
            </thead>
          </table>
         </template>
         <template #amount="{ item }">
          <table>
            <thead class="number-center">
              <tr>$ {{item.amount || 0}}</tr>
            </thead>
          </table>
         </template>
         <template #deposit_action="{ item }">
          <table>
            <thead>
              <tr>{{formatString(item.deposit_action)}}</tr>
            </thead>
          </table>
         </template>
         <template #transaction_id="{ item }">
          <table>
            <thead>
              <tr>{{item.transaction_id}}</tr>
            </thead>
          </table>
         </template>
         <template #transaction_date="{ item }">
          <table>
            <thead>
              <tr>{{item.transaction_date}}</tr>
            </thead>
          </table>
         </template>
         <!-- <template #previous_provider="{ item }">
          <table>
            <thead>
              <tr>{{ item.previous_provider }}</tr>
            </thead>
          </table>
         </template> -->
         <template #paid_reference_id="{ item }">
          <table>
            <thead>
              <tr>{{item.paid_reference_id}}</tr>
            </thead>
          </table>
         </template>
         <template #remarks="{ item }">
          <table>
            <thead>
              <tr>{{ item.remarks }}</tr>
            </thead>
          </table>
         </template>
         <template #reason_code="{ item }">
          <table>
            <thead class="number-center">
              <tr>{{ item.reason_code }}</tr>
            </thead>
          </table>
         </template>
         <template #curr_provider="{ item }">
          <table>
            <thead>
              <tr>{{ item.curr_provider }}</tr>
            </thead>
          </table>
         </template>
         <template #tax_exempted="{ item }">
          <table>
            <thead>
              <tr>{{ item.tax_exempted }}</tr>
            </thead>
          </table>
         </template>
         <template #error="{ item }">
          <table>
            <thead v-if="item.error">
              <a @click="showingErrorMessages(item, $event)"><tr>{{ "ERROR" }}</tr></a>
            </thead>
            <thead v-else>
              <tr>NO ERROR</tr>
            </thead>
          </table>
         </template>
         <template #disbursed_date="{ item }">
          <table>
            <thead>
              <tr>{{ item.disbursed_date }}</tr>
            </thead>
          </table>
         </template>
         <template #pay_status="{ item }">
          <table>
            <!-- <thead v-if="item.pay_status === 'HOLD'">
              <a v-click-outside="hideMemo" @click="showingMemoMessages(item,$event)"><tr>{{ item.pay_status }}</tr></a>
            </thead> -->
            <thead v-if="item.pay_status === 'PAID' || item.pay_status === 'PROCESSED'">
              <tr>{{ 'DISBURSED' }}</tr>
            </thead>
            <thead v-else>
              <tr>{{ item.pay_status }}</tr>
            </thead>
          </table>
         </template>
        </data-table>
      </div>
     </div>
    </div>
    <div v-if="loader"><span class="loading"><Loader /></span></div>
  </base-modal>
</template>
<script>
import { DataTable,TableHeader,Loader } from "@/components";
import micromodal from "micromodal";
import BaseModal from "@/components/modals/BaseModal";
import { reconciliationApi,reconciliation } from "@/http/http.config";
import { encodeWithParam } from "@/filters";
import ClickOutside from "vue-click-outside";
export default {
  name : "AdochCsvModal",
  components: { BaseModal,DataTable,Loader},
  directives: { ClickOutside },
  data: () => ({
    csvEntries : [],
    adochCsvModal : 'adochModalName',
    totalPaginationCount: 0,
    perPage: 50,
    currentPage: 1,
    offset: 0,
    loader : false,
    csv_dump_id : '',
    errorMenu : false,
    errorMenuTop : 0,
    errorMenuLeft : 0,
    errorMessage : '',
    payONE : 0,
    payACH : 0,
    payBRANCH : 0,
    paidONE : 0,
    paidACH : 0,
    paidBRANCH : 0,
    totalAmount : 0,
    totalHold : 0,
    totalPaid : 0,
    totalToPay : 0,
    partner_id : '',
    fullName : '',
    isStatus : true,
    isPopup : false,
    isPayment : false,
    PopupMessage : '',
    paymentMessage : "",
    onePartner : 0,
    achPartner : 0,
    branchPartner : 0,
    totalPartner : 0,
    holdPartner : 0,
    searchTerm : "",
    searchedItem : [],
    totalPagination : 0,
    totalPaginationCounts : 0,
    memoMessage : '',
    isMemo : false,
    disburseCount : 0,
    disbursedCount : 0,
    holdCount : 0,
    pendingCount : 0
  }),
  props : {
    csvDetailsId: {
      type: String,
      required: true,
      default: "",
    },
    csvFileName: {
      type: String,
      required: true,
      default: "",
    },
    csvUploadedDate : {
      type: String,
      required: true,
      default: "",
    },
    csvStatus : {
      type: String,
      required: true,
      default: "",
    },
    forceBool: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  // watch: {
  //   csvDetailsId: function(newVal) {
  //     console.log("newVal",newVal)
  //     this.csv_dump_id = newVal;
  //     this.csvDetails(newVal);
  //    }
  //   },
  watch: {
    forceBool() {
      this.csv_dump_id = this.csvDetailsId
      this.handleFileClicked(this.csvDetailsId);
    },
  },
  computed: {
    csvDetailViewHeaders() {
      return [
        new TableHeader({ headerClass: "cell2", label: "Partner ID", name: "partner_id", cellContents: "partner_id", cellClass: "padd-12" }),
        new TableHeader({ headerClass: "cell2", label: "Full Name", name: "full_name", cellContents:"full_name", cellClass: "padd-12" }),
        new TableHeader({ headerClass: "cell2", label: "Transaction Type", name: "transaction_type", cellContents: "transaction_type", cellClass: "padd-12" }),
        new TableHeader({ headerClass: "cell2", label: "Amount ($)", name: "amount", cellContents: "amount", cellClass: "padd-12" }),
        new TableHeader({ headerClass: "cell2", label: "Deposit Summary (Add/Correct)", name: "deposit_action", cellContents: "deposit_action", cellClass: "padd-12" }),
        new TableHeader({ headerClass: "cell2", label: "Generated Transaction ID", name: "transaction_id", cellContents: "transaction_id", cellClass: "padd-12" }),
        new TableHeader({ headerClass: "cell2", label: "Transaction Date", name: "transaction_date",  cellContents: "transaction_date",cellClass: "padd-12" }),
        //new TableHeader({ headerClass: "cell2", label: "Provider Request Reference Payable", name: "previous_provider",  cellContents: "previous_provider",cellClass: "padd-12" }),
        new TableHeader({ headerClass: "cell2", label: "Transaction ID", name: "paid_reference_id",  cellContents: "paid_reference_id",cellClass: "padd-12" }),
        new TableHeader({ headerClass: "cell2", label: "Deposit Summary Comments", name: "remarks",  cellContents: "remarks",cellClass: "padd-12" }),
        new TableHeader({ headerClass: "cell2", label: "Reason Internal", name: "reason_code",  cellContents: "reason_code",cellClass: "padd-12" }),
        new TableHeader({ headerClass: "cell2", label: "Current Primary Earnings Provider", name: "curr_provider",  cellContents: "curr_provider",cellClass: "padd-12" }),
        new TableHeader({ headerClass: "cell2", label: "Tax Exempt", name: "tax_exempted",  cellContents: "tax_exempted",cellClass: "padd-12" }),
        new TableHeader({ headerClass: "cell2", label: "Error", name: "error",  cellContents: "error",cellClass: "padd-12" }),
        new TableHeader({ headerClass: "cell2", label: "Disbursed Date", name: "disbursed_date",  cellContents: "disbursed_date",cellClass: "padd-12" }),
        new TableHeader({ headerClass: "cell2", label: "Disbursement Status", name: "pay_status",  cellContents: "pay_status",cellClass: "padd-12" }),
      ];
    },
    adochPaymentViewEntries() {
      return this.adochPaymentEntries;
    },
    // WILL GIVE ENTRIES RESPECTIVE OF CONDITIONS
    entriesToPass() {
      if ((this.searchTerm))  {
        return this.searchedItem;
      } else {
        return this.csvEntries;
      }
    },
    totalPageCount() {
    if ((this.searchTerm))  {
      return this.totalPagination;
      } else {
        return this.totalPaginationCounts;
      }
    },
  },
  methods: {
    handleFileClicked(fileID) {
      this.csvDetails(fileID);
    },
    fetchData(event) {
      this.loader = true;
      this.currentPage = Number(event.pageNumber);
      this.offset = event.offset;
      if(this.searchTerm) {
        this.searchStr('');
      } else{
        this.csvDetails('');
      }
    },
    // WILL GET ALL THE DETAILS THAT REQUIRED FOR UI
    async csvDetails (csv_dump_id) {
      if(csv_dump_id) {
        this.currentPage = 1,
        this.offset = 0,
        this.totalPaginationCount = 0
      }
      let param = {
        csv_dump_id : csv_dump_id || this.csv_dump_id,
        limit : this.perPage,
        offset : this.offset,
      }
      this.loader = true;
      let csv_dump = param.csv_dump_id
      const totals = (await reconciliationApi.post("/getTotalAmounts",encodeWithParam({csv_dump})))?.data;
      if(!this.payONE && !this.payACH && this.payONE <= 0 && this.payACH <=0) {
        this.isStatus = false;
      }
      this.payONE = totals.result[0].topay_one;
      this.payACH = totals.result[0].topay_ach;
      this.payBRANCH = totals.result[0].topay_branch;
      this.paidONE = totals.result[0].paid_one;
      this.paidACH = totals.result[0].paid_ach;
      this.totalHold = totals.result[0].total_hold;
      this.paidBRANCH = totals.result[0].paid_branch;
      this.totalAmount = totals.result[0].total_amount;
      this.totalPaid = totals.result[0].total_paid;
      this.totalToPay = totals.result[0].total_topay;

      const partner_count = (await reconciliationApi.post("/getPartnersCount",encodeWithParam({csv_dump})))?.data;
      this.onePartner = partner_count.result[0].one_partner;
      this.achPartner = partner_count.result[0].ach_partner;
      this.branchPartner = partner_count.result[0].branch_partner;
      this.holdPartner = partner_count.result[0].hold_partner;
      this.totalPartner = partner_count.result[0].total_count;

      const status_count = (await reconciliationApi.post("/getstatusCount",encodeWithParam({csv_dump})))?.data;
      this.disburseCount = status_count.result[0].disburse_count;
      this.disbursedCount = status_count.result[0].disbursed_count;
      this.holdCount = status_count.result[0].hold_count;
      this.pendingCount = status_count.result[0].pending_count;
      
      const result = (await reconciliationApi.post("/getUploadedCsvDetails", encodeWithParam({param})))?.data;
      this.csvEntries = result.result;
      this.totalPaginationCounts = Number(result?.rowCount);
      this.loader = false;
      },
    cancel() {
      this.errorMenu = false;
      this.isPopup = false;
      this.searchTerm = "";
      this.searchedItem = [];
      micromodal.close("adoch-csv-modal");
      document.body.style.overflow = "visible";
    },
    hidePopup() {
        this.isPopup = false;
    },
    hideMemo() {
      this.isMemo = false;
    },
    getPagination(){
      return this.loader || (this.totalPagination == 0 && this.searchTerm != '') ? 'data-table' : '';
    },
    formatString(str) {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    async showingErrorMessages (item,event) {
      this.partner_id = item.partner_id;
      this.fullName = item.full_name;
      let flattenArray = item.error.flat();
      this.errorMessage = flattenArray.filter(error => error !== null);
      this.errorMenuTop = event.target.getBoundingClientRect().top;
      this.errorMenuLeft = event.target.getBoundingClientRect().left-450;
      this.errorMenu = true;
    },
    async showingMemoMessages (item, event) {
      this.errorMenuTop = event.target.getBoundingClientRect().top-100;
      this.errorMenuLeft = event.target.getBoundingClientRect().left-480;
      let transaction_id = item.transaction_id;
      const result = (await reconciliationApi.post("/getMemoMessage", encodeWithParam({transaction_id: transaction_id})))?.data;
      this.memoMessage = result.result.memo;
      this.isMemo  = true;
    },
    async hideErrorMenu () {
        this.errorMenu = false;
        this.errorMessage = [];
      },
    async hidePaymentMenu () {
      this.isPayment = false
    },
    async initiatePaymentMenu () {
      this.paymentMessage = 'Are you sure to start the Disbursement';
      this.isPayment = true;
    },
    async downloadAdhocCsv (event) {
      this.errorMenuTop = event.target.getBoundingClientRect().top-100;
      this.errorMenuLeft = event.target.getBoundingClientRect().left-480;
      if(this.csvStatus =='Partially Disbursed'|| this.csvStatus =='Fully Disbursed') {
        const {data} = (await reconciliation.post("adhoc/getAdhocCSV", encodeWithParam({csv_dump_id: this.csv_dump_id})));
        let decodedData = JSON.parse(atob(data));
        if(decodedData.result.success){
          window.open(decodedData.result.rows, '_blank');
        } else {
          this.memoMessage = "Data is not availble, kindly contact Admin Team"
          this.isMemo = true;
        }
      } else {
        this.memoMessage = "Document will be availble, After a day of Disbursement Initialization"
        this.isMemo = true;
      }
    },
    // INITIATE THE PAYMENT
    async initiatePayment () {
      (await reconciliation.post("adhoc/updateUserId", encodeWithParam({userId: this.$store.getters.getUserID,csvId: this.csv_dump_id })))?.data;
      this.isPayment = false;
      await reconciliationApi.post("/updateStatus",encodeWithParam({status: "Disbursement Initialized",csvId: this.csv_dump_id}))?.data;
      this.$emit('modalClosed','closed');
      micromodal.close("adoch-csv-modal");
      document.body.style.overflow = "visible";
      const result = (await reconciliation.post("adhoc/initiateAdhocPayment", encodeWithParam({csvId: this.csv_dump_id})))?.data;
      if(result.success) {
        //(await reconciliation.post("adhoc/updateUserId", encodeWithParam({userId: this.$store.getters.getUserID,csvId: this.csv_dump_id })))?.data;
      } else {
        await reconciliationApi.post("/updateStatus",encodeWithParam({status: "Ready for Disbursement",csvId: this.csv_dump_id}))?.data;
      }
    },
    // Search method
    async searchStr(search) {
      if(search) {
        this.currentPage = 1,
        this.offset = 0;
      }
      if(this.searchTerm) {
      const partnerParam = {searchTerm : this.searchTerm,csv_dump_id: this.csv_dump_id, limit : this.perPage,offset : this.offset};
      const searchedResult = await reconciliationApi.post("/searchItem",encodeWithParam(partnerParam));
      this.searchedItem = searchedResult.data.result;
      this.totalPagination = Number(searchedResult.data?.rowCount);
      } else {
        this.searchedItem = [];
        this.csvDetails(this.csv_dump_id);
      }
      this.loader = false;
    },
    redirectProfile(partnerID) {
    document.body.style.overflow = "visible";
     //document.body.classList.remove("no-scroll");
    const routeData = this.$router.resolve({ name: 'partner', params: { id: partnerID } });
    window.open(routeData.href, '_blank');
    },
    // WILL PROVIDE DOLLAR SYMBOL
    formatMoney(amount, formatNotRequired = false) {
      amount == undefined || isNaN(amount) ? (amount = 0) : amount;
      if(formatNotRequired) return amount;
      return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(amount)
    },
  }
}
</script>
<style scoped>
 tbody tr:nth-child(odd) {
    background: none;
  }
  .table-container{
    padding: 16px 5px
  }
 .adoch-header{
    background-color: #3171ff;
    border-bottom: 0px;
    position: sticky;
    top: 0;
    z-index: 6;
    padding: 20px 0px 0px 50px;
 }
 .button-group {
    margin: 0px !important;
    padding: 0px 0px 20px 0px;
 }
 .button-secondary{
    padding: 15px 25px;
    font-size: 20px;
    font-weight: 600;
    border: none;
    border-radius: 6p
 }
 .number-center {
    text-align: center;
 }
 .table-content{
  overflow-y: auto;
  max-height: 150px;
}
.menu{
  background-color: #eee;
}
.heading{
  padding: 0 0;
  font-weight: 700;
  border-bottom: none;
  border-left: none;
  border-right: none;
}
.box-size{
  width: 300px;
}
th, td {
  border: 0.8px solid #ddd;
  padding: 8px;
  text-align: center;
  line-height: normal;
}
.container-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    margin-top: 20px;
    margin-bottom: -50px;
    padding-left: 35px;
  }

.amount-table {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ebf2ee;
  padding: 10px;
}
.amount-table > div {
  margin: 5px;
}
.outer-layer{
  padding: 20px;
  margin: 19px;
  background: white;
  text-align: center;
}
.head, .partner-amount {
  border: 1px solid black;
  border-collapse: collapse;
}
.partner-amount {
  margin-top: 20px;
  height: 100px;
}
.partner-count{
  margin-top: 20px;
  height : 113px
}
.head{
  padding: 6px;
  font-weight: 900;
  background-color: #eee;
}
.headings{
  width: 130px;
  font-weight: 900;
}
.count-width{
  width: 295px;
  font-weight: 900;
}
.main-container{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
}
.content{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.searchbox{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: -59px;
  justify-content: flex-end;
  width: 87%;
}
.data-table{
  margin-top: 55px;
}
.head_cnt{
  display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
</style>