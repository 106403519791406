<template>
  <fragment>
    <div class="grid space-between">
      <div class="col-12" v-if="cDisplayToast">
        <Message class="toast" @close="closeToast" :severity="toastSeverity"> {{toastMessage}}</Message>
      </div>
      <div class="col-5">
        <p class="label">Filters</p>
        <base-filter name="Partner Status" :options="partnerStatusFilterOptions" @onFilter="fetchFilteredPartners((selectedPartnerStatuses = $event))" />
        <base-filter name="Recent Partners" :options="partnersApprovedLastWeek" @onFilter="fetchFilteredPartners((recentPartners = $event))" />
        <base-filter ref="stageFilter" type="radio" name="Maintenance Alert" :options="maintenanceAlertFilterOptions" @onFilter="fetchFilteredPartners((selectedMaintenanceAlerts = $event))" />
        <location-filter name="zones" :options="locationsFilterOptions" min-width="100px" @onFilter="fetchFilteredPartners((selectedLocation=$event))" />
        <button class="button" @click="searchFilter">Search</button>  
        <!-- <div class="checkbox-custom">
              <input id="select-all-locations" type="checkbox" @click="selectAllLocations($event)" />
              <label for="select-all-locations"><span class="label-container">Select All Locations</span> </label>
          </div> -->
        <!-- <select v-model="selectedLocation" :style="{width:'250px'}">
          <option disabled value="">Please select one</option>
          <option v-for="option in locationsFilterOptions" :key="option.id" :value="option.id">
            {{ option.name }}
          </option>
        </select> -->
      </div>
      <div class="col-7 flex inherit-flex">
        <!-- <button class="col-1 button w-button-primary">Add Test Partner</button> -->
      <div v-if="elgibleToAddTestPartner && this.getPermission('addTestPartner')" style="padding-right:0px" class="col-1">
        <router-link  style="height:40px" class="button w-button-primary test-partner-btn" :to="{ name: 'addTestPartner' }">Add Test Partner</router-link>
      </div>
        <button class="button w-button-primary" @click="wOpenModal('bulk-activate-partner-modal')">Activate</button>
        <button class="button secondary w-button-secondary" @click="wOpenModal('bulk-deactivate-partner-modal')">Deactivate</button>
        <div class="col-0.5" style="padding-right:0px" @click="wOpenCommsFlyout('SMS')">
          <img src="@/assets/images/logos/walmart-sms.svg"/>
        </div>
        <div class="col-0.5" style="padding-right:0px" @click="wOpenCommsFlyout('EMAIL')">
          <img src="@/assets/images/logos/walmart-email.svg"/>
        </div>
        <div class="col-2" style="padding-right:0px">
          <div class="search-container">
            <input v-model="searchTerm" class="deposit-search" type="search" placeholder="Search Partner Name" />
            <button type="submit"><span v-if="searchIcon()" class="icon-x deposit-icon" @click="resetSearch"></span> <span v-else class="icon-search deposit-icon"></span></button>
          </div>
        </div>
        <!-- <button class="button s secondary" @click="showBulkMenu">Bulk Actions</button>
        <div v-if="displayBulkMenu" class="bulk-options-container">
          <div v-if="isLoading">
            <span><i class="icon icon-loader"></i> Loading...</span>
          </div>
          <div v-else>
            <div class="bulk-options">
              <div class="bulk-count">
                <div class="bulk-count-options">Partners Selected:</div>
                <div style="font-weight: 700">{{ bulkActionCount }} Selected</div>
              </div>
              <button class="button s secondary" :disabled="disablebtn" @click="sendMessage">Send Message</button>
            </div>
            <div style="margin-left: -6px" class="margin-bottom-16">
              <div class="checkbox-custom margin-right-8">
                <input id="select-all" v-model="allPartners" type="checkbox" @click="selectAll" />
                <label for="select-all"><span class="label-container">Select All</span> </label>
              </div>
                         <p class="checkbox-custom flex margin-right-8"><input v-model="allPartners" class="select-all-input" type="checkbox" @click="selectAll(partners)" /><span class="checkbox-custom label" style="padding-left: 12px">Select all</span></p>
              <button class="button s secondary" @click="cancelBulkMenu">Cancel</button>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="onboarding-partners">
      <div v-if="hasPartners" class="module location-header" style="margin:0px" :class="{'paginator-align': totalCount > perPage }">
      <div>
        <div><label for="total-candidates"><span class="label-container"><b>Showing ({{ totalCount}} Partners)</b></span></label></div>
        <div class="checkbox-custom checkAlign" style="margin:0">
          <input id="select-all" v-model="wSelectAllPartners" type="checkbox"  @change="selectAllPartners"/>
          <label for="select-all"><span class="label-container"><b>Select All ({{ selectedPartners.length }} Partners)</b></span> </label>
        </div>
        <div class="checkbox-custom checkAlign" style="margin:0">
          <input id="select-all-pages" v-model="wSelectAllPages" type="checkbox" @change="selectAllPages"/>
          <label for="select-all-pages"><span class="label-container"><b>Select All Pages({{ totalCount}} Partners)</b></span> </label>
        </div>
      </div>
    </div>
      <data-table
        :sticky-header="true"
        :headers="headers"
        :loading="loader"
        :entries="filteredPartners"
        :pagination-total-count="totalCount"
        :pagination-per-page="perPage"
        :pagination-current-page="currentPage"
        @onPaginate="fetchData($event)"
        @onChangePerPage="onChangeCount($event)"
      >

      <template #select="{ item }"> <input type="checkbox" class="checkbox-input-deposit" @click="multiSelect(item, $event)" v-model="selectedPartners" :value="item.id" ></template>

        <template v-if="displayBulkMenu" #checkbox="{ item }">
          <div>
            <input :id="item.partnerID" v-model="singleChecks" :value="item.partnerID" type="checkbox" class="select-all-input" @change="selectPartner" />
          </div>
        </template>
        <template #name="{ item }">
          <a @click="openFlyout(item)">{{ item.name }}</a>
        </template>
        <template #location="{ item }">
          <p v-for="location in item.locations" :key="location.id">{{ location.name }}</p>
        </template>
        <template #approvalDate="{ item }">
          <p>{{ item.approvalDate }}</p>
        </template>
        <template #status="{ item }">
          <p>{{ item.partnerStatus }}</p>
        </template>
        <!-- <template #approvalDate="{ item }">
          <p>{{ item.approvalDate }}</p>
        </template> -->
        <template #step="{ item }">
          <p>
            <i v-if="item.alertType == 'alert'" class="alert icon-alert-octagon"
              ><span class="main-alert">{{ item.maintenanceAlert }}</span></i
            >
            <i v-if="item.alertType == 'warning'" class="warning icon-alert-triangle"
              ><span class="main-alert">{{ item.maintenanceAlert }}</span></i
            >
            <span v-if="item.alertType == 'none'">{{ item.maintenanceAlert }}</span>
          </p>
        </template>
        <template #alertDate="{ item }">
          <p v-if="!item.alertDate">---</p>
          <p v-else>{{ item.alertDate | formatDate }}</p>
        </template>
      </data-table>
      <div id="bulk-activate-partner-modal" class="modal" aria-hidden="true">
        <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
          <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby" style="min-height: 300px;">
            <div class="modal-content" style="text-align: center">
              <div class="module">
                <span><img src="@/assets/images/logos/walmart-info.svg"/></span>
              </div>
              <div class="module">
                <h4>Are you sure you want to activate partners?</h4>
                <p>You've selected {{ selectedPartners.length }} partners to be activated, and they can't be recovered</p>
              </div>
              <div class="button-group close-top">
                <a class="button w-button-primary" data-micromodal-close @click="confirmationModal('ACTIVE')">Yes, Activate</a>
                <a class="button secondary w-button-secondary" style="margin-left: 20px" data-micromodal-close>No, Cancel</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="bulk-deactivate-partner-modal" class="modal" aria-hidden="true">
        <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
          <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby" style="min-height: 300px;">
            <div class="modal-content" style="text-align: center">
              <div class="module">
                <span><img src="@/assets/images/logos/walmart-alert-circle.svg"/></span>
              </div>
              <div class="module">
                <h4>Are you sure you want to deactivate partners?</h4>
                <p>You've selected {{ selectedPartners.length }} partners to be deactivated, and they can't be recovered</p>
              </div>
              <div class="button-group close-top">
                <a class="button" style="background: #c2272c; color: #ffffff;" data-micromodal-close @click="confirmationModal('INACTIVE')">Yes, Deactivate</a>
                <a class="button secondary" style="margin-left: 20px" data-micromodal-close>No, Cancel</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bulk-communications-flyout 
      :bulk-obj="partnerObj" 
      :on-close="closeBulkComms" 
      :display-editor="displayEmailEditor" 
      :comms-type="commsFlyoutType" 
      @sendEmail="sendEmail" 
      @sendText="sendSMS"
    />
    <partner-flyout :data="selectedPartner" @statusupdated="fetchFilteredPartners" @cbcverified="refetchSelectedPartner" @refresh="refresh"/>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import { BaseFilter, DataTable, TableHeader, Direction,LocationFilter } from "@/components";
import { mapActions, mapGetters } from "vuex";
import { OnboardingStatus } from "@/modules/admin/partners/model";
import { DateTime, Interval } from "luxon";
import PartnerFlyout from "@/modules/admin/onboarding/flyouts/PartnerFlyout";
import { BulkCommunicationsFlyout } from "@/modules/shared/communications";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/filters";
import micromodal from "micromodal";
import Message from 'primevue/message';
import { decode } from "@/filters";
// import { convertBufferToContent } from "@/util/BufferContentConverter";

export default {
  components: { Fragment, BaseFilter,  DataTable, PartnerFlyout, BulkCommunicationsFlyout, LocationFilter, Message },
  data: () => ({
    displayEmailEditor: true,
    searchTerm: "",
    locations: [],
    partners: [],
    onboardingPartnerView: [],
    selectedLocations: [],
    selectedPartnerStatuses: [],
    selectedMaintenanceAlerts: [],
    recentPartners: [],
    totalCount: 0,
    currentPage: 0,
    perPage: 50,
    offset: 0,
    selectedPartner: {},
    selectedPartnerID: "",
    // selectedPartners:{},
    displayBulkMenu: false,
    allPartners: false,
    disablebtn: true,
    singleChecks: [],
    partnerObj: [],
    isLoading: false,
    bulkActionPartners: [],
    bulkActionCount: 0,
    alertDate: new Date().toISOString(),
    onboardingPartnerViewLoader: true,
    selectedLocation: [],
    flyoutDataObj:{},
    commsFlyoutType: '',
    wSelectAllPartners: false,
    toastMessage: '',
    displayToast: false,
    toastSeverity:'',
    rolesToAddTestPartner: ['SUPERUSER', "ONBOARDING_ACCOUNT_MANAGER"],

    selectedPartners:[],
    selectedCandidatesList:[],
    commstoselected:[],

    wSelectAllPages:false,

  }),
  watch: {
    getSelectedEmployee(newData, oldData) {
      if (newData.name !== oldData.name) {
        this.displayBulkMenu = false;
      }
    },
    searchTerm(newData, oldData) {
      if (newData != oldData && newData !== '') {
        this.getPartnersList();
      }
    },
    isFlyoutShowing(value) {
      if (!value) {
        this.selectedPartner= {};
      }
    }
  },
  computed: {
    ...mapGetters(["isFlyoutShowing", "getUserID", "getPermission"]),
    hash() {
      return this.$route.hash === "#partners";
    },
    ...mapGetters(["getSelectedEmployee", "isFlyoutShowing"]),
    loader() {
      return this.isLoading;
    },
    locationsFilterOptions() {
      return [...this.$store.state.onboarding.candidatesTab.locationsFilterOptions];;
    },
    elgibleToAddTestPartner(){
      return this.rolesToAddTestPartner.includes(localStorage.getItem('role'));
    },
    cDisplayToast() {
      return this.displayToast;
    },
    partnerStatusFilterOptions() {
      return [
        { id: "op-active", name: "ACTIVE", label: "Active", checked: false },
        { id: "op-inactive", name: "INACTIVE", label: "Inactive", checked: false },
        { id: "op-idle", name: "IDLE", label: "Idle", checked: false },
        { id: "op-newly-approved", name: "NEWLY_APPROVED", label: "Newly Approved", checked: false },
        { id: "op-blocked", name: "BLOCKED", label: "Blocked", checked: false },
      ];
    },
    partnersApprovedLastWeek() {
      return [
        { id: "op-last-week-approved", name: "LAST_WEEK_APPROVED_PARTNERS", label: "Last Week Approved Partner", checked: false },
      ]
    },
    maintenanceAlertFilterOptions() {
      return [
        // { id: "op-screen-renewal", name: "screenRenewal", label: "Screen Renewal" },
        { id: "op-auto-insurance", name: "autoInsurance", label: "Auto Insurance" },
        { id: "op-drivers-license", name: "driversLicense", label: "Drivers License" },
        { id: "op-unverified-insurance", name: "unverifiedInsurance", label: "Unverified Insurance" },
        // { id: "op-expired-document", name: "expiredDocument", label: "Expired Document" },
      ];
    },
    hasPartners() {
      return this.filteredPartners && this.filteredPartners.length > 0;
    },
    filteredPartners() {
      return this.onboardingPartnerView.map((partner) => {
        let status = partner.partnerStatus == null ? "NO STATUS" : partner.partnerStatus.toUpperCase();
        return {
          id: partner.partnerLocationID,
          notes: partner.notes,
          name: `${partner.firstName} ${partner.lastName}`.trim(),
          email: partner.email,
          phone: partner.phone ? partner.phone : "",
          locations: [{ id: partner.locationID, name: partner.locationName }],
          locationID: partner?.locationID ? partner.locationID : null,
          partnerID: partner.partnerID,
          partnerStatus: this.sentenceCase(status),
          approvalDate: partner?.approvalDate ? this.format((partner.approvalDate)?.split('T')[0]) : "",
          userID: partner?.userID ? partner.userID : "",
          // externalID: partner.partner.ids[0]?.externalID ? partner.partner.ids[0].externalID : null,
          lastPay: partner?.lastPay ? partner.lastPay : "",
          insuranceExpiry: partner?.insuranceExpiry ? partner.insuranceExpiry : "",
          driversLicense: partner?.DLExpiration ? partner.DLExpiration : "",
          maintenanceAlert: this.setMaintenanceAlerts(partner),
          alertDate: this.setMaintenanceAlerts(partner) === "None" ? "" : this.alertDate,
          alertType: this.alertType(this.setMaintenanceAlerts(partner)),
          insuranceUpdatedDate:partner.last_insurance_upload_on_ts ? this.format(partner.last_insurance_upload_on_ts) :"---"
        };
      });

      //return Array.from(new Set(list.map(a=>a.id))).map(id => list.find(a => a.id === id))
    },
    headers() {
      return [
        //new TableHeader({ headerClass: "cell2", cellClass: "bulk-checkbox-actions", name: "checkbox" }),
        new TableHeader({ name: "select", label: "", cellContents: "select", headerClass: "cell2"}),
        new TableHeader({ 
          name: "name", 
          label: "Partners Name", 
          cellContents: "name", 
          cellClass: "bulk-checkbox-actions", 
          headerClass: "cell15", 
          sortable: true }),
        new TableHeader({ 
          name: "location", 
          label: "Location", 
          cellContents: "location", 
          headerClass: "cell15", 
          sortable: true }),
        new TableHeader({ 
          name: "approvalDate", 
          label: "Approval Date", 
          cellContents: "approvalDate", 
          headerClass: "cell10", 
          sortable: true }),
        new TableHeader({ 
          name: "status", 
          label: "Partner Status", 
          cellContents: "partnerStatus", 
          headerClass: "cell10", 
           sortable: true,
          sortFunction: this.sortPartnerStatus,
          sorted: true,
          direction: Direction.ASCE,
          cellClass: "time" }),
        // new TableHeader({ name: "stage", label: "Approval Date", cellContents: "approvalDate", headerClass: "cell10", sortable: false, cellClass: "stage" }),
        new TableHeader({
          name: "step",
          label: "Maintenance Alert",
          cellContents: "step",
          headerClass: "cell22",
          cellClass: "step",
          sortable: true,
          sortFunction: this.sortStatus,
          sorted: true,
          direction: Direction.DESC,
        }),
        new TableHeader({ 
          name: "alertDate", 
          label: "Exp Date", 
          cellContents: "alertDate", 
          headerClass: "cell10",
          sortable: true, 
          cellClass: "alertDate" }),
        new TableHeader({ 
          name: "insuranceUpdatedDate", 
          label: "Updated On", 
          cellContents: "insuranceUpdatedDate", 
          headerClass: "cell15",
          sortable: true, 
          cellClass: "insuranceUpdatedDate" }),
      ];
    },
    startOfWeek() {
      const startweek = 6 > DateTime.now().setZone("America/Los_Angeles").weekday ? DateTime.now().setZone("America/Los_Angeles").weekNumber - 1 : DateTime.now().setZone("America/Los_Angeles").weekNumber;
      return DateTime.utc().setZone("America/Los_Angeles").startOf("day").set({ weekNumber: startweek, weekday: 6 });
    },
    startOfPreviousWeek() {
      return DateTime.fromISO(this.startOfWeek).startOf("day").minus({ days: 7 });
    },
    endOfPreviousWeek() {
      return DateTime.fromISO(this.endOfWeek).endOf("day").minus({ days: 7 });
    },
    endOfWeek() {
      return DateTime.fromISO(this.startOfWeek).endOf("day").plus({ days: 6 });
    },
  },
  methods: {
    ...mapActions(["showFlyout"]),
    searchIcon() {
      return this.searchTerm !== "";
    },

    searchFilter(){
      this.selectedCandidatesList=[];
      this.wSelectAllPartners=false;
      this.selectedPartners=[];

      let locSelected=this.getLocationFilterData();
      if(locSelected.length == 0){
        this.selectedLocation = this.locationsFilterOptions;
      }
      this.getPartnersList();

    },
    // selectAllLocations(event){
    //   if(event.target.checked){
    //     this.totalCount=0;
    //     this.selectedLocation = this.locationsFilterOptions;
    //     this.getPartnersList(); 
    //   }
    //   if(!event.target.checked){
    //     this.totalCount=0;
    //     this.selectedLocation=[];
    //     this.onboardingPartnerView =[];
    //   }
    // },
    getPartnersList() {
      if(this.selectedLocation.length) {
           this.isLoading=true;
           restApi.post('/employee/onboardingPartnerViewV3',encodeWithParam(this.partnersListParams())).then((data)=>{
           this.onboardingPartnerView = data.data.result;
           this.totalCount = Number(data.data?.total.total_partner_count);
           this.isLoading=false
      })
      }
    },
    partnersListParams () {
      let backDate = new Date("1990","01","01");
      let futureDate = new Date("2050","01","01")
      return {
        phone:this.searchMatch("phone"),
        email:this.searchMatch("email"),
        fullName:this.searchMatch("fullName"),
	      userID: this.getSelectedEmployee.id,
	      locationIds:this.getLocationFilterData(),
	      driverLicenseExpiryDate: this.findMaintenanceAlerts("drivers license"),
	      insuranceExpiryDate: this.findMaintenanceAlerts("auto insurance"),
        insuranceVerified: this.selectedMaintenanceAlerts.includes("unverified insurance") ? [false]:[true,false],
	      status: this.filteredStatus(),
	      lastWeekStartDate: this.recentPartners.length > 0 ? this.startOfPreviousWeek : backDate,
	      lastWeekEndDate: this.recentPartners.length > 0 ? this.endOfPreviousWeek : futureDate,
        limit: this.perPage,   
        offset: this.offset,
      }
    },

    searchMatch(filter){
    let searchData="";
    let isPhone = /^[0-9]*$/;
    let isEmail = /\S+@\S+\.\S+/;
    if(filter === "email"){
      if(isEmail.test(this.searchTerm)){
        searchData=this.searchTerm;
      }
      else{
        searchData = "";
      }
    }
    if(filter === "phone"){
      if(isPhone.test(this.searchTerm)){
        searchData=this.searchTerm;
      }
      else{
        searchData = "";
      }
    }
    if(filter === "fullName"){
    if(!isEmail.test(this.searchTerm) && !isPhone.test(this.searchTerm)){
      
      searchData=this.searchTerm
    }
    else{
      searchData = "";
    }
    }
     return searchData
  },
    sortPartnerStatus() {
      return (partner1, partner2) => {
        const partner1Urgency = this.computeStatus(partner1);
        const partner2Urgency = this.computeStatus(partner2);
        return partner1Urgency - partner2Urgency;
      };
    },

    computeStatus(partner) {
      let status = partner.partnerStatus.toLowerCase();
      switch(status){
        case "active" :
          return 1
        case "inactive" :
          return 2
        case "idle" :
          return 3
        case "newly_approved" :
          return 4
        case "blocked" :
          return 5
      }
    },
    resetSearch() {
      this.searchTerm = "";
      // this.currentPage = 1;
      // this.getPartnersList();
      this.onboardingPartnerView = [];
      this.totalCount = 0;
      this.isLoading=false
      this.wSelectAllPartners=false;
      this.wSelectAllPartners=false;
      this.selectedPartners=[];
      //this.$apollo.queries.onboardingPartnerView.refresh()
    },
    onChangeCount(event) {
      this.perPage = event.perPage;
    },
    fetchData(event) {
      this.wSelectAllPartners=false;
      this.selectedPartners=[];
      this.currentPage = Number(event.pageNumber);
      this.offset = event.offset;
      this.getPartnersList();
      //this.$apollo.queries.onboardingPartnerView.refresh()
    },
    openFlyout(partner) {
      this.selectedPartnerID = partner.id;
      this.flyoutData(partner)
      
    },
    filterPartnerStatuses(candidate) {
      const urgencyFound = this.selectedPartnerStatuses.findIndex((urgency) => {
        if (urgency === "status alert") {
          return OnboardingStatus.isWarning(candidate.status);
        } else if (urgency === "time alert") {
          return candidate.alertTime && DateTime.fromISO(candidate.alertTime) < DateTime.utc();
        } else if (urgency === "approved") {
          return OnboardingStatus.APPROVED === candidate.status;
        } else if (urgency === "dismissed") {
          return OnboardingStatus.isWithdrawn(candidate.status);
        } else if (urgency === "normal") {
          return !(
            OnboardingStatus.isWarning(candidate.status) ||
            (candidate.alertTime && DateTime.fromISO(candidate.alertTime) < DateTime.utc()) ||
            OnboardingStatus.APPROVED === candidate.status ||
            OnboardingStatus.isWithdrawn(candidate.status)
          );
        }
        return false;
      });
      return this.selectedPartnerStatuses.length === 0 || urgencyFound >= 0;
    },
    filterMaintenanceAlerts(candidate) {
      const stageFound = this.selectedMaintenanceAlerts.findIndex((stage) => {
        if (stage === "not yet approved") {
          return candidate.stage !== "APPROVAL";
        }
        return candidate.stage.replaceAll("_", " ").toLowerCase() === stage.toLowerCase();
      });
      return this.selectedMaintenanceAlerts.length === 0 || stageFound >= 0;
    },
    filterLocations(candidate) {
      const locationFound = this.selectedLocations.findIndex((location) => location.name === candidate.location);
      return this.selectedLocations.length === 0 || locationFound >= 0;
    },
    fetchFilteredPartners() {
      // this.resetPaginator();

      // this.getPartnersList();
      //this.$apollo.queries.onboardingPartnerView.refresh();
      return
    },
    refetchSelectedPartner() {
      this.$apollo.queries.partners.refresh();
      this.getPartnersList();
    //   this.$apollo.queries.onboardingPartnerView.refresh();
    },
    getLocationFilterData() {
        return this.selectedLocation.map((item) => {
          return item.id;
        }); 
    },
    getStatusFilterData() {
      return [];
    },
    getMaintenanceFilterData() {
      return [];
    },
    selectPartner() {
      this.allPartners = false;
      if (this.singleChecks.length > 0) {
        this.disablebtn = false;
      } else {
        this.disablebtn = true;
      }
      if (this.bulkActionPartners.length == this.singleChecks.length) {
        this.allPartners = true;
      }
      this.bulkActionCount = this.singleChecks.length;
    },
    showBulkMenu() {
      this.isLoading = true;
        restApi.post('/employee/onboardingPartnerViewV3',encodeWithParam(this.partnersListParams())).then(({ data }) => {
          this.bulkActionPartners = data.result.map((partner) => {
            let status = partner.partnerStatus == null ? "NO STATUS" : partner.partnerStatus.toUpperCase();
            return {
              id: partner.partnerLocationID,
              notes: partner.notes,
              name: `${partner.firstName} ${partner.lastName}`.trim(),
              email: partner.email,
              phone: partner.phone ? partner.phone : "",
              locations: [{ id: partner.locationID, name: partner.locationName }],
              locationID: partner?.locationID ? partner.locationID : null,
              partnerID: partner.partnerID,
              partnerStatus: this.sentenceCase(status),
              approvalDate: partner?.approvalDate ? partner.approvalDate : "",
              userID: partner?.userID ? partner.userID : "",
              // externalID: partner.partner.ids[0]?.externalID ? partner.partner.ids[0].externalID : null,
              lastPay: partner?.lastPay ? partner.lastPay : "",
              insuranceExpiry: partner?.insuranceExpiry ? partner.insuranceExpiry : "",
              driversLicense: partner?.DLExpiration ? partner.DLExpiration : "",
              maintenanceAlert: status === "INACTIVE" ? "None" : this.setMaintenanceAlerts(partner),
              alertDate: this.setMaintenanceAlerts(partner) === "None"||  status === "INACTIVE" ? "" : this.alertDate,
              alertType: status === "INACTIVE" ? "none" : this.alertType(this.setMaintenanceAlerts(partner)),
            };
          });
          this.isLoading = false;
        });
      this.bulkActionCount = 0;
      this.allPartners = false;
      this.displayBulkMenu = !this.displayBulkMenu;
    },

    resetPage() {
      this.selectedPartnerStatuses = [];
      this.selectedLocations = [];
      this.selectedMaintenanceAlerts = [];
      this.recentPartners = [];
      this.resetPaginator();
    //  this.$apollo.queries.onboardingPartnerView.refresh();
    },
    cancelBulkMenu() {
      this.singleChecks = [];
      this.displayBulkMenu = false;
    },
    selectAll() {
      this.bulkActionCount = 0;
      this.singleChecks = [];
      if (this.allPartners) {
        this.singleChecks = [];
      } else {
        this.bulkActionPartners.forEach((item) => {
          this.singleChecks.push(item.partnerID);
        });
      }
      this.disablebtn = this.singleChecks.length > 0 ? false : true;
      this.bulkActionCount = this.singleChecks.length;
    },
    sendMessage() {
      this.displayEmailEditor = true;
      this.partnerObj = [];
      this.bulkActionPartners.forEach((item) => {
        if (this.singleChecks.includes(item.partnerID)) {
          let customObj = {
            name: item?.name ? item.name : "",
            email: item?.email ? item.email : "",
            partnerID: item?.partnerID ? item.partnerID : "",
            externalID: item?.externalID ? item.externalID : "",
            phone: item?.phone ? item.phone : "",
            userID: item?.userID ? item.userID : "",
          };
          this.partnerObj.push(customObj);
        }
      });
      document.body.classList.add("no-scroll");
      this.showFlyout("bulk-comms-flyout");
    },
    closeComms() {
      this.singleChecks = [];
      this.partnerObj = [];
      this.displayBulkMenu = false;
      this.resetPage();
      this.showFlyout("");
    },
    disableEmail() {
      this.displayEmailEditor = false;
    },
    filteredStatus() {
      if (this.selectedPartnerStatuses.length > 0) {
        return this.selectedPartnerStatuses.map((item) => {
          const formatting = item.replaceAll(" ", "_");
          return formatting.toUpperCase();
        });
      } else {
        return ["ACTIVE", "INACTIVE", "IDLE", "NEWLY_APPROVED", "BLOCKED"];
      }
    },
    format(date) {
      let dateToFormat = date;
      dateToFormat = DateTime.fromISO(date);
      return dateToFormat.toFormat("dd LLL yyyy");
    },
    sentenceCase(string) {
      if (string === "NEWLY_APPROVED") {
        return "Newly Approved";
      }
      let removeCharacters = string.replaceAll("_", " ");
      return removeCharacters.charAt(0) + removeCharacters.slice(1).toLowerCase();
    },
    alertType(alertMessage) {
      switch (alertMessage) {
        case "Verify Auto Insurance(1 more)":
          return "warning";
        case "Drivers License Expiring(1 more)":
          return "warning";
        case "Drivers License Expiring":
          return "warning";
        case "Verify Auto Insurance":
          return "warning";
        case "Expired Drivers License":
          return "alert";
        case "Verify Auto Insurance update":
          return "alert";
        case "Expired Drivers License(1 more)":
          return "alert";
        case "Verify Auto Insurance update(1 more)":
          return "alert";
        case "CBC Consider":
          return "alert"
        case "Insurance Not Verified":
          return "alert"
        case "None":
          return "none";
      }
    },
    setMaintenanceAlerts(partner) {
      if(!partner.insurance_verified) {
        this.alertDate = partner?.insuranceExpiry
        return "Insurance Not Verified"
      }
      let insuranceExpiry = new Date(partner.insuranceExpiry);
      let driverLicenceExpiry = new Date(partner.DLExpiration);
      let today = DateTime.now();
      let insuranceExpirayDifference = Interval.fromDateTimes(today, insuranceExpiry).length("days");
      let driversLicenceExpirayDifference = Interval.fromDateTimes(today, driverLicenceExpiry).length("days");
      if(partner.partnerCBCResult === "consider") {
       return "CBC Consider"
      } else {
         if (Math.round(insuranceExpirayDifference) <= 14 || Math.round(driversLicenceExpirayDifference) <= 14) {
            if (Math.round(insuranceExpirayDifference) <= 14 && Math.round(driversLicenceExpirayDifference) <= 14) {
              if (insuranceExpiry > driverLicenceExpiry) {
                this.alertDate = insuranceExpiry.toISOString();
                return "Verify Auto Insurance(1 more)";
              } else {
                  this.alertDate = driverLicenceExpiry.toISOString();
                  return "Drivers License Expiring(1 more)";
                }
            } else {
              if (insuranceExpiry > driverLicenceExpiry) {
                this.alertDate = driverLicenceExpiry.toISOString();
              if (isNaN(driversLicenceExpirayDifference)) {
                return "Expired Drivers License";
              }
                return "Drivers License Expiring";
            } else {
              this.alertDate = insuranceExpiry.toISOString();
              if (isNaN(insuranceExpirayDifference)) {
                return "Verify Auto Insurance update";
              }
                return "Verify Auto Insurance";
            }
          }
        } else if (isNaN(insuranceExpirayDifference) || isNaN(driversLicenceExpirayDifference)) {
          if (isNaN(insuranceExpirayDifference) && isNaN(driversLicenceExpirayDifference)) {
            if (insuranceExpiry > driverLicenceExpiry) {
              this.alertDate = driverLicenceExpiry.toISOString();
              return "Expired Drivers License(1 more)";
            } else {
              this.alertDate = insuranceExpiry.toISOString();
              return "Verify Auto Insurance update(1 more)";
            }
          }
          if (insuranceExpiry > driverLicenceExpiry) {
            this.alertDate = driverLicenceExpiry.toISOString();
            return "Expired Drivers License";
          } else {
            this.alertDate = insuranceExpiry.toISOString();
            return "Verify Auto Insurance update";
         }
        } else {
          return "None";
          }
        }
     },
    findMaintenanceAlerts(searchString) {
      let fortNight = new Date(Date.now() + 12096e5);
      let futureDate = new Date();
      if (this.selectedMaintenanceAlerts.indexOf(searchString) !== -1) {
        return fortNight;
      } else {
        futureDate.setDate(futureDate.getDate() + 7300);
        return futureDate;
      }
    },
    refresh(){
      this.totalCount = 0;
      this.currentPage = 1;
      this.offset = 0;
      this.getPartnersList();
    },
    resetPaginator() {
      this.totalCount = 0;
      this.currentPage = 1;
      this.offset = 0;
      //this.getPartnersList();
    //  this.$apollo.queries.onboardingPartnerView.refresh()
    },
    sortStatus() {
      return (candidate1, candidate2) => {
        const candidate1Urgency = this.computeUrgency(candidate1);
        const candidate2Urgency = this.computeUrgency(candidate2);
        return candidate1Urgency - candidate2Urgency;
      };
    },
    convertDate(date){
      let fullDate=date.split('T');
      return fullDate[0]
    },
    async flyoutData(partner){
      // param.clientLocationPartnerID, param.clientID, param.clientLocationID
      let params={
        partnerID:partner.partnerID, 
        candidateID:partner.id,
        clientLocationPartnerID:partner.id,
        clientID:"b8b95baf-e76b-49b6-b3a8-2ca0706765b8",
        clientLocationID:partner.locationID
        }
      let getPartnerDetails = await restApi.post('partner/getPartnerDetails',encodeWithParam(params))
      let partnerData=getPartnerDetails.data.PartnerDetails[0];
      let flyoutData={
        candidateID: partnerData?.id ? partnerData.id:"",
        partnerID: partnerData?.partnerID ? partnerData.partnerID:"",
        locationID:partnerData?.locationID ? partnerData.locationID:"",
        firstName: partnerData?.firstName ? partnerData.firstName :"",
        lastName: partnerData?.lastName ? partnerData.lastName:"",
        name:`${partnerData.firstName} ${partnerData.lastName}`,
        phone: partnerData?.phone ? partnerData.phone:"",
        email: partnerData?.email ? partnerData.email:"",
        approvalDate:partnerData?.approvalDate ? partnerData.approvalDate:"",
        externalID: partnerData?.externalID ? partnerData.externalID:"",
        DMSID:partnerData?.externalID ? partnerData.externalID:null,
        locationName:partnerData?.locationName ? partnerData.locationName:"",
        dateOfBirth: partnerData?.dateOfBirth ? partnerData.dateOfBirth:"",
        insuranceExpiry: partnerData?.insuranceExpiry ? partnerData.insuranceExpiry:"",
        insuranceVerified: partnerData.insuranceVerified,
        userID: partnerData?.userID ? partnerData.userID:"",
        username: partnerData?.username ? partnerData.username:"",
        dlNumber: partnerData?.number ? partnerData.number:"",
        dlExpiration: partnerData?.expiration ? partnerData.expiration:"",
        dlState: partnerData?.state ? partnerData.state:"",
        status: partnerData?.status ? partnerData.status:"",
        reportConfig:getPartnerDetails.data.CheckrReportConfig.length ? JSON.parse(getPartnerDetails.data.CheckrReportConfig[0].value) : { rootCheckrLink: "", checkrTestQuery: "" },
        cbcDate:getPartnerDetails.data.CBCDate[0] ? getPartnerDetails.data.CBCDate[0].reportDate : [],
        notesList:getPartnerDetails.data.Notes.length ? getPartnerDetails.data.Notes[0].notesList : [],
        lastPay:getPartnerDetails.data.lastPay[0] ? getPartnerDetails.data.lastPay[0].endDate : [],
        decoded: partnerData.encodedData ? decode(partnerData.encodedData) : "",
        insuranceFileId: null,
        insuranceImageURL:null,
        documentTemplateID:null,
        insuranceFileDetails:getPartnerDetails.data.docs,
      }
        this.selectedPartner=flyoutData
        this.showFlyout("partner-flyout");
      // this.getDocuments(getPartnerDetails.data.docs,flyoutData)
    },

    // getdocumentTemplateID(docs){
    //   let insuranceFileDetails = docs.filter((item)=>item.title.toLowerCase() === "insurance");
    //   this.fileID = insuranceFileDetails[0].fileID;
    //   return insuranceFileDetails[0]?.id?insuranceFileDetails[0]?.id:null

    // },

    // async getDocuments(docs,flyoutData) {
    //     let insuranceFileDetails = docs.filter((item)=>item.title.toLowerCase() === "insurance");
    //     flyoutData.documentTemplateID=insuranceFileDetails[0]?.id?insuranceFileDetails[0]?.id:null
    //     let data = await restApi.post('/employee/getUploadedFile',encodeWithParam({fileID: insuranceFileDetails[0].fileID}));
    //     flyoutData.insuranceImageURL = data?.data?.bufferInHex ? convertBufferToContent(data.data.bufferInHex,data.data.contentType):null;
    //       this.selectedPartner=flyoutData
    //       this.showFlyout("partner-flyout");
    // },
    computeUrgency(partner) {
      if (partner.alertType === "alert") {
        return 3;
      }
      if (partner.alertType === "warning") {
        return 2;
      }
      return 1;
    },
    wOpenCommsFlyout(type) {
      if(this.selectedPartners.length>0 || this.wSelectAllPages) {
        this.commsFlyoutType = type;
        this.displayEmailEditor = true;
        this.partnerObj = [];
        let filtered=[];
        if(this.wSelectAllPages){
          filtered  = this.filteredPartners;
        }
        else{
        filtered  = this.selectedCandidatesList;
        }
        filtered.forEach((item) => {
          let obj = {
            name: item.name,
            partnerID: item.partnerID
          }
          this.partnerObj.push(obj)
        })
        this.showFlyout('bulk-comms-flyout')
      } else {
          this.errorToast();
      }
    },
    closeBulkComms() {
      this.partnerObj = [];
      this.wSelectAllPartners = false;
      this.selectedPartners=[],
      this.selectedCandidatesList=[],
      this.commstoselected=[]
      this.showFlyout("");
    },
    wOpenModal(id) {
      if(this.selectedPartners.length>0 || this.wSelectAllPages) {
        micromodal.show(id);
      } else {
        this.errorToast();
      }
    },
    confirmationModal(status) {
      if(this.selectedPartners.length>0) {
        // const params = this.partnersListParams();
          // params.limit = 0;
          // params.offset = 0;
          
          const candidatesSend = this.selectedCandidatesList;
          candidatesSend.forEach((item)=>{
          let obj = {
            email: item?.email ? item.email : "",
            partnerID: item?.partnerID ? item.partnerID : "",
            name:item?.name ? item.name : "",
            phone:item?.phone ? item.phone : "",
            userid:item?.userID ? item.userID : ""
            }
            this.commstoselected.push(obj)
          })
          const payload =  {
            filterData: this.commstoselected,
            status: status,
            action: "STATUS_CHANGE"
        }
        restApi.post("/onboard/bulkAction", encodeWithParam(payload))
          .then(({data}) => {
            this.successToast(data);
            this.selectedPartners=[],
            this.selectedCandidatesList=[],
            this.commstoselected=[]
          })
      } else {
        this.errorToast();
      }
    },
    sendEmail(event) {
      let payload={}
      if(this.wSelectAllPages){
      const params = this.partnersListParams();
      params.limit = 0;
      params.offset = 0;
      payload = {
        html :event.html,
        subject: event.subject,
        action: 'EMAIL',
        userID: this.getUserID,
        filterData: params,
        type: 'PARTNER',
        sendCommForAllPages:true
      }
    }
    else{
      const candidatesSendSMS = this.selectedCandidatesList;
      candidatesSendSMS.forEach((item)=>{
      let obj = {
          email: item?.email ? item.email : "",
          partnerID: item?.partnerID ? item.partnerID : "",
          name:item?.name ? item.name : "",
          phone:item?.phone ? item.phone : "",
          userid:item?.userID ? item.userID : ""
          }
          this.commstoselected.push(obj)
        })
      payload = {
        html :event.html,
        subject: event.subject,
        action: 'EMAIL',
        userID: this.getUserID,
        filterData: this.commstoselected,
        type: 'PARTNER',
        sendCommForAllPages:false
    }
  }
      restApi.post("/onboard/bulkAction",encodeWithParam(payload))
        .then(({data}) => {
          this.successToast(data)
        })
      this.displayEmailEditor = false;
    },
    sendSMS(event) {
      let payload={};
      if(this.wSelectAllPages){
        const params = this.partnersListParams();
        params.limit = 0;
        params.offset = 0;
        payload = {
        body: event.body,
        sendDate: event.sendDate,
        action:'SMS',
        userID:this.getUserID,
        filterData: params,
        type: 'PARTNER',
        sendCommForAllPages:true
        }
      }
      else{
          const candidatesSend = this.selectedCandidatesList;
          candidatesSend.forEach((item)=>{
          let obj = {
            email: item?.email ? item.email : "",
            partnerID: item?.partnerID ? item.partnerID : "",
            name:item?.name ? item.name : "",
            phone:item?.phone ? item.phone : "",
            userid:item?.userID ? item.userID : ""
          }
          this.commstoselected.push(obj)
        })

          payload = {
          body: event.body,
          sendDate: event.sendDate,
          action:'SMS',
          userID:this.getUserID,
          filterData: this.commstoselected,
          type: 'PARTNER',
          sendCommForAllPages:false
      }
    }
      restApi.post("/onboard/bulkAction",encodeWithParam(payload))
        .then(({data}) => {
          this.successToast(data)
        })
      this.displayEmailEditor = false;
    },
    successToast(data) {
      this.displayToast=true;
      this.toastSeverity="success";
      this.toastMessage = data.message;
      this.wSelectAllPartners = false;
    },
    errorToast() {
      this.toastMessage="Click on select all"
      this.displayToast = true;
      this.toastSeverity="error"
    },
    closeToast() {
      this.displayToast=false;
    },

    selectAllPartners() {
    if (this.wSelectAllPartners) {
      this.wSelectAllPages=false
      this.selectedPartners = [];
      this.selectedCandidatesList=[];
      this.selectedPartners = this.filteredPartners.map(partner => partner.id);
      this.selectedCandidatesList=this.filteredPartners.slice();

    } else {
      this.selectedPartners = [];
      this.selectedCandidatesList=[];
    }
  },

  selectAllPages(){
      this.wSelectAllPartners=false
      this.selectedPartners = [];
      this.selectedCandidatesList=[];
    },

  multiSelect(val, e){
    if(e.target.checked){
          if (val.id != this.selectedPartners.map((item) => item.id) && this.selectedPartners.length > 0) {
              this.selectedPartners.push(val.id)
              this.selectedCandidatesList.push(val);
              this.wSelectAllPartners = this.selectedPartners.length === this.filteredPartners.length;
          }
          else{
            this.selectedPartners.push(val.id)
            this.selectedCandidatesList.push(val);
            this.wSelectAllPartners = this.selectedPartners.length === this.filteredPartners.length;
          }
    }
    else{
      let index=this.selectedPartners.indexOf(val.id);
      this.selectedPartners.splice(index, 1)
      this.selectedCandidatesList.splice(index, 1)
      this.wSelectAllPartners = this.selectedPartners.length === this.filteredPartners.length;
    }
  },

  },
};
</script>
<style scoped>

.test-partner-btn{
  padding: 12px 16px;
}
.warning {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #a85d07;
}
.alert {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #c2272c;
}
.main-alert {
  padding-left: 2px;
  font-family: "Open Sans", sans-serif;
}
.inherit-flex {
  flex: inherit !important;
}
.w-button-primary {
  background: #0071DC;
}
.w-button-secondary {
  border-color: #0071DC !important;
  color: #0071DC;
}
 button.w-button-primary, button.w-button-secondary {
  height: 40px;
  margin-left: 12px;
}
button:hover.w-button-primary, a:hover.w-button-primary {
  background: #0071DC;
}
.toast {
    width: 40%;
    margin: -18px auto 0 auto;
}
.p-message.p-message-error {
  background: #c2272c;
  color: #ffffff;
}
.p-message.p-message-success {
  background: #2A8703;
  color: #ffffff;
}
.paginator-align {
  margin-bottom: -40px !important;
}
.checkbox-input-deposit{
  width: 16px;
    cursor: pointer;
    height: 15px;
    margin-top: 2px;
}
.checkAlign{
  margin-top: 7% !important;
  margin-left: 0% !important;
} 
</style>
