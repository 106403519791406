<template>
  <div class="deposit-download">
    <div class="flex">
      <div class="cell350">
        <div class="box"></div>
      </div>
      <div class="cell50">
        <div class="margin-left-36">
        <h4>Deposit Summary</h4>
        <p>
          Period Beginning: <span class="right-para">{{ getPayPeriod[0] }}</span>
        </p>
        <p>
          Period Ending: <span class="left-para">{{ getPayPeriod[1]?getPayPeriod[1]:getPayPeriod[0] }}</span>
        </p>
        <p class="mt16">
          <strong>{{ getName }}</strong>
        </p>
        <p v-if="getAddressLine1">
          <strong>{{ getAddressLine1 }}</strong>
        </p>
        <p v-if="getAddressLine2">
          <strong>{{ getAddressLine2 }}</strong>
        </p>
        <p v-if="getCityState">
          <strong>{{ getCityState }}</strong>
        </p>
        </div>
      </div>
    </div>
    <div class="deposit-flyout-content summary cell60 mt16">
      <div class="module-m">
        <h5 class="bottom-border">
          Business Income: <span>{{ amount("business") | formatMoney("s") }}</span>
        </h5>
        <div v-if="$props.adhocDepositSummaryDetails" class="adhoc-deposit-flyout">
          <div v-for="(item, index) in $props.adhocDepositSummaryDetails.items" :key="index" class="line-item">
            <div class="flex">
              <div class="wide">
                <p :class="isTaxExempted(item)">{{ item.amount | formatMoney("s") }}</p>
              </div>
              <div class="right-para" >
                <p :class="isTaxExempted(item)">{{ item.name === "Delivery" ? `Deliveries` : item.name === "Tip" ? "Tips" : item.name }}</p>
              </div>
            </div>
            <div class="flex">
              <div class="wide">
                <p>Remarks: </p>
              </div>
              <div class="right-para" >
                <p>{{ item.remarks }}</p>
              </div>
            </div>
              <h5 class="bottom-border"></h5>
          </div>
        </div>
        <div v-else-if="settlementItem('BUSINESS_INCOME')">
          <div v-for="(item, index) in settlementItem('BUSINESS_INCOME')" :key="index" class="flex">
            <div class="wide">
              <p>{{ item[1] | formatMoney("s") }}</p>
            </div>
            <div class="right-para">
              <p>{{ item[0] === "Delivery" ? `Deliveries` : item[0] === "Tip" ? "Tips" : item[0] }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="module-m">
        <h5 class="bottom-border">
          Credits: <span>{{ amount("credits") | formatMoney("s") }}</span>
        </h5>
        <div v-if="settlementItem('CREDITS')">
          <div v-for="(item, index) in settlementItem('CREDITS')" :key="index" class="flex">
            <div class="wide">
              <p>{{ item[1] | formatMoney("s") }}</p>
            </div>
            <div class="right-para">
              <p>{{ item[0] }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="module-m">
        <h5 class="bottom-border">
          Debits: <span>{{ amount("debits") != 0 ? "-" : "" }}{{ amount("debits") | formatMoney("s") }}</span>
        </h5>
        <div v-if="settlementItem('DEBITS')">
          <div v-for="(item, index) in settlementItem('DEBITS')" :key="index" class="flex">
            <div class="wide">
              <p>{{ item[1] | formatMoney("s") }}</p>
            </div>
            <div class="right-para">
              <p>{{ item[0] }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="module-l">
        <h5 class="bottom-border">
          Other Withholdings: <span>{{ amount("withholding") != 0 ? "-" : "" }}{{ amount("withholding") | formatMoney("s") }}</span>
        </h5>
        <div v-if="settlementItem('WITHHOLDING')">
          <div v-for="(item, index) in settlementItem('WITHHOLDING')" :key="index" class="flex">
            <div class="wide">
              <p>{{ item[1] | formatMoney("s") }}</p>
            </div>
            <div class="right-para">
              <p>{{ item[0] }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="gray-bg module-m">
        <h5 class="net-total">
          Total Earnings (Net)<span>{{ amount("net") | formatMoney("s") }}</span>
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    details: {
      type: Object,
      required: false,
      default: () => {},
    },
    adhocDepositSummaryDetails: {
      type: Object,
      required: false,
      default: () => null,
    }
  },
  computed: {
    resize() {
      if (window.screen.height >= 1080) {
        return 50;
      }
      const resizeHieigh = 1080 - window.screen.height;
      return 50 - resizeHieigh;
    },
    getLocation() {
      return this.$props.details.clientLocation ? this.$props.details.clientLocation["name"] : "";
    },
    getName() {
      return this.$props.details.partner ? this.partnerNameNullCheck(this.$props.details.partner) : "";
    },
    getAddressLine1() {
      const addr = this.$props.details.partner.address;
      return addr ? `${this.checkAddr(addr["address1"]) ? `${addr["address1"]},` : ""}` : "";
    },
    getAddressLine2() {
      const addr = this.$props.details.partner.address;
      return addr ? `${this.checkAddr(addr["address2"]) ? `${addr["address2"]},` : ""}` : "";
    },
    getCityState() {
      const addr = this.$props.details.partner.address;
      return addr
        ? `${this.checkAddr(addr["city"]) ? `${addr["city"]},` : ""} ${this.checkAddr(addr["state"]) ? addr["state"] : ""} ${this.checkAddr(addr["postalCode"]) ? addr["postalCode"] : ""}`
        : "";
    },
    getPayPeriod() {
      return this.$props.details.payPeriod.split("-");
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("enableDownload");
    });
  },
  methods: {
    amount(value) {
      switch (value) {
        case "business":
          return this.$props.details.check["BUSINESS_INCOME"] ? this.$props.details.check["BUSINESS_INCOME"].amount : 0;
        case "credits":
          return this.$props.details.check["CREDITS"] ? this.$props.details.check["CREDITS"].amount : 0;
        case "debits":
          return this.$props.details.check["DEBITS"] ? this.$props.details.check["DEBITS"].amount : 0;
        case "withholding":
          return this.$props.details.check["WITHHOLDING"] ? this.$props.details.check["WITHHOLDING"].amount : 0;
        case "net":
          return this.$props.details.check["NET"] ? this.$props.details.check["NET"].amount : 0;
      }
    },
    settlementItem(value) {
      const item = this.$props.details.check[value]?.items;
      const map = new Map();
      if (item?.length) {
        item.forEach((element) => {
          if (map.has(element.name)) {
            map.set(element.name, element.amount + map.get(element.name));
          } else {
            map.set(element.name, element.amount);
          }
        });
        return map.entries();
      } else {
        return false;
      }
    },
    checkAddr(addr) {
      return addr != null && addr != "" && addr != "NULL";
    },
    isTaxExempted(item) {
      return item?.tax_exempted?.toLowerCase() === 'y' ? 'tax-exempted' : '';
    },
    partnerNameNullCheck(partner) {
      return `${partner.firstName != null && partner.firstName != "" && partner.firstName != "NULL" ? partner.firstName : ""} ${
        partner.lastName != null && partner.lastName != "" && partner.lastName != "NULL" ? partner.lastName : ""
      }`;
    },
  },
};
</script>

<style scoped>
.box {
  height: 250px;
  width: 100%;
}
.cell350 {
  width: 350px;
}
.margin-left-36 {
  margin-left: 36px;
}
.adhoc-deposit-flyout p {
  margin-bottom: 5px;
}
.adhoc-deposit-flyout h5 {
  margin-bottom: 10px;
}
.adhoc-deposit-flyout .line-item{
  margin-top: 5px;
} 
</style>
