const PRODUCT = "QuickBooks Enterprise Solutions";
const QUICKBOOK_VERSION = "Version 28.0D";
const IIF_STATIC_HEADER = `!HDR\tPROD\tVER\tREL\tIIFVER\tDATE\tTIME\tACCNTNT\tACCNTNTSPLITTIME \nHDR\t${PRODUCT}\t${QUICKBOOK_VERSION}\tRelease R16P\t1\t2021-03-19\t1616217515\tN\t0\n!CUSTNAMEDICT\tINDEX\tLABEL\tCUSTOMER\tVENDOR\tEMPLOYEE\n!ENDCUSTNAMEDICT\n`;
const IIF_STATIC_HEADER_DATA = `CUSTNAMEDICT\t0\tDrvrs Lic Exp.\tN\tY\tN\nCUSTNAMEDICT\t1\tIns. Exp.\tN\tY\tN\nCUSTNAMEDICT\t2\tDMV Report\tN\tY\tN\nCUSTNAMEDICT\t3\tDepartment\tN\tY\tN\nCUSTNAMEDICT\t4\tStart Date\tN\tY\tN\nCUSTNAMEDICT\t5\tDOB\tN\tN\tN\nCUSTNAMEDICT\t6\tDrvrs Lic. #\tN\tY\tN\nCUSTNAMEDICT\t7\tWeb\tY\tN\tN\nCUSTNAMEDICT\t8\tWebsite ID #\tY\tN\tN\nCUSTNAMEDICT\t9\tNotes\tN\tY\tN\nCUSTNAMEDICT\t10\tBusiness License #\tN\tY\tN\nCUSTNAMEDICT\t11\tBusiness License Exp.\tN\tY\tN\nCUSTNAMEDICT\t12\tHealth and Safety Exp.\tN\tY\tN\nCUSTNAMEDICT\t13\tDPDLogID\tN\tY\tN\nCUSTNAMEDICT\t14\t \tN\tN\tN\nCUSTNAMEDICT\t15\t \tN\tN\tN\nCUSTNAMEDICT\t16\t \tN\tN\tN\nCUSTNAMEDICT\t17\t \tN\tN\tN\nCUSTNAMEDICT\t18\t \tN\tN\tN\nCUSTNAMEDICT\t19\t \tN\tN\tN\nCUSTNAMEDICT\t20\t \tN\tN\tN\nCUSTNAMEDICT\t21\t \tN\tN\tN\nCUSTNAMEDICT\t22\t \tN\tN\tN\nCUSTNAMEDICT\t23\t \tN\tN\tN\nCUSTNAMEDICT\t24\t \tN\tN\tN\nCUSTNAMEDICT\t25\t \tN\tN\tN\nCUSTNAMEDICT\t26\t \tN\tN\tN\nCUSTNAMEDICT\t27\t \tN\tN\tN\nCUSTNAMEDICT\t28\t \tN\tN\tN\nCUSTNAMEDICT\t29\t \tN\tN\tN\nENDCUSTNAMEDICT\n`;
const IIF_STATIC_HEADER_INFO = `!VEND\tNAME\tREFNUM\tTIMESTAMP\tPRINTAS\tADDR1\tADDR2\tADDR3\tADDR4\tADDR5\tVTYPE\tCONT1\tCONT2\tPHONE1\tPHONE2\tFAXNUM\tEMAIL\tNOTE\tTAXID\tLIMIT\tTERMS\tNOTEPAD\tSALUTATION\tCOMPANYNAME\tFIRSTNAME\tMIDINIT\tLASTNAME\tCUSTFLD1\tCUSTFLD2\tCUSTFLD3\tCUSTFLD4\tCUSTFLD5\tCUSTFLD6\tCUSTFLD7\tCUSTFLD8\tCUSTFLD9\tCUSTFLD10\tCUSTFLD11\tCUSTFLD12\tCUSTFLD13\tCUSTFLD14\tCUSTFLD15\t1099\tHIDDEN\tDELCOUNT\n`;

export const IIF_COMPLETE_STATIC_DATA = `${IIF_STATIC_HEADER}${IIF_STATIC_HEADER_DATA}${IIF_STATIC_HEADER_INFO}`;

export const MARK_AS_DISTRIBUTION_HEADING = "Mark Invoices as Distributed";
export const MARK_AS_DISTRIBUTION_BTN = "Mark as Distributed";
export const MARK_AS_DISTRIBUTION_TEXT = "Please verify through the Payment processor that the selected invoices have indeed distributed funds to Partners.";

export const MARK_AS_NOT_DISTRIBUTION_HEADING = "Mark Invoices as Not Distributed";
export const MARK_AS_NOT_DISTRIBUTION_BTN = "Not Distributed";
export const MARK_AS_NOT_DISTRIBUTION_TEXT = "Did these payment really not get distributed? This will move the payments back into the “To Be Distributed” tab.";

export const BRANCH_PAYMENT_HEADING = "Send Payments to Branch";
export const BRANCH_PAYMENT_BTN = "Send to Branch";
export const BRANCH_PAYMENT_TEXT = "This will tell Branch to sent the payments to the partners and will also mark the payment with its invoice and settlement as “Distributed”.";

export const SUCCESS_CODES = ["6D89", "7HUI", "9Z", "LTER72", "J36IO"];

export const branchEmail = `
<body class="clean-body" style="margin: 0; padding: 0; -webkit-text-size-adjust: 100%; background-color: #FFFFFF;">
    <div class="txtTinyMce-wrapper" style="padding:50px; font-size: 14px; line-height: 1.2; color: #555555; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 17px;">
        <p style="margin: 0; font-size: 14px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 17px; margin-top: 0; margin-bottom: 0;">Hi {{name}},</p>
        <p style="margin: 0; font-size: 14px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 17px; margin-top: 0; margin-bottom: 0;"><br/>Here is the link to setup your personalized DDI digital wallet via Branch:</p>
        <p style="margin: 0; font-size: 14px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 17px; margin-top: 0; margin-bottom: 0;"><br/></p>
        <p style="margin: 0; font-size: 14px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 17px; margin-top: 0; margin-bottom: 0;"><a href="{{magicLink}}">{{magicLink}}</a></p>
        <p style="margin: 0; font-size: 14px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 17px; margin-top: 0; margin-bottom: 0;"><br/></p>
        <p style="margin: 0; font-size: 14px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 17px; margin-top: 0; margin-bottom: 0;">Thanks,</p>
        <p style="margin: 0; font-size: 14px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 17px; margin-top: 0; margin-bottom: 0;"><br/></p>
        <p style="margin: 0; font-size: 14px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 17px; margin-top: 0; margin-bottom: 0;">Delivery Drivers, Inc.</p>
    </div>
</body>
`;
