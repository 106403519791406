<template>
  <fragment>
    <div class="grid">
      <div class="col-12">
        <h2>Documents</h2>
        <div class="wrapper">
          <div class="spread-md module-large">
            <form-select label="Select Location" :value="locationSelected" :options="locationOptions" option-label="name" />
          </div>
          <div v-if="this.getPermission('dwnldDocuments')" class="download">
            <button class="button right" v-if="loader" :disabled="loader"> Downloading please wait...</button>
            <button class="button right" v-else @click="downloadAllDocuments" :disabled="!disableDownload"> <i class="icon-download"></i>&nbsp;Download All Documents</button>
          </div>
        </div>
        <div class="download" v-if="getPermission('dwnldDocuments')">
          <button class="button" @click="multiDownload" :disabled="disableDownload"><i class="icon-download"></i>&nbsp;Download</button>
        </div>
      </div>
    </div>
    <data-table :headers="headers" :entries="computedDocuments">
      <template #name="{ item }">
        <input v-if="getPermission('dwnldDocuments')" type="checkbox" class="checkbox-input-deposit" @click="multiSelect(item, $event)">
        <a @click="previewTemplate(item.id, item.title,item.signedOn)">
          <span>{{ item.title }}</span>
          <i class="icon-external-link right-icon"></i>
        </a>
      </template>
      <template #signedOn="{ item }">
        {{ item.signedOn }}
      </template>
    </data-table>
    <document-preview
      :pdf="false"
      :downloadable="getPermission('dwnldDocuments')"
      :preview="false"
      :templateid="previewID"
      :partnerObj="partnerObj"
      @pdfGenerationComplete="pdfGenerationComplete"
      @changePreview="pdfGenerationComplete"
    ></document-preview>
  </fragment>
</template>
<script>
import micromodal from "micromodal";
import { FormSelect } from "@/components";
import { Fragment } from "vue-fragment";
import DataTable from "@/components/table/DataTable";
import TableHeader from "../../../../components/table/TableHeader";
import DocumentPreview from "@/modules/shared/documents/DocumentPreview";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/Base64Encoding";
import { convertBufferToContent } from "@/util/BufferContentConverter";
import html2pdf from "html2pdf.js";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import handlebars from "handlebars";
import { mapGetters } from "vuex";

export default {
  name: "PartnerDocuments",
  components: {
    DataTable,
    FormSelect,
    Fragment,
    DocumentPreview,
    // VueHtml2pdf
  },
  data() {
    return {
      signedDocuments: [],
      selectedDocuments:[],
      locationSelected: "",
      locationOptions: [],
      clientIDs: [],
      locationIDs: [],
      locationPartnerIDs: [],
      previewID: "",
      partnerObj: {
        date:"",
        partner:{
          signature:"",
          name:"name",
          phone:"phone",
          address:{
            street1:"street1",
            city:"city",
            zip:"zip"
          }
        }
      },
      signObj:{date:"",signature:""},
      w9DocId: "",
      loader:false,
      disableDownload:true
    };
  },
  computed: {
    ...mapGetters(["getPermission"]),
    headers() {
      return [
        new TableHeader({ label: "Name", headerClass: "cell50", name: "name", cellContents: "name", cellClass: "link-color" }),
        new TableHeader({ label: "Signed On", headerClass: "cell15 ", name: "signedOn", cellContents: "signedOn" }),
      ];
    },
    computedDocuments() {
      return this.signedDocuments;
    },
    partnerID() {
      return this.$route.params.id;
    },
  },
  mounted() {
    const params = { id: this.partnerID }
    restApi.post('partner/getFinanceLocations', encodeWithParam(params))
      .then((data) => {
        if (data.data.result.length > 0) {
          this.locationPartnerID = data.data.result[0].id;
          this.locationPartnerIDs = data.data.result.map((item) => item.id);
          const locations = data.data.result.map((item) => {
            return {
              id: item.locationId,
              name: item.locationName,
            }
          });
          this.locationOptions = Array.from(new Set(locations));
          this.locationSelected = data.data.result[0].locationId;
        }
      });
    restApi.post('partner/getSignedDocuments', encodeWithParam(params))
      .then((data) => {
        let partner=data.data.partnerInfo;
        this.partnerObj.partner.name=partner.name;
        this.partnerObj.partner.signature=this.decodeStr(partner.electronicSignature);
        this.partnerObj.partner.phone=partner.phone
        this.partnerObj.partner.address.street1=partner.street1
        this.partnerObj.partner.address.city=partner.city
        this.partnerObj.partner.address.zip=partner.zip
        this.signObj.signature = this.decodeStr(data.data.electronicSignature)

        let signedDocs = data.data.result.filter((item) => {
          return !["Insurance"].includes(item.title)
        })
        signedDocs.map((item) =>{
          if(item.title == 'W9'){
            item.title = item.name
            this.w9DocId = item.fileID
          }
        })
        this.signedDocuments = signedDocs;
      });
    micromodal.init({
      onShow: function () {
        document.body.classList.add("no-scroll");
      },
      onClose: function () {
        document.body.classList.remove("no-scroll");
      },
    });
  },
  methods: {
    pdfGenerationComplete() {
      this.previewID = "";
      this.signObj.date ="";
    },
    previewTemplate(templateID, docTitle,signedDate) {
      if(docTitle.includes('.pdf')){
      let param = { fileID : this.w9DocId }
       restApi.post("employee/getW9Path", encodeWithParam(param)).then(({data}) => {
        let url = convertBufferToContent(data.bufferInHex,data.contentType);
        let link = document.createElement('a');
        link.href = url;
        link.download = docTitle
        link.click()
    });
      }
      else{
         this.previewID = templateID;
         this.signObj.date = signedDate;
         this.partnerObj.date=signedDate
      }
    },

    multiSelect(val, e){
      if(e.target.checked){
          if (val.id != this.selectedDocuments.map((item) => item.id) && this.selectedDocuments.length > 0) {
                this.selectedDocuments.push(val.id)
          }
          else{
              this.selectedDocuments.push(val.id)
          }
      }
      else{
        this.selectedDocuments.splice(this.selectedDocuments.indexOf(val.id), 1)
      }
      this.selectedDocuments.length ? this.disableDownload=false :this.disableDownload=true
    },

    downloadAllDocuments(){
      if(this.signedDocuments.length){
      this.signedDocuments.map(item=>{
        if(!item.title.includes('W9')){
          this.selectedDocuments.push(item.id)
        }
      })
      this.multiDownload()
      } 

    },

    decodeStr(str) {
      try {
        decodeURIComponent(str);
      } catch (e) {
        return str;
      }
      return decodeURIComponent(str);
    },
    async multiDownload(){
      if(this.selectedDocuments.length){
        this.loader=true;
      let obj={documentIDs:this.selectedDocuments,partnerID:this.$route.params.id}
        let data=await restApi.post('partner/getDocumentsHtml', encodeWithParam(obj))
      const htmlContents = data.data.result;
      const pdfPromises = [];
      const zip = new JSZip();
      htmlContents.forEach(async (item) => {

        this.partnerObj.date=item.signedOn
      //   let partner={
      //   date:item.signedOn,
      //   partner:{
      //     signature:item.signature
      //   }
      // }
        const pdfPromise = new Promise((resolve) => {
            const element = document.createElement('div');
            let htm=this.decodeStr(item.html)
            let myHtml = htm.replace(/(<style[\w\W]+style>)/g, "");
            myHtml = myHtml.replaceAll('{{partner.signature}}', `<span  style="font-family: La Belle Aurore; font-style: italic; font-weight: 900; font-size: 20px;">${this.decodeStr(this.partnerObj.partner.signature)}</span>`);
            myHtml = handlebars.compile(myHtml)(this.partnerObj);
            element.innerHTML = myHtml;
            const opt = {
                margin: 1,
                filename: `file_${item.title}.pdf`,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            };
           resolve(html2pdf().from(element).set(opt).outputPdf().then(pdf => {
                zip.file(`file_${item.title}.pdf`, pdf, { binary: true });

            }))
        });
        pdfPromises.push(pdfPromise);
      });
      await Promise.all(pdfPromises);
        const content = zip.generateAsync({ type: 'blob' });
        content.then(blob => {
          var checkboxes = document.getElementsByClassName('checkbox-input-deposit');
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = false;
            }
            this.disableDownload=true;
          saveAs(blob, `${this.$route.params.id}.zip`);
          this.loader=false;
          this.selectedDocuments=[];
        });
      }
    },
  },
};
</script>
<style scoped>
.checkbox-input-deposit{
  width: 16px;
    cursor: pointer;
    height: 15px;
    margin-top: 2px;
}
.wrapper .spread-md{
  min-width: 40%;
}
.wrapper{
  display: flex;
}
.download{
  margin-top: 16px;
  width: 50%;
}
.download .download-btn{
  cursor: pointer;
}
.download .green{
  background: green;
  color: white;
  border: 1px solid ;
  padding: 10px;
  border-radius: 5px;
}
</style>
