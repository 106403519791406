<template>
  <base-modal id="upload-partner-requests" class="">
    <h4>Upload Partner Requests</h4>
    <br><br>
    <date-picker v-model="startDateJS" format="MM/DD/YYYY" placeholder="MM/DD/YYYY" :disabled-date="(date) => date < new Date()" @input="updateStartDate($event)"></date-picker>
    <date-picker v-model="endDateJS" format="MM/DD/YYYY" placeholder="MM/DD/YYYY" :disabled-date="(date) => date <= new Date()" @input="updateEndDate($event)"></date-picker>
    <p v-if="invalidDate" class="error-text">End date should be ahead of start date</p>
    <p v-if="sameDate" class="error-text">Start date and end date cannot be same.</p>
    <p v-if="pendingHCR" class="error-text">The selected date already have the head counts registered.</p>
    <div class="upload-file">
      <label>Upload Partner's Request File (CSV only)</label>
      <input id="partnerRequestFile" ref="fileUpload" type="file" name="partnerRequestFile" @change="uploadFile" />
    </div>
    <div class="response-msgs">
      <div v-if="invalidFile" class="failed">Invalid file format</div>
      <div v-if="uploadSuccess" class="success">Upload Successful</div>
      <div v-if="uploadFail" class="failed">Error during upload</div>
    </div>
    <div class="button-group">
      <button class="button primary primary-blue" :disabled="disabled || !isDisabled" @click="uploadData">
        Upload
        <span v-if="loader" class="loading loader-class"><Loader /></span>
      </button>
      <button class="button secondary" @click="closeModal">Cancel</button>
    </div>
  </base-modal>
</template>
<script>

import { DateTime } from "luxon";
import BaseModal from "@/components/modals/BaseModal";
import micromodal from "micromodal";
import { mapGetters } from "vuex";
import { restApi } from "@/http/http.config";
import Loader from "@/components/navigation/Loader";
import DatePicker from 'vue2-datepicker';

export default {
  name: "UploadPartnerRequestModal",
  components: { 
    BaseModal, 
    Loader,
    DatePicker
  },
  props: {
    clientID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      startDateJS: "",
      endDateJS: "",
      startDate: "",
      endDate: "",
      invalidFile: false,
      uploadSuccess: false,
      loader: false,
      disabled: false,
      files: "",
      fileName: "",
      uploadFail: false,
      existingHCR: []
    };
  },
  computed: {
    ...mapGetters(["getUsername"]),
    notEmptyDates() {
      return this.startDate != "" && this.endDate != "";
    },
    invalidDate() {
      return this.notEmptyDates && new Date(this.startDate).getTime() > new Date(this.endDate).getTime();
    },
    sameDate() {
      return this.notEmptyDates && new Date(this.startDate).getTime() === new Date(this.endDate).getTime();
    },
    pendingHCR() {
      return this.notEmptyDates &&
      this.existingHCR.some((hcr) => DateTime.fromISO(hcr.startDate).toJSDate() <= new Date(this.startDate) && new Date(this.startDate) <= DateTime.fromISO(hcr.endDate).toJSDate() ||
      DateTime.fromISO(hcr.startDate).toJSDate() <= new Date(this.endDate) && new Date(this.endDate) <= DateTime.fromISO(hcr.endDate).toJSDate())
    },
    isDisabled() {
      return !this.invalidFile && this.fileName != "" && !this.invalidDate && !this.sameDate && !this.pendingHCR;
    }
  },
  mounted() {
    restApi.get(`/location/getPendingHCR`).then((data) => {
      this.existingHCR = data.data.pendingHCR
    })
  },
  methods: {
    inputDateFormatter(value){
      return DateTime.fromJSDate(value).toFormat('MM/dd/yyyy');
    },
    updateStartDate(value) {
      this.startDateJS = value;
      this.startDate = this.inputDateFormatter(value);
    },
    updateEndDate(value) {
      this.endDateJS = value;
      this.endDate = this.inputDateFormatter(value);
    },
    async uploadFile(e) {
      this.files = e.target.files[0];
      this.fileName = this.files.name.split(".")[0];
      const fileExt = this.files.name.split(".")[this.files.name.split(".").length - 1];
      this.invalidFile = fileExt == "csv" ? false : true;
    },
    async uploadData() {
      this.loader = true;
      this.disabled = true;
      const formData = new FormData();
      formData.append("fileName", this.fileName);
      formData.append("file", this.files);
      formData.append("startDate", this.startDate);
      formData.append("endDate", this.endDate);
      formData.append("clientID", config.CLIENT_ID);
      formData.append("username", this.getUsername);
      await restApi.post(`/location/uploadPartnerRequests`, formData)
        .then(({data}) => {
          this.loader = false;
          this.disabled = false;
          if (data.success) {
            this.uploadSuccess = true;
            setTimeout(() => this.closeModal(), 1500);
          } else {
            this.uploadFail = true;
            setTimeout(() => this.closeModal(), 1500);
          }
        })
    },
    closeModal() {
      micromodal.close("upload-partner-requests");
      this.startDateJS = "";
      this.endDateJS = "";
      this.startDate = "";
      this.endDate = "";
      this.invalidFile = false;
      this.uploadSuccess = false;
      this.$refs.fileUpload.value = "";
      this.fileName = "";
      this.files = "";
      this.uploadFail = false;
      this.$emit("refresh");
    }
  },
};
</script>
<style scoped>
.date-text {
  color: #707070;
  font-size: 14px;
}
.date-input {
  width: 168px;
  margin-right: 18px;
}
.date-error {
  border-radius: 4px;
  border: 1px solid #E71742;
}
.error-text {
  color: #E71742;
  font-size: 14px;
  line-height: normal;
}
.response-msgs .failed {
  color: brown;
}
.response-msgs {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
}
.upload-file {
  margin-top: 48px;
}
.response-msgs .success {
  color: seagreen;
}
.primary-blue {
  background-color: #0071DC;
}
.primary-blue:disabled {
  opacity: 0.5;
  background-color: #0071DC;
}
.pad-left-90 {
  padding-left: 90%;
}
.margin-left-60 {
  margin-left: 60px;
}
.loader-class {
  color: white;
}
</style>
