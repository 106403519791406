<template>
  <fragment>
    <div v-if="alwaysShow || perPage < total" class="paginator flex">
      <a :class="{ 'color-paginator': pageNumber == 1 || loading }" @click="goToPrevious"><i class="icon-chevron-left"></i></a>
      <input v-model.lazy="pageNumber" class="page-display" @keyup.enter="goToDefinedPage" />
      <div class="pageTotal">/ {{ totalNumPages }}</div>
      <a :class="{ 'color-paginator': pageNumber >= totalNumPages || loading }" @click="goToNext"><i class="icon-chevron-right"></i></a>
    </div>
    <div v-if ="total <= 50 && !loading" class="paginator flex">
      <a :class="{ 'color-paginator': pageNumber == 1 || loading }" @click="goToPrevious"><i class="icon-chevron-left"></i></a>
      <input v-model.lazy="pageNumber" class="page-display" readonly />
      <div class="pageTotal">/ {{ "1" }}</div>
      <a :class="{ 'color-paginator': pageNumber >= totalNumPages || loading || pageNumber == 1}" @click="goToNext"><i class="icon-chevron-right"></i></a>
    </div>

    <div v-if="displayRowsPerPageSelector" class="flex header-small perPage">
      <p>Rows per page</p>
      <select v-model="count" name="perPage" class="flex" @change="onChangePerPage($event)">
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="25">25</option>
        <option value="50">50</option>
      </select>
    </div>
    <div v-if="displayRowsPerPageMaxSelector" class="flex header-small perPage">
      <p>Rows per page</p>
      <select v-model="count" name="perPage" class="flex" @change="onChangePerPage($event)">
        <option value="50">50</option>
        <option value="75">75</option>
        <option value="100">100</option>
      </select>
    </div>
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";

export default {
  components: { Fragment },
  props: {
    total: {
      type: Number,
      required: true,
      default: 0,
    },
    perPage: {
      type: Number,
      required: false,
      default: 25,
    },
    syncPage: {
      type: Number,
      required: false,
      default: 0,
    },
    displayRowsPerPageSelector: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayRowsPerPageMaxSelector: {
      type: Boolean,
      required: false,
      default: false,
    },
    alwaysShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    startingPage: {
      type: Number,
      required: false,
      default: 1,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  emits: ["onPaginate", "onChangePerPage"],
  data() {
    return {
      pageNumber: 1,
      count: this.perPage,
    };
  },
  computed: {
    totalNumPages() {
      return Math.ceil(this.$props.total / this.$props.perPage);
    },
  },
  watch: {
    perPage() {
      this.count = this.$props.perPage;
    },
    syncPage() {
      if (this.$props.syncPage != 0) {
        this.pageNumber = this.$props.syncPage;
      }
    },
  },
  methods: {
    goToNext() {
      this.pageNumber++;
      this.$emit("onPaginate", { offset: (this.pageNumber - 1) * this.perPage, limit: this.perPage, pageNumber: this.pageNumber });
    },
    goToPrevious() {
      this.pageNumber--;
      this.$emit("onPaginate", { offset: (this.pageNumber - 1) * this.perPage, limit: this.perPage, pageNumber: this.pageNumber });
    },
    goToDefinedPage() {
      this.$emit("onPaginate", { offset: (this.pageNumber - 1) * this.perPage, limit: this.perPage, pageNumber: this.pageNumber });
    },
    onChangePerPage(event) {
      this.$emit("onChangePerPage", { perPage: Number(event.target.value) });
    },
  },
};
</script>
