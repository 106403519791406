<template>
  <fragment>
    <flyout :name="$props.name" :title="getName">
      <template #action>
        <div class="edit">
          <a v-if="!hideProfileBtn" class="button secondary" @click="doAction"> Full Profile </a>
        </div>
      </template>

      <div class="deposit-flyout-content deposit-flyout">
        <!-- <h3 class="marginT24">LOCATION</h3> -->
        <!-- <h5>{{ getLocation }}</h5> -->
        <div class="flex module marginT58">
          <div class="cell70">
            <h3>DEPOSIT SUMMARY</h3>
          </div>
          <div class="flyout-icon-download cell40">
            <a v-if="getPermission('dwnldDeposits')" :class="{ 'pointer-event': !downloadEnable }" @click="generateReport"> <i class="icon-download"></i>Download PDF</a>
            <vue-html2pdf
              ref="html2Pdf"
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="false"
              :paginate-elements-by-height="1500"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="letter"
              filename=""
              pdf-orientation="portrait"
              pdf-content-width="100%"
              :html-to-pdf-options="htmlToPdfOptions"
              @beforeDownload="generateFileName"
              @hasGenerated="hasGenerated($event)"
            >
              <section slot="pdf-content">
                <download-deposit :details="$props.details" @enableDownload="enableDownload" :adhocDepositSummaryDetails="adhocDepositSummaryDetails"/>
              </section>
            </vue-html2pdf>
          </div>
        </div>
        <div v-if="lineItemLoader" class="loader-cls">
          <loader />
        </div>
        <div v-else>
          <div class="module-m">
            <h5 class="bottom-border">
              Business Income: <span>{{ amount("business") | formatMoney("s") }}</span>
            </h5>
            <div v-if="adhocDepositSummaryDetails" class="adhoc-deposit-flyout">
              <div v-for="(item, index) in adhocDepositSummaryDetails.items" :key="index" class="line-item">
                <div class="flex">
                  <div class="wide">
                    <p :class="isTaxExempted(item)">{{ item.amount | formatMoney("s") }}</p>
                  </div>
                  <div class="right-para" >
                    <p :class="isTaxExempted(item)">{{ item.name === "Delivery" ? `Deliveries` : item.name === "Tip" ? "Tips" : item.name }}</p>
                  </div>
                </div>
                <div class="flex">
                  <div class="wide">
                    <p>Remarks: </p>
                  </div>
                  <div class="right-para" >
                    <p>{{ item.remarks }}</p>
                  </div>
                </div>
                  <h5 class="bottom-border"></h5>
              </div>
            </div>
            <div v-else-if="settlementItem('BUSINESS_INCOME')">
              <div v-for="(item, index) in settlementItem('BUSINESS_INCOME')" :key="index" class="flex">
                <div class="wide">
                  <p>{{ item[1].amount | formatMoney("s") }}</p>
                </div>
                <div class="right-para">
                  <p>{{ item[0] === "Delivery" ? `Deliveries` : item[0] === "Tip" ? "Tips" : item[0] }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="module-m">
            <h5 class="bottom-border">
              Credits: <span>{{ amount("credits") | formatMoney("s") }}</span>
            </h5>
            <div v-if="settlementItem('CREDITS')">
              <div v-for="(item, index) in settlementItem('CREDITS')" :key="index" class="flex">
                <a v-if="item[1].editable && showAddLineItem" @click="deleteLineItem(index, 'CREDITS')"> <i class="icon-trash-2"></i></a>
                <div class="wide">
                  <p>{{ item[1].amount | formatMoney("s") }}</p>
                </div>
                <div class="right-para">
                  <p>{{ item[0] }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="module-m">
            <h5 class="bottom-border">
              Debits: <span>{{ amount("debits") != 0 ? "-" : "" }}{{ amount("debits") | formatMoney("s") }}</span>
            </h5>
            <div v-if="settlementItem('DEBITS')">
              <div v-for="(item, index) in settlementItem('DEBITS')" :key="index" class="flex">
                <a v-if="item[1].editable && showAddLineItem" @click="deleteLineItem(index, 'DEBITS')"> <i class="icon-trash-2"></i></a>
                <div class="wide">
                  <span v-if="item"></span>
                  <p>{{ item[1].amount | formatMoney("s") }}</p>
                </div>
                <div class="right-para">
                  <p>{{ item[0] }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="module-m">
            <h5 class="bottom-border">
              Other Withholdings: <span>{{ amount("withholding") != 0 ? "-" : "" }}{{ amount("withholding") | formatMoney("s") }}</span>
            </h5>
            <div v-if="settlementItem('WITHHOLDING')">
              <div v-for="(item, index) in settlementItem('WITHHOLDING')" :key="index" class="flex">
                <a v-if="item[1].editable && showAddLineItem" @click="deleteLineItem(index, 'WITHHOLDING')"> <i class="icon-trash-2"></i></a>
                <div class="wide">
                  <p>{{ item[1].amount | formatMoney("s") }}</p>
                </div>
                <div class="right-para">
                  <p>{{ item[0] }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="gray-bg module-large">
          <h5 class="net-total">
            (Net) Total Earnings <span>{{ amount("net") | formatMoney("s") }}</span>
          </h5>
        </div>
        <div>
          <p v-if="showAddLineItem">
            <a @click="showModal"><i class="icon-plus-circle"></i>Add Line Item</a>
          </p>
        </div>
      </div>
    </flyout>
    <deposit-line-item :details="$props.details" @save="addLineItem" @updateDistribution="reAssignDistribution"/>
  </fragment>
</template>
<style scoped>
.icon-trash-2 {
  color: #c2272c;
}
.loader-cls {
  min-height: 450px;
  text-align: center;
  padding-top: 80px;
}
.adhoc-deposit-flyout p {
  margin-bottom: 5px;
}
.adhoc-deposit-flyout h5 {
  margin-bottom: 10px;
}
.adhoc-deposit-flyout .line-item{
  margin-top: 5px;
} 
</style>
<script>
import Flyout from "@/components/flyouts/Flyout";
import { Loader } from "@/components";
import DownloadDeposit from "@/modules/admin/accounting/invoicing/DownloadDeposit";
import DepositLineItem from "@/modules/admin/accounting/invoicing/AddDepositLineItemModal";
import { Fragment } from "vue-fragment";
import VueHtml2pdf from "vue-html2pdf";
import { mapActions, mapGetters } from "vuex";
import micromodal from "micromodal";
import { restApi } from "../../../../http/http.config";
import { encodeWithParam } from "../../../../util/Base64Encoding";
export default {
  name: "DepositSummaryFlyout",
  components: { Flyout, DownloadDeposit, Fragment, VueHtml2pdf, DepositLineItem, Loader },
  props: {
    name: {
      type: String,
      required: true,
      default: "default",
    },
    details: {
      type: Object,
      required: false,
      default: () => {},
    },
    hideProfileBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      htmlToPdfOptions: {
        filename: "",
        html2canvas: { useCORS: true, height: 1024, logging: false },
      },
      downloadEnable: false,
      deleteIndex: "",
      distributionTotal: 0,
      showAddLineItem: false,
      lineItemLoader: false,
    };
  },
  computed: {
    ...mapGetters(["getPermission"]),
    getName() {
      return this.$props.details.partner ? this.partnerNameNullCheck(this.$props.details.partner) : "";
    },
    getLocation() {
      return this.$props.details.clientLocation ? this.$props.details.clientLocation["name"] : "";
    },
    adhocDepositSummaryDetails() {
      return this.$props.details?.pay_type?.toUpperCase() === 'ADHOC' ? this.$props.details?.check?.BUSINESS_INCOME : null;
    }
  },
  methods: {
    ...mapActions(["showFlyout"]),
    doAction() {
      this.showFlyout("");
      this.$router.push({ name: "partner", params: { id: this.$props.details.partnerID } });
    },
    generateFileName() {
      this.htmlToPdfOptions.filename = `${this.$props.details.partner["firstName"]}_DepositSummary`;
    },
    hasGenerated() {
      this.$emit("pdfGenerationComplete", "");
    },
    async generateReport() {
      await this.$refs.html2Pdf.generatePdf();
    },
    enableDownload() {
      this.downloadEnable = true;
    },
    getDistributionTotal() {
      restApi.post('accounting/getDistributionTotal', encodeWithParam({id: this.$props.details.distributionID})).then((data) => {
        const res = data.data.result[0]
        this.distributionTotal = res.totalPayout;
        this.$props.details.distributionTotal=res.totalPayout;
        if (res.status != "DISTRIBUTED") {
          this.showAddLineItem = true;
        }
      }).catch((err) => {console.log(err)})
    },
    reAssignDistribution(total){
      this.distributionTotal=total
    },
    amount(value) {
      switch (value) {
        case "business":
          return this.$props.details.check["BUSINESS_INCOME"] ? this.$props.details.check["BUSINESS_INCOME"].amount : 0;
        case "credits":
          return this.$props.details.check["CREDITS"] ? this.$props.details.check["CREDITS"]?.amount : 0;
        case "debits":
          return this.$props.details.check["DEBITS"] ? this.$props.details.check["DEBITS"].amount : 0;
        case "withholding":
          return this.$props.details.check["WITHHOLDING"] ? this.$props.details.check["WITHHOLDING"].amount : 0;
        case "net":
          return this.$props.details.check["NET"] ? this.$props.details.check["NET"].amount : 0;
      }
    },
    settlementItem(value) {
      const item = this.$props.details.check[value]?.items;
      const map = new Map();
      if (item?.length) {
        item.forEach((element) => {
          if (map.has(element.name)) {
            const elementName = element.name;
            map.set(elementName, { amount: element.amount + map.get(element.name)?.amount });
          } else {
            if (element.editable) {
              const elementName = element.name;
              map.set(elementName, { amount: element.amount, editable: element.editable });
            } else {
              const elementName = element.name;
              map.set(elementName, { amount: element.amount });
            }
          }
        });
        return map.entries();
      } else {
        return false;
      }
    },
    partnerNameNullCheck(partner) {
      return `${partner.firstName != null && partner.firstName != "" && partner.firstName != "NULL" ? partner.firstName : ""} ${
        partner.lastName != null && partner.lastName != "" && partner.lastName != "NULL" ? partner.lastName : ""
      }`;
    },
    showModal() {
      micromodal.show("depositSummaryLineItem", {});
    },
    addLineItem(data) {
      micromodal.close("depositSummaryLineItem", {}); 
      this.addDepositSummaryLineItem(data);
    },
    deleteLineItem(index, category) {
      this.lineItemLoader = true;
      this.deleteIndex = index;
      const item = this.$props.details.check[category]?.items;
      item.forEach((element, index1) => {
        if (index1 === index) {
          this.netCalc(category, element);
          item.splice(index1, 1);
        }
      });
      let total = 0;
      if (item.length == 0) {
        this.$props.details.check[category].amount = total;
      } else {
        total = this.sum(item);
        this.$props.details.check[category].amount = total;
      }

      this.addDepositSummaryLineItem(this.$props.details.check);
      // recalculate
      

      // this.$apollo.mutate({ mutation: DEPOSIT_SUMMARY_ADD_LINEITEM, variables: { id: this.$props.details.id, check: this.$props.details.check } }).then(() => {
      //   if (this.$props.details.distributionID) {
      //     let total = Number(this.distributionTotal);
      //     this.$apollo.mutate({ mutation: SET_DISTRIBUTION_TOTAL, variables: { id: this.$props.details.distributionID, totalPayout: total } }).then(() => {
      //       this.lineItemLoader = false;
      //     });
      //   }
      // });
    },
    sum(lineItems) {
      return lineItems.reduce((acc, curr) => {
        const num = parseFloat(curr.amount);
        return acc + num;
      }, 0);
    },

    addDepositSummaryLineItem(check) {
      restApi.post('accounting/addDepositSummaryLineItem', encodeWithParam({id: this.$props.details.id, check: check})).then(() => {
        this.lineItemLoader = false;  
        if (this.$props.details.distributionID) {
          console.log("NUMBER",Number(this.distributionTotal))
          let total = Number(this.distributionTotal);
          this.setDistributionTotal(total)
      }
      }).catch((err) => {console.log(err)})
    },
    setDistributionTotal(total) {
      restApi.post('accounting/setDistributionTotal', encodeWithParam({id: this.$props.details.distributionID, total: Number(total)})).then((data) => {
        const res = data.data.result[0]
        console.log(res)
      }).catch((err) => {console.log(err)})
    },

    netCalc(category, element) {
      let netAmount = 0;
      let lineamount = 0;
      if (category == "DEBITS" || category == "WITHHOLDING") {
        netAmount = this.$props.details.check["NET"].amount;
        lineamount = element.amount;
        this.distributionTotal = this.$props.details.distributionTotal = (parseFloat(this.distributionTotal) + parseFloat(lineamount)).toFixed(2);
        this.$props.details.check["NET"].amount = Number((parseFloat(netAmount) + parseFloat(lineamount)).toFixed(2));
        //console.log(this.distributionTotal)
      } else {
        netAmount = this.$props.details.check["NET"].amount;
        lineamount = element.amount;
        this.distributionTotal = this.$props.details.distributionTotal = (parseFloat(this.distributionTotal) - parseFloat(lineamount)).toFixed(2);
        this.$props.details.check["NET"].amount = Number((parseFloat(netAmount) - parseFloat(lineamount)).toFixed(2));
      }
    },
    isTaxExempted(item) {
      return item?.tax_exempted?.toLowerCase() === 'y' ? 'tax-exempted' : '';
    },
  },
  mounted() {
    this.getDistributionTotal()
  }
};
</script>
