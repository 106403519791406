<template>
  <validation-observer v-slot="validationRules">
    <overlay />
    <sticky-header :title="$props.formTitle" classes = "margin-zero"> 
      <h4>{{ $props.subTitle }}</h4>
      <save-cancel :save-enabled="!validationRules.pristine && !validationRules.invalid" @onSave="save" />
    </sticky-header>
    <slot></slot>
    <!--    <Footer />-->

    <pre>{{ JSON.stringify(validationRules, null, 2) }}</pre>
  </validation-observer>
</template>
<script>
import {
  SaveCancel,
  // Footer
} from "@/components";
import { ValidationObserver } from "vee-validate";
import Overlay from "@/components/modals/Overlay";
import StickyHeader from "@/components/layout/StickyHeader";
export default {
  name: "FormWithValidation",
  components: {
    Overlay,
    // Footer,
    SaveCancel,
    StickyHeader,
    ValidationObserver,
  },
  props: {
    formTitle: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
      default: "",
    },
  },
  methods: {
    save() {
      this.$emit("onSave");
    },
  },
};
</script>
