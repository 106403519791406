<template>
  <fragment>
    <div>
      <div class="grid">
        <div class="col-8">
          <h2>Invoices</h2>
          <!-- <p class="client-invoice-header">Review invoices settlements and payouts.</p> -->
        </div>
      </div>
    </div>
    <button-bar :buttons="invoiceButtons" @onSelect="buttonSelected" />
    <div v-if="draftedInvoicesButtonSelected" class="module-xlarge">
      <h3>DRAFTED INVOICES</h3>
      <!-- <p class="drafted-invoice-msg">
        Invoices that have been created for the client but that have not yet been approved. Clients have entered their submissions and have received the invoice but have not yet approved it
      </p> -->
      <data-table :entries="draftedEntries" :headers="draftedHeaders" :loading="loader">
        <template #total="{ item }">{{ item.total | formatMoney("s") }}</template>
        <template #actions="{ item }">
          <div>
            <div class="text-right">
              <a class="button ghost" @click="viewInvoice(item, 'PROCESSED')"><i class="icon-dollar-sign"></i>View Invoice</a>
            </div>
            <div class="text-right">
              <router-link :to="{ name: 'accountingReview', params: { settlementID: item.settlementID, client: item.client, payPeriod: item.payPeriod } }" class="button ghost">
                <i class="icon-file"></i>View Settlement
              </router-link>
            </div>
          </div>
        </template>
      </data-table>
    </div>
    <div v-else-if="approvedInvoicesButtonSelected" class="module-xlarge">
      <h3>APPROVED INVOICES</h3>
           <div class="filters align-bottom module invoice-date-filters">
        <div class="filter-options">
          <div class="filter-input">
            <div class="field">
              <date-pick :value="startDate" :format="'YYYY-MM-DD'" :formatDate="formatDate" @input="updateStartDate($event)">
                <template #default="{ toggle }">
                  <div class="input-has-icon input-icon-tab">
                    <div class="input-icon" @click="toggle"><i class="icon-calendar"></i></div>
                    <input id="startDate" :value="startDate" type="text" placeholder="Start Date" readonly required @click="toggle" />
                  </div>
                </template>
              </date-pick>
            </div>
          </div>
          <div class="filter-input">
            <div class="field">
              <date-pick :value="endDate" :format="'YYYY-MM-DD'" :formatDate="formatDate" @input="updateEndDate($event)">
                <template #default="{ toggle }">
                  <div class="input-has-icon input-icon-tab">
                    <div class="input-icon" @click="toggle"><i class="icon-calendar"></i></div>
                    <input id="endDate" :value="endDate" type="text" placeholder="End Date" readonly required @click="toggle" />
                  </div>
                </template>
              </date-pick>
            </div>
          </div>

          <div class="filter-input">
            <button class="button secondary" @click="applyDateFilter">Apply</button>
            <!-- <button v-if="clearDateFilterBtn" class="button secondary clear-btn" style="margin-left: 5px" @click="clearDateFilter">Clear Date Filter</button> -->
          </div>
          
        </div>
        <div v-if="filterErrorComputed">
          <p class="error-message">Please select Valid Dates</p>
        </div>
      </div>
      
      <!-- <p>Invoices that have been approved by the client and are ready for payment distribution</p> -->
      <!-- <div class="cell100 acc-page paddingT24">
          <paginator alwaysShow="TRUE" :total="totalCount" :per-page="50" :sync-page="currentPage" @onPaginate="fetchData($event)" />
        </div> -->
      <data-table 
        :entries="approvedEntries" 
        :headers="approvedHeaders" 
        :pagination-total-count="totalCount" 
        :pagination-per-page="perPage"
        :pagination-current-page="currentPage"
        @onPaginate="fetchDataApproved($event)"
        @onChangePerPage="onChangeCount($event)"
        :loading="approvedLoader"
        :key="refresh">
        <template #invoiceDate="{ item }">{{ item.invoiceDate }}</template>
        <template #invoiceNumber="{ item }">{{ item.invoiceNumber }}</template>
        <template #payPeriod="{ item }">{{ item.payPeriod }}</template>
        <template #total="{ item }">{{ item.total | formatMoney("s") }}</template>
        <template #approvedDate="{ item }">{{ item.approvedDate }}</template>
        <template #actions="{ item }">
          <meatball :id="`${item.id}`" right class="padding-top-12">
            
            <li >
              <a class="button ghost" @click="viewInvoice(item, 'APPROVED')"><i class="icon-dollar-sign"></i>View Invoice</a>
            </li>

            <li >
              <router-link :to="{ name: 'accountingReview', params: { settlementID: item.settlementID, client: item.client, payPeriod: item.payPeriod } }" class="button ghost"
                ><i class="icon-file"></i>View Settlement</router-link
              >
            </li>


              <!-- <menu-item label="View Invoice">
                <a class="button ghost" @click="viewInvoice(item, 'APPROVED')"><i class="icon-dollar-sign"></i>View Invoice</a>
              </menu-item>
              <menu-item label="View Settlement"><router-link :to="{ name: 'accountingReview', params: { settlementID: item.settlementID, client: item.client, payPeriod: item.payPeriod } }" class="button ghost"
                ><i class="icon-file"></i>View Settlement</router-link
              ></menu-item> -->
            </meatball>
        </template>
      </data-table>
      <!-- <div class="cell100 acc-page paddingT24">
          <paginator alwaysShow="TRUE" :total="(this.selectedButton == 'approvedInvoices' ? approvedCount : draftedCount)" :per-page="perPage" :sync-page="currentPage" @onPaginate="fetchData($event)" />
      </div> -->
    </div>
    <invoice-modal :get-client-invoicing="currentButton" :total-line-items="totalLineItems" :custom-address="customAddress" :line-items="lineItems" :loading-file="loadingFile" :fetch-invoice="fetchInvoice" :selected-invoice="selectedInvoice" invoice-modal-name="invoicingTab" :invoice-id="selectedInvoiceId" :status="status" @statusUpdated="getInvoice" />
    <create-custom-invoice-modal />
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import micromodal from "micromodal";
import { DataTable, Meatball, Direction } from "@/components";
import TableHeader from "@/components/table/TableHeader";
import CreateCustomInvoiceModal from "@/modules/admin/accounting/invoicing/CreateCustomInvoiceModal";
import ButtonBar from "@/components/buttons/ButtonBar";
import { formatDatePacific, formatDate } from "@/filters";
import InvoiceModal from "@/modules/admin/accounting/invoicing/InvoiceModal";
import { restApi } from "../../../../http/http.config";
import { encodeWithParam } from "../../../../util/Base64Encoding";
import {
  BRANCH_PAYMENT_HEADING,
  BRANCH_PAYMENT_TEXT,
  BRANCH_PAYMENT_BTN,
  MARK_AS_DISTRIBUTION_HEADING,
  MARK_AS_DISTRIBUTION_TEXT,
  MARK_AS_DISTRIBUTION_BTN,
} from "@/modules/admin/constants";
//import Paginator from "../../../../components/paginator/Paginator.vue";
import DatePick from "vue-date-pick";
import { DateTime } from "luxon";

export default {
  components: { InvoiceModal, ButtonBar, CreateCustomInvoiceModal, DataTable, Fragment, Meatball, DatePick },
  data: () => ({
    invoices: [],
    draftedInvoices:[],
    approvedInvoices:[],
    selectedButton: "draftedInvoices",
    selectedLocation: "",
    selectedInvoiceId: "",
    selectedInvoice: {},
    customAddress: {},
    lineItems: [],
    totalLineItems: [],
    loadingFile: true,
    status: "",
    loader: true,
    refresh: 0,
    totalCount: 0,
    currentPage: 0,
    perPage: 75,
    offset: 0,
    processor: "",
    popupHeading: MARK_AS_DISTRIBUTION_HEADING,
    popupBtn: MARK_AS_DISTRIBUTION_BTN,
    popupText: MARK_AS_DISTRIBUTION_TEXT,
    startDate:"",
    endDate:"",
    clearDateFilterBtn: false,
    filterError:false

  }),
  computed: {
    filterErrorComputed() {
      return this.filterError;
    },
    approvedLoading() {
      return this.approvedLoader
    },
    draftedEntries() {
      const draftInvoices = this.draftedInvoices;
      return draftInvoices.map((invoice) => {
        const total = invoice.invoice.lineItems?.reduce((pre, cur) => {
          return Number(pre) + Number(cur.amount);
        }, 0);
        const number = invoice.invoiceNumber.toString();
        const invoiceNumber = number.padStart(10 - number.length, "0");
        return {
          id: invoice.id,
          invoiceName: invoice.name,
          total,
          invoiceNumber,
          payPeriod: `${formatDate(invoice.settlement?.startDate)} - ${formatDatePacific(invoice.settlement?.endDate)}`,
          settlementID: invoice.settlement?.id,
        };
      });
    },
    draftedHeaders() {
        return [
          new TableHeader({ cellContents: "payPeriod", label: "Earnings Period", name: "payPeriod"}),
          new TableHeader({ cellContents: "invoiceName", label: "Invoice Name", name: "invoiceName" }),
          new TableHeader({ cellContents: "invoiceNumber", label: "Invoice Number", name: "invoiceNumber", sorted: true, sortable: true, direction: Direction.DESC }),
          new TableHeader({ cellContents: "total", label: "Total Invoiced", name: "total", headerClass: "text-right", cellClass: "text-right" }),
          new TableHeader({ label: "", name: "actions" }),
        ];
    },
    approvedHeaders() {
      return [
          new TableHeader({ cellContents: "invoiceDate", label: "Date Invoiced", name: "invoiceDate"}),
          new TableHeader({ cellContents: "invoiceNumber", label: "Invoice Number", name: "invoiceNumber", sorted: true, sortable: true, direction: Direction.DESC }),
          new TableHeader({ cellContents: "payPeriod", label: "Earnings Period", name: "payPeriod" }),
          new TableHeader({ cellContents: "total", label: "Total Invoiced", name: "total", headerClass: "text-right", cellClass: "text-right" }),
          new TableHeader({ cellContents: "approvedDate", label: "Date Approved", name: "approvedDate", sorted: true, sortable: true }),
          new TableHeader({ label: "", name: "actions" })
        ]
    },
    approvedEntries() {
      const approvedInvoices = this.approvedInvoices;
      return approvedInvoices.map((invoice) => {
        const total = invoice.invoice.lineItems?.reduce((pre, cur) => {
          return Number(pre) + Number(cur.amount);
        }, 0);
        const number = invoice.invoiceNumber.toString();
        const invoiceNumber = number.padStart(10 - number.length, "0");
        // console.log(invoice?.updatedAt ? formatDatePacific(invoice?.updatedAt) : "")
        return {
          id: invoice.id,
          invoiceName: invoice.name,
          total,
          invoiceNumber,
          payPeriod: `${formatDate(invoice.settlement?.startDate)} - ${formatDatePacific(invoice.settlement?.endDate)}`,
          settlementID: invoice.settlement?.id,
          invoiceDate: invoice?.settlement?.endDate ? formatDatePacific(invoice?.settlement?.endDate) : "",
          approvedDate: invoice?.settlement?.endDate ? formatDatePacific(invoice?.settlement?.endDate) : "",
        };
      });
    },
    approvedCount() {
      return this.approvedEntries.length
    },
    draftedCount() {
      return this.draftedEntries.length
    },
    invoiceButtons() {
      return [
        { id: "draftedInvoices", label: "Drafted Invoices" },
        { id: "approvedInvoices", label: "Approved Invoices" },
      ];
    },
    draftedInvoicesButtonSelected() {
      this.getDraftedInvoices(this.perPage, 0)
      return this.selectedButton === "draftedInvoices";
    },
    approvedInvoicesButtonSelected() {
      // this.getApprovedInvoices(this.perPage, 0)
      return this.selectedButton === "approvedInvoices";
    }
  },
  methods: {
    formatDate(dateObj) {
      return DateTime.fromISO(dateObj.toISOString()).toFormat("dd LLL yyyy");
    },
    fetchDataApproved(event) {
      this.currentPage = Number(event.pageNumber);
      this.offset = event.offset;
      this.fetchApprovedInvoices();
    },
    getInvoice(){
      this.getDraftedInvoices(this.perPage, 0)
    },
    onChangeCount(event) {
      this.perPage = event.perPage;
    },
    clearDateFilter() {
      this.startDate="";
      this.endDate="";
      this.fetchApprovedInvoices();
      this.clearDateFilterBtn = false;
    },
    applyDateFilter() {
      this.filterError = false;
      let parsedStartDate = Date.parse(this.startDate);
      let parsedEndDate = Date.parse(this.endDate);
      if ((parsedEndDate <= parsedStartDate) || this.startDate=="" ||this.endDate=="") {
        this.filterError = true;
      } else {
        this.fetchApprovedInvoices();
        this.clearDateFilterBtn = true;
      }
    },
    fetchApprovedInvoices() {
      this.approvedLoader=true;
      this.approvedInvoices=[];
      restApi.post('/accounting/approvedInvoice',encodeWithParam(this.approvedInvoicePayload())).then((data)=>{
        const res =data.data.result;
        // setTimeout(() => {
        this.totalCount = parseInt(data.data.count.count) 
        this.approvedLoader = false;
        this.approvedInvoices = res;
        // }, 1000);
  
      })
    },
    approvedInvoicePayload() {
      return {
        startDate: this.startDate ? this.startDate : '1990-01-01',
        endDate: this.endDate ? this.endDate : '2100-12-31',
        offset: this.offset,
        limit: this.perPage
      }
    },
    updateStartDate(value) {
      this.startDate=value;
    },
    updateEndDate(value) {
      this.endDate=value
    },
    forceRerender() {
      this.refresh += 1;
    },
    buttonSelected(button) {
      this.selectedButton = button.id;
      if(this.selectedButton === 'approvedInvoices') {
        this.fetchApprovedInvoices()
      }
    },
    printPayout(settlement) {
      return settlement.payouts_aggregate.aggregate.sum.netAmount;
    },
    viewInvoice(item, status) {
      this.selectedInvoiceId = item.id;
      this.status = status;
      this.fetchInvoice(this.selectedInvoiceId);
      micromodal.show("invoicingTab");
      document.body.style.overflow = "hidden";
    },
    branchPayment() {
      this.processor = "BRANCH";
      this.popupHeading = BRANCH_PAYMENT_HEADING;
      this.popupBtn = BRANCH_PAYMENT_BTN;
      this.popupText = BRANCH_PAYMENT_TEXT;
      micromodal.show("modal-mark-as-paid", {});
    },
    fetchInvoice(invoiceId) {
      restApi.post('accounting/getInvoice', encodeWithParam({id: invoiceId})).then((data)=>{
        const res = data.data.result[0];

        if (res?.invoice) {
          this.lineItems = res.invoice?.lineItems ? res.invoice.lineItems : [];
          if (typeof this.lineItems[0]?.sortOrder === "number") {
            this.lineItems = this.lineItems.sort((a, b) => a.sortOrder - b.sortOrder);
          }
          this.totalLineItems = this.lineItems;
          this.selectedInvoice = res;
          this.customAddress = res.address; 
          this.loadingFile = false;

        }
      }).catch((e) => {console.log(e)})
    },
    fetchData(event) {
      this.currentPage = Number(event.pageNumber);
      if (this.searchTerm) {
        this.searchQuery(Number(event.limit), Number(event.offset));
      } else {
        this.depositLoader = true;
        this.getClientInvoicing(null, Number(event.limit), Number(event.offset))
      }
    },
    resetSearch() {
      this.searchTerm = "";
      this.currentPage = 1;
    },
    searchQuery(limit, offset) {
      this.approvedLoader = true;
      this.getClientInvoicing(limit, offset)
    },


    currentButton(){
     if(this.selectedButton === 'approvedInvoices'){
      this.fetchApprovedInvoices()
     }
     else{
      this.getDraftedInvoices(this.perPage, 0)
     }
    },
    getDraftedInvoices(limit, offset) {
      this.loader = true;
      this.draftedInvoices=[];
        restApi.post("/accounting/getClientInvoicing", encodeWithParam({status: 'PROCESSED', limit: limit, offset: offset})).then((data) => {
        const res = data.data.result;
        this.totalCount = res.length
        this.loader = false;
        this.draftedInvoices = res.map((sub) => sub.invoices).flat();
      }).catch((e) => {console.log(e)})
    },
  },
  mounted() {
    this.getInvoice();
  }
};
</script>
