<template>
  <base-modal :id="reconModalName" :full="true">
    <sticky-header title="" classes = "margin-zero">
      <div class="button-group">
        <h2>{{ viewType == "disbursement-view" ? "Disbursement View" : "Settlement View" }}</h2>
        <div>
          <button class="button secondary" @click="back">Back</button>
        </div>
      </div>
    </sticky-header>
    <div v-if="viewType==='disbursement-view'" class="header-alignment flex space-between">
      <div>
        <h4>
          Disbursement Date: {{ formatDate(disbursementDate) }}
        </h4>
      </div>
      <div class="col-0.5 tooltip-i" style="padding-right: 0px; margin-right: 24px" @click="downloadUnsettledTxns()">
        <a v-if="!downloadLoader"><img src="@/assets/images/logos/download.svg" /></a>
        <span v-else class="loading"><Loader /></span>
        <span class="tooltip-text summary-tooltip-align">Click to download</span>
      </div>
    </div>
    <div v-else-if="viewType==='settlement-view'" class="header-alignment">
      <h4>
        Invoice No: {{ invoiceNo }}
      </h4>
    </div>
    <div class="content-align">
      <p class="black" v-if="!loader">Showing {{ tableEntries.length }} of {{ totalCount }} Transactions</p>
      <data-table 
        :headers="headers" 
        :entries="tableEntries" 
        :pagination-total-count="totalCount"
        :pagination-per-page="perPage" 
        :pagination-current-page="currentPage" 
        :loading="loader"
        @onPaginate="onPaginate($event)"
      >
      <template #driver_id="{item}"> 
        <div class="tooltip-i">
          <a @click="redirectProfile(item['Driver Id'])">{{ `..........${item['Driver Id'].substr(-4)}` }}</a>
          <span class="tooltip-modal">{{ item['Driver Id'] }}</span>
        </div>
      </template>
      <template #disbursed_amount="{item}"> {{ item['Disbursement Amount'] | formatMoney }} </template>
      <template #transaction_id="{item}">
        <div class="font-14"> {{ item['Transaction ID'] }} </div> 
      </template>
      <template #disbursed_time="{item}"> {{ formatTableDate(item['Disbursement Date']) }} </template>
      <template #settled_time="{item}"> {{ formatTableDate(item['Settlement Date']) }} </template>
      </data-table>
    </div>
  </base-modal>
</template>

<script>
  import StickyHeader from "@/components/layout/StickyHeader";
  import BaseModal from "@/components/modals/BaseModal";
  import micromodal from "micromodal";
  import { reconciliationApi } from "../../../../http/http.config";
  import { DataTable, TableHeader, Loader } from "@/components";
  import { encodeWithParam } from "@/filters";
  import DateTime from "luxon/src/datetime";
  export default {
    name: "ReconciliationTransactionModal",
    components: { BaseModal, StickyHeader, DataTable, Loader },
    props: {
      reconModalName: {
        type: String,
        required: false,
        default: "reconModalName",
      },
      type: {
        type: String,
        required: false,
        default: "disbursement-view",
      },
      rowItem: {
        type: Object,
        required: false,
        default: () => { },
      },
    },
    data() {
      return {
        tableEntries: [],
        totalCount: 0,
        currentPage: 0,
        perPage: 50,
        offset: 0,
        loader: true,
        downloadLoader: false
      };
    },
    watch: {
      rowItem() {
        this.fetchEntries();
      }
    },
    computed: {
      viewType() {
        return this.$props.type;
      },
      disbursementDate() {
        return this.$props.rowItem?.disbursement_date;
      },
      invoiceNo() {
        return this.$props.rowItem?.settlement_invoice;
      },
      invoiceType() {
        return this.$props.rowItem?.invoice_report_type;
      },
      provider() {
        return this.$props.rowItem?.provider;
      },
      headers() {
        return [
          new TableHeader({ headerClass: "cell5 padding-th", label: "Driver ID", name: "driver_id", cellContents: "Driver Id", cellClass: "breadcrumbs font-12" }),
          new TableHeader({ headerClass: "cell15 padding-th", label: "Driver Name", name: "driver_name", cellContents: "Driver Name", cellClass: "breadcrumbs font-12" }),
          new TableHeader({ headerClass: "cell15 padding-th", label: "Transaction ID", name: "transaction_id", cellContents: "Transaction ID", cellClass: "breadcrumbs font-12" }),
          new TableHeader({ headerClass: "cell10 padding-th", label: "Payment Type", name: "payment_type", cellContents: "Payment Type", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell5 padding-th right-align", label: "Disbursed Amount", name: "disbursed_amount", cellClass: "right-align font-12", cellContents: "Disbursement Amount" }),
          new TableHeader({ headerClass: "cell10 padding-th", label: "Disbursed Time", name: "disbursed_time", cellContents: "Disbursement Date", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell10 padding-th", label: "Settle Time", name: "settled_time", cellContents: "Settlement Date", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell10 padding-th", label: "Transaction Status", name: "txn_status", cellContents: "Transaction Status", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell15 padding-th", label: "Remarks", name: "remarks", cellContents: "remarks", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell5 padding-th", label: "Earnings Provider", name: "earnings_provider", cellContents: "Earnings Provider", cellClass: "font-12" }),
        ]
      }
    },
    methods: {
      back() {
        this.currentPage = 0;
        this.offset = 0;
        micromodal.close(this.$props.reconModalName);
        document.body.classList.remove("no-scroll");
      },
      formatDate(date) {
        if (date) {
          const [year, month, day] = date.substr(0, 10).split("-");
          return [month, day, year].join("/");
        }
        return date;
      },
      onPaginate(event) {
        this.currentPage = Number(event.pageNumber);
        this.offset = event.offset;
        this.fetchEntries();
      },
      async fetchEntries() {
        this.loader = true;
        const value = this.$props.rowItem;
        const param = {
          limit: this.perPage,
          offset: this.offset,
          startDate: `${value.disbursement_date?.substr(0,10)} 00:00:00.000`,
          endDate: `${value.disbursement_date?.substr(0,10)} 23:59:59.999`,
          type: this.invoiceType,
          provider: this.provider
        }
        if (this.$props.type === "disbursement-view") {
          this.totalCount = parseInt((await reconciliationApi.post("/getDisbursementTxnCount", encodeWithParam({ param })))?.data?.result[0]?.disbursement_count);
          this.tableEntries = (await reconciliationApi.post("/getDisbursementTxn", encodeWithParam({ param })))?.data?.result;
        }
        this.loader = false;
      },
      formatTableDate(date) {
        if (date) {
          return DateTime.fromISO(date).toFormat("MM/dd/yyyy, hh:mm:ss.SSS")
        }
        return date;
      },
      async downloadUnsettledTxns() {
        this.downloadLoader = true;
        const headers = ["Driver Id",
          "Driver Name",
          "Email address",
          "Payment Type",
          "Transaction ID",
          "Disbursement Date",
          "Disbursement Amount",
          "Settlement Date",
          "Transaction Status",
          "Remarks",
          "Earnings Provider"]
        const param = {
          startDate: `${this.disbursementDate?.substr(0,10)} 00:00:00.000`,
          endDate: `${this.disbursementDate?.substr(0,10)} 23:59:59.999`,
          type: this.invoiceType,
          provider: this.provider
        }
        const result = (await reconciliationApi.post("/getDisbursementTxnCsv", encodeWithParam({ param })))?.data?.result;
        const csv = this.$papa.unparse(result, {
            delimiter: ",",
            columns: Array.from(headers),
          });
        this.$papa.download(csv, `${this.disbursementDate.substr(0,10)}_${this.invoiceType}_discrepancy`);
        this.downloadLoader = false;
      },
      redirectProfile(partnerID) {
        document.body.classList.remove("no-scroll");
        this.$router.push({ name: "partner", params: { id: partnerID } });
      }
    },
  };
</script>

<style scoped>
  .header-alignment {
    padding-left: 24px;
    padding-top: 24px;
  }

  .content-align {
    padding: 24px 24px;
  }

 .font-14 {
    font-size: 14px !important;
  }

  .tooltip-i .tooltip-modal {
  visibility: hidden;
  width: 150px;
  background-color: white;
  color: green;
  text-align: left;
  border-radius: 8px;
  padding: 5px 5px;
  border: 2px solid #ddd;
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.18);
  /* Position the tooltip */
  position: relative;
  z-index: 1;
  font-size: 12px;
  min-height: 28px;
  max-height: 100px;
  font-weight: 700;
  display: inline-block;
}

.tooltip-i:hover .tooltip-modal {
  visibility: visible;
}

  .summary-tooltip-align {
    margin-left: -150px;
    margin-top: 40px;
  }

</style>