<template>
    <base-modal id="dialoge-modal" class="conatiner">
      <div v-if="dialogeMessage === 'delete'" class="confirm-delete">
        <p class="message">Are you sure you want to delete this item? This action cannot be undone.</p>
        <div style="display: flex;justify-content:center;gap: 15px;">
          <button @click="confirmDelete">Yes, delete</button>
          <button @click="cancel" class="cancel">Cancel</button>
        </div>
      </div>
    </base-modal>
</template>

<script>
import micromodal from "micromodal";
import BaseModal from "@/components/modals/BaseModal";

export default {
    name : "DialogeModal",
    components: { BaseModal},
    data : () => ({
        csv_dump_id : "",
    }),
    props : {
      csvDumpId: {
        type: String,
        required: true,
        default: "",
      },
      dialogeMessage : {
        type : String,
        required: false,
        default: "",
      },
      paymentMessage : {
        type : String,
        required: false,
        default: "",
      }
    },
    watch: {
    csvDumpId: function(newVal) {
      this.csv_dump_id = newVal;
     }
    },
    methods : {
      confirmDelete() {
        this.$emit('deleteConfirmed', this.csv_dump_id);
        micromodal.close("dialoge-modal");
        document.body.style.overflow = "visible";
      },
      initiatePayment() {
        this.$emit('startPayment', this.csv_dump_id);
        micromodal.close("dialoge-modal");
        document.body.style.overflow = "visible";
      },
      cancel () {
        micromodal.close("dialoge-modal");
        document.body.style.overflow = "visible";
      }

    }
}
</script>

<style scoped>
.confirm-delete {
  background-color: #ebf2ee;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.confirm-delete p {
  margin-top: 0;
  margin-bottom: 20px;
  color: #333;
}

.confirm-delete button {
  background-color: rgba(255, 0, 0, 0.719);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirm-delete button:hover {
  background-color: #b30000;
}

.confirm-delete button.cancel {
  background-color: #999;
}

.confirm-delete button.cancel:hover {
  background-color: #999999ac;
}
.message {
    font-size: larger;
    font-weight: 600;
}

</style>