<template>
  <div>
    <data-table :table-id="$props.tableid" :headers="headers" :entries="allTemplates" :loading="$props.loading">
      <template #noContents><p>There are no documents added yet, please add one now!</p></template>
      <template #documentName="{ item }">
        <a @click="previewTemplate(item.id)">
          <span>{{ item.title }}</span> <i class="icon-external-link right-icon"></i
        ></a>
        <span v-if="inherited(item)" class="light"> (Inherited)</span>
        <span v-if="item.optional" class="light"> (Optional) </span>
      </template>
      <template #status="{ item }">
        <span class="light"> {{ status(item.status) }}</span>
      </template>
      <template #version="{ item }">
        <router-link :to="{ name: 'versionPreview', query: { clientID: $route.params.id, locationID: $route.params.locationID, documentID: item.originalID } }"
          >V{{ item.version }}<i class="icon-external-link right-icon"></i
        ></router-link>
      </template>
      <template #dateUpdated="{ item }">{{ item.updated_at | formatDateTime }}</template>
      <template #locations="{ item }">
        <a @click="showLocationsModal(item.id, item)">
          <span>{{$props.locationList.length - item.locations.length}} Locations</span> <i class=""></i>
          <!-- proposed when getClientDoc response exceed the limit -->
          <!-- <span>{{item.doc_count}} Locations</span> <i class=""></i> -->
        </a>
        <location-assignment
          :list="assignmentList"
          :assignments="returnSelectedLocations(item)"
          :modal-id="setModelID(item.id)"
          :template-i-d="item.id"
          :client="true"
          @change="updateAssignmentList($event)"
          @saveLocations="saveUpdatedLocations($event,item)"
        />
      </template>
      <template #meatball="{ item }">
        <meatball :id="item.id" large long @selected="meatballSelected">
          <menu-item label="Edit" :show="true" :on-click="() => editDoc(item)" />
          <menu-item label="Publish" :show="item.status === 'draft'" :on-click="() => publishDoc(item)" />
          <!-- <menu-item label="Delete" :show="!inherited(item) && !inUse(item)" :on-click="openDeleteTemplateModal" /> -->
          <menu-item label="Remove" :show="inherited(item)" :on-click="removeInheritedTemplateModal" />
          <menu-item label="Deactivate" :show="!inherited(item)" :on-click="openDeactivateTemplateModal" />
          <!--          <menu-item label="Request Resubmission" :show="inUse(item)" :on-click="requestResubmission" />-->
          <!--          <menu-item label="Where is this document used" :show="!inherited(item)" :on-click="openDocumentUsedModal" />-->
        </meatball>
      </template>
    </data-table>
    <deactivate-parent-document-modal :template="selected" @deactivate="reloadDocumentList"></deactivate-parent-document-modal>
    <remove-inherited-document-modal :template="selected"></remove-inherited-document-modal>
    <document-used-modal :template="selected"></document-used-modal>
    <document-preview
      :pdf="false"
      :downloadable="true"
      :preview="false"
      :templateid="previewID"
      @pdfGenerationComplete="pdfGenerationComplete"
      @changePreview="pdfGenerationComplete"
    ></document-preview>

    <CreateEditDocument 
        :edit="createEdit.edit" 
        :template-id="createEdit.templateId" 
        :client-id="createEdit.clientID" 
        :location-id="createEdit.locationID" 
        @onSave="saveDocument" 
        :optional="createEdit.optional"
    />
  </div>
</template>
<script>
import DataTable from "@/components/table/DataTable";
import micromodal from "micromodal";
import DeactivateParentDocumentModal from "@/modules/shared/documents/DeactivateParentDocumentModal";
import RemoveInheritedDocumentModal from "@/modules/shared/documents/RemoveInheritedDocumentModal";
import DocumentUsedModal from "@/modules/shared/documents/DocumentUsedModal";
import DocumentPreview from "@/modules/shared/documents/DocumentPreview";
import TableHeader from "@/components/table/TableHeader";
import Meatball from "@/components/popups/Meatball";
import MenuItem from "@/components/popups/MenuItem";
import CreateEditDocument from "@/modules/shared/documents/CreateEditDocument";
import { v4 as uuidv4 } from "uuid";
import documentService from "@/services/document.service";
import LocationAssignment from "@/modules/shared/documents/LocationAssignmentModal";
import { mapGetters } from "vuex";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";

export default {
  name: "DocumentsTable",
  components: {
    LocationAssignment,
    CreateEditDocument,
    MenuItem,
    Meatball,
    DocumentPreview,
    DocumentUsedModal,
    RemoveInheritedDocumentModal,
    DeactivateParentDocumentModal,
    DataTable,
  },
  props: {
    noLocations: {
      type: Boolean,
      required: false,
      default: false,
    },
    documentTemplates: {
      type: Array,
      default: () => [],
    },
    tableid: {
      type: String,
      required: false,
      default: "",
    },
    locationList: {
      type: Array,
      required: false,
      default: () => [],
    },
    client: {
      type: Boolean,
      required:false,
      default:true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      clientID: config.CLIENT_ID,
      locationID: this.$route.params.locationID,
      popup: false,
      selected: {},
      previewID: "",
      locationAssignmentList: [],
      clients: [],
      clientLocations: [],
      createEdit: {
        templateId: "",
        isDDI: false,
        clientID: "",
        locationID: "",
        edit: false,
        title: "",
        optional: false
      },
    };
  },
  computed: {
    ...mapGetters(["getFullName"]),
    headers() {
      if (this.noLocations) {
        return [
          new TableHeader({
            label: "Document Name",
            name: "documentName",
            sortable: true,
          }),
          new TableHeader({
            label: "Status",
            name: "status",
            sortable: false,
          }),
          new TableHeader({
            label: "Version",
            name: "version",
            sortable: false,
          }),
          new TableHeader({
            label: "Date Updated",
            name: "dateUpdated",
            sortable: true,
          }),
          new TableHeader({
            label: "Updated By",
            cellContents: (item) => item.updatedBy,
            sortable: true,
          }),
          new TableHeader({
            label: "",
            name: "meatball",
            sortable: false,
          }),
        ];
      } else {
        return [
          new TableHeader({
            label: "Document Name",
            name: "documentName",
            sortable: true,
          }),
          new TableHeader({
            label: "Status",
            name: "status",
            sortable: false,
          }),
          new TableHeader({
            label: "Version",
            name: "version",
            sortable: false,
          }),
          new TableHeader({
            label: "Date Updated",
            name: "dateUpdated",
            sortable: true,
          }),
          new TableHeader({
            label: "Updated By",
            cellContents: (item) => item.updatedBy,
            sortable: true,
          }),
          new TableHeader({
            label: `${this.isDDI ? "Clients" : "Locations"}`,
            name: "locations",
            sortable: false,
          }),
          new TableHeader({
            label: "",
            name: "meatball",
            sortable: false,
          }),
        ];
      }
    },
    isDDI() {
      return !this.$route.params.id && !this.$route.params.locationID;
    },
    isClient() {
      return this.$props.client;
    },
    assignmentList() {
        return this.$props.locationList;
    },
    allTemplates() {
      return this.$props.documentTemplates
       .filter((template) => {
        return template.status !== "inactive";
        })
      // if (this.$props.documentTemplates.length > 0 && this.$props.documentTemplates[0].template) {
      //   return this.$props.documentTemplates.map((item) => {
      //     item.template.inherited = item.status === "inherited";
      //     return item.template;
      //   });
      // } else if (this.$props.documentTemplates.length > 0) {
      //   return this.$props.documentTemplates;
      // } else {
      //   return [];
      // }
    },
  },
  mounted() {

    this.$nextTick(
      micromodal.init({
        onShow: function () {
          document.body.classList.add("no-scroll");
        },
        onClose: function () {
          document.body.classList.remove("no-scroll");
        },
      })
    );
  },
  methods: {
    async publishDoc(template) {
      await documentService.changeDocumentStatus(template.id, "published");
      if (template.previousID) {
        await documentService.changeDocumentStatus(template.previousID, "inactive");
      }
      this.reloadDocumentList();
    },
    reloadDocumentList() {
      this.$emit('locationModified');
    },
    meatballSelected(e) {
      const template = this.allTemplates.filter((template) => template.id === e)[0];
      this.selected = template;
      this.createEdit = {
        templateId: template.id,
        isDDI: template.isDDI,
        clientID: template.clientID,
        locationID: template.locationID,
        edit: template.status === "draft",
        optional: template.optional
      };
    },
    setModelID(id) {
      return `modal-location-assignment` + id;
    },
    async saveUpdatedLocations(e,item) {
      let previousItems =  this.returnSelectedLocations(item)?.map((data)=>data.id);
      //delete from overrides
      let newLocationAssignment = e.list.filter((locationID)=>!previousItems.includes(locationID));

      //add location to overrides
      let oldLocationRemove = previousItems.filter((locationID)=>!e.list.includes(locationID));
    
      if(oldLocationRemove.length > 0 || newLocationAssignment.length >0) {
        restApi.post('/document/docsLocationAssignUnassign',encodeWithParam({templateID:item.id,clientID:config.CLIENT_ID,locations:oldLocationRemove,newLocation:newLocationAssignment})).then((data)=>{
          if(data.data.success)
          this.$emit('locationModified')
        })
      }
  

     // const updatedBy = this.getFullName;
      // if (this.isDDI) {
      //   const remove = this.clients
      //     .filter((client) => !e.list.includes(client.id))
      //     .map((item) => item.id)
      //     .filter((client) => {
      //       const ids = this.selected.locations.map((item) => item.id);
      //       return ids.includes(client);
      //     });
      //   const clear = this.clients.filter((client) => e.list.includes(client.id)).map((item) => item.id);
      //   // filter and set document overrides client
      //   await documentService.clearRemoveClientOverride(clear, this.selected.id);
      //   await documentService.setRemoveOverrideClients(remove, this.selected.id, updatedBy);
      //   return;
      // }
      // if (this.isClient) {
      //   const remove = this.clientLocations
      //     .filter((location) => !e.list.includes(location.id))
      //     .map((item) => item.id)
      //     .filter((client) => {
      //       const ids = this.selected.locations.map((item) => item.id);
      //       return ids.includes(client);
      //     });
      //   const clear = this.clientLocations.filter((client) => e.list.includes(client.id)).map((item) => item.id);
      //   await documentService.clearRemoveLocationOverride(clear, this.selected.id);
      //   await documentService.setRemoveOverrideLocations(remove, this.selected.id, updatedBy, this.$route.params.id);
      //   // filter and set document overrides location
      //   return;
      // }
    },
    updateAssignmentList(newList) {
      this.locationAssignmentList = newList;
    },
    returnSelectedLocations(location) {
      if(location.locations.length ==0) {
        return this.$props.locationList
      }else {
        let overrideLocations = location.locations.map((item)=>item.locationID);
        return this.$props.locationList.filter((item)=>!overrideLocations.includes(item.id))
      }
    },
    showLocationsModal(id, template) {
      this.selected = template;
      //this.preSelectedLocations = this.returnSelectedLocations(template);
      let modelName = `modal-location-assignment` + id;
      this.templateID = template.id;
      micromodal.show(modelName, {});
    },
    hidePopup() {
      this.popup = false;
    },
    pdfGenerationComplete() {
      this.previewID = "";
    },
    previewTemplate(templateID) {
      this.previewID = templateID;
    },
    status(status) {
      switch (status) {
        case "published": {
          return "Published";
        }
        case "draft": {
          return "Draft";
        }
        default: {
          return "N/A";
        }
      }
    },
    inherited(template) {
      if (this.isDDI) {
        return false;
      } else if (this.isClient) {
        return !!template.isDDI;
      } else if (this.isLocation) {
        return !template.locationID;
      }
    },
    inUse(template) {
      if (!template.signedDocuments_aggregate) {
        return false;
      } else {
        return template.signedDocuments_aggregate.aggregate.count > 0 ? true : false;
      }
    },
    sort(array) {
      return array.sort((a, b) => {
        const nameA = a.title.toUpperCase();
        const nameB = b.title.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    },
    addDocButton() {
      micromodal.show("modal-add-document", {});
    },
    removeInheritedTemplateModal() {
      micromodal.show("modal-remove-document", {});
    },
    openDocumentUsedModal() {
      micromodal.show("modal-document-used", {});
    },
    requestResubmission() {
      this.$router.push(`${this.$route.path}/documents/request-resubmission?documentID=${this.selected.id}`);
    },
    editDoc() {
      micromodal.show("create-edit-document", {});
    },
    openDeactivateTemplateModal() {
      micromodal.show("modal-deactivate-document", {});
    },
    moreMenu(template) {
      if (this.popup) {
        this.popup = false;
      } else {
        this.popup = template.id;
        this.selected = template;
      }
    },
    insertTemplate(template) {
      const variables = {
        id: template.id,
        title: template.title,
        originalID: template.id,
        version: template.version,
        updatedBy: template.updatedBy,
        text: template.text,
        html: template.html,
        isDDI: this.isDDI,
        clientID: this.isClient ? template.clientID : null,
        locationID: this.isLocation ? template.locationID : null,
        templateStatus: template.status,
      };
      documentService.createDocumentTemplate(variables,this.$props.noLocations);
    },
    async saveDocument(event) {
      let removeOverrideClientIDs;
      const id = uuidv4();
      const template = event.template;
      template.updatedBy = this.getFullName;
      // template.version = template.status === "draft" ? 0 : 1;
      template.id = id;

      await this.insertTemplate(template);

      if (template.status === "draft" || template.status === "inactive") {
        removeOverrideClientIDs = event.assignments;
      } else {
        // TODO: Add to funnel documents
        removeOverrideClientIDs = event.deSelected;
        await documentService.clearRemoveClientOverride(event.assignments, template.id);
      }

      await documentService.setRemoveOverrideClients(removeOverrideClientIDs, template.id, template.updatedBy);
      micromodal.close("create-edit-document");
    },
  },
  apollo: {
    // clients: {
    //   query: GET_CLIENTS_DETAILS,
    //   skip() {
    //     return !this.isDDI;
    //   },
    // },
    // clientLocations: {
    //   query: GET_CLIENT_LOCATIONS_DETAILS,
    //   variables() {
    //     return {
    //       id: this.$route.params.id,
    //     };
    //   },
    //   skip() {
    //     return !this.isClient;
    //   },
    // },
  },
};
</script>
