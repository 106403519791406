<template>
  <div class="field" :style="computeWidth">
    <validation-provider v-slot="{ errors }" :name="$props.label" mode="eager" :rules="$props.rules">
      <label :style="labelStyle" :class="{ invisible: $props.invisible }">{{ $props.label }}{{ $props.required && $props.label.length > 0 ? "*" : "" }}</label>
      <input
        v-maska="inputMask"
        :class="{ 'has-error': errors.length > 0, inline: $props.inlineLabel, 'has-error': checkDriverNumber }"
        :name="$props.name"
        :value="value"
        :type="type"
        :disabled="$props.disabled"
        :maxlength="maxlength"
        :required="$props.required"
        :placeholder="$props.placeholder"
        :autocomplete="$props.noAutocomplete ? 'off' : ''"
        @input="handleChangeClicked($event)"
      />
      <p class="error-message" :class="$props.errorClass">{{ errors[0] }}</p>
    </validation-provider>
  </div>
</template>
<script>
import { ValidationProvider, extend } from "vee-validate";
import { email, required, length, numeric } from "vee-validate/dist/rules.umd";
// import PhoneNumber from "awesome-phonenumber";
import { validateSocialSecurityNumber, checkValueExists, specialChars, trailSpaces, DriversLicenseValidation, validateAddressText, validateZip, hasNum,validateWalmartEmail } from "@/util/validator";
import { maska } from "maska";

extend("length", length);
extend("numeric", numeric);
extend("required", required);
extend("email", email);
extend("min", {
  message: (field, param) => `${field} cannot be less than ${param[0]} characters`,
  validate: (value, param) => {
    const valid = value.length >= param[0] ? true : false;
    return Promise.resolve({
      valid: valid,
    });
  }
});
extend("max", {
  message: (field, param) => `${field} cannot be more than ${param[0]} characters`,
  validate: (value, param) => {
    const valid = value.length <= param[0] ? true : false;
    return Promise.resolve({
      valid: valid,
    });
  }
});
extend("phone", {
  message: (field) => `${field} is not valid.`,
  validate: (value) => {
    const phone = value.length == 14 ? true : false;
    return Promise.resolve({
      valid: phone,
    });
  },
});
extend("valueExists", {
  message: () => `Drivers License Number already exists.`,
  validate: async (value, type) => {
    const exist = await checkValueExists(value, type[0]);
    return Promise.resolve({
      valid: !exist
    })
  }
});
extend("SSN", {
  message: (field) => `Please provide valid ${field}`,
  validate: (value) => {
    return Promise.resolve({
      valid: validateSocialSecurityNumber(value)
    })  
  },
});
extend("specialChar", {
  message: () => `Special characters are not allowed`,
  validate: (value) => {
    return Promise.resolve({
      valid: !specialChars(value)
    })
  }
})
extend("trailSpaces", {
  message: () => `Can't contain white space at the start or end`,
  validate: (value) => {
    return Promise.resolve({
      valid: !trailSpaces(value)
    })
  }
})
extend("license", {
  message: () => "License doesn't appear to be valid",
  validate: (value, param) => {
    return Promise.resolve({
      valid: DriversLicenseValidation(value, param[0])
    })
  }
})
extend("licenseState", {
  message: () => "License state doesn't match with the number",
  validate: (value, param) => {
    return Promise.resolve({
      valid: DriversLicenseValidation(param[0], value)
    })
  }
})
extend("addressText", {
  message: (field) => `Enter a valid ${field}`,
  validate: (value) => {
    return Promise.resolve({
      valid: validateAddressText(value)
    })
  }
})
extend("zip", {
  message: () => `Please provide a valid zip`,
  validate: (value) => {
    return Promise.resolve({
      valid: validateZip(value)
    })
  }
})
extend("hasNum", {
  message: () => `Name cannot include numbers`,
  validate: (value) => {
    return Promise.resolve({
      valid: !hasNum(value)
    })
  }
})
extend("walmartEmail",{
  message:() => `Not a walmart email`,
  validate: (value) =>{
    return Promise.resolve({
      valid: validateWalmartEmail(value)
    })
  }
})

export default {
  name: "FormInput",
  directives: { maska },
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: [String, null],
      required: false,
      default: "",
    },
    mask: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    checkDriverNumber:{
      type: Boolean,
      required:false,
      default:false,
    },
    rules: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    maxlength:{
      type: String || Number,
      required:false,
    },
    width: {
      type: String,
      required: false,
      default: null,
    },
    inlineLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    invisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    noAutocomplete: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorClass: {
      type: String,
      required: false,
      default: ""
    },
    labelStyle: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data: () => {
    return {
      errors: [],
    };
  },
  computed: {
    inputMask() {
      return this.$props.mask ? "(###) ###-####" : "";
    },

    computeWidth() {
      if (this.$props.width) {
        return { width: `${this.$props.width}${this.$props.width.includes('%') ? '' : 'px'}` };
      }
      return {};
    },
  },
  methods: {
    handleChangeClicked(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>
