<template>
  <fragment>
    <h6 :class="headerClass">Funnel Progress</h6>
    <ul class="events">
      <li v-for="(stage) in funnelStages" :key="stage.id" :class="resolveStage(stage)">
        <div class="date-time">
          <span class="show-date" v-if="displayStageTime(stage)">{{displayStageTime(stage) | formatDateTimePacific}} </span>
        </div>
        <div class="step">
          <div>{{ stage.label}}</div>
          <div class="funnel-steps">
            <div v-for="step in stage.steps" :key="step.id">
              <div :class="`${!getDisplayFlag(stage) ? 'collapse-steps-short' : 'collapse-steps'}`">
                <div v-if="getDisplayFlag(stage)">
                <div class="step-date">
                  <span class="show-date">
                    <span v-if="displayStepTimeStamp(step)">{{displayStepTimeStamp(step)  | formatDateTimePacific}}</span>
                    </span>
                </div>
                <span :class="resolveStatus(step)"></span>
                <span class="step-class">{{step.label}}</span>
                <span v-if="displayStatus(step)">{{displayStatus(step)}}</span>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="expand-collapse-icon">
          <span v-if="getDisplayFlag(stage)" @click="expandCollapseSteps(stage,true)">
            <img src="@/assets/images/svg/up-arrow.svg"  />
          </span>
          <span v-if="!getDisplayFlag(stage)" @click="expandCollapseSteps(stage,false)">
            <img src="@/assets/images/svg/down-arrow.svg"/>
          </span>  
        </div>
      </li>
    </ul>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import {FunnelProgress,FunnelStages,FunnelStatus} from "@/util/funnelProgress";

export default {
  components: { Fragment },
  data:()=>({
    fp: new FunnelProgress(),
    funnelStages:[],
    collapsExpand:[]
  }),
  props: {
    partnerSteps: {
      type: Array,
      required: false,
      default: () => [],
    },
    headerClass: String
  },
  computed: {
    computedShowHideFlag() {
      return this.collapsExpand
    }
  },
  watch: {
    partnerSteps(newValue) {
      if (newValue !== this.$props.partnerSteps) {
        this.$forceUpdate();
      }
    },
  },
  mounted() {
    this.funnelStages = this.fp.progressBarStages();
    this.collapsExpand = this.fp.getAllStages().map((stage)=>{return { name: stage.name,hide:false}});

  },
  methods:{
    expandCollapseSteps(step,action) {
      let index = this.collapsExpand.findIndex(x=>x.name === step.name);
      this.collapsExpand[index].hide = !action;
       this.$forceUpdate();
    },
    getDisplayFlag(stage) {
      let index = this.collapsExpand.findIndex((x)=>x.name === stage.name);
      return this.collapsExpand[index].hide;
    },
    displayStatus(step) {
      for(let i=0;i<this.$props.partnerSteps.length;i++) {
        if(step.name === this.$props.partnerSteps[i].step) {
          return ` - ${this.fp.getStatusLabel(this.$props.partnerSteps[i].status)}`
        }
      }
    },
    displayStepTimeStamp(step) {
      for(let i=0;i<this.$props.partnerSteps.length;i++) {
        if(step.name === this.$props.partnerSteps[i].step) {
          return this.$props.partnerSteps[i].date
        }
      }
    },
    displayStageTime(stage) {
      for(let i=this.$props.partnerSteps.length-1;i >= 0;i--) {
        if(stage.name === this.$props.partnerSteps[i].stage) {
          return this.$props.partnerSteps[i].date
        }
      }
    },
    resolveStatus(step) {
      const partnerStatus = this.$props.partnerSteps.map((item)=>item.step);
      if(partnerStatus.includes(step.name)) {
        return "status-completed"
      }
      else {
        return "status-not-completed"
      }
    },
    resolveStage(stage) {

      switch(stage.name) {

        case FunnelStages.ENROLLMENT:
         return  this.enrollmentProgress();

        case FunnelStages.EARNINGS_METHOD:
          return this.earningsMethodProgress();
        
        case FunnelStages.INSURANCE:
          return this.insuranceProgress();
        
        case FunnelStages.RISK_MITIGATION:
          return this.riskMitigationProgress();

        case FunnelStages.APPLICATION:
          return this.applicationProgress();

        case FunnelStages.WITHDRAWN:
          return this.withdrawnProgress()
        
        default:
          return "not-started"
        
      }
    },
    enrollmentProgress() {
      const mandatoryStatus = [FunnelStatus.ENROLLMENT_COMPLETE,FunnelStatus.IDV_PASS,FunnelStatus.DOCUMENTS_COMPLETE,
      FunnelStatus.PERSONAL_DETAILS_COMPLETE,FunnelStatus.INSURANCE_UPLOAD_COMPLETE];
      const partnerCompletedStatus = this.$props.partnerSteps.filter((partner)=> mandatoryStatus.includes(partner.status));
      if(mandatoryStatus.length === partnerCompletedStatus.length) {
        return "success"
      } else if(partnerCompletedStatus.length > 0) {
        return "current"
      } else {
        return "not-started"
      }
    },
    earningsMethodProgress() {
      const mandatoryStatus = [FunnelStatus.PAYMENT_AGREEMENT_COMPLETE];
      const pendingStatus = [FunnelStatus.PAYMENT_AGREEMENT_PENDING]
      const partnerCompletedStatus = this.$props.partnerSteps.filter((partner)=> mandatoryStatus.includes(partner.status));
      const partnerPendingStatus = this.$props.partnerSteps.filter((partner)=> pendingStatus.includes(partner.status));
      if(mandatoryStatus.length === partnerCompletedStatus.length) {
        return "success"
      } else if(partnerPendingStatus.length > 0) {
        return "current"
      } else {
        return "not-started"
      }
    },
    insuranceProgress() {
      const mandatoryStatus = [FunnelStatus.OCR_CLEAR,FunnelStatus.OCR_MANUAL_CLEAR];
      const pendingStatus = [FunnelStatus.OCR_FAILED,FunnelStatus.INSURANCE_IMAGE_INVALID]
      const partnerCompletedStatus = this.$props.partnerSteps.filter((partner)=> mandatoryStatus.includes(partner.status));
      const partnerPendingStatus = this.$props.partnerSteps.filter((partner)=> pendingStatus.includes(partner.status));
      if(partnerCompletedStatus.length >= 1) {
        return "success"
      } else if(partnerPendingStatus.length > 0) {
        return "current"
      }
      else {
        return "not-started"
      }
    },
    riskMitigationProgress() {
      const mandatoryStatus = [FunnelStatus.MVR_SUBMITTED,FunnelStatus.CBC_SUBMITTED,FunnelStatus.APPLICATION_WAITLIST];
      const partnerCompletedStatus = this.$props.partnerSteps.filter((partner)=> mandatoryStatus.includes(partner.status));
      if(mandatoryStatus.length === partnerCompletedStatus.length) {
        return "success"
      } else if(partnerCompletedStatus.length > 0) {
        return "current"
      } else {
        return "not-started"
      }
    },
    applicationProgress() {
      const mandatoryStatus = [FunnelStatus.APPLICATION_WAITLIST,FunnelStatus.APPROVED_AUTO,FunnelStatus.APPROVED_MANUAL];
      const partnerCompletedStatus = this.$props.partnerSteps.filter((partner)=> mandatoryStatus.includes(partner.status));
      if(partnerCompletedStatus.length >= 2) {
        return "success"
      } else if(partnerCompletedStatus.length === 1) {
        return "current"
      } else {
        return "not-started"
      }
    },
    withdrawnProgress() {
      const mandatoryStatus = [FunnelStatus.WITHDRAWN];
      const partnerCompletedStatus = this.$props.partnerSteps.filter((partner)=> mandatoryStatus.includes(partner.status));
      if(partnerCompletedStatus.length >= 1) {
        return "withdrawn"
      } else {
        return "not-started"
      }
    }
  }
};
</script>
<style scoped>
  .expand-collapse-icon {
    position: absolute;
    right: 50px;
  }

  .funnel-steps {
    position: relative;
    bottom: 3px;
    right: 22px;
  }
  .collapse-steps {
    padding-top: 15px;
    padding-left: 19px;
    white-space: nowrap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
  .collapse-steps-short{
    padding-left: 19px;
    white-space: nowrap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
  /* .collapse-steps li::last-child {
    padding-bottom: 20px;
  } */
.status-completed {
  position: relative;
  right: 22px;
  height: 7px;
  width: 7px;
  background-color: #3171ff;
  border-radius: 50%;
  display: inline-block;
  z-index: 1;
}
.status-not-completed {
  position: relative;
  right: 22px;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-block;
  z-index: 1;
  background-color: #CCCCCC;
}

.step-date{
    position: relative;
    color: #ccc;
    color: var(--gray-50);
    right: 215px;
    top: 15px;
    white-space: nowrap;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}

.grey-out {
  background-color: #9f0c0c;
}

</style>
