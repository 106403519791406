<template>
  <add-edit-page className="full-white">
    <template #header="{ validation }">
      <sticky-header title="Add Test Partner" classes = "margin-zero">
        <div class="document-toolbar">
          <div class="document-actions">
            <div class="button-group">
              <button class="button" :disabled="validation.pristine || validation.invalid || selectedLocation.length == 0 || emailError || emailCheckError || !form.firstName || !form.lastName || !form.email || !form.phone || isLoading || phoneCheckError || phoneValidationError" @click="handleSubmit">Save</button>
              <a class="button secondary" @click="$router.back()">Cancel</a>
            </div>
          </div>
        </div>
      </sticky-header>
    </template>
    <template>
        <div>
          <div class="grid-container">
           <div> <form-input  v-model="form.firstName"  label="First Name"  @input="checkFirst" />
            <p class="error-message" v-if="firstError">{{errMessage}}</p></div>
            <div>
            <form-input  v-model="form.lastName"  label="Last Name" rules="required" @input="checkSecond" />
            <p class="error-message" v-if="secondError">{{errMessages}}</p>

          </div>
            <form-input  v-model="form.email"  label="Email ID" rules="required"  @input="emailCheck"/>
            <p class="error-message-email" v-if="emailError">{{errMessages}}</p>
            <p class="error-message-email" v-if="emailCheckError">Email already exist</p>
            <form-input  v-model="form.phone"  mask label="Phone Number" rules="phone" @input="phoneCheck" />
            <p class="error-message-phone" v-if="phoneValidationError">Enter Valid Phone No</p>
            <p class="error-message-phone" v-if="phoneCheckError">Phone already exists</p>
            <form-input  v-model="form.ssn" label="SSN" :disabled="true" />
            <form-input  v-model="form.dlExpiration"  label="Driver License Expiry Date" :disabled="true"/>
            <form-input  v-model="form.dlNumber"  label="Driver License Number" :disabled="true"/>
            <form-input  v-model="form.city"  label="City" :disabled="true"/>
            <form-input  v-model="form.state"  label="State" :disabled="true"/>
            <form-input  v-model="form.postalCode"  label="Zip" :disabled="true"/>
            <form-input  v-model="form.country"  label="Country" :disabled="true"/>
            <div>
                <label>Location Name</label>
                <item-picker
                    :search-results="dropDownValues"
                    :max-size="1"
                    :noTitle="true"
                    placeholder="Type Location Name"
                    auto-select
                    @itemsChanged="updateLocation"
                    />
            </div>
          </div>
        </div>
    </template>
  </add-edit-page>
</template>
<script>
import AddEditPage from "@/components/layout/AddEditPage";
import StickyHeader from "@/components/layout/StickyHeader";
import FormInput from "@/components/forms/fields/FormInput";
import { ItemPicker } from "@/components";
import { restApi } from "@/http/http.config";
import { DateTime } from "luxon";
import { encodeWithParam } from "@/util/Base64Encoding";
import { encrypt } from "@/filters";
import Base64 from "base-64";

export default {
    name: "AddTestPartner",
    components: {AddEditPage,StickyHeader,FormInput,ItemPicker},
    data() {
        return {
            dropDownValues : [],
            selectedLocation: [],
            form: {
                firstName: "",
                lastName: "",
                phone: "",
                email: "",
                address1: "702 SW 8th St",
                address2: "",
                city: "Bentonville",
                state: "AR",
                postalCode: "72716",
                country: "USA",
                dateOfBirth: "30-03-1991",
                ssn: "555-55-5555",
                dlState: "CA",
                dlNumber: "T0123456",
                dlExpiration: "2033-12-31",
                approvalDate: DateTime.utc().toISO().split("T")[0],
                locationID: "",
                },
            errMessage:"",
            errMessages:"",
            secondError:false,
            firstError:false,
            emailError:false,
            emailCheckError:false,
            isLoading: false,
            phoneCheckError:false,
            phoneValidationError:false
        }
    },
    mounted() {
        restApi.get("/employee/getAllLocations").then((data)=>{
            if(data.data.success) {
                this.dropDownValues = data.data.result.result
            }
        })
    },
    methods: {
        async handleSubmit() {
            this.isLoading = true;
            this.form.ssn = await encrypt(this.form.ssn.replace(/\D/g, ''));
            
            let file, clientLocationPartnerID, userID, clientLocationID;
            let options = {
              type: 'image/jpeg',
            }
            file = await fetch('../../blank.jpg').then(res => res.blob()).then((res) => new File([res], 'insurance.jpg', options)).catch((e) => console.error(e));
            restApi.post("/employee/addTestPartner",encodeWithParam(this.form))
              .then( result => {
                  clientLocationPartnerID = result.data?.data?.data?.candidateID;
                  userID = result.data?.data?.data?.userID;
                  clientLocationID = result.data?.data?.data?.locationID;
              }).then(() => {
                  return restApi.post('/onboard/getDocuments', encodeWithParam({
                    clientLocationPartnerID: clientLocationPartnerID,
                    clientID: config.CLIENT_ID,
                    clientLocationID: clientLocationID,
                    userID: userID
                }))
              }).then(async (docs) => {
                  let documentTemplateID = (docs.data.result.filter((item) => item.title.toLowerCase() === "insurance"))[0].id;

                  let formData = new FormData();
                  formData.append("clientLocationID", clientLocationID);
                  formData.append("userID", userID);
                  formData.append("filename", "insurance.jpg");
                  formData.append("acl", "public");
                  formData.append("file", file);
                  formData.append("orginalName", "tempInsurance.jpg");
                  formData.append("clientID", config.CLIENT_ID);
                  formData.append("clientLocationPartnerID", clientLocationPartnerID);
                  formData.append("documentTemplateID", documentTemplateID);
                  formData.append("signature", this.form?.firstName);
                  
                  await restApi.post('/employee/uploadFile/', formData);
                  this.$router.back();
              }).finally(() => {
                this.isLoading = false;
              })
        },
        updateLocation(location) {
            if(location.itemAdded) {
                this.selectedLocation.push( location.itemAdded);
                this.form.locationID = location.itemAdded.id;
            } else {
                this.selectedLocation = [];
                this.form.locationID = "";
            }
        },
        checkFirst(val){
          const space = /\s/;
          const letter = /^.*[^\w\s].*$|.*\d.*$/;
      if(letter.test(val)){
        this.firstError=true
        this.errMessage="First name cannot contain numbers or special characters."
            }
      else if(space.test(val)){
        this.firstError=true
        this.errMessage="First name cannot have spaces."
      }
      else return this.firstError=false
    },
    checkSecond(val){
          const space = /\s/;
          const letter = /^.*[^\w\s].*$|.*\d.*$/;
      if(letter.test(val)){
        this.secondError=true
        this.errMessages="Last name cannot contain numbers or special characters."
            }
      else if(space.test(val)){
        this.secondError=true
        this.errMessages="Last name cannot have spaces."
      }
      else return this.secondError=false
    },
    emailCheck(val){
      const eRegex = /^[a-zA-Z][a-zA-Z0-9._-]*[a-zA-Z0-9]*\+?[a-zA-Z0-9._-]*@(walmart)\.com$/;
      if(!eRegex.test(val)){
        this.emailError=true;
        this.errMessages = "Not a valid walmart Email"
        this.emailCheckError = false;
      } else{
        this.userExist(val);
        // return this.emailError=false
      } 

    },
     async userExist(val) {
      let result = await restApi.post("/partner/checkEmail", {
         param: Base64.encode(JSON.stringify({ email: val.toLowerCase() })),
      })

      if (result?.data?.success) {
        this.emailError=false;
        this.emailCheckError = true;
                
      } else  {
        this.emailError=false;
        this.emailCheckError = false}
      
    },
    async phoneCheck(val) {
      let addtestpartnerphone=val;
      val = val.replace(/\D/g, '')
      if(val.length==10){
      this.phoneValidationError=false;
      const result = await restApi.post("/partner/checkPhone", { param: Base64.encode(JSON.stringify({ phone: val ,isaddtestpartner:true,addtestpartnerphone:addtestpartnerphone})) })
      const hasEnteredValue = val.length > 0
      const phoneExists = result?.data?.success
      if (hasEnteredValue && phoneExists) {
        this.phoneCheckError = true
      } else {
        this.phoneCheckError = false
      }
    }
    else{
      this.phoneCheckError = false
      this.phoneValidationError=true
    }
    }
    }
}
</script>
<style scoped>
    .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}
.error-message {
  color: var(--error-40);
  margin-top: 4px;
}

.error-message-email{
  color: var(--error-40);
  position: absolute;
  top: 138px;
}
.error-message-phone{
  color: var(--error-40);
  position: absolute;
  top: 138px;
  left:51%
}

</style>