<template>
  <div>
    <div :class="displayView === 'transaction view' ? 'datePicker-download' : ''">
      <div v-if="displayView === 'transaction view'"  class="datePicker-download">
        <h6 style="margin-top:20px">Transactions</h6>
        <div style="margin-left:720px">
          <date-picker v-model="dateRange" range format="DD-MMM-YYYY" :disabled-date="(date) => this.displayView === 'hold'? date >= new Date(): false" @input="fetchFilteredView('date', $event, dateRange)"></date-picker>
        </div>
       <div class="col-2 tooltip-i p-right-0" @click="downloadReconCsv()">
          <a v-if="!downloadLoader"><img src="@/assets/images/logos/download.svg" /></a>
          <span v-else class="loading"><Loader /></span>
          <span class="tooltip-text summary-tooltip-align">Click to download</span>
       </div>
       </div>
       <div :class="{ 'heading': displayView === 'hold to paid' }">
       <div v-if="displayView === 'hold to paid'">
        <h6 style="margin-top:20px">Disbursed Transaction from Hold Status</h6>
       </div>
       <div v-if="paginationTotalCount > 0" class="flex-right row-reverse margin-bottom-16">
        <paginator
        :total="paginationTotalCount"
        :per-page="paginationPerPage"
        :sync-page="paginationCurrentPage"
        :display-rows-per-page-selector="displayRowsPerPageSelector"
        :display-rows-per-page-max-selector="displayRowsPerPageMaxSelector"
        :loading="loading"
        @onPaginate="onPaginate($event)"
        @onChangePerPage="onChangePerPage($event)"
        />
      </div>
      </div>
    </div>
    
    <div v-if="loading">
      <table-loader :min-height="minHeight" />
    </div>
    <div v-else-if="tableContents.length === 0" class="well text-center no-contents">
      <slot name="noContents"><p>No records found</p></slot>
    </div>
    <div v-else>
      <table>
         <thead :class="{'sticky-header':$props.stickyHeader}" v-if="!$props.hideHeaders && displayView==='transaction view'">
           <tr>
             <th colspan="2" class="table-header"></th>
             <th colspan="2" class="table-header">Total</th>
             <th colspan="2" class="table-header">To Earn</th>
             <th colspan="2" class="table-header">Hold</th>
             <th colspan="1" class="table-header">Disbursed</th>
             <th colspan="3" class="table-header"></th>
            </tr>
          <tr>
            <data-table-header v-for="(header, index) in sortableHeaders" :key="`${$props.tableId}-${index}`" :index="index" :header="header" @onSort="sort" @onToggle="toggle" :displayView="displayView"/>
          </tr>
        </thead>
        <thead :class="{'sticky-header':$props.stickyHeader}" v-if="!$props.hideHeaders && displayView==='hold to paid'">
           <tr>
             <th colspan="2" class="table-header"></th>
             <th colspan="1" style="font-weight: bold;padding-bottom: 0px ">ONE</th>
             <th colspan="1" style="font-weight: bold;padding-bottom: 0px ">BRANCH</th>
             <th colspan="2" class="table-header" style="padding-left: 120px; padding-bottom: 8px;border-bottom: 4px solid #0b4d85">
                 <span class="under-line">ACH</span>
              </th>
            </tr>
          <tr>
            <data-table-header v-for="(header, index) in sortableHeaders" :key="`${$props.tableId}-${index}`" :index="index" :header="header" @onSort="sort" @onToggle="toggle" :displayView="displayView"/>
          </tr>
        </thead>
        <thead class='dataTable' :class="{'sticky-header':$props.stickyHeader}" v-if="!$props.hideHeaders && displayView!='transaction view' && displayView != 'hold to paid' && provider != 'ACH WEBHOOK'">
          <tr>
            <data-table-header v-for="(header, index) in sortableHeaders" :key="`${$props.tableId}-${index}`" :index="index" :header="header" @onSort="sort" @onToggle="toggle" :displayView="displayView"/>
          </tr>
        </thead>

        <thead :class="{'sticky-header':$props.stickyHeader}" v-if="!$props.hideHeaders && provider === 'ACH WEBHOOK'">
           <tr>
             <th colspan="6" class="table-header"></th>
             <th colspan="4" style="font-weight: bold;padding-bottom: 0px;border-bottom: 4px solid #0b4d85;padding-left: 140px;padding-bottom: 8px;">Webhook Status</th>
            </tr>
          <tr>
            <data-table-header v-for="(header, index) in sortableHeaders" :key="`${$props.tableId}-${index}`" :index="index" :header="header" @onSort="sort" @onToggle="toggle" :displayView="displayView"/>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, rowIndex) in tableContents" :key="newKey(item)" :class="$props.rowClassFunction(item)" @click="rowSelected(item)">
            <td v-for="(header, index) in $props.headers" :key="`${index}-${newKey(item)}`" :class="[header.headerClass, cellClass(item, header.cellClass)]">
              <slot :name="header.name" :item="{ ...item, index: $props.useIndex ? rowIndex : rowIndex }" :cellIndex="index" :rowIndex="rowIndex">{{ cellContents(item, header.cellContents) }}</slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="paginationTotalCount > paginationPerPage" class="flex-right row-reverse mt16">
      <paginator
        :total="paginationTotalCount"
        :per-page="paginationPerPage"
        :sync-page="paginationCurrentPage"
        :display-rows-per-page-selector="displayRowsPerPageSelector"
        :display-rows-per-page-max-selector="displayRowsPerPageMaxSelector"
        :loading="loading"
        @onPaginate="onPaginate($event)"
        @onChangePerPage="onChangePerPage($event)"
      />
    </div>
  </div>
</template>
<script>
import DataTableHeader from "@/components/table/DataTableHeader";
import { Loader } from "@/components";
import { v4 as uuidv4 } from "uuid";
import Direction from "@/components/table/direction";
import TableLoader from "@/components/loader/TableLoader";
import Paginator from "@/components/paginator/Paginator";
import DatePicker from 'vue2-datepicker';

export default {
  name: "DataTable",
  components: { Paginator, TableLoader, DataTableHeader,DatePicker, Loader},
  props: {
    stickyHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    paginationPerPage: {
      type: Number,
      required: false,
      default: 50,
    },
    paginationTotalCount: {
      type: Number,
      required: false,
      default: 0,
    },
    paginationCurrentPage: {
      type: Number,
      required: false,
      default: 1,
    },
    displayRowsPerPageSelector: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayRowsPerPageMaxSelector: {
      type: Boolean,
      required: false,
      default: false,
    },
    remoteSort: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    tableId: {
      type: String,
      default: () => uuidv4(),
    },
    headers: {
      type: Array,
      required: false,
      default: () => [],
    },
    entries: {
      type: Array,
      required: true,
      default: () => [],
    },
    hideHeaders: {
      type: Boolean,
      required: false,
      default: false,
    },
    rowClassFunction: {
      type: Function,
      required: false,
      default: () => {},
    },
    rowClickable: Boolean,
    minHeight: String,
    useIndex: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayView: {
      type: String,
      required: false,
      default: "disbursement view",
    },
    provider: {
      type: String,
      required: false,
      default : "",
    }
  },
  emits: ["onChangePerPage", "onPaginate"],
  data: function () {
    return {
      tableContents: [...this.$props.entries],
      sortableHeaders: [...this.$props.headers],
      downloadLoader : false,
      dateRange: null,
    };
  },
  // watch: {
  //   entries: function (newVal) {
  //     const sortedHeader = this.sortableHeaders.filter((header) => {
  //       return header.sorted;
  //     });
  //     if (!this.$props.remoteSort && sortedHeader && sortedHeader.length > 0) {
  //       newVal.sort(sortedHeader[0].sortFunction(sortedHeader[0].direction));
  //       if (sortedHeader[0].direction === Direction.DESC) {
  //         newVal.reverse();
  //       }
  //     }
  //     this.tableContents = [...newVal];
  //   },
  //   headers: function (newVal) {
  //     this.sortableHeaders = [...newVal]
  //   }
  // },
  watch: {
  entries: function (newVal) {
    const sortedHeader = this.sortableHeaders.filter((header) => header.sorted);
    if (!this.$props.remoteSort && sortedHeader.length > 0) {
      const sortedEntries = [...newVal].sort(sortedHeader[0].sortFunction(sortedHeader[0].direction));
      if (sortedHeader[0].direction === Direction.DESC) {
        sortedEntries.reverse();
      }
      if (JSON.stringify(sortedEntries) !== JSON.stringify(this.tableContents)) {
        this.tableContents = sortedEntries;
      }
    } else {
      if (JSON.stringify(newVal) !== JSON.stringify(this.tableContents)) {
        this.tableContents = [...newVal];
      }
    }
  },
  headers: function (newVal) {
    this.sortableHeaders = [...newVal];
  }
},
  mounted() {
    let newVal = this.tableContents;
    const sortedHeader = this.sortableHeaders.filter((header) => {
      return header.sorted;
    });
    if (sortedHeader && sortedHeader.length > 0) {
      newVal.sort(sortedHeader[0].sortFunction(sortedHeader[0].direction));
      if (sortedHeader[0].direction === Direction.DESC) {
        newVal.reverse();
      }
    }
    this.tableContents = [...newVal];
  },
  methods: {
    async fetchFilteredView(type, value, dateRange ) {
      this.$emit("date-emitted",type, value, dateRange );
    },
    async downloadReconCsv() {
      this.$emit("download-emitted");
    },
    onChangePerPage(event) {
      this.$emit("onChangePerPage", event);
    },
    onPaginate(event) {
      this.$emit("onPaginate", event);
    },
    onSort(event) {
      this.$emit("onSort", event);
    },
    cellClass(item, classname) {
      if (typeof classname === "string") return classname;
      if (typeof classname === "function") return classname(item);
      return "";
    },
    cellContents(item, cellValue) {
      if (typeof cellValue === "string") {
        return item ? item[cellValue] : "";
      } else if (typeof cellValue === "function") {
        return cellValue(item);
      } else {
        return "";
      }
    },
    sort(clickedHeader) {
      this.sortableHeaders.forEach((header, index) => {
        if (index === clickedHeader) {
          header.sorted = true;
          header.direction = !header.direction || header.direction === Direction.DESC ? Direction.ASC : Direction.DESC;
          if (this.$props.remoteSort) {
            this.onSort(header);
          } else {
            const sortFunction = header.sortFunction(header.direction);
            this.tableContents.sort(sortFunction);
            if (header.direction === Direction.DESC) {
              this.tableContents.reverse();
            }
          }
        } else {
          header.sorted = false;
          header.direction = null;
        }
      });
      this.sortableHeaders = [...this.sortableHeaders];
    },
    newKey() {
      return `${this.$props.tableId}-${uuidv4()}`;
    },
    rowSelected(item) {
      if (this.$props.rowClickable) {
        this.$emit("onRowSelected", item);
      }
    },
    toggle(value) {
      this.$emit("onToggle", value);
    }
  },
};
</script>
<style scoped>
.table-header{
  padding-left: 70px;
  font-weight: bold;
  padding-top:8px;
  padding-bottom:0px;

  /* text-align: center; */
}
.datePicker-download{
    display: flex;
  }
  .heading{
    display: flex;
    justify-content: space-between;
  }
</style>