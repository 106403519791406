<template>
  <add-edit-page>
    <template #header="{ validation }">
      <sticky-header :title="$props.dataAvailable ? 'Edit Payment Settings' : 'Add Payment Settings'" classes = "margin-zero">
        <h5>{{ getClientName }} - Client</h5>
        <save-cancel :save-enabled="!validation.invalid && valid" @onSave="savePaymentSettings" />
      </sticky-header>
    </template>
    <payment-form v-model="payment" :flag="$props.dataAvailable" :selected-pay-cycle="selectedPayCycle" />
  </add-edit-page>
</template>

<script>
import { AddEditPage, SaveCancel, StickyHeader } from "@/components";
import PaymentForm from "@/modules/admin/clients/components/PaymentForm";
import { mapGetters } from "vuex";
import { CREATE_PAYMENT_SETTINGS, UPDATE_PAYMENT_SETTINGS, ADD_PAYPERIOD_TO_FINANCE_TEMPLATE } from "@/modules/admin/clients/graph/mutations";
export default {
  name: "AddEditPaymentSettings",
  components: { AddEditPage, StickyHeader, SaveCancel, PaymentForm },
  props: {
    paymentData: {
      type: Object,
      required: false,
      default: () => {},
    },
    dataAvailable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => {
    return {
      payment: {
        payCycle: "",
        secondaryPayCycle: "",
        startDay: "",
        payDay: "",
        paymentProcessor: "",
        submissionDueDay: "",
        submissionDueTime: "",
      },
      valid: false,
    };
  },
  computed: {
    ...mapGetters(["getClientName", "getTemplateID"]),
    selectedPayCycle() {
      return this.$props.paymentData ? (this.$props.paymentData["payCycle"] === "NEXT_DAY" ? true : false) : false;
    },
  },
  watch: {
    payment() {
      for (const attrib in this.payment) {
        if (this.payment["payCycle"] === "NEXT_DAY" && attrib == "submissionDueDay") {
          this.payment["submissionDueDay"] = null;
          continue;
        } else if (this.payment["payCycle"] !== "NEXT_DAY" && attrib == "secondaryPayCycle") {
          this.payment["secondaryPayCycle"] = null;
          continue;
        } else if (this.payment[attrib] === "" || this.payment[attrib] == null) {
          return (this.valid = false);
        }
      }
      return (this.valid = true);
    },
  },
  created() {
    if (this.$props.dataAvailable) {
      for (const key in this.$props.paymentData) {
        this.payment[key] = this.$props.paymentData[key];
      }
    }
  },
  methods: {
    savePaymentSettings() {
      if (this.$props.dataAvailable) {
        delete this.payment["__typename"];
        this.$apollo
          .mutate({
            mutation: UPDATE_PAYMENT_SETTINGS,
            variables: { payment: this.payment, id: this.payment.id },
          })
          .catch((response) => this.$log.error(response))
          .finally(() => {
            this.$router.back();
          });
      } else {
        this.$apollo
          .mutate({
            mutation: CREATE_PAYMENT_SETTINGS,
            variables: { payment: this.payment },
          })
          .then(({ data }) => {
            this.$apollo
              .mutate({
                mutation: ADD_PAYPERIOD_TO_FINANCE_TEMPLATE,
                variables: { payPeriodID: data["insert_paymentSettings_one"].id, id: this.getTemplateID },
              })
              .catch((error) => this.$log.error(error));
          })
          .catch((response) => this.$log.error(response))
          .finally(() => {
            this.$router.back();
          });
      }
    },
  },
};
</script>
