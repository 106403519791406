<template>
  <fragment>
    <data-table :headers="screeningTableHeaders" :entries="screeningTableEntries" hide-headers>
      <template #name="{ item }">
      <span> {{screeningTableData}}</span>
        <div v-if="item.editing" class="editing partner-flyout-editing">
          <validation-observer v-slot="validation" slim>
            <div class="screening-actions">
              <save-cancel no-margin emit-cancel :save-enabled="!checkValidation(validation) || !validator" @onCancel="cancel(item)" @onSave="save(item)" />
            </div>
            <div>
              <label class="section-header">
                <span>Edit {{ item.name }}</span>
              </label>
              <div v-if="isArray(item.data)" class="dl-update">
                <fragment v-for="header in editableItems(item.data)" :key="`${header.label}-input`">
                  <date-picker v-if="header.type === 'date'" v-model="candidateDetails[header.key]" :label="getLabel(header)" :name="header.label" :width="254" inline-label :disableDates="dateDisable(header.key)" @input="() => validator=false" />
                  <form-input v-else v-model="candidateDetails[header.key]" :label="getLabel(header)" :name="header.label" :width="'254'" inline-label :rules="rules(header.key)" :type="header.type" errorClass="max-width" required />
                </fragment>
              </div>
              <form-input v-else v-model="candidateDetails[item.key]" label="" :name="item.name" :width="'254'" :type="item.type" invisible required autocomplete="new-password" :rules="item.rules" />
            </div>
          </validation-observer>
        </div>
        <div v-else class="partner-flyout-reading">
          <div>
            <label class="section-header">
              <i v-if="isValid(item.status)" class="icon med-size icon-check-circle"></i>
              <i v-else-if="isExpired(item.status)" class="icon med-size icon-alert-octagon"></i>
              <i v-else-if="isReadyToExpire(item.status)" class="icon med-size icon-alert-triangle"></i>
              <i v-else-if="cbcConsider(item)" class="icon med-size icon-alert-octagon"></i> 
              <i v-else-if="insuranceNotVerified(item.status)" class="icon med-size icon-alert-octagon"></i> 
              <span class="screening-name">{{ setTableHeader(item) }}</span>
              <div v-if="item.id == 'screening-package'" class="view-edit-btn">
                <a :href="$props.checkrData" target="_blank">View in Checkr&nbsp;<i class="icon icon-external-link"></i></a>
              </div>
              <!-- <div v-if="item.id == 'drivers-license'" class="view-edit-btn">
                <a v-if="getPermission('viewAllPII')" @click="edit(item)">Edit&nbsp;<i class="icon icon-edit-2"></i></a>
              </div> -->
              <div v-if="item.id == 'auto-insurance' && getPermission('viewUpldInsurance')" class="view-edit-btn">
                <a @click="item.validate()">View/Edit&nbsp;<i class="icon icon-external-link"></i></a>
              </div>
            </label>
          </div>

          <table v-if="isArray(item.data)" class="display-data-table" style="width: 310px">
            <thead>
              <tr>
                <th v-for="header in item.data" :key="`${header.label}-header`" :class="header.class">{{ header.label }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-for="header in item.data" :key="`${header.label}-value`">
                  {{ header.value }}
                  <i :class="checkIsMasked(header)" @click="toggleMaskDisplay(header.key)"></i>
                </td>
              </tr>
            </tbody>
          </table>
          <p v-else class="display-data">{{ item.data }}</p>
          <div v-if="reScreening && item.name == 'Screening Package' && isExpired(item.status)">
            <!-- <div><span>Submitted</span> &nbsp;<span>23 Nov 2020 - 3:45 PDT</span></div> -->
            <div>
              <div class="screening-status">Screening Status</div>
              <div class="screening-return">
                <div>Returned Consider</div>
                <div v-if="isValid(item.status)" class="view-edit-btn">
                  <a @click="edit(item)">View&nbsp;<i class="icon icon-external-link"></i></a>
                </div>
              </div>
            </div>

            <div class="radio-container">
              <div class="radio-custom">
                <input id="location-assignments-approve" :checked="radioSelect === 'approve'" value="approve" type="radio" name="approve-screening" @change="changeSelection($event)" />
                <label>
                  <span class="label-container"> Approve </span>
                </label>
              </div>
              <div class="radio-custom">
                <input id="location-assignments-deny" :checked="radioSelect === 'deny'" value="deny" type="radio" name="deny-screening" @change="changeSelection($event)" />
                <label>
                  <span class="label-container"> Deny (this will deactivate the partner) </span>
                </label>
              </div>
            </div>
            <div class="screening-actions">
              <a class="button secondary savebtn" style="padding: 12px 16px" @click="onScreenSubmit">SAVE</a>
            </div>
          </div>

          <div v-if="item.name == 'Screening Package' && isReadyToExpire(item.status)">
            <div><span>Submitted</span> &nbsp;<span>This is a bug</span></div>
            <div>
              <div class="screening-status">Screening Status</div>
              <div class="screening-return">
                <div>Not Returned</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </data-table>
    <div>
      <div>
        <hr />
        <expansion-panel label="Insurance Failure Reason">
          <ul>
            <li v-for="reason in personaOCRInsuranceFailureReasons" :key="reason">
              <p>{{ reason }}</p>
            </li>
          </ul>
        </expansion-panel>
      </div>
    </div>
    <preview-insurance-modal 
      :id="'maintenance-table-insurance-preview'" 
      :data="insuranceModalData"
      :isInsuranceLoading="isDataLoading"
      @onRemove="removeInsurance" 
      @onSave="updateInsurance" 
      @onCancel="closeModal" 
      @imageChanged="imageChanged" 
      @refresh="refresh"
    />
  </fragment>
</template>
<script>
import { DataTable, TableHeader, FormInput, SaveCancel, ExpansionPanel } from "@/components";
import { formatDatePacific } from "@/filters";
import { DateTime, Interval } from "luxon";
import { extend, ValidationObserver } from "vee-validate";
import micromodal from "micromodal";
import { mapActions, mapGetters } from "vuex";
import DatePicker from "@/components/forms/fields/DatePicker";
import { Fragment } from "vue-fragment";
import { UPDATE_CBC_VERIFIED } from "@/modules/admin/onboarding/graph/mutations";
import PreviewInsuranceModal from "@/modules/admin/onboarding/modals/PreviewInsuranceModal";
import { restApi } from "@/http/http.config";
import { encodeWithParam,formatDate } from "@/filters";
import { convertBufferToContent } from "@/util/BufferContentConverter";
import {FunnelStatus} from "@/util/funnelProgress";
import { isPDFFile } from "../../../../util/validator";
extend("ssn", {
  message: (field) => `${field} is not valid.`,
  validate: (value) => {
    const ssnRegex = /^(?!219099999|078051120)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/g;
    return Promise.resolve({
      valid: value.match(ssnRegex),
    });
  },
});

export default {
  components: { DatePicker, SaveCancel, FormInput, DataTable, ValidationObserver, Fragment, PreviewInsuranceModal, ExpansionPanel },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    checkrData: {
      type: String,
      required: false,
      default: () => {},
    },
    cbcDate: {
      type: String || null,
      required: false,
      default:"" || null
    }
  },
  data: () => ({
    insuranceImageURL: "",
    funnelFilesId: "",
    checkExp: "",
    radioSelect: "approve",
    reScreening: false,
    runScreen: true,
    insuranceModalData:{},
    initialValues: {
      license: {},
    },
    screeningTable: [
      {
        id: "screening-package",
        key: "ssn",
        name: "Screening Package",
        editing: false,
        status: "VALID",
        type: "password",
        rules: "ssn|ssnExists",
        value: "",
        data: [],
      },
      {
        id: "auto-insurance",
        name: "Auto Insurance",
        data: [{ label: "Exp", value: "", class: "cell100", editable: true, type: "date" }],
        editing: false,
        status: "READYTOEXPIRE",
        validate: () => {
          micromodal.show("maintenance-table-insurance-preview", {});
        },
      },
      {
        id: "drivers-license",
        name: "Drivers License",
        data: [
          { label: "Exp", value: "", key: "licenseExpiry", class: "cell55", editable: true, type: "date",  format: "mm/dd/yyyy" },
          { label: "State", value: "", key: "licenseState", class: "cell10", editable: true, type: "text" },
          { label: "Number", editLabel: "Num", value: "", key: "licenseNumber", class: "cell35", editable: true, type: "text" },
          { label: "", value: "", key: "dl-eye", isMasked: true }
        ],
        editing: false,
        status: "VALID",
      },
    ],
    validator: true,
    formError: false,
    isDataLoading: false,
    personaOCRInsuranceFailureReasons: [],
  }),
  watch: {
    isFlyoutShowing(value) {
      if (!value) {
        this.closeModal();
        this.insuranceModalData = {};
        this.screeningTable[2].editing = false;
      }
    }
  },
  computed: {
    ...mapGetters(["getSelectedEmployee", "isFlyoutShowing", "getPermission"]),
    screeningTableHeaders() {
      return [new TableHeader({ name: "name", cellClass: "screening-data" })];
    },
    candidateID() {
      return this.$props?.data?.candidateID ? this.$props.data.candidateID : null;
    },
    locationID() {
      return this.$props?.data?.locationID ? this.$props.data.locationID : null;
    },
    screeningTableEntries() {
      return this.screeningTable;
    },
    // insuranceModalData() {
    //   return {
    //     insuranceImageURL: this.$props.data.insuranceImageURL,
    //     insuranceExpiry: formatDate(this.$props.data.insuranceExpiry),
    //     funnelFilesID: this.funnelFilesId,
    //     locationPartnerID: this.candidateID,
    //     locationID: this.$props.data.locationID,
    //     partnerID:this.$props.data.partnerID,
    //     // clientID:this.$props.candidate.clientID,
    //     documentTemplateID:this.$props.data.documentTemplateID,
    //     userID: this.$props.data.userID,
    //     phone: this.$props.data.phone
        
    //   }
    // },
    candidateDetails() {
      return {
        dob: this.$props.data.dateOfBirth,
        licenseNumber: this.screeningTable[2].editing ? this.$props.data.decoded.dnumber : this.$props.data.dlNumber,
        licenseExpiry: this.screeningTable[2].editing ? this.$props.data.decoded.dexpiration : this.$props.data.dlExpiration,
        licenseState: this.screeningTable[2].editing  ? this.$props.data.decoded.dstate : this.$props.data.dlState,
        insuranceExpiry: this.formatDate(this.$props.data.insuranceExpiry),
      }
    },
    displayVerifyButton() {
      return this.$props.data.partnerCBCResult === "consider" ? true : false
    },
    displayCBCReturnDate() {
      return this.$props.data.partnerCBCResult === "consider"|| this.$props.data.partnerCBCResult === "verified" || this.$props.data.partnerCBCResult === "verified-inactive"? true : false
    },
    cbcReturnedDate() {
      return this.format(this.$props.cbcDate);
    },
    screeningTableData() {
      this.getDocuments()
      this.screeningTable.forEach((screeningTableEntry) => {
        if (screeningTableEntry.id === "drivers-license") {
          screeningTableEntry.data[0].value = screeningTableEntry.data[3].isMasked ? this.$props.data.dlExpiration : this.formatDate(this.$props.data.decoded.dexpiration);
          screeningTableEntry.data[1].value = screeningTableEntry.data[3].isMasked ? this.$props.data.dlState : this.$props.data.decoded.dstate;
          screeningTableEntry.data[2].value = screeningTableEntry.data[3].isMasked ? this.$props.data.dlNumber : this.$props.data.decoded.dnumber;
          screeningTableEntry.status = this.setStatus(this.$props.data?.decoded?.dexpiration,"drivers-license");
        } else if (screeningTableEntry.id === "auto-insurance") {
          screeningTableEntry.data[0].value = this.$props.data.insuranceExpiry ? this.formatDate(this.$props.data.insuranceExpiry) : "";
          screeningTableEntry.status = this.setStatus(this.$props.data.insuranceExpiry,"auto-insurance");
        }
      });
      const valid = this.screeningTable.reduce((isValid, tableEntry) => isValid && tableEntry.status === "VALID", true);
      if(this.$props.data.cbcDate){
        this.addonMonths(this.$props.data.cbcDate, 6, "checkrData");
      } 
      this.$emit("input", valid);
      return '';
    },
    getRole() {
      return localStorage.getItem("role");
    }
  },
  methods: {
    ...mapActions(["showFlyout"]),
    async getDocuments() {
      if(this.$props.data?.insuranceFileDetails){
        this.isDataLoading = true;
        restApi.post('/onboard/getInsuranceData', encodeWithParam({ partnerID: this.$props.data.partnerID })).then((res) => {
          let docs=this.$props.data.insuranceFileDetails
          let insuranceFileDetails = docs.filter((item)=>item.title.toLowerCase() === "insurance");
          let insuranceFileID = insuranceFileDetails[0].fileID;
          this.$props.data.documentTemplateID=insuranceFileDetails[0]?.id?insuranceFileDetails[0]?.id:null;
          
          if ((res.data?.result?.length)) {
            insuranceFileID = res.data.result[0].file_id;
            this.$props.data.insuranceExpiry = res.data.result[0].insurance_expiry;
            this.personaOCRInsuranceFailureReasons = res.data.result[0].personaOCRInsuranceFailureReasons;
          }
          this.$props.data.insuranceFileId = insuranceFileID;
          
          restApi.post('/employee/getUploadedFile', encodeWithParam({ fileID: insuranceFileID }))
            .then((data) => {
              let imageContent = data.data?.bufferInHex ? convertBufferToContent(data.data.bufferInHex, data.data.contentType) : null
              this.$props.data.insuranceImageURL = imageContent;
              
              this.insuranceModalData=
              {
                ...this.insuranceModalData,
                insuranceImageURL: this.$props.data.insuranceImageURL,
                isPDF: isPDFFile(data?.data?.fileName),
                insuranceExpiry: formatDate(this.$props.data.insuranceExpiry),
                insuranceFileId: this.$props.data.insuranceFileId,
                funnelFilesID: this.funnelFilesId,
                locationPartnerID: this.candidateID,
                locationID: this.$props.data.locationID,
                partnerID:this.$props.data.partnerID,
                // clientID:this.$props.candidate.clientID,
                documentTemplateID:this.$props.data.documentTemplateID,
                userID: this.$props.data.userID,
                phone: this.$props.data.phone,
                externalID:this.$props.data?.DMSID?this.$props.data.DMSID:null
              }
              this.isDataLoading = false;
          }).catch(() => {
            this.isDataLoading = false;
          })
        }).catch(() => {
          this.isDataLoading = false;
        })
      }
    },
    checkValidation(val) {
      for (let err in val.errors) {
        if (val.errors[err].length > 0) {
          this.formError = true;
          return true
        }
      }
      this.formError = false;
      return val.pristine
    },
    runScreening() {
      this.runScreen = false;
      this.reScreening = true;
    },

    changeSelection(e) {
      this.radioSelect = e.target.value;
      if (e.target.value === "all") {
        this.assignedList = this.filteredList.map((item) => item.id);
        this.emit();
      } else {
        this.assignedList = [];
        this.checkbox = false;
        this.filterText = "";
        this.emit();
      }
    },
    onScreenSubmit() {
      if (this.radioSelect == "approve") {
        this.screeningTable[0].status = "VALID";
      }
    },
    isArray(data) {
      return data instanceof Array;
    },
    isValid(status) {
      return status === "VALID";
    },
    isReadyToExpire(status) {
      return status === "READYTOEXPIRE";
    },
    isExpired(status) {
      return status === "EXPIRED";
    },
    cbcConsider(item) {
      return  item.status == "cbcConsider" && this.$props.data.partnerCBCResult === "consider" ? true : false 
    },
    insuranceNotVerified(status) {
      return status === "NOTVERIFIED"
    },
    edit(data) {
      if (data.id == "screening-package") {
        this.runScreening();
      } else if (data.id == "drivers-license") {
        const tableEntry = this.screeningTableEntries.filter((screening) => screening.id === data.id);
        if (tableEntry && tableEntry.length) {
          tableEntry[0].editing = true;
        }
      }
    },
    closeModal() {
      micromodal.close("maintenance-table-insurance-preview");
    },
    cancel(data) {
      const tableEntry = this.screeningTableEntries.filter((screening) => screening.id === data.id);
      if (tableEntry && tableEntry.length) {
        tableEntry[0].editing = false;
      }
      this.screeningTable[2].data[3].isMasked = true;
      this.validator = true;
    },
    save(data) {
      switch (data.id) {
        case "drivers-license":
          this.updateDL(data);
          break;
        case "auto-insurance":
          this.updateAutoInsurance();
          break;
        default:
          console.log("Nothing to update for data", data);
      }
    },
    removeInsurance() {
      this.insuranceModalData = {
        ...this.insuranceModalData,
        insuranceFileId: null,
        insuranceExpiry: null,
        insuranceImageURL: null,
      }
      this.$props.data.insuranceImageURL = null;
    },
    imageChanged(data){
      this.$props.data.insuranceFileId=data.fileID;
      this.$props.data.insuranceImageURL=data.fileURL;
    },
    updateInsurance(updateInsuranceData) {
      if (typeof updateInsuranceData.expirationDate != "string") {
        this.$props.data.insuranceExpiry = updateInsuranceData.expirationDate.toISOString();
      }
      restApi.post("/employee/updateVerifiedinsurance",encodeWithParam({partnerID: this.$props.data.partnerID,insuranceVerified: true, userID:this.$store.getters.getUserID}))
      this.updateAutoInsurance();
      this.insuranceModalData = {};
      this.closeModal();
      this.$props.data.insuranceVerified=true;
    },
    convertDate(date){
      let fullDate=date.split('T');
      return fullDate[0]
    },
    editableItems(data) {
      return data && data.length > 0 ? data.filter((item) => item.editable) : [];
    },
    getLabel(header) {
      return header.editLabel ? header.editLabel : header.label;
    },
    formatDate(dateString) {
      return DateTime.fromISO(dateString).toFormat("dd MMM yyyy");
    },
    roundAge(dateString) {
      return Math.floor(Math.abs(DateTime.fromISO(dateString).diffNow("years").years));
    },
    async updateDL(data) {
        if (this.$props.data.partnerID) {
          let obj={
                licenseNumber: this.candidateDetails.licenseNumber,
                licenseState: this.candidateDetails.licenseState,
                licenseExpiry: this.candidateDetails.licenseExpiry,
                partnerID: this.$props.data.partnerID,
              }
          setTimeout(async () => {
            if (!this.formError) {
              let result= await restApi.post(`/partner/updateDL`,encodeWithParam(obj))
              if(result.data.success){
                this.$emit("dlChange",obj);
                this.$emit("refresh", {});
                this.cancel(data)
              }
            }
          }, 1500)
        }
    },
    async updateAutoInsurance() {     
      let dt = new Date(this.candidateDetails.insuranceExpiry);
      let dtStr=`${dt.getFullYear()}-${(dt.getMonth()+1).toString().padStart(2,'0')}-${dt.getDate().toString().padStart(2,'0')}`
        let obj={
          insuranceExpiry:dtStr,
          partnerID:this.$props.data.partnerID,
          fileId: this.$props.data.insuranceFileId
        }   
        restApi.post(`/partner/updateInsurance`,encodeWithParam(obj)).then((data)=>{
          if(data.data.success){
            let localExpiry = new Date(obj.insuranceExpiry);
            let today = DateTime.now();
            let localExpirayDifference = Interval.fromDateTimes(today, localExpiry).length("days");
            if(Math.ceil(localExpirayDifference) > 0) {
              let obj = {
                assignedOS: this.$props.data.specialist ? this.$props.data.specialist : null,
                partnerID: this.$props.data.partnerID,
                stepStatus: FunnelStatus.OCR_MANUAL_CLEAR
              }
              restApi.post("/onboard/saveCandidateStatus", encodeWithParam(obj));
            }
            this.screeningTable[1].status =  "VALID";
            this.$emit("refresh", {});
            this.showFlyout('');
          }
        })
    },
    refresh() {
      this.$emit("refresh", {});
    },
    setStatus(expiryDate,type) {
      let localExpiry = new Date(expiryDate);
      let today = DateTime.now();
      let localExpirayDifference = Interval.fromDateTimes(today, localExpiry).length("days");
      if(type === "auto-insurance") {
        if(!this.$props.data.insuranceVerified) {
          console.log("Ddddd",this.$props.data)
          return "NOTVERIFIED"
        }
      }
      if (Math.round(localExpirayDifference) <= 14) {
        return "READYTOEXPIRE";
      }
      if (isNaN(localExpirayDifference)) {
        return "EXPIRED";
      } else {
        return "VALID";
      }
    },
    setTableHeader(value) {
      if(this.$props.data.partnerCBCResult ==="consider") {
        if(value.id === "screening-package") {
          return `CBC Consider`
        }
      }
      if(this.$props.data.partnerCBCResult ==="verified") {
        if(value.id === "screening-package") {
          return `CBC Verified`
        }
      }
      if(this.$props.data.partnerCBCResult ==="verified-inactive") {
        if(value.id === "screening-package") {
          return `CBC Consider-Inactive`
        }
      }
      if (value.status == "READYTOEXPIRE") {
        if (value.id == "screening-package") {
          return `Need Screening Renewal`;
        } else {
          return `${value.name} Expiring`;
        }
      }
      if( value.status === "NOTVERIFIED") {
        return `Auto Insurance Not Verified`
      }
      if (value.status == "EXPIRED") {
        if (value.id == "screening-package") {
          return `Need to Run Screening`;
        } else {
          return `Expired ${value.name}`;
        }
      } else {
        return value.name;
      }
    },
    addonMonths(date, numberOfMonths, key) {
      let addedDate = DateTime.fromISO(date).plus({ months: numberOfMonths });
      this.checkExp = DateTime.fromISO(addedDate).setZone("America/Los_Angeles").toISODate();
      if (key == "checkrData") {
        this.addCheckrData();
      }
    },
    addCheckrData() {
      this.screeningTable[0].value = this.checkExp?formatDatePacific(this.checkExp):"";
    },
    verifyPartnerCBC(status) {
         this.$apollo
        .mutate({
          mutation: UPDATE_CBC_VERIFIED,
          variables: {
            candidateID: this.$props.partner.id,
            status: status
          },
        }).then(() => {
          this.$emit("cbcverified");
        })
    },
    deactivatePartner() {
      this.verifyPartnerCBC("verified-inactive");
      this.$emit("inactivepartner")
    },
    format(date) {
      let dateToFormat = date;
      if (typeof date === "string") {
        dateToFormat = DateTime.fromISO(date);
      }
      return dateToFormat.toFormat("d LLL yyyy");
    },
    checkIsMasked(header) {
      if (!this.getPermission("viewAllPII")) return "";
      else if (header.key?.includes("eye")) return header.isMasked ? "eye icon-eye-off" : "eye icon-eye";
    },
    toggleMaskDisplay(key) {
      if(key == "dl-eye") {
        this.screeningTable[2].data[3].isMasked = false;
        setTimeout(() => this.screeningTable[2].data[3].isMasked = true, 2000)
      }
    },
    dateDisable(key) {
      return key == "licenseExpiry" ? true : false
    },
    rules(key) {
      if (key == "licenseNumber")
        return `valueExists:license|trailSpaces|license:${this.candidateDetails["licenseState"]}`
      else if (key == "licenseState")
        return `trailSpaces|licenseState:${this.candidateDetails["licenseNumber"]}`
    }
  },
};
</script>
