<template>
  <li v-if="wrap">
    <router-link :to="$props.to"><slot></slot></router-link>
  </li>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    to: {
      type: Object,
      required: true,
      default: () => {},
    },
    include: {
      type: Array,
      required: false,
      default: () => [],
    },
    exclude: {
      type: Array,
      required: false,
      default: () => [],
    },
    wrap: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["getRole"]),
    allowedRole() {
      const included = this.$props.include.includes(this.getRole);
      const excluded = this.$props.exclude.includes(this.getRole);
      return included && !excluded;
    },
  },
};
</script>
