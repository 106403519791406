<template>
  <base-modal :id="invoiceModalName" :full="true">
    <sticky-header :title="editing ? `Editing Invoice - ${invoiceName}` : `Invoice - ${invoiceName}`" classes = "margin-zero">
      <div class="button-group">
        <h4> Walmart </h4>
        <div v-if="!editing && !editingDefaultItem">
          <button v-if="status === 'PROCESSED'" class="button" @click="approveInvoice">{{ buttonText }}</button>
          <button class="button secondary" @click="cancel">Back</button>
          <button v-if="!editing && status === 'PROCESSED'" class="button secondary" @click="edit">Add Line Items</button>
          <button class="button secondary" @click="editInvoice">Edit Invoice</button>
        </div>
        <div v-else-if="editingDefaultItem">
          <button class="button secondary" @click="itemEditCancel">Back</button>
        </div>
        <div v-else>
          <button class="button secondary" @click="editCancel">Back</button>
        </div>
      </div>
    </sticky-header>
    <div class="container background-style">
      <div class="pdf-btn">
        <a @click="downloadPDF"><i class="icon-download"></i>PDF</a>
      </div>
      <vue-html2pdf
        ref="html2Pdf"
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1600"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="letter"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        :html-to-pdf-options="htmlToPdfOptions"
      >
        <section slot="pdf-content" v-html="invoiceHtml"></section>
      </vue-html2pdf>
      <section slot="content" ref="content">
        <div v-if="loadingFile" class="content">
          <div class="well text-center no-contents module" :style="{ minHeight: '500px', minWidth: '300px', height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }">
            <vue-simple-spinner />
          </div>
        </div>
        <div v-if="!loadingFile" class="content" :style="{ backgroundColor: '#fff', padding: '0 50px' }">
          <main>
            <div class="module-xlarge">
              <div class="grid">
                <div class="col-4">
                  <div class="module">
                    <h4>Bill To</h4>
                    <div class="businessAddress">
                      <p> Walmart</p>
                      <p>123 Walmart Abigail,</p>
                      <p>Cypress, NY 95726 </p>
                 </div>
                  </div>
                </div>
                <div class="col-8">
                  <h2>Invoice # {{ invoiceNumber }}</h2>
                  <div class="table-container">
                    <table>
                      <tbody>
                        <tr>
                          <td>Invoice Date</td>
                          <td class="flex-right">{{ invoiceDate }}</td>
                        </tr>
                        <tr>
                          <td>Work Period</td>
                          <td class="flex-right">{{ workPeriod }}</td>
                        </tr>
                        <tr>
                          <td>Invoice Due Date</td>
                          <td class="flex-right">{{ invoiceDueDate }}</td>
                        </tr>
                        <tr>
                          <td>Terms</td>
                          <td class="flex-right">{{ terms }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Account</th>
                    <th>Memo</th>
                    <th class="right-align">Amount</th>
                    <th class="cell5"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in totalLineItems" :key="index">
                    <td class="col-width">{{ item.chartOfAccounts }}</td>
                    <td>{{ item.servicesRenderedMemo ? item.servicesRenderedMemo : item.memo }}</td>
                    <td class="cell20 right-align">{{ formatAmount(item.amount) | formatMoney }}</td>
                    <td class="cell5">
                      <a v-if="(item.editable && editing) || editingDefaultItem" @click="deleteLineItem(item, index)"> <i class="icon-trash-2"></i></a>
                    </td>
                    <td class="cell5">
                      <a v-if="editingDefaultItem" @click="openEditFlyout(item)">Edit</a>
                    </td>
                  </tr>
                  <tr>
                    <td class="weight700">Total</td>
                    <td></td>
                    <td class="weight700 right">{{ sum | formatMoney }}</td>
                    <td class="cell5"></td>
                    <td class="cell5"></td>
                  </tr>
                </tbody>
              </table>
              <delete-invoice-line-modal :getClientInvoicing="this.$props.getClientInvoicing" :fetchInvoice="this.$props.fetchInvoice" :deleteIndex="this.deleteIndex" @delete-item="deleteLineModal" :invoiceId="this.invoiceId" :deleteDefaultItems="deleteDefaultItems" :selectedInvoice="this.selectedInvoice"></delete-invoice-line-modal>
            </div>
            <p :style="{ 'margin-top': '40px' }">
              <a v-if="editing" @click="add"><i class="icon-plus-circle"></i>Add Invoice Line Item</a>
            </p>
          </main>
        </div>
      </section>
    </div>
    <editable-invoice-flyout v-if="isFlyoutShowing" @edit="editLineItem" :getClientInvoicing="this.$props.getClientInvoicing" :fetchInvoice="this.$props.fetchInvoice" :addLineItems="this.addLineItems" :itemEditCancel="this.itemEditCancel" :editingDefaultItem="this.editingDefaultItem" :lineItemID="lineItemID" :invoiceID="this.invoiceId" :originalLineItem="originalLineItem" />
  </base-modal>
</template>

<script>
import StickyHeader from "@/components/layout/StickyHeader";
import VueHtml2pdf from "vue-html2pdf";
import { formatDatePacific, formatDate } from "@/filters";
import BaseModal from "@/components/modals/BaseModal";
import micromodal from "micromodal";
import { mapActions, mapGetters } from "vuex";
import EditableInvoiceFlyout from "./EditableInvoiceFlyout";
import DeleteInvoiceLineModal from "./DeleteInvoiceLineModal.vue";
import VueSimpleSpinner from "vue-simple-spinner";
import { restApi } from "../../../../http/http.config";
import { encodeWithParam } from "../../../../util/Base64Encoding";

export default {
  name: "InvoiceModal",
  components: { BaseModal, StickyHeader, VueHtml2pdf, EditableInvoiceFlyout, DeleteInvoiceLineModal, VueSimpleSpinner },
  props: {
    selectedInvoice: Object,
    loadingFile: Boolean,
    customAddress: Object,
    lineItems: Array,
    getClientInvoicing: {type: Function},
    fetchInvoice: {type: Function},
    invoiceId: {
      required: true,
      default: "",
    },
    invoiceModalName: {
      type: String,
      required: false,
      default: "invoiceModalName",
    },
    status: String,
  },
  data() {
    return {
      invoiceHtml: "",
      editing: false,
      newLineItem: false,
      lineItemID: "",
      flyoutOverlay: false,
      invoice: { client: { logo: "" }, invoice: { name: "", total: 0 } },
      htmlToPdfOptions: {
        filename: "Invoice",
        html2canvas: { useCORS: true, height: 1024, logging: true },
      },
      mutatedLineItems: [],
      tempLineItems: [],
      tempLineItem: {},
      totalLineItems: [],
      deleteIndex: "",
      type: "",
      editLineItems: new Map(),
      originalLineItem: {},
      editingDefaultItem: false,
      deleteDefaultItems: []
    };
  },
  computed: {
    ...mapGetters(["isFlyoutShowing"]),
    invoiceDueDate() {
      return this.selectedInvoice?.dueDate ? formatDate(this.selectedInvoice?.dueDate) : "";
    },
    invoiceDate() {
      if (this.selectedInvoice?.settlement?.endDate) {
        return this.selectedInvoice?.settlement?.endDate ? formatDatePacific(this.selectedInvoice?.settlement?.endDate) : "";
      } else if (this.selectedInvoice?.invoice?.startDate) {
        return this.selectedInvoice?.invoice?.startDate ? formatDatePacific(this.selectedInvoice?.invoice?.startDate) : "";
      } else {
        return "";
      }
    },
    clientName() {
      return this.selectedInvoice?.client?.name ? this.selectedInvoice.client.name : "";
    },
    buttonText() {
      return this.editing ? "Save" : "Approve Invoice";
    },
    // clientAddress1() {
    //   if (Object.keys(this.customAddress)?.length > 0) {
    //     return `${this.customAddress.address1 || ""}, ${this.customAddress.address2 || ""}`;
    //   } 
    //   return "";
    // },
    // clientAddress2() {
    //   if (Object.keys(this.customAddress)?.length > 0) {
    //     return `${this.customAddress.city || ""}, ${this.customAddress.state || ""} ${this.customAddress.postalCode || ""}`;
    //   } 
    //   return "";
    // },
    terms() {
      return this.selectedInvoice?.terms ? this.selectedInvoice.terms : "";
    },
    workPeriod() {
      if (this.selectedInvoice?.settlement?.startDate && this.selectedInvoice?.settlement?.endDate) {
        return `${formatDate(this.selectedInvoice.settlement.startDate)} - ${formatDatePacific(this.selectedInvoice.settlement.endDate)}`;
      } else if (this.selectedInvoice?.invoice?.startDate && this.selectedInvoice?.invoice?.endDate) {
        return `${formatDate(this.selectedInvoice.invoice.startDate)} - ${formatDatePacific(this.selectedInvoice.invoice.endDate)}`;
      } else {
        return "";
      }
    },
    invoiceNumber() {
      if (this.selectedInvoice.invoiceNumber) {
        return this.selectedInvoice.invoiceNumber.toString().padStart(10 - this.selectedInvoice.invoiceNumber.toString().length, "0");
      } else {
        return 0;
      }
    },
    sum() {
      return this.totalLineItems.reduce((acc, curr) => {
        const num = parseFloat(curr.amount);
        return acc + num;
      }, 0);
    },
    invoiceName() {
      return this.selectedInvoice?.invoice?.name ? this.selectedInvoice?.invoice?.name : "";
    },
  },

  watch: {
    tempLineItems() {
      this.totalLineItems = [...this.mutatedLineItems, ...this.tempLineItems];
    },
    lineItems: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.mutatedLineItems = JSON.parse(JSON.stringify(newValue))
        return this.totalLineItems = [...this.mutatedLineItems, ...this.tempLineItems];
      }
    }
  },
  methods: {
    addLineItems(data) {
      data.editable = true;
      this.$props.getClientInvoicing()
      this.fetchInvoice(this.invoiceId);
    },
    deleteLineItem(item, index) {
      this.deleteDefaultItems.push(item.id)
      this.deleteIndex = index;
      this.$props.getClientInvoicing()
      this.fetchInvoice(this.invoiceId);
      micromodal.show("modal-delete-line");
    },

    deleteLineModal() {
      const that = this;
      for (let i = 0; i <= this.tempLineItems.length; i++) {
        if (this.tempLineItems[i]?.chartOfAccounts == that.totalLineItems[that.deleteIndex]?.chartOfAccounts && this.tempLineItems[i]?.memo == that.totalLineItems[that.deleteIndex]?.memo) {
          that.totalLineItems.splice(that.deleteIndex, 1);
          that.tempLineItems.splice(i, 1);
        }
      }
      for (let i = 0; i <= this.mutatedLineItems.length; i++) {
        if (this.mutatedLineItems[i]?.chartOfAccounts == that.totalLineItems[that.deleteIndex]?.chartOfAccounts && this.mutatedLineItems[i]?.memo == that.totalLineItems[that.deleteIndex]?.memo) {
          that.mutatedLineItems.splice(that.deleteIndex, 1);
          break;
        }
      }
    },
    fileLoading(value) {
      this.loadingFIle = value;
    },
    ...mapActions(["showFlyout"]),
    add() {
      this.originalLineItem = {}; 
      this.flyoutOverlay = true;
      this.showFlyout("edit-invoice");
      document.body.style.overflow = "hidden";
    },
    editCancel() {
      this.editing = false;
      this.tempLineItems = [];
    },
    itemEditCancel() {
      this.deleteDefaultItems = [];
      this.editLineItems.clear();
      this.editingDefaultItem = false;
    },
    cancel() {
      micromodal.close(this.invoiceModalName);
      document.body.style.overflow = "visible";
    },
    edit() {
      this.editing = true;
    },
    downloadPDF() {
      const content = this.$refs.content;
      this.invoiceHtml = content.innerHTML;
      this.$refs.html2Pdf.generatePdf();
    },
    formatAmount(int) {
      return parseFloat(int);
    },
    save() {
      this.$props.getClientInvoicing()
      this.fetchInvoice(this.invoiceId);
      this.editing = false;
    },
    updateInvoiceStatus() {
      restApi.post('accounting/approveInvoice', encodeWithParam({id: this.invoiceId})).then(()=>{}).catch((e) => {console.log(e)})
    },
    approveInvoice() {
      this.updateInvoiceStatus()
      this.$props.getClientInvoicing()
      this.fetchInvoice(this.invoiceId);
      this.$emit("statusUpdated", "");

      this.cancel()
    },
    editInvoice() {
      this.editingDefaultItem = true;
    },
    editLineItem(item) {
      delete item.default
      this.editLineItems.set(item.id, item);
    },
    saveLineItem() {
      const lineItemsAdd = this.selectedInvoice.invoice;
      lineItemsAdd.lineItems = 
        lineItemsAdd.lineItems.map((item) => { 
          if (this.editLineItems.has(item.id)) {
            item.chartOfAccounts = this.editLineItems.get(item.id)?.chartOfAccounts
            item.memo = this.editLineItems.get(item.id)?.memo
            item.amount = this.editLineItems.get(item.id)?.amount
          }
          return item;
        }).filter(item => !this.deleteDefaultItems.includes(item.id));
    },
    openEditFlyout(item) {
      this.flyoutOverlay = true;
      item.default = true;
      this.originalLineItem = item;
      this.lineItemID = item.id;
      this.invoiceID = this.invoiceId

      this.showFlyout("edit-invoice");
      document.body.style.overflow = "hidden";
    },
  },
}
</script>
<style scoped>
table .col-width {
  max-width: 50%;
  word-break: break-all;
}

.businessAddress p {
  margin-bottom: 8px;
}

.table-container tbody:nth-child(2) tr:nth-last-child(2) {
  border-bottom: 4px solid var(--primary);
}

.weight700 {
  font-weight: 700;
}

.background-style {
  background-color: #eee;
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>
