<template>
  <fragment>
    <flyout name="candidate-flyout" :title="$props.candidate.name">
      <template v-if="$props.displayFullProfileButton" #action>
        <a class="button secondary" @click="showFullProfile">Full Profile</a>
      </template>
      <template #contactaction>
        <div v-if="getPermission('sendComms')" class="contact-block flyout-header">
          <div class="communication-tag" @click="communication"><i class="icon med-size icon-mail"></i><span>Email</span></div>
          <div class="communication-tag" @click="communication"><i class="icon med-size icon-message-square"></i><span>Text</span></div>
          <div class="communication-tag"><i class="icon med-size icon-phone"></i><a :href="`tel:${$props.candidate.phone}`">Phone</a></div>
        </div>
      </template>
      
      <div class="partner-info-heading" style="display: flex;">
        <div class="partner-heading" style="width:50%">
          <h3 class="flyout-heading-h3">Candidate Info</h3>
        </div>
        <!-- <div class="partner-status" style="width: 45%; text-align: end;" v-if="invalidate($props.candidate.stage)">
          <span style="color:#c2272c; cursor: pointer;" v-if="invalidateCandidate" @click="invalidCandidate">Invalidate</span>
          <span style="color:#30a154; cursor: pointer;" v-if="!invalidateCandidate" @click="rollBackStatus">Re-validate</span>
          <div style="color:#c2272c; cursor: pointer;margin-top:10px" v-if="!invalidateCandidate" @click="deleteCandidate">Delete</div>
        </div>     -->
      </div> 
      <p>{{ $props.candidate.name }}</p>
      <p>{{ $props.candidate.username }}</p>
      <p>
        <a :href="mailLink">{{ $props.candidate.email }}</a>
      </p>
      <p>
        <a :href="`tel:${$props.candidate.phone}`">{{ $props.candidate.phone | formatPhone }}</a>
      </p>
      <hr />
      <h3 class="flyout-heading-h3">Location Approval Progress</h3>
      <div class="flex space-between">
        <div>
          <p>{{ $props.candidate.location }}</p>
        </div>
        <div class="right right-align" v-if="currentStep.displayChangeLocation && getPermission('changeZone')">
          <change-location :candidateID="candidateID" @locationChanged="refresh"/>
        </div>
      </div>
      <fragment>
        <funnel-progress-bar :partner-steps="partnerSteps" header-class="inline-offset" />
        <hr />
        <meatball id="current-step-meatball" right large title="Options">
          <menu-item label="Snooze Alert Status" :on-click="openModal('modal-snooze-time-alert')" />
          <menu-item v-if="getPermission('resendAppLink')" label="Resend Application Link to Candidate" :on-click="openComms" />
          <div v-if="showWithdrawn && getPermission('withdrawApplicant')">
            <menu-item label="Remove Candidate from Funnel (Withdraw)" :on-click="openModal('modal-withdraw-candidate')" />
          </div>
          <!-- <menu-item label="Reset Application for Candidate" :on-click="openModal('modal-reset-application')" /> -->
        </meatball>
        <h3 class="flyout-heading-h3">Current Step</h3>
        <h5>{{ fp.getStageLabel($props.candidate.stage) }} - {{ fp.getStepLabel($props.candidate.step) }}</h5>
        <current-step v-if="$props.candidate.name"
          ref="currentStepRef"
          :data="currentStepData"
          :candidate="$props.candidate"
          :viewIns="$props.viewIns"
          :personalDetails="$props.personalDetails"
          :partner-steps="partnerSteps"
          :isPartnerPage="$props.isPartnerPage"
          :isCandidateFlyoutDataLoading="$props.isCandidateFlyoutDataLoading"
          @onSaveQualifyingAnswers="updateEligibilityInfo"
          @onSavePersonalDetails="updatePersonalDetails"
          @onRemoveInsurance="removeInsuranceImage"
          @insuranceImageUploaded="insuranceImageUploaded"
        />
        <!--        <maintenance-alerts v-model="screeningVerified" :candidate="$props.candidate"/>-->
      </fragment>
      <hr />
      <div v-if="getPermission('rwNotes')">
        <div class="view-notes-btn">
          <a class="button ghost" @click="notesToggel"> View candidate notes<i class="right-icon" :class="{ 'icon-chevron-up': toggleNotes, 'icon-chevron-down': !toggleNotes }"> </i> </a>
        </div>
        <div v-if="toggleNotes">
          <div v-if="$props.candidate.notes">
            <div class="notes-user-main">
            <div class="notes-user-card">
                <div class="notes-user-message"> <p>{{$props.candidate.notes}}</p></div>
              </div>
               </div>
        </div>

            <div v-if="notesData" class="notes-user-main">
            <div v-for="item in notesData" :key="item.date" class="notes-user-card">
                <div class="user-heading"> <p class="user-p">{{item.user}}</p> <p class="notes-date">{{item.date | formatDatePacific}}</p></div>
                <div class="notes-user-message"> <p>{{item.message}}</p></div>
              </div>
               </div>
            <div v-if="!$props.candidate.notes && !notesData"> 
              No Notes
            </div>
        </div>
      </div>

      <div v-if="getPermission('rwNotes')" class="field">
        <label>Candidate Notes</label>
        <div class="has-charLimit">
          <textarea v-model="notesText" placeholder="" @blur="saveNotes($event.target.value)"></textarea>
          <p class="charlimit">500</p>
        </div>
      </div>
    </flyout>
    <communications-flyout :candidate="$props.candidate" :current-step="currentStep" :on-close="closeComms" :message-data="messageData" />
    <withdraw-candidate-modal :candidate-step="candidateStep" @onWithdraw="withdraw" />
    <!-- <reset-application-modal :candidate-step="candidateStep" @onReset="resetCandidateToStep" /> -->
    <snooze-time-alert-modal :candidate-step="candidateStep" @onSnooze="updateSnooze" />
    <single-action :single-obj="singlePartnerObj" :on-close="closeSingleAction" />
  </fragment>
</template>
<script>
import { Flyout, Meatball,
  MenuItem,FunnelProgressBar } from "@/components";
import { mapActions, mapGetters } from "vuex";
import CurrentStep from "@/modules/admin/onboarding/components/CurrentStep";
//import { OnboardingStatus } from "@/modules/admin/partners/model";
import { Fragment } from "vue-fragment";
import { CommunicationsFlyout } from "@/modules/shared/communications";
//import ResetApplicationModal from "@/modules/admin/onboarding/modals/ResetApplicationModal";
import micromodal from "micromodal";
import SnoozeTimeAlertModal from "@/modules/admin/onboarding/modals/SnoozeTimeAlertModal";
import { DateTime } from "luxon";
import WithdrawCandidateModal from "@/modules/admin/onboarding/modals/WithdrawCandidateModal";
import { SingleAction } from "@/modules/shared/communications";
import { v4 as uuidv4 } from "uuid";
import ChangeLocation from "@/modules/admin/onboarding/components/ChangeLocation";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";
import PollingService from "@/services/polling.service";
import { encrypt } from "@/filters";
import {FunnelProgress,FunnelStatus,FunnelStages,FunnelSteps} from "@/util/funnelProgress";
//import FunnelProgressBar from '../../../../components/progress/FunnelProgressBar.vue';

export default {
  components: {
    WithdrawCandidateModal,
    SnoozeTimeAlertModal,
   // ResetApplicationModal,
    MenuItem,
    Meatball,
    Flyout,
    CurrentStep,
    CommunicationsFlyout,
    Fragment,
    SingleAction,
    ChangeLocation,
    FunnelProgressBar
    /*
    MaintenanceAlerts
*/
  },
  props: {
    viewIns:{
      type: Boolean,
      required:false,
      default:false
    },
    cid: {
      type: String,
      required:true,
      default:""
    },
    candidate: {
      type: Object,
      required: true,
      default: () => {},
    },
    displayFullProfileButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    eligibilityQuestions: {
      type: Array,
      required: true,
      default: ()=>[]
    },
    partnerApplication: {
      type: Array,
      required: true,
      default: ()=>[]
    },
    personalDetails: {
      type: Object,
      required: true,
      default: ()=>{}
    },
    isPartnerPage: {
      type: Boolean,
      required: false,
      default: false
    },
    isCandidateFlyoutDataLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
     fp: new FunnelProgress(),
   // eligibilityQuestions: [],
    singlePartnerObj: [],
    funnelFiles: [],
    insuranceImageURL: "",
    notesText:"",
    screeningVerified: true,
    invalidateCandidate:true,
    toggleNotes:false,
    messageData: {
      subject: "Continue Your Application",
      body: `Hi There,\nwe are actively seeking drivers in your zone, and we noticed that you have not completed driver enrollment.Are you still interested in signing up to deliver on the Spark Driver app? you can continue here bit.ly/3Hac6CS \n\nThanks, \nSpark Driver™ Team `,
    },
    notes: "",
    templates: [],
    overrides: [],
    isoverrideDone: false,
    partnerSteps: [],
    intervalName: "",
    steps: [FunnelStages.INSURANCE,FunnelStages.RISK_MITIGATION,FunnelStages.APPLICATION],
    checkrData: null,
  }),
  watch: {
    async isFlyoutShowing(value) {
      if(value) {
        await this.getPartnerSteps(this.candidateID);
        await this.getCheckrData(this.candidateID);
        if (this.steps.includes(this.partnerSteps[0]?.stage)) {
          this.startPoll();
        }
      } else if (!value && this.intervalName != "") {
          PollingService.clearPolling(this.intervalName);
          this.refresh();
      }
    }
  },
  computed: {
    ...mapGetters(["getUsername", "isFlyoutShowing", "getPermission"]),
    candidateID() {
      return this.$props.cid ? this.$props.cid : "";
    },
    locationID() {
      return this.$props.candidate ? this.$props.candidate.locationID : null;
    },
    clientID() {
      return this.$props.candidate ? this.$props.candidate.clientID : null;
    },
    notesData(){
      return this.$props.candidate.notesList ?.notes ? this.$props.candidate.notesList.notes : null;
    },
    mailLink() {
      return `mailto:${this.$props.candidate.email}`;
    },
    showWithdrawn() {
      if(this.partnerSteps.length) {
        const stepsNotToShowWithDrawn = [FunnelSteps.APPLICATION_APPROVED,FunnelSteps.WITHDRAWN];
        return stepsNotToShowWithDrawn.includes(this.partnerSteps[0].step) ? false : true
      } else {
        return false
      }
    
    },
    currentStep() {
      const currentStep = Object.assign({}, this.partnerSteps[0]);
      let mvrSubmittedDate;
      let mvrClearDate;
      let packageSubmittedDate;
      let packageClear;
      let packageConsider;
      let mvrConsiderDate;
      let mvrDeniedDate;
      let displayScreeningFlag = false;
      let screeningStatus = "";
      let screeningDenied;
      let cbcPromotedDate;
      let mvrsubmittedneededdate;
      let reportErrorDateMVR;
      let reportErrorDateCBC;
      let checkrOnboardError;
      let approvedTimeStamp;
      let displayChangeLocation = true;
      const currentStepScreening = this.partnerSteps.filter((item) => {
        
          if (item.status === FunnelStatus.MVR_SUBMITTED) {
            mvrSubmittedDate = this.format(item.date);
            return item;
          }
          if (item.status === FunnelStatus.MVR_CLEAR) {
            mvrClearDate = this.format(item.date);
            return item;
          }
    
          if (item.status === FunnelStatus.MVR_CONSIDER) {
            mvrConsiderDate = this.format(item.date);
            return item;
          }
          if(item.status === FunnelStatus.MVR_DENIED) {
              mvrDeniedDate = this.format(item.date)
              return item;
          }
          if (item.status === FunnelStatus.CBC_SUBMITTED) {
            packageSubmittedDate = this.format(item.date);
            return item;
          }
          if (item.status === FunnelStatus.CBC_CLEAR) {
            packageClear = this.format(item.date);
            return item;
          }
          if (item.status === FunnelStatus.CBC_CONSIDER) {
            packageConsider = this.format(item.date);
            return item;
          }
          if (item.status === FunnelStatus.CBC_DENIED) {
            screeningDenied = this.format(item.date);
            return item;
          }
      });

      switch (currentStepScreening[0]?.status) {
        case FunnelStatus.MVR_SUBMITTED:
          screeningStatus = "MVR Pending";
          break;

        case FunnelStatus.MVR_CONSIDER:
          screeningStatus = "MVR Returned Consider";
          break;

        case FunnelStatus.CBC_SUBMITTED:
          screeningStatus = "CBC Pending";
          break;

        case FunnelStatus.CBC_CLEAR:
          screeningStatus = "CBC Returned Eligible";
          break;

        case FunnelStatus.CBC_CONSIDER:
          screeningStatus = "CBC Returned Consider";
          break;

        case FunnelStatus.CBC_DENIED:
          screeningStatus = "Denied";
          break;
        
      }
      if (this.partnerSteps && this.partnerSteps[0]?.stage === FunnelStages.RISK_MITIGATION) {
        displayScreeningFlag = true;
      }
  
      if (this.partnerSteps[0]?.stage === FunnelStages.APPLICATION) {
        displayChangeLocation = false;
      }
      return {
        ...currentStep,
        screeningSteps: currentStepScreening,
        displayScreeningFlag,
        mvrSubmittedDate,
        mvrClearDate,
        packageSubmittedDate,
        mvrConsiderDate,
        screeningStatus,
        packageClear,
        packageConsider,
        screeningDenied,
        cbcPromotedDate,
        mvrsubmittedneededdate,
        displayChangeLocation,
        mvrDeniedDate,
        reportErrorDateCBC,
        reportErrorDateMVR,
        checkrOnboardError,
        approvedTimeStamp

      };
    },
    candidateStep() {
      if (this.$props.candidate && this.$props.candidate.id) {
        const steps = Array.from(new Set(this.partnerSteps.flatMap((partnerStep) => partnerStep.step))).map((step) => ({ id: step, label: this.$options.filters.titleCase(step) }));
        return { ...this.candidate, steps };
      }
      return { name: "" };
    },
    currentStepData() {
      return { currentStep: this.currentStep, steps: this.partnerSteps, checkrData: this.checkrData, eligibilityQuestions: this.eligibilityInfo, funnelDocuments: this.templatesWithOverrides };
    },
    eligibilityInfo() {
      return this.$props.eligibilityQuestions.map((question) => {
        let response = "";
         if (this.$props.partnerApplication.length) {
          const responseIndex = this.$props.partnerApplication.findIndex((response) => response.questionID === question.id)
          if (responseIndex >= 0) {
             response = this.$props.partnerApplication[responseIndex].response;
          }
        }
        return { ...question, response };
      });
    },
    templatesWithOverrides() {
      if (this.isoverrideDone) {
        return this.templates
          .filter(() => {
            return !this.overrides.some((override) => {
              return override.locationID === location.id && override.type === "REMOVE";
            });
          })
          .filter((template) => !this.overrides.some((item) => item.templateID === template.id))
          .map((template) => {
            if (template.isDDI || (template.clientID != null && template.locationID == null)) {
              template.inherited = true;
            }
            return template;
          });
      }
      return [];
    },
  },
  methods: {
    ...mapActions(["showFlyout"]),
    deleteCandidate() {
      restApi.post("/onboard/deletePartner",encodeWithParam({partnerID:this.$props.candidate.partnerID})).then(()=>{
        this.showFlyout("");
      })
    },
    insuranceImageUploaded() {
      this.$emit("insuranceImageUploaded")
    },
    removeInsuranceImage() {
      this.$emit("onRemoveInsurance")
    },
    refresh() {
      this.$emit("refresh", {});
    },
    startPoll() {
      this.intervalName = PollingService.startPolling(this.getPartnerSteps, 3000, [this.candidateID]);
    },
    showFullProfile() {
      this.showFlyout("");
      this.$router.push({ name: "partner", params: { id: this.$props.candidate.partnerID } });
    },
    resolveClass(step) {
      console.log(step)
      return "success"
    },
    openComms() {
      document.body.classList.add("no-scroll");
      this.showFlyout("comms-flyout");
    },
    closeComms() {
      document.body.classList.remove("no-scroll");
      this.showFlyout("candidate-flyout");
    },
    closeSingleAction() {
      this.singlePartnerObj = [];
      document.body.classList.remove("no-scroll");
      this.showFlyout("");
    },
    communication() {
      this.singlePartnerObj = [];
      this.singlePartnerObj.push(this.$props.candidate);
      document.body.classList.add("no-scroll");
      this.showFlyout("single-comms-flyout");
    },
    openModal(id) {
      return () => micromodal.show(id, {});
    },

    updateSnooze(snooze) {
      let alertTime = DateTime.utc();
      switch (snooze) {
        case "TWO_HOURS":
          alertTime = alertTime.plus({ hours: 2 });
          break;
        case "SIX_HOURS":
          alertTime = alertTime.plus({ hours: 6 });
          break;
        case "TWELVE_HOURS":
          alertTime = alertTime.plus({ hours: 12 });
          break;
        case "ONE_DAY":
          alertTime = alertTime.plus({ days: 1 });
          break;
        case "TWO_DAYS":
          alertTime = alertTime.plus({ days: 2 });
          break;
        case "SEVEN_DAYS":
          alertTime = alertTime.plus({ days: 7 });
          break;
        case "NEXT_MONDAY":
          alertTime = alertTime.set({ weekday: 1 }).plus({ days: 7 });
      }

      restApi.post('/onboard/updateSnoozeTime',encodeWithParam({id:this.currentStep.id,alertTime: alertTime.toJSDate()}))
    },
    withdraw(reason) {
      let candidateProgress=this.candidateProgressObject(FunnelStatus.WITHDRAWN)
      restApi.post('/onboard/saveCandidateStatus',encodeWithParam(candidateProgress)).then(()=>{
        let withdrawnReason = `WITHDRAWN REASON-${reason}`
        this.saveNotes(withdrawnReason)
       this.$emit("onClose");
      })
    },

    candidateProgressObject(status) {
      return {
        assignedOS: this.candidate.specialist,
        partnerID: this.candidate.partnerID,
        stepStatus: status
      };
    },
    updateEligibilityInfo(eligibilityInfo) {
      const eligibilityKeys = Object.keys(eligibilityInfo);
      const responses = this.$props.eligibilityQuestions
        .filter((question) => eligibilityKeys.includes(question.key))
        .filter((question) => question.key != "vehicleType")
        .filter((question) => question.key != "isWalmartAssociate")
        .map((question) => {
          const response = eligibilityInfo[`${question.key}`];
          return {
            applicationID: this.partnerApplication.id,
            questionID: question.id,
            response,
            updatedBy: this.getUsername,
            updatedDate: new Date(),
            answeredBy: this.getUsername,
            answeredDate: new Date(),
          };
        });
            responses.map((item)=>{
          restApi.post('/employee//updFunnelAnswer',encodeWithParam({response:item.response,questionID: item.questionID,partnerID: this.$props.candidate.partnerID,
          clientLocationID: this.$props.candidate.locationID}))
        }) 
    },

    async updatePersonalDetails(personalDetailsInfo) {
      let addressID;
      const SnnFour =  personalDetailsInfo.ssn.substr(personalDetailsInfo.ssn.length - 4);
      const encryptedSnn =  await encrypt(personalDetailsInfo.ssn.replace(/\D/g, ''))
      restApi.post('/onboard/updateProfileDetails',encodeWithParam({ 
        id: this.$props.candidate.partnerID,
        ssn: encryptedSnn,
        ein: personalDetailsInfo.ein,
        dateOfBirth: personalDetailsInfo.dob,
        ssnFour: SnnFour
        }))
        .then(({ data }) => {
          const driversLicenseID = data?.result[0]?data?.result[0].dlid : uuidv4();
          addressID = data?.result[0]?data?.result[0].addressID : uuidv4();
          return restApi.post('/onboard/updateDL',encodeWithParam({
            id: driversLicenseID,
            number: personalDetailsInfo.licenseNumber,
            state: personalDetailsInfo.licenseState,
            expiration: personalDetailsInfo.licenseExpiry
          }))
        })
        .then(() => {
          return restApi.post('/onboard/updateAddress',encodeWithParam({
            id:addressID,
            address1: personalDetailsInfo.streetAddress,
            city: personalDetailsInfo.city,
            state: personalDetailsInfo.state,
            postalCode: personalDetailsInfo.zip

          }))
        })
        .then(() => {
          this.refresh()
          this.$emit("refetchPII", this.candidateID )
        });
    },
    format(date) {
      let dateToFormat = date;
      if (typeof date === "string") {
        dateToFormat = DateTime.fromISO(date);
      }
      return dateToFormat.toFormat("d LLL yyyy - t");
    },
    saveNotes(message) {
      if(message.length > 0){
      let localObj={"user":localStorage.getItem("role"),"date":new Date().toISOString(),"message":message}
      if(!this.$props.candidate.notesList){
        this.$props.candidate.notesList={"notes":[]}
      }
      this.$props.candidate.notesList.notes.push(localObj)
      restApi.post('/onboard/updatePartnerNotes',encodeWithParam({id:this.$props.candidate.id,notes:this.$props.candidate.notesList}))
      .then(()=>{
        this.notesText="";
        this.refresh()
      })
    }
    },
    manualApproval() {
      this.$refs.currentStepRef.manualApproval("yes");
    },

    notesToggel(){
      this.toggleNotes= !this.toggleNotes;
    },
    async getPartnerSteps(id) {
      await restApi.post('onboard/getFunnelProgress',encodeWithParam({candidateID: id}))
        .then((data) => {
          this.partnerSteps = data.data.result.map((partnerStep) => {
            return {
              id: partnerStep.id,
              step: partnerStep.name,
              displayName: partnerStep.description,
              stage: partnerStep.stage,
              status: partnerStep.candidateStatus,
              date: partnerStep.date,
            };
          });
          if ((this.partnerSteps[0]?.stage == FunnelStages.APPLICATION && this.intervalName != "")) {
                PollingService.clearPolling(this.intervalName);
          }
        })
    },
    async getCheckrData(id) {
      if ((this.partnerSteps[0]?.stage === FunnelStages.RISK_MITIGATION) || this.partnerSteps[0]?.stage === FunnelStages.APPLICATION) {
        await restApi.post('onboard/getDriverCheckrStatus',encodeWithParam({candidateID: id}))
        .then((data) => {
          if(data.data?.result?.length > 0) {
            this.checkrData = {
              checkrStatus: data.data?.result[0]?.checkrStatus,
              screeningPackage: data.data?.result[0]?.screeningPackage,
              isNewCheckrFlow: (data.data?.result[0]?.key === 'checkrVersion' && data.data?.result[0]?.value === 'v2'),
            }
          }
        });  
      }
    }

  },
  mounted() {
    micromodal.init({
      onShow: function () {
        document.body.classList.add("no-scroll",);
      },
      onClose: function () {
        document.body.classList.remove("no-scroll");
      },
    });
  },
};
</script>
<style>
.has-charLimit {
  position: relative;
}
.has-charLimit textarea {
  padding-bottom: 30px;
  max-width: 540px;
  min-width: 540px;
}
.has-charLimit .charlimit {
  position: relative;
  bottom: 27px;
  left: 510px;
  text-align: right;
  font-size: 12px;
  font-size: 0.75rem;
  color: var(--text-color-light);
}</style>