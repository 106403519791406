<template>
  <base-modal id="modal-withdraw-candidate">
    <h4>Withdraw Candidate Application</h4>
    <p>Are you sure you would like to withdraw this candidate from consideration?</p>
    <div class="module no-space">
      <table class="no-zebra">
        <thead>
          <th>Name</th>
          <th>Location</th>
          <th>Step</th>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>{{ $props.candidateStep.name }}</strong>
            </td>
            <td>{{ $props.candidateStep.location }}</td>
            <td>{{ $props.candidateStep.step | titleCase }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="field">
      <label>Withdrawal Reason*</label>
      <div class="has-charLimit">
        <textarea v-model="reason" placeholder="" maxlength="500" @input="reasonInput($event.target.value)"></textarea>
        <p class="charlimit">500</p>
      </div>
    </div>
    <div class="button-group close-top">
      <button :disabled="isInvalid" class="button" @click="withdraw">Continue</button>
      <a class="button secondary" @click="cancel" @click.prevent>Cancel</a>
    </div>
  </base-modal>
</template>
<script>
import { BaseModal } from "@/components";
import micromodal from "micromodal";
export default {
  components: { BaseModal },
  props: {
    candidateStep: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data: () => ({
    reason: "",
    isInvalid: true,
  }),
  methods: {
    reasonInput(value) {
      this.isInvalid = value.length > 0 ? false : true;
    },
    withdraw() {
      this.$emit("onWithdraw", this.reason);
      this.cancel();
    },
    cancel() {
      this.reason = "";
      this.isInvalid = true;
      micromodal.close("modal-withdraw-candidate");
    },
  },
};
</script>
