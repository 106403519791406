import Base64 from "base-64";
  export const encodeWithParam=(data)=> {
    const toStr= JSON.stringify(data)
    return {"param":Base64.encode(toStr)}
   };

   export const encodeWithParamutf = (data) => {
    const toStr = JSON.stringify(data);
    const encodedStr = Buffer.from(toStr, 'utf-8').toString('base64');
    return { "param": encodedStr };
  };
  
   export const encode=(data)=> {
    const toStr= JSON.stringify(data)
    return Base64.encode(toStr)
   };

   export const decode=(data)=> {
    const str=Base64.decode(data)
    return JSON.parse(str)
   };
   
   export const PUBLIC_AUTH_KEY = "LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS1cbk1Gd3dEUVlKS29aSWh2Y05BUUVCQlFBRFN3QXdTQUpCQUtKMHp0SkdMblFvc2FESHlxRkpndDEwREtkUnNRY0Jcbm05WkQwYlpqUkhIWWtrelVRUTBmTDhKQ3N1Y3lnTDZsMmdGc0FjdlBaTEwvc2Z6Q0loQTA4T01DQXdFQUFRPT1cbi0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQ==";

