<template>
  <base-modal id="upload-threshold-request" class="">
    <h4>Upload Threshold</h4>
    <br><br>
    <div class="upload-file">
      <label>Upload Thresholds Request File (CSV only)</label>
      <input id="thresholdRequestFile" ref="fileUpload" type="file" name="thresholdRequestFile" @change="uploadFile" />
    </div>
    <div class="response-msgs">
      <div v-if="invalidFile" class="failed">Invalid file format</div>
      <div v-if="uploadSuccess" class="success">Upload Successful</div>
      <div v-if="uploadFail" class="failed">Error during upload</div>
    </div>
    <div class="button-group">
      <button class="button primary primary-blue" :disabled="disabled || !isDisabled" @click="uploadData">
        Upload
        <span v-if="loader" class="loading loader-class"><Loader /></span>
      </button>
      <button class="button secondary" @click="closeModal">Cancel</button>
    </div>
  </base-modal>
</template>
<script>

import BaseModal from "@/components/modals/BaseModal";
import micromodal from "micromodal";
import { mapGetters } from "vuex";
import { restApi } from "@/http/http.config";
import Loader from "@/components/navigation/Loader";

export default {
  name: "UploadThresholdRequestModal",
  components: { 
    BaseModal, 
    Loader,
  },
  props: {
    clientID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      invalidFile: false,
      uploadSuccess: false,
      loader: false,
      disabled: false,
      files: "",
      fileName: "",
      uploadFail: false,
    };
  },
  computed: {
    ...mapGetters(["getUsername"]),
    isDisabled() {
      return !this.invalidFile && this.fileName != "" ;
    }
  },
  methods: {
    async uploadFile(e) {
      this.files = e.target.files[0];
      this.fileName = this.files.name.split(".")[0];
      const fileExt = this.files.name.split(".")[this.files.name.split(".").length - 1];
      this.invalidFile = fileExt == "csv" ? false : true;
    },
    async uploadData() {
      this.loader = true;
      this.disabled = true;
      const formData = new FormData();
      formData.append("fileName", this.fileName);
      formData.append("file", this.files);
      formData.append("clientID", config.CLIENT_ID);
      formData.append("username", this.getUsername);
      await restApi.post(`/location/uploadThreshold`, formData)
        .then(({data}) => {
          this.loader = false;
          this.disabled = false;
          if (data.success) {
            this.uploadSuccess = true;
            setTimeout(() => this.closeModal(), 1500);
          } else {
            this.uploadFail = true;
            setTimeout(() => this.closeModal(), 1500);
          }
        })
    },
    closeModal() {
      micromodal.close("upload-threshold-request");
      this.invalidFile = false;
      this.uploadSuccess = false;
      this.$refs.fileUpload.value = "";
      this.fileName = "";
      this.files = "";
      this.uploadFail = false;
      this.$emit("refresh");
    }
  },
};
</script>
<style scoped>
.date-text {
  color: #707070;
  font-size: 14px;
}
.date-input {
  width: 168px;
  margin-right: 18px;
}
.date-error {
  border-radius: 4px;
  border: 1px solid #E71742;
}
.error-text {
  color: #E71742;
  font-size: 14px;
  line-height: normal;
}
.response-msgs .failed {
  color: brown;
}
.response-msgs {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
}
.upload-file {
  margin-top: 0px;
}
.response-msgs .success {
  color: seagreen;
}
.primary-blue {
  background-color: #0071DC;
}
.primary-blue:disabled {
  opacity: 0.5;
  background-color: #0071DC;
}
.pad-left-90 {
  padding-left: 90%;
}
.margin-left-60 {
  margin-left: 60px;
}
.loader-class {
  color: white;
}
</style>
