<template>
  <div>
    <hr />
    <div v-if="isCandidateFlyoutDataLoading" class="flex align-center">
      <a class="button ghost"> View Documents </a> 
      <custom-spin-loader radius="20px"/>
    </div>
    <expansion-panel v-else :label="`View Documents (${signedDocumentCount}/${totalDocs.length})`">
      <data-table hide-headers :headers="documentTableHeaders" :entries="totalDocs">
        <template #status="{ item }">
          <i class="icon" :class="{ 'icon-check': isSigned(item), 'icon-alert-triangle': !isSigned(item) }"></i>
        </template>
        <template #name="{ item }">
          <span v-if="!isSigned(item)">{{ item.title }}</span>
          <a v-else @click="openDocument(item)">{{ item.title }} <i class="icon icon-external-link"></i></a>
        </template>
      </data-table>
    </expansion-panel>

    <flyout-document-preview pdf :downloadable="false" :preview="true" :content="content"></flyout-document-preview>
  </div>
</template>
<script>
import { DataTable, ExpansionPanel, TableHeader, CustomSpinLoader } from "@/components";
import FlyoutDocumentPreview from "@/modules/shared/documents/FlyoutDocumentPreview";
import debounce from "debounce";
import axios from "axios";
import { fileApi } from "@/http/http.config";
// import { restApi } from "@/http/http.config";
// import {encodeWithParam} from "@/util/Base64Encoding";
export default {
  components: { ExpansionPanel, DataTable, FlyoutDocumentPreview, CustomSpinLoader },
  props: {
    funnelDocuments: {
      type: Array,
      required: true,
      default: () => [],
    },
    partnerId: {
      type: String,
    },
    isCandidateFlyoutDataLoading: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data: () => ({
    content: "",
    newlyAdded: false
  }),
  computed: {
    signedDocumentCount() {
      if (this.totalDocs.length > 0) {
        return this.totalDocs.filter((funnelDocument) => funnelDocument.signedDocumentID !== null).length;
      }
      return 0;
    },
    totalDocs() {
      return [...this.$props.funnelDocuments].filter(item => !["W9", "Insurance"].includes(item.title));
    },
    documentTableHeaders() {
      return [new TableHeader({ name: "status", headerClass: "cell5", cellClass: (item) => (this.isSigned(item) ? "signed" : "unsigned") }), new TableHeader({ name: "name" })];
    },
  },
  mounted() {
    this.contentToDisplay = debounce(this.contentToDisplay, 500);
  },
  methods: {
    // getW9Docs() {
    //   restApi.post('/onboard/getCandidateW9Docs',encodeWithParam({userID:this.$props.partnerId})).then((data)=>{
    //     data.data.result.map(async (res) => {
    //       if (res.name.indexOf("W9") > -1 && res.path.indexOf("W9") > -1) {
    //         const W9Contents = await this.getW9ContentDocs(res.id);
    //         console.log(W9Contents);
    //         const W9DocObj = {
    //           title: res.name,
    //           signedDocuments: [{ documentContents: `${res.path}` }],
    //         };
    //         this.w9Documents = [];
    //         this.w9Documents.push(W9DocObj);
    //       }
    //     });
    // })
    // },
    openDocument(template) {
      this.content = "";
      this.contentToDisplay(template);
    },
    isSigned(template) {
      return template?.signedDocumentID == null ?false :true;
    },
    contentToDisplay(template) {
      if(template.documentContents){
        template.documentContents=decodeURIComponent(template.documentContents)
      }
      this.content = template.signedDocumentID !== null ? template?.documentContents : "";
    },
    async getW9ContentDocs(fileID) {
      try {
        const res = await fileApi.get(`${config.VUE_APP_API_BASE_URL || process.env.VUE_APP_API_BASE_URL}/files?id=${fileID}`);
        //res.data is the signed url
        return await axios.get(res.data);
      } catch (error) {
        this.$log.error(error);
      }
    },
  },
};
</script>
