<template>
  <add-edit-page>
    <template #header="{ validation }">
      <sticky-header title="Create Team" classes = "margin-zero">
        <save-cancel :save-enabled="!validation.invalid && selectedDepartmentManagers.length > 0" @onSave="saveTeam()" />
        <p v-if="selectedDepartmentManagers.length < 1" class="error-message">You cannot create a team without a Department Manager</p>
      </sticky-header>
    </template>
    <div>
      <div class="grid module-large">
        <div class="col-6">
          <div class="spread">
            <h3>Team Name</h3>
            <form-input v-model="team.teamName" name="teamName" required rules="required" :tabindex="1" />
          </div>
        </div>
        <div class="col-6">
          <h3>Team Totals</h3>
          <ul class="list-unstyled team-totals">
            <li>
              <p class="label">Clients</p>
              <p>{{ countClientsForDepartmentManagers() }}</p>
            </li>
            <li>
              <p class="label">Locations</p>
              <p>{{ countLocationsForDepartmentManagers() }}</p>
            </li>
            <li>
              <p class="label">Partners</p>
              <p>{{ countPartnersForDepartmentManagers() }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="grid module-xlarge">
        <div class="col-6">
          <people-picker
            :tabindex="2"
            :add-button-tab-index="3"
            person-label="Department Manager"
            add-person-label="Add Department Manager"
            placeholder="Type department manager name"
            auto-select
            :search-results="findDepartmentManagers()"
            @peopleChanged="selectedDepartmentManagers = $event.people"
          />
        </div>
        <div class="col-6">
          <people-picker
            :tabindex="4"
            :add-button-tab-index="5"
            person-label="Account Manager"
            add-person-label="Add Account Manager"
            placeholder="Type account manager name"
            :max-size="1"
            auto-select
            :search-results="findAccountManagers()"
            @peopleChanged="addAccountManager($event)"
          />
        </div>
      </div>
      <div class="grid">
        <div class="col-6">
          <h3>Unassigned Team Locations</h3>
        </div>
      </div>
      <div class="grid module-xlarge">
        <div class="col-6">
          <location-picker
            ref="unassignedLocations"
            :tabindex="6"
            assign-location-label="Assign Location to Team"
            delete-icon="icon-trash-2"
            auto-select
            location-column-header="Unassigned Locations"
            :search-results="availableUnassignedLocations"
            @locationsChanged="handleLocationsChanged"
          />
        </div>
      </div>
      <div class="grid">
        <div class="col-6">
          <people-picker
            ref="onboardingSpecialists"
            :tabindex="7"
            :add-button-tab-index="8"
            auto-select
            person-label="Onboarding Specialists"
            :search-results="getAvailableOnboardingSpecialists"
            placeholder="Type onboarding specialist name"
            add-person-label="Add Onboarding Specialists"
            @peopleChanged="handleSpecialistSubmission"
          >
            <template #results><span v-show="false"></span></template>
          </people-picker>
        </div>
      </div>
      <div class="grid module-xlarge">
        <div v-for="(specialist, index) in onboardingSpecialists" :key="computeSpecialistKey(index, specialist)" class="col-6">
          <location-picker
            :ref="`specialist-${index}`"
            :search-results="availableUnassignedLocations"
            :available-onboarding-specialists="getAvailableOnboardingSpecialists"
            auto-select
            assign-location-label="Assign Location to Onboarding Specialist"
            :specialist="specialist"
            @locationsChanged="handleSpecialistLocationsChanged"
            @specialistPopup="selectedSpecialist = $event"
            @employeeRemoved="handleEmployeeRemoved"
            @employeeAdded="handleEmployeeAdded"
          />
        </div>
      </div>
      <delete-specialist-modal :specialist="selectedSpecialist" @onDeleteSpecialist="handleDelete($event)" />
    </div>
  </add-edit-page>
</template>
<script>
import LocationPicker from "@/modules/admin/employees/teams/LocationPicker";
import DeleteSpecialistModal from "@/modules/admin/employees/teams/DeleteSpecialistModal";
import { mapActions, mapGetters } from "vuex";
import { AddEditPage, PeoplePicker, StickyHeader, SaveCancel, FormInput } from "@/components";

export default {
  name: "CreateTeamView",
  components: { DeleteSpecialistModal, LocationPicker, PeoplePicker, FormInput, SaveCancel, StickyHeader, AddEditPage },
  data: () => {
    return {
      team: {
        teamName: "",
        manager: {},
      },
      availableDepartmentManagers: [],
      availableAccountManagers: [],
      availableOnboardingSpecialists: [],
      onboardingSpecialists: [],
      selectedDepartmentManagers: [],
      unassignedLocations: [],
      assignedLocations: [],
      selectedSpecialist: null,
    };
  },
  computed: {
    ...mapGetters(["getAvailableOnboardingSpecialists"]),
  },
  methods: {
    ...mapActions(["updateAvailableOnboardingSpecialists", "addAvailableOnboardingSpecialist", "removeAvailableOnboardingSpecialist"]),
    addAccountManager(personChangedEvent) {
      this.team.manager = personChangedEvent.personAdded;
    },
    findDepartmentManagers() {
      return this.availableDepartmentManagers;
    },
    findAccountManagers() {
      return this.availableAccountManagers;
    },
    availableUnassignedLocations() {
      const unassignedLocations = [];
      this.selectedDepartmentManagers.forEach((manager) => {
        const locations = manager.clientsManaged ? manager.clientsManaged.flatMap((clientManager) => clientManager.client.locations).filter((location) => location.teamSpecialist === null) : [];
        unassignedLocations.push(...locations);
      });
      unassignedLocations.sort((first, second) => first.name.localeCompare(second.name));
      return unassignedLocations;
    },
    computeSpecialistKey(index, specialist) {
      return `${specialist.id}-${specialist.employee ? specialist.employee.id : ""}-${index}`;
    },
    countClientsForDepartmentManagers() {
      const locations = [...this.unassignedLocations, ...this.assignedLocations];
      const clients = locations.map((location) => location.clientID).filter((value, index, self) => self.indexOf(value) === index) || [];
      return clients.length;
    },
    countLocationsForDepartmentManagers() {
      const locations = [...this.unassignedLocations, ...this.assignedLocations];
      return locations.length;
    },
    countPartnersForDepartmentManagers() {
      let count = 0;
      const locations = [...this.unassignedLocations, ...this.assignedLocations];
      locations.forEach((location) => {
        count += location.partners_aggregate.aggregate.count;
      });
      return count;
    },
    handleSpecialistSubmission(personChangedEvent) {
      if (personChangedEvent.personAdded) {
        const employee = personChangedEvent.personAdded;
        const specialistExists = this.onboardingSpecialists.findIndex((specialist) => specialist.employee && specialist.employee.id === employee.id) >= 0;
        if (!specialistExists) {
          this.onboardingSpecialists.unshift({
            employee,
            team: this.team,
            locations: [],
          });
        }
        this.removeAvailableOnboardingSpecialist(employee);
      } else if (personChangedEvent.personRemoved) {
        this.addAvailableOnboardingSpecialist(personChangedEvent.personRemoved);
      }
      this.onboardingSpecialists.forEach((specialist, index) => (specialist.index = index));
    },
    handleLocationsChanged(locationChangedEvent) {
      locationChangedEvent.locations.forEach((location) => (location.team = this.team));
      this.unassignedLocations = locationChangedEvent.locations;
    },
    handleSpecialistLocationsChanged(locationChangedEvent) {
      if (locationChangedEvent.locationAdded) {
        this.assignedLocations.push(locationChangedEvent.locationAdded);
        this.$refs["unassignedLocations"].removeLocation(locationChangedEvent.locationAdded);
      } else if (locationChangedEvent.locationRemoved) {
        this.assignedLocations = this.assignedLocations.filter((location) => location.id !== locationChangedEvent.locationRemoved.id);
        this.$refs["unassignedLocations"].addLocation(locationChangedEvent.locationRemoved);
      }
    },
    handleEmployeeRemoved(employee) {
      if (employee) {
        this.$refs["onboardingSpecialists"].removePerson(employee);
      }
    },
    handleEmployeeAdded(employee) {
      if (employee) {
        this.$refs["onboardingSpecialists"].addPerson(employee);
      }
    },
    handleDelete(specialist) {
      this.onboardingSpecialists = this.onboardingSpecialists.filter((existingSpecialist) => {
        if (
          specialist.index === existingSpecialist.index ||
          (specialist.id && existingSpecialist.id === specialist.id) ||
          (specialist.employee && existingSpecialist.employee && existingSpecialist.employee.id === specialist.employee.id)
        ) {
          if (specialist.locations) {
            specialist.locations.forEach((location) => {
              this.$refs[`specialist-${specialist.index}`][0].removeLocation(location);
            });
          }
          if (specialist.employee) {
            this.$refs["onboardingSpecialists"].removePerson(specialist.employee);
          }
          return false;
        }
        return true;
      });
    },
    saveTeam() {},
  },
};
</script>
