<template>
  <div v-if="this.requested === 0" class="small-progress-bar not-recruiting">
    <div class="icon"><i class="icon-slash"></i></div>
    <div>no request</div>
  </div>
  <div v-else :class="'small-progress-bar ' + iconColorClass(progress)">
    <div class="icon">
      <i :class="iconClass(progress)"></i>
    </div>
    <div class="progress-bar">
      <div class="inner" :style="{ width: progress + '%' }"></div>
    </div>
    <div class="percent">{{ progress }}%</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DateTime from "luxon/src/datetime";
export default {
  name: "LocationProgressCell",
  props: {
    approved: {
      type: Number,
    },
    requested: {
      type: Number,
    },
    location: {
      type: Object,
      default: () => {
        return {
          approvedPartners: { aggregate: { count: 0 } },
          headCountRequests: [],
          recruitingGoal: 0,
          isRecruiting: false,
        };
      },
      required: true,
    },
    weekFilter: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["getStartDate", "getEndDate"]),
    progress: function () {
      if(this.approved && this.requested){
        let percent = !this.requested || this.requested === 0 ? 100 : Math.floor(this.approved / this.requested / Math.pow(10, -2));
        return percent > 100 ? 100 : percent;
      }
      return this.getHeadCountFulfilledPercent(this.approvedCount(this.location), this.requestedCount(this.location));
    },
    finalLocation: function () {
      return this.$props.location;
    },
  },
  methods: {
    approvedCount(location) {
      if (this.$props.weekFilter) {
        return location.stageCounts
          .filter(
            (stageCount) =>
              stageCount.stage === "APPROVAL" &&
              stageCount.weekly >= DateTime.fromISO(this.getStartDate).toFormat("yyyy-MM-dd") &&
              stageCount.weekly <= DateTime.fromISO(this.getEndDate).toFormat("yyyy-MM-dd")
          )
          .reduce((start, next) => start + next.count, 0);
      } else {
        return this.stageCount(location, "APPROVAL");
      }
    },
    stageCount(location, stage) {
      if (location.stageCounts) {
        return location.stageCounts.filter((stageCount) => stageCount.stage === stage).reduce((start, next) => start + next.count, 0);
      }
      return 0;
    },
    requestedCount(location) {
      if (this.$props.weekFilter) {
        return location.headCountRequests
          .filter((headcount) => headcount.startDate >= DateTime.fromISO(this.getStartDate).toFormat("yyyy-MM-dd") && headcount.endDate <= DateTime.fromISO(this.getEndDate).toFormat("yyyy-MM-dd"))
          .reduce((start, next) => start + next.count, 0);
      } else {
        return location.headCountRequests.reduce((start, next) => start + next.count, 0);
      }
    },
    iconClass: (num) => {
      if (num <= 50) {
        return "icon-alert-triangle";
      }
      if (num > 50 && num <= 99) {
        return "icon-circle-dashed";
      }
      if (num <= 50) {
        return "icon-circle";
      }
      if (num >= 100) {
        return "icon-check";
      }
      return "icon-slash";
    },
    iconColorClass: (num) => {
      if (num <= 50) {
        return "warning";
      }
      if (num > 50 && num <= 99) {
        return "on-track";
      }
      if (num <= 50) {
        return "not-requested";
      }
      if (num >= 100) {
        return "fulfilled";
      }
      return "not-recruiting";
    },
    getHeadCountFulfilledPercent: function (fulfilled, total) {
      const percent = !total || total === 0 ? 100 : Math.floor(fulfilled / total / Math.pow(10, -2));
      return percent > 100 ? 100 : percent;
    },
  },
};
</script>
