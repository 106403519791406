<template>
    <div id="tax-file-viewer" class="modal" aria-hidden="true">
        <div class="modal-window_overlay" tabindex="-1" @click="closeModal">
            <div class="modal-window_container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby"
                @click.stop>
                <header class="modal-header">
                    <div class="close-modal close-modal-header" aria-label="Close modal">
                    </div>
                </header>
                <div class="modal-content">
                    <div class="closeBut">
                        <span :class="['xbutton']" @click="closeModal">
                            <span class="icon-x"></span>
                        </span>
                    </div>
                    <slot>
                        <div> </div>
                        <div class='pdf-container'>
                            <span v-if="isLoading" class="loading">
                                <Loader />
                            </span>
                            <div v-else>
                                <div v-if="isError" class="text-center">
                                    Error while fetching the file. Please Retry!.
                                </div>
                                <div v-else>
                                    <div v-if="isPDF && pdfUrl">
                                        <vue-pdf-embed :source="pdfUrl"/>
                                    </div>
                                    <div v-else>
                                        File Not Found
                                    </div>
                                </div>
                            </div>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/filters";
import { convertBufferToContent } from "@/util/BufferContentConverter";
import { Loader } from "@/components";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import { isPDFFile } from "../../util/validator";

export default {
    name: "TaxFilePreview",
    components: {
        Loader,
        VuePdfEmbed
    },
    props: {
        fileId: {
            type: String,
            required: true,
            default: () => { },
        },
    },
    data: function () {
        return {
            isPDF: false,
            isLoading: true,
            pdfUrl: null,
            isError: false
        };
    },
    watch: {
        fileId: {
            immediate: true,
            handler: function (newVal) {
                this.pdfUrl = null;
                newVal && this.getFileData()
            }
        },
    },
    methods: {
        async getFileData() {
            this.isLoading = true;
            try {
                let data = await restApi.post('/employee/getUploadedFile', encodeWithParam({ fileID: this.$props.fileId }));
                if (!data.data.success) {
                    this.isError = true;
                    return;
                };
                this.isPDF = isPDFFile(data?.data?.fileName);
                this.pdfUrl = convertBufferToContent(data.data.bufferInHex, data.data.contentType);

                this.isLoading = false;

            } catch (err) {
                this.isLoading = false;
                this.isError = true;
            }
        },
        resetData() {
            this.isError = false;
            this.isLoading = true;
            this.pdfUrl = null;
        },
        closeModal() {
            this.resetData();
            this.$emit("click");
        },
    },
};
</script>
  
<style scoped>
.modal-window_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0000007a;
    --webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    z-index: 40;
    height: 100vh;
    width: 100vw;
}

.modal-window_container {
    max-width: 870px;
    max-height: calc(100vh - 300px);
    width: 870px;
    overflow: auto;
    background-color: #fff;
    padding: 0 16px 16px 16px;
    border-radius: 0px 0px 0px 0px;
    box-sizing: border-box;
    position: relative;
    font-family: 'Open Sans';
}

.closeBut {
    position: relative;
}

.xbutton {
    position: absolute;
    right: 11px;
    top: 0px;
    cursor: pointer;
    z-index: 100;
}

.pdf-container {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
</style>