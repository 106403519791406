var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-modal',{attrs:{"id":"edit-zones"}},[_c('h4',[_vm._v("Edit Zones ")]),_c('data-table',{attrs:{"headers":_vm.headers,"entries":_vm.editedValues,"useIndex":true,"table-id":"editZonesTable"},scopedSlots:_vm._u([{key:"location",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"funnelStatus",fn:function(ref){
var item = ref.item;
return [_c('InputSwitch',{attrs:{"disabled":!_vm.getPermission('onOffLocations')},on:{"change":function($event){return _vm.changedStatus(item, 'status')}},model:{value:(item.funnelStatus),callback:function ($$v) {_vm.$set(item, "funnelStatus", $$v)},expression:"item.funnelStatus"}})]}},{key:"threshold",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex"},[(_vm.getPermission('addThreshold') || _vm.getPermission('editThreshold'))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.threshold),expression:"item.threshold"}],attrs:{"type":"number","min":"0"},domProps:{"value":(item.threshold)},on:{"change":function($event){return _vm.changedStatus(item, 'threshold')},"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "threshold", $event.target.value)}}}):_vm._e()])]}},{key:"HCR",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex"},[(_vm.getPermission('addHCR') || _vm.getPermission('editHCR'))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.HCR),expression:"item.HCR"}],attrs:{"type":"number","min":"0"},domProps:{"value":(item.HCR)},on:{"change":function($event){return _vm.changedStatus(item, 'HCR')},"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "HCR", $event.target.value)}}}):_vm._e()])]}}])}),_c('div',{staticClass:"button-group"},[_c('button',{staticClass:"button primary",on:{"click":_vm.updateData}},[_vm._v("Save")]),_c('button',{staticClass:"button secondary",on:{"click":_vm.closeModal}},[_vm._v("Cancel")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }