<template>
  <div class="button-group" :class="{ 'no-margin': $props.noMargin }">
    <slot name="additional-buttons"></slot>
    <a v-if="$props.cancelFirst" class="button secondary" @click="cancel">
      {{ $props.cancelLabel }}
    </a>
    <button class="button alignSaveButton" :disabled="!saveEnabled" @click="save()">
      {{ $props.saveLabel }}
      <span v-if="$props.loader && saveLoader" class="loading"><Loader /></span>
    </button>
    <button v-if="!$props.cancelFirst" class="button secondary" :disabled="!cancelEnabled" @click="cancel">
      {{ $props.cancelLabel }}
    </button>
  </div>
</template>
<script>
import Loader from "../navigation/Loader.vue";
export default {
  name: "SaveCancel",
  components: { Loader },
  props: {
    saveLabel: {
      type: String,
      required: false,
      default: "Save",
    },
    cancelLabel: {
      type: String,
      required: false,
      default: "Cancel",
    },
    cancelRoute: {
      type: String,
      required: false,
      default: "",
    },
    cancelFirst: {
      type: Boolean,
      required: false,
      default: false,
    },
    saveEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    cancelEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    noMargin: {
      type: Boolean,
      required: false,
      default: false,
    },
    emitCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    loader: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      saveLoader: false,
    };
  },
  methods: {
    save() {
      this.$emit("onSave");
      if (this.$props.loader) {
        this.$props.saveEnabled = false;
        this.saveLoader = true;
      }
    },
    cancel() {
      if (this.$props.cancelRoute) {
        this.$router.push(this.$props.cancelRoute);
      } else if (this.$props.emitCancel) {
        this.$emit("onCancel");
      } else {
        this.$router.back();
      }
    },
  },
};
</script>
<style scoped>
.alignSaveButton{
  height: 42px;
}
</style>

