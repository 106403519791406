class OnboardingStatus {
  static STARTED = "STARTED";
  static UNFINISHED = "UNFINISHED";
  static WITHDRAWN = "WITHDRAWN";
  static INVALID="INVALID";
  static DID_NOT_QUALIFY = "DID_NOT_QUALIFY";
  static COMPLETE = "COMPLETE";
  static NEEDED = "NEEDED";
  static LEFT_VOICEMAIL_1 = "LEFT_VOICEMAIL_1";
  static LEFT_VOICEMAIL_2 = "LEFT_VOICEMAIL_2";
  static DID_NOT_PASS = "DID_NOT_PASS";
  static PASSED = "PASSED";
  static APPOINTMENT_NEEDED = "APPOINTMENT_NEEDED";
  static SCHEDULED = "SCHEDULED";
  static NO_RESULT = "NO_RESULT";
  static INCOMPLETE = "INCOMPLETE";
  static SUBMITTED = "SUBMITTED";
  static CONSIDER = "CONSIDER";
  static SUSPENDED = "SUSPENDED";
  static DENIED = "DENIED";
  static ON_HOLD = "ON_HOLD";
  static APPROVED = "APPROVED";
  static APPROVED_MANUAL= "Approved Manual";
  static MANUAL = "MANUAL";
  static AUTO = "AUTO";
  static MVR_CONSIDER = "MVR Consider";
  static MVR_SUBMITTED = "MVR Submitted";
  static MVR_CLEAR = "MVR Clear";
  static CBC_CONSIDER = "CBC Consider";
  static PACKAGE_SUBMITTED = "CBC Submitted";
  static COMPLETE_MANUAL = "COMPLETE-MANUAL";
  static CBC_PROMOTED = "CBC-PROMOTED";
  static MVR_SUBMITTED_NEEDED = "MVR Submitted - Needed";
  static CBC_SUBMITTED_NEEDED = "CBC Submitted - Needed";
  static MVR_DENIED = "MVR_DENIED";
  static CBC_DENIED = "CBC_DENIED";
  static INSURANCE_NEEDED = "Insurance Needed";
  static INSURANCE_COMPLETE = "Insurance Complete";
  static INSURANCE_INCOMPLETE = "Insurance Incomplete";
  static CHKR_ONBOARD_ERROR = "CHKR_ONBOARD_ERROR";
  static CHKR_REPORT_ERROR_MVR ="CHKR_REPORT_ERROR_MVR";
  static CHKR_REPORT_ERROR_CBC ="CHKR_REPORT_ERROR_CBC";
  static ONHOLD ="ONHOLD";

  static STATUS_MAP = {
    [OnboardingStatus.APPOINTMENT_NEEDED]: { display: "Appointment Needed", statusDetail: false },
    [OnboardingStatus.APPROVED]: { display: "Approved", statusDetail: false },
    [OnboardingStatus.APPROVED_MANUAL]: { display: "Approved Manual", statusDetail: false },
    [OnboardingStatus.AUTO]: { display: "Auto", statusDetail: false },
    [OnboardingStatus.COMPLETE]: { display: "Complete", statusDetail: true },
    [OnboardingStatus.CONSIDER]: { display: "Consider", statusDetail: false },
    [OnboardingStatus.DENIED]: { display: "Denied", statusDetail: false },
    [OnboardingStatus.MVR_DENIED]: {display: "MVR Denied",statusDetail: false},
    [OnboardingStatus.CBC_DENIED]: {display: "CBC Denied", statusDetail: false},
    [OnboardingStatus.DID_NOT_PASS]: { display: "Did Not Pass", statusDetail: true },
    [OnboardingStatus.DID_NOT_QUALIFY]: { display: "Did Not Qualify", statusDetail: false },
    [OnboardingStatus.INCOMPLETE]: { display: "Incomplete", statusDetail: false },
    [OnboardingStatus.LEFT_VOICEMAIL_1]: { display: "Left Voice Mail 1", statusDetail: false },
    [OnboardingStatus.LEFT_VOICEMAIL_2]: { display: "Left Voice Mail 2", statusDetail: false },
    [OnboardingStatus.MANUAL]: { display: "Manual", statusDetail: false },
    [OnboardingStatus.NEEDED]: { display: "Needed", statusDetail: false },
    [OnboardingStatus.NO_RESULT]: { display: "No Result", statusDetail: false },
    [OnboardingStatus.ON_HOLD]: { display: "On Hold", statusDetail: false },
    [OnboardingStatus.PASSED]: { display: "Passed", statusDetail: true },
    [OnboardingStatus.SCHEDULED]: { display: "Scheduled", statusDetail: false },
    [OnboardingStatus.STARTED]: { display: "Started", statusDetail: false },
    [OnboardingStatus.SUBMITTED]: { display: "Submitted", statusDetail: false },
    [OnboardingStatus.SUSPENDED]: { display: "Suspended", statusDetail: false },
    [OnboardingStatus.WITHDRAWN]: { display: "Withdrawn", statusDetail: false },
    [OnboardingStatus.INVALID]: { display: "Invalid", statusDetail: false },
    [OnboardingStatus.UNFINISHED]: { display: "Unfinshed", statusDetail: false },
    [OnboardingStatus.MVR_CONSIDER]: { display: "MVR Consider", statusDetail: false },
    [OnboardingStatus.MVR_SUBMITTED]: { display: "MVR Submitted", statusDetail: false },
    [OnboardingStatus.MVR_CLEAR]: { display: "MVR Eligible", statusDetail: false },
    [OnboardingStatus.CHC_CONSIDER]: { display: "CBC Consider", statusDetail: false },
    [OnboardingStatus.PACKAGE_SUBMITTED]: { display: "CBC Submitted", statusDetail: false },
    [OnboardingStatus.COMPLETE_MANUAL]: { display: "CBC Submitted", statusDetail: false },
    [OnboardingStatus.CBC_PROMOTED]: { display: "CBC Promoted", statusDetail: false },
    [OnboardingStatus.MVR_SUBMITTED_NEEDED] : {display: "MVR Submitted Needed", statusDetail: false},
    [OnboardingStatus.INSURANCE_NEEDED] : {display: "Insurance Needed", statusDetail: false},
    [OnboardingStatus.INSURANCE_INCOMPLETE] : {display: "Insurance Incomplete", statusDetail: false},
    [OnboardingStatus.INSURANCE_COMPLETE] : {display: "Insurance Complete", statusDetail: false},
    [OnboardingStatus.CBC_SUBMITTED_NEEDED] : {display: "CBC Submitted - Needed",statusDetail: false},
    [OnboardingStatus.CHKR_ONBOARD_ERROR] : {display: "Checkr Onboard Error",statusDetail: false},
    [OnboardingStatus.CHKR_REPORT_ERROR_MVR] : {display: "Checkr MVR Error",statusDetail: false},
    [OnboardingStatus.CHKR_REPORT_ERROR_CBC] : {display: "Checkr CBC Error",statusDetail: false},
    [OnboardingStatus.ONHOLD] : {display: "On hold",statusDetail: false},

  };


  static getDisplay = (status) => {
    return status ? OnboardingStatus.STATUS_MAP[status].display : "";
  };

  static isSuccess = (status) => {
    return (
      status === OnboardingStatus.PASSED ||
      status === OnboardingStatus.COMPLETE ||
      status === OnboardingStatus.STARTED ||
      status === OnboardingStatus.APPROVED ||
      status === OnboardingStatus.MANUAL ||
      status === OnboardingStatus.AUTO ||
      status === OnboardingStatus.COMPLETE_MANUAL ||
      status === OnboardingStatus.CBC_PROMOTED ||
      status === OnboardingStatus.INSURANCE_COMPLETE
    );
  };

  static isWithdrawn = (status) => {
    return status === OnboardingStatus.WITHDRAWN || OnboardingStatus.checkDidNotPassSubStatus(status) === OnboardingStatus.WITHDRAWN || status === OnboardingStatus.DENIED || status === OnboardingStatus.INVALID ||status === OnboardingStatus.MVR_DENIED || status === OnboardingStatus.CBC_DENIED;
  };

  static isWarning = (status) => {
    return (
      status === OnboardingStatus.DID_NOT_QUALIFY ||
      status === OnboardingStatus.NEEDED ||
      status === OnboardingStatus.APPOINTMENT_NEEDED ||
      status === OnboardingStatus.CONSIDER ||
      status === OnboardingStatus.SUSPENDED ||
      status === OnboardingStatus.ON_HOLD ||
      status === OnboardingStatus.MVR_CONSIDER ||
      status === OnboardingStatus.CBC_CONSIDER ||
      OnboardingStatus.checkDidNotPassSubStatus(status) === OnboardingStatus.DID_NOT_PASS ||
      status === OnboardingStatus.MVR_SUBMITTED ||
      status === OnboardingStatus.MVR_SUBMITTED_NEEDED ||
      status === OnboardingStatus.INSURANCE_INCOMPLETE ||
      status === OnboardingStatus.PACKAGE_SUBMITTED ||
      status === OnboardingStatus.CBC_SUBMITTED_NEEDED ||
      status === OnboardingStatus.CHKR_ONBOARD_ERROR  ||
      status === OnboardingStatus.CHKR_REPORT_ERROR_MVR ||
      status === OnboardingStatus.CHKR_REPORT_ERROR_CBC
      // TODO need to get INTERVIEW | PASSED - ON HOLD (sub status)
    );
  };
  static isWarningCandidateStatus = (status) => {
    return (
      status === OnboardingStatus.DID_NOT_QUALIFY ||
      //status === OnboardingStatus.NEEDED ||
      status === OnboardingStatus.APPOINTMENT_NEEDED ||
      status === OnboardingStatus.CONSIDER ||
      status === OnboardingStatus.SUSPENDED ||
      status === OnboardingStatus.ON_HOLD ||
      status === OnboardingStatus.MVR_CONSIDER ||
      status === OnboardingStatus.CBC_CONSIDER ||
      OnboardingStatus.checkDidNotPassSubStatus(status) === OnboardingStatus.DID_NOT_PASS ||
      // status === OnboardingStatus.MVR_SUBMITTED ||
      status === OnboardingStatus.NEEDED
      // TODO need to get INTERVIEW | PASSED - ON HOLD (sub status)
    );
  };


  static isCurrent = (status) => {
    return (
      status === OnboardingStatus.UNFINISHED ||
      status === OnboardingStatus.LEFT_VOICEMAIL_1 ||
      status === OnboardingStatus.LEFT_VOICEMAIL_2 ||
      status === OnboardingStatus.SCHEDULED ||
      status === OnboardingStatus.NO_RESULT ||
      status === OnboardingStatus.SUBMITTED ||
      status === OnboardingStatus.INCOMPLETE ||
      status === OnboardingStatus.INSURANCE_NEEDED
      // TODO need to get VETTING | PASSED - ON HOLD (sub status)
    );
  };

  static checkDidNotPassSubStatus = (status) => {
    if (status === OnboardingStatus.DID_NOT_PASS) {
      // TODO we need to check the sub status from somewhere to determine if it is DID_NOT_PASS (parent status) or WITHDRAWN
    }
    return null;
  };
}

export default OnboardingStatus;