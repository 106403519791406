<template>
  <header class="container site-masthead" :style="{ borderWidth: 0 }">
    <div class="content">
      <router-link class="main-logo" :to="{ name: 'home' }"><img src="@/assets/images/logos/Spark.png" alt="DDI Logo" /></router-link>
      <nav class="main-nav">
        <ul class="list-unstyled">
          <!-- TODO Need to protect these routes from showing based on role. -->
          <authorized-link :to="{ name: 'accounting'}" wrap v-if="getPermission('viewAccounting')">Accounting</authorized-link>
          <authorized-link :to="{ name: 'client', params: { id: clientID }}" wrap>Zones</authorized-link>
          <authorized-link :to="{ name: 'employees' }" wrap>Employees</authorized-link>
          <authorized-link :to="{ name: 'ddi' }" wrap>System</authorized-link>
          <authorized-link :to="{ name: 'reports' }" wrap v-if="getPermission('viewReports')">Reports</authorized-link>
        </ul>
      </nav>
      <div class="user-control" :class="{ 'show-nav': show }">
        <p v-click-outside="hide" @click="show = !show">{{ getFullName }}<i class="icon-user"></i></p>
        <div v-if="environment !== 'production'" :style="{ color: 'var(--error-40)', padding: '10px' }">Environment: {{ environment }}</div>
        <nav class="user-nav" @click="show = false">
          <ul class="list-unstyled">
            <li v-show="false"><router-link :to="{ name: 'settings' }">Settings</router-link></li>
            <li v-show="false"><router-link :to="{ name: 'profile' }">Profile</router-link></li>
            <li class="sign-out">
              <a @click="logout">Sign Out</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";
import AuthorizedLink from "@/components/navigation/AuthorizedLink";
import { authenticationService } from "@/services";
import { PINGFED_URL } from "@/modules/login/constants";

export default {
  name: "Header",
  components: { AuthorizedLink },
  directives: { ClickOutside },
  props: {
    name: {
      type: String,
      default: "John Doe",
    },
  },
  data: () => {
    return {
      drawer: false,
      show: false,
    };
  },
  computed: {
    ...mapGetters(["getFullName", "getPermission"]),
    environment() {
      return process.env.VUE_APP_ENV || config.VUE_APP_ENV || "Unknown";
    },
    clientID() {
      return process.env.CLIENT_ID || "b8b95baf-e76b-49b6-b3a8-2ca0706765b8";
    },
  },
  methods: {
    logout() {
      authenticationService.logout().then(() => {
        this.$store.dispatch("clearOnboarding");
        this.$store.dispatch("userLogout");
        //this.$router.push({ name: "login" });
        window.location.href = PINGFED_URL;
      });
    },
    hide() {
      this.show = false;
    },
  },
};
</script>
