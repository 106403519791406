<template>
  <flyout name="comms-flyout" title="Communications" full-screen>
    <template #header>
      <sticky-header title="Resend Application Link" classes = "margin-zero">
        <div class="button-group">
          <button class="button secondary" @click="done">Done</button>
        </div>
      </sticky-header>
    </template>
    <main class="off-white">
      <div class="container">
        <div class="content">
          <div class="grid module-large">
            <div class="col-6">
              <h4>{{ $props.candidate.name }}</h4>
              <p>{{ $props.candidate.email }}</p>
              <p>{{ $props.candidate.phone | formatPhone }}</p>
            </div>
            <div class="col-6">
              <h4>{{ $props.candidate.location }}</h4>
              <p>Current Step: {{ $props.currentStep.displayName }}</p>
              <p>Current Stage: {{ $props.currentStep.status | titleCase }}</p>
            </div>
          </div>
          <tabs :tabs="commsTabs" :use-history="false">
            <template #email>
              <validation-observer ref="emailForm" v-slot="validation" slim>
                <div class="grid email">
                  <div class="col-10_md-12">
                    <EditorToolbar client partner ddi />
                  </div>
                  <div class="col-4_md-6_sm-12">
                    <div class="field">
                      <form-input v-model="subject" name="subject" label="Subject" required placeholder="Email Subject" />
                    </div>
                  </div>
                  <div class="col-6_md-6_sm-12"></div>
                  <div class="col-6_md-12">
                    <Editor ref="editor" v-model="emailText" @emptyStatus="isQuillEmpty" />
                  </div>
                  <div class="col-6_md-12">
                    <textarea v-model="formattedEmail" readonly class="template-preview" placeholder="Email preview"></textarea>
                  </div>
                </div>
                <div class="button-group close-top">
                  <button :disabled="validation.invalid || quillEmpty" class="button" @click="sendEmail">Send Request</button>
                  <a class="button secondary" @click="done">Cancel</a>
                </div>
              </validation-observer>
            </template>
            <template #sms>
              <validation-observer ref="smsForm" v-slot="validation" slim>
                <div class="grid sms">
                  <div class="col-10_md-12">
                    <EditorToolbar client partner ddi small />
                  </div>
                  <div class="col-6_md-12">
                    <Editor ref="smsEditor" v-model="smsText" small @emptyStatus="isQuillEmpty" />
                  </div>
                  <div class="col-6_md-12">
                    <textarea v-model="formattedSms" readonly class="template-preview" placeholder="SMS preview"></textarea>
                  </div>
                </div>
                <div class="button-group close-top">
                  <button :disabled="validation.invalid || quillEmpty" class="button" @click="sendText">Send Request</button>
                  <a class="button secondary" @click="done">Cancel</a>
                </div>
              </validation-observer>
            </template>
          </tabs>
        </div>
      </div>
    </main>
  </flyout>
</template>
<script>
import { Flyout } from "@/components";
import Editor from "@/components/editor/Editor";
import EditorToolbar from "@/components/editor/EditorToolbar";
import { ValidationObserver } from "vee-validate";
import StickyHeader from "@/components/layout/StickyHeader";
import Tabs from "@/components/tabs/Tabs";
import FormInput from "@/components/forms/fields/FormInput";
import handlebars from "handlebars";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import { restApi } from "@/http/http.config";
import {encodeWithParam,encodeWithParamutf} from "@/util/Base64Encoding";

export default {
  components: { FormInput, Flyout, StickyHeader, Editor, EditorToolbar, Tabs, ValidationObserver },
  props: {
    candidate: {
      type: Object,
      required: false,
      default: () => {},
    },
    bulkObj: {
      type: Array,
      required: false,
      default: () => [],
    },
    currentStep: {
      type: Object,
      required: false,
      default: () => {},
    },
    onClose: {
      type: Function,
      required: false,
      default: () => {},
    },
    messageData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data: () => ({
    emailText: {},
    smsText: {},
    subject: "",
    partners: [],
    quillEmpty: true,
    from: "admin@ddiwork.com",
  }),
  mounted() {
    this.subject = this.$props.messageData.subject;
    this.emailText = { ops: [{ insert: this.$props.messageData.body }] };
    this.smsText = { ops: [{ insert: this.$props.messageData.body }] };
  },
  computed: {
    ...mapGetters(["getUserID", "getUsername"]),
    charsUsed() {
      return this.smsText.length;
    },
    commsTabs() {
      return [
        { key: "email", label: "Email Message" },
        { key: "sms", label: "Text Message" },
      ];
    },
    formattedEmail() {
      if (this.emailText && this.emailText.ops && this.emailText.ops.length > 0) {
        return handlebars.compile(this.emailText.ops[0].insert)({
          driver: {
            location: {
              application: {
                link: `https://my.ddiwork.com`,
              },
            },
            name: {
              first: this.candidate?.name ? this.candidate.name : "",
              last: this.candidate?.name ? this.candidate.name : "",
            },
          },
        });
      }
      return "";
    },
    formattedSms() {
      if (this.smsText && this.smsText.ops && this.smsText.ops.length > 0) {
        return handlebars.compile(this.smsText.ops[0].insert)({
          driver: {
            location: {
              application: {
                link: `https://my.ddiwork.com`,
              },
            },
            name: {
              first: this.candidate?.name ? this.candidate.name : "",
              last: this.candidate?.name ? this.candidate.name : "",
            },
          },
        });
      }
      return "";
    },
    createHTML() {
      const cfg = {};
      if (this.emailText.ops === undefined) {
        return "";
      } else {
        const converter = new QuillDeltaToHtmlConverter([{ insert: this.formattedEmail }], cfg);
        return converter.convert();
      }
    },
  },
  methods: {
    sendText() {
      const textData = encodeWithParamutf({
        to: this.candidate.phone,
        body: this.formattedSms,
        partnerID: this.candidate.partnerID,
        userID: this.getUserID,
        sendDate: DateTime.utc().toFormat("yyyy-MM-dd"),
      });
      return restApi.post('/onboard/insertCommunicationText',textData)
        .then(({ data }) => {
          const id = data.result[0].id;
          const comms = encodeWithParam({ candidateProgressID: this.currentStep.id, communicationType: "SMS", communicationsID: id, date: new Date() })
          return restApi.post('/onboard/candidateProgressComms',comms)
        })
        .then(() => {
          this.$props.onClose();
        });
    },
    sendEmail() {
      const mailData = encodeWithParamutf({
        userID: this.getUserID,
        from: this.from,
        html: this.createHTML,
        partnerID: this.candidate.partnerID,
        subject: this.subject,
        to: this.candidate.email,
        sentBy:true
      });
      return restApi.post('/onboard/sendEmail',mailData)
        .then(({ data }) => {
          const id = data.result[0].id;
          const comms = encodeWithParam({ candidateProgressID: this.currentStep.id, communicationType: "EMAIL", communicationsID: id, date: new Date() })
          return restApi.post('/onboard/candidateProgressComms',comms)
        })
        .then(() => {
          this.$props.onClose();
        })
        .catch((err) => this.$log.error(err));
    },
    isQuillEmpty(status) {
      this.quillEmpty = status;
    },
    done() {
      this.subject = "";
      this.emailText = {};
      this.smsText = {};
      this.$nextTick(() => {
        this.$refs.editor.clearText();
        this.$refs.emailForm.reset();
        this.$refs.smsEditor.clearText();
        this.$refs.smsForm.reset();
      });
      this.$props.onClose();
    },
  },
};
</script>
