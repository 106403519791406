<template>
  <div>
    <div class="grid">
      <div class="col-8">
        <h2>Individuals</h2>
      </div>
      <div class="col-4 flex-right">
        <router-link v-if="this.getPermission('addEmployee')" class="button secondary" :to="{ name: 'createEmployee' }"> Add Employee</router-link>
      </div>
    </div>
    <div class="grid">
      <div class="col-12">
        <p class="label">Filters</p>
        <base-filter name="Roles" :options="roleFilterOptions" @onFilter="filtersAppliedRoles((filteredRoles = $event))" />
        <base-filter name="Status" :options="statusFilterOptions" @onFilter="filtersAppliedStatuses((filteredStatuses = $event))" />
      </div>
    </div>
    <div class="col-6 search-cont">
      <div class="search-container">
        <input v-model="searchTerm" class="deposit-search" type="search" placeholder="Search Employee Name" />
        <button type="submit"><span v-if="searchIcon()" class="icon-x deposit-icon" @click="resetSearch"></span> <span v-else class="icon-search deposit-icon"></span></button>
      </div>
      <button class="button secondary" @click="searchEmployees(true)">Search</button>
    </div>
    <div v-if="!searchTerm.length > 0" class="module showing-results">
      <p>Showing {{ employees.length }} of {{ totalCount }} Employees</p>
    </div>

    <div v-if="searchTerm.length > 0" class="module showing-results">
      <p> {{ totalCountForPagination }} of {{ totalCount }} employess found</p>
    </div>
    <data-table
      :headers="headers"
      :entries="filteredEmployees"
      :pagination-total-count="totalCountForPagination"
      :pagination-per-page="perPage"
      :pagination-current-page="currentPage"
      @onPaginate="onPaginate($event)"
    >
      <template #employee="{ item }">
        <a @click.self="openDialogFor(item)" @click.prevent> {{ item.firstName }} {{ item.lastName }} </a>
      </template>
      <template #status="{ item }">{{ item.status | titleCase }}</template>
    </data-table>
    <employee-flyout name="individual-info" :employee-id="selectedEmployee.id" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import EmployeeFlyout from "@/modules/admin/employees/EmployeeFlyout";
import * as Roles from "@/security/rolesHelper";
import TableHeader from "@/components/table/TableHeader";
import { DataTable, BaseFilter } from "@/components";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/Base64Encoding";

export default {
  name: "EmployeeTab",
  components: { DataTable, EmployeeFlyout, BaseFilter },
  data() {
    return {
      loading: true,
      employees: [],
      selectedEmployee: {},
      error: "",
      filteredRoles: [],
      filteredStatuses: [],
      roles: Roles.getDDIRoles(),
      statuses: ["ACTIVE", "INACTIVE"],
      totalCount: 0,
      totalCountForPagination: 0,
      currentPage: 0,
      offset: 0,
      perPage: 50,
      searchTerm: "",
      searchedEmployee: [],
      searchReset:false
    };
  },
  computed: {
    filteredEmployees() {
      return this.employees.filter(() => this.isRoleFiltered());
    },
    ...mapGetters(["isFlyoutShowing", "getPermission"]),
    headers() {
      return [
        new TableHeader({ label: "Employee", cellContents: "employee", name: "employee", headerClass: "cell20", sortable: true, sorted: true, sortFunction: this.sortEmployees }),
        new TableHeader({ label: "Status", cellContents: "status", name: "status", headerClass: "cell5", sortable: true }),
        new TableHeader({ label: "Role", cellContents: this.employeeRoleDisplay, name: "role_name", headerClass: "cell15", sortable: true }),
        // new TableHeader({ label: "Team(s)", cellContents: "teams", name: "teams" }),
      ];
    },
    roleFilterOptions() {
      return Roles.getDDIRoleFilters();
    },
    statusFilterOptions() {
      return [
        { id: "active", name: "active", label: "Active", checked: false },
        { id: "inactive", name: "inactive", label: "Inactive", checked: false },
      ];
    },
  },
  mounted() {
    this.refetch('%');
  },


  // watch: {
  //   searchTerm() {
  //     if (this.searchTerm) {
  //       let regex = new RegExp(this.searchTerm, "i");
  //       this.searchedEmployee = this.employees.filter((item) => {
  //         return regex.test(`${item.firstName} ${item.lastName}`);
  //       });
  //       this.totalCount = this.searchedEmployee.length;
  //     }
  //   },
  // },
  methods: {
    ...mapActions(["showFlyout"]),
    searchIcon() {
      return this.searchTerm !== "";
    },
    refetch(searchTerm) {
      const param = {
        roles: this.roleFiltersEnabled() ? this.getFilteredRoles() : this.roles,
        statuses: this.statusFiltersEnabled() ? this.getFilteredStatus() : this.statuses,
        limit: this.perPage,
        offset: this.offset,
        searchTerm:searchTerm
      }
      restApi.post('employee/getEmployees', encodeWithParam(param))
        .then(data => {
          this.employees = data.data.result
          this.totalCount = data.data.totalCount
          this.totalCountForPagination = data.data.totalCount
        });
    },

    searchEmployees(resetPaginator) {
      if(resetPaginator) this.resetPaginator();
      const param = {
        searchTerm: this.searchTerm? `%${this.searchTerm}%`:'%',
        roles: this.roleFiltersEnabled() ? this.getFilteredRoles() : this.roles,
        statuses: this.statusFiltersEnabled() ? this.getFilteredStatus() : this.statuses,
        limit: this.perPage,
        offset: this.offset,
       
      }
      restApi.post('employee/getEmployees', encodeWithParam(param))
        .then(data => {
          this.employees = data.data.result
          if(this.searchReset) this.totalCount = data.data.totalCount;
          this.totalCountForPagination = data.data.totalCount;
          this.searchReset = false;
        });

    },
    resetSearch() {
      this.searchTerm = "";
      this.currentPage = 1;
      this.refetch("%");
    },
    isRoleFiltered() {
      return true;
    },
    openDialogFor(employee) {
      this.selectedEmployee = employee;
      this.showFlyout("individual-info");
    },
    employeeRoleDisplay(employee) {
      return Roles.getDisplayValue(employee.user ? employee.user.role : employee.role_name);
    },
    sortEmployees() {
      return (employee1, employee2) => {
        const employee1Name = `${employee1.firstName} ${employee1.lastName}`;
        const employee2Name = `${employee2.firstName} ${employee2.lastName}`;
        return employee1Name.localeCompare(employee2Name);
      };
    },
    onPaginate(event) {
      this.currentPage = Number(event.pageNumber);
      this.offset = event.offset; 
      this.searchEmployees(false);
    },
    filtersAppliedRoles() {
      this.searchReset = true;
      this.resetPaginator();
    },
    filtersAppliedStatuses() {
      this.searchReset = true;
      this.resetPaginator();
    },
    resetPaginator() {
      this.currentPage = 1;
      this.offset = 0;
    },
    roleFiltersEnabled() {
      return this.filteredRoles.length === 0 ? false : true;
    },
    statusFiltersEnabled() {
      return this.filteredStatuses.length === 0 ? false : true;
    },
    getFilteredRoles() {
      let input = this.filteredRoles.map((item) => {
        return item.replaceAll(" ", "_").toUpperCase();
      });
      let transform = input.map((item) => {
        return item.replaceAll("READ_ONLY", "READONLY");
      });
      return transform;
    },
    getFilteredStatus() {
      let data = this.filteredStatuses.map((item) => {
        return item.toUpperCase();
      });
      return data;
    },
  },

  // apollo: {
  //   employees: {
  //     fetchPolicy: "no-cache",
  //     query: GET_EMPLOYEES_PAGNATION,
  //     variables() {
  //       return {
  //         fullName: `%${this.searchTerm}%`,
  //         role: this.roleFiltersEnabled() ? this.getFilteredRoles() : this.roles,
  //         status: this.statusFiltersEnabled() ? this.getFilteredStatus() : this.statuses,
  //         limit: this.perPage,
  //         offset: this.offset,
  //       };
  //     },
  //     result({ data }) {
  //       this.employees = data.employees;
  //       this.totalCount = data.total.aggregate.count;
  //     },
  //   },
  // },
};
</script>

<style scoped>
  .search-cont{
    display: flex;
    padding-left: 0px;
  }
  .search-cont .secondary{
    margin-bottom: 8px;
  }
</style>