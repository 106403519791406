<template>
  <div id="advance-search" class="modal" aria-hidden="true">
    <div @click="closeModal" class="modal-window_overlay" tabindex="-1">
      <div  @click.stop class="modal-window_container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby">
        <header class="modal-header">
          <div class="close-modal close-modal-header" aria-label="Close modal">
          </div>
        </header>
        <div class="modal-content">
          <div class="closeBut">
            <span @click="closeModal" :class="['xbutton']">
              <span class="icon-x"></span>
            </span>
          </div>
          <slot>
            <p class="c87"><span class="c43"><b>Advanced Search</b></span></p>
            <div>
              <span class="textColor"><label class="label black">Search by column(s)</label><br></span>
            </div>
 
            <div class="filter-options">
              <slot>
                <div class="filter-options">
                  
                  <div class="filter-option"><!---->
                    <input id="adv1" @click="getResult"
                      name="filter-dfn" type="checkbox" v-model="cch"
                      ref="driverName"
                      value="Driver's Full Name"><label
                      for="adv1">Driver's Full Name</label>
                  </div>
 
                  <div class="filter-option"><!----><input id="adv2" @click="getResult"
                      name="filter-email" type="checkbox" v-model="cch" ref="email" value="Email"><label
                      for="adv2">Email</label>
                  </div>
 
                  <!-- <div class="filter-option"><input id="adv3" @click="getResult"
                      name="filter-city" type="checkbox" v-model="cch" ref="city" value="City"><label
                      for="adv3">City</label>
                  </div> -->
 
                  <!-- <div class="filter-option"><input  id="adv4" @click="getResult"
                      name="filter-state" type="checkbox" v-model="cch" ref="state" value="State"><label
                      for="adv4">State</label>
                  </div> -->
 
                  <div class="filter-option"><!----><input id="adv5" @click="getResult"
                      name="filter-pn" type="checkbox" v-model="cch" ref="phoneNumber" value="Phone Number"><label
                      for="adv5">Phone Number</label>
                  </div>
 
                  <div class="filter-option"><!----><input  id="adv6" @click="getResult"
                      name="filter-dl" type="checkbox" v-model="cch" ref="DL" value="DL"><label
                      for="adv6">DL</label>
                  </div>
 
                  <div class="filter-option"><!----><input id="adv7" @click="getResult"
                      name="filter-dob" type="checkbox" v-model="cch" ref="dob" value="Date of Birth"><label
                      for="adv7">Date of Birth</label>
                  </div>
 
                  <div class="filter-option"><!----><input id="adv8" @click="getResult"
                      name="filter-ssn" type="checkbox" v-model="cch" ref="ssn"
                      value="Last Four Digit of SSN"><label for="adv8">Last Four Digits of SSN</label>
                  </div>

                  <div class="filter-option"><!----><input id="adv9" @click="getResult"
                      name="filter-partnerID" type="checkbox" v-model="cch" ref="partner_id"
                      value="Partner ID"><label for="adv9">Partner ID</label>
                  </div>

                  <div v-if="roleisAM" class="filter-option"><!----><input id="adv10" @click="getResult"
                      name="filter-Fullssn" type="checkbox" v-model="cch" ref="fullSSN"
                      value="Full SSN"><label for="adv10">Full SSN</label>
                  </div>

                </div>
              </slot> 
            </div>
            <div class="margin-top-24">
                <span class="textColor"><label class="label black">Search</label></span>                
                <div class="input-group">
                <input @input="getFilters" v-model="searchTerm" ref="searchText" type="search" class="search-field" placeholder="Search here" />
                  <button v-if="searchTerm.length === 0" disabled><span class="icon-search deposit-icon"></span></button>
                  <button v-else @click.prevent="clearSearch"><span class="icon-x-circle deposit-icon"></span></button>
                </div>
            </div>
             
          </slot>
          <div class="searchSubContainer"> 
                <ul v-if="searchResults.total > 0 && searchTerm.length !== 0" class="searchResult" @click.prevent="clearSearch">
                  <!-- <li v-if="searchResults.partners.length !== 0" class="inline-header">Partners</li> -->
                  <li v-for="result in searchResults.partners" :key="result.id">
                    <div class="searchResultRow">
                      <span v-if="result.type === 'partner'" class="icon-user" title="Partner"></span>
                      <a v-if="result.type === 'partner'"   @click="openinNewTab({ name: 'partner', params: { id: result.id } })">
                        {{ result.firstName }} {{ result.lastName }},
                        <span class="small gray-light" :style="{margin:0}">{{ result.email }}</span> 
                      </a
                      >
                    </div>
                  </li>  
                </ul>
                <ul v-if="(searchResults.total === 0 || searchResults.total === undefined)  && searchTerm.length > 0" class="searchResultNone"> 
 
                  <div class="searchResultRowNone">
                    <span style="margin-left: 50%;" v-if="checkrLoading" class="loading"><Loader/></span>
                  <li v-else>
                    <a>
                        No Records Found 
                      </a>
                  </li>
 
                </div>
 
                </ul>
          </div>
        </div>
        
      </div>
      
    </div> 
             
  </div>
  
</template>

<script>
 
import micromodal from "micromodal";
import { restApi } from "@/http/http.config"; 
import {encodeWithParam} from "@/util/Base64Encoding";
import debounce from "debounce";
import { encrypt } from "@/filters";
import { Loader } from "@/components";

export default { 
  name: "AdvanceSearch", 
  components: { Loader }, 
  data: function () {
    return {
      searchTerm: "",
      search: [], 
      partners: [],
      cch: [],
      roleisAM: false,
      checkrLoading: true,
    };
  },
  computed: {

    // checkUserRole(){
    //   if(localStorage.getItem("role") == "ACCOUNT MANAGER"){
    //      this.roleisAM = true;
    //   }
    // },
    
    searchResults() {
 
      const partnerResults = this.partners?.map((item) => { 
        item.type = "partner";
        return item;
      });
     
 
      const partners = [partnerResults].flat();
 
      const total = partnerResults?.length; 
      return { partners, total };
    },
    isUUID() {
      const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/g;
      return this.searchTerm.match(regex);
    },
  },
  methods: {

    getResult(){
      if(this.$refs["searchText"].value != "") {
        this.getFilters()
      }
    },


    clearSearch() {  
      this.searchTerm = "";
      this.$refs["searchText"].value = "";
      this.partners = [];
      this.searchResults.total = 0;
    },
    // async getFilters(){  
      getFilters: debounce(async function () { 
        this.checkrLoading = true;
      // console.log(this.$refs["searchText"].value.length," count")
      let filtersToQuery = []; 
        
      if(this.$refs.driverName.checked){
        filtersToQuery.push("driverName");
      }else{
        filtersToQuery = filtersToQuery.filter(function(e) { return e !== 'driverName' })
      } 
       
      if(this.$refs.email.checked){
        filtersToQuery.push("email");
      }else{
        filtersToQuery = filtersToQuery.filter(function(e) { return e !== 'email' })
      }

      // if(this.$refs.city.checked){
      //   filtersToQuery.push("city");
      // }else{
      //   filtersToQuery = filtersToQuery.filter(function(e) { return e !== 'city' })
      // }

      // if(this.$refs.state.checked){
      //   filtersToQuery.push("state");
      // }else{
      //   filtersToQuery = filtersToQuery.filter(function(e) { return e !== 'state' })
      // }

      if(this.$refs.phoneNumber.checked){
        filtersToQuery.push("phone");
      }else{
        filtersToQuery = filtersToQuery.filter(function(e) { return e !== 'phone' })
      }

      if(this.$refs.DL.checked){
        filtersToQuery.push("number");
      }else{
        filtersToQuery = filtersToQuery.filter(function(e) { return e !== 'number' })
      }

      if(this.$refs.dob.checked){
        filtersToQuery.push("dateOfBirth");
      }else{
        filtersToQuery = filtersToQuery.filter(function(e) { return e !== 'dateOfBirth' })
      } 


      if(this.$refs.ssn.checked){
        filtersToQuery.push("ssnFour");
      }else{
        filtersToQuery = filtersToQuery.filter(function(e) { return e !== 'ssnFour' })
      } 

      if(this.$refs.partner_id.checked){
        filtersToQuery.push("partnerId");
      }else{
        filtersToQuery = filtersToQuery.filter(function(e) { return e !== 'partnerId' })
      }

      if(localStorage.getItem("role") == "ACCOUNT_MANAGER"){
      if(this.$refs.fullSSN.checked){
        filtersToQuery.push("fullSSN");
      }else{
        filtersToQuery = filtersToQuery.filter(function(e) { return e !== 'fullSSN' })
      }
    }
     
      if(filtersToQuery){
             let result = this.searchTerm.split(",");
             let queryParams = await Promise.all(result.map(async function (item) {
              const regex = /^\d{4}-\d{2}-\d{2}$/; // check for DOB 
              const regexForSSN = /^\d{3}-\d{2}-\d{4}$/; // check for DOB 
              const regexForFullSSN = /^\d{9}$/;
              if(regex.test(item)){
                  return item.trim();
                }else if(item.includes("-")){ // for partner ID
                   if(regexForSSN.test(item)){
                    // console.log(item.replace('-', '')," result")
                    let one = item.split("-");
                    // console.log(one.join(""), "result")
                    let ssn = await encrypt(one.join(""))
                    return `${ssn}:**`

                    // return await encrypt(item.replace('-', ''));
                   }else{
                    return item.trim();
                   }
                  
                }
                else if(regexForFullSSN.test(item)){
                  let ssn = await encrypt(item);
                  return `${ssn}:**`
                }
                else{
                  return '%' +item.trim() + '%';
                }
                 
              })  )
              // console.log(queryParams," searchTerm is ")
              // console.log(filtersToQuery," filters is ")
              const usernameParam = {searchTerm : queryParams, filters : filtersToQuery}
              restApi.post('onboard/advancedSearch', encodeWithParam(usernameParam))
              .then(data => {
                this.checkrLoading = false;
                  this.partners = data.data.result;
                });
      } 
    }, 2000),  

    
    openinNewTab(data){
      let route= this.$router.resolve(data);
      window.open(route.href, '_blank');
    },
    uncheck() {
      this.cch = [];
    }, 
    closeModal() {
      this.clearSearch();
      this.uncheck();
      micromodal.close("advance-search");
    }
  },
  mounted() {
    // console.log(localStorage.getItem("role"), "role is ")
    
    this.searchStr = debounce(this.searchStr, 500)

    if(localStorage.getItem("role") == "ACCOUNTING_SUPERVISOR"){
         this.roleisAM = true;
    }
  },
   
};
</script>

<style scoped>
.modal-window_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0000007a;
  backdrop-filter: blur(10px);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  /* CHANGED */
  align-items: center;
  -webkit-align-items: center;
  overflow: hidden;
  z-index: 40;
}

.modal-window_container {
  background-color: #fff;
  padding: 0 16px 16px 16px;
  width: 100%;
  max-width: 500px;
  max-height: 100%;
  /* CHANGED */
  border-radius: 0px 0px 0px 0px;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;
  font-family: 'Open Sans';
}


.micro-modal_container {
  background-color: #fff;
  padding: 32px;
  max-width: 700px;
  min-height: 50%;
  border-radius: 8px 8px 8px 8px;
  overflow-y: auto;
  box-sizing: border-box;
  bottom: auto;
  position: absolute;
  display: flex;
  margin: 16px;
}

.micro-modal_congrats-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.lDist {
  margin-left: 10px;
}

.pointDist {
  margin-left: 20px;
}

.liTop {
  margin-bottom: 0em;
  color: #000000;
}

.points {
  margin-left: 20px;
  color: #000000;
}

.searchResultNone {
  position: absolute;
  right: 17px;
    left: 17px;
  max-height: 500px;
  z-index: 100;
  list-style: none;
  min-width: 216px;
  width: auto;
  padding: 10px;
  background-color: #fdfdfd;
  margin-top: 3px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.searchResult {
  position: relative;
  right: 0;
    left: 0;
  max-height: 393px;
  overflow: auto;
  z-index: 100;
  list-style: none;
  min-width: 280px;
  width: auto;
  padding-left: 0;
  background-color: #fdfdfd;
  margin-top: 3px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  -webkit-box-shadow: 0 0 2px 1px rgba(229, 237, 245);
  -moz-box-shadow: 0 0 2px 1px rgba(229, 237, 245);
  box-shadow: 0 0 2px 1px rgba(229, 237, 245);
  font-family: 'Open Sans';
}

.searchResultRowNone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  font-size: 12px;
}

.searchResultRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  border-bottom: 1px solid lightgray;
  font-size: 12px;
}
.searchResultRow .icon-user{
  color: #0071DC;
}
.searchResult li {
  margin-bottom: 0;
}
.searchResult li a {
  width: 100%;
  padding: 10px 5px;
  text-decoration: none;
  color: #46474A;
}
.searchResultRow:hover {
  background-color: #eaeaea;
}

.spacepoints {
  margin-left: 50px;
  color: #000000;
}

.spacepoints2 {
  margin-left: 50px;
  margin-top: -18px;
  color: #000000;

}

.leftMargin {
  margin-left: 20px;
  margin-top: -10px;
}

.topMain {
  margin-top: -18px;
}

::marker {
  list-style: none;
  font-size: 9px;
}

.namePoints {
  margin-bottom: 0em;
}

.textColor {
  color: #000000
}

li.liTop::marker {
  font-size: 16px;
}

li::marker {
  font-size: 16px;
}
.xbutton{
  position: absolute;
  right: 11px;
  top: 11px;
  cursor: pointer;
}
.filter-option label {
  background-color: #F4F4F4;
  color: #0071DC;
  border: 1px solid transparent;
  font-size: 12px;
}
.filter-option input[type=checkbox]:checked + label{
  border: 1px solid #0071DC;
  background-color: #F4F4F4;
  color: #0071DC;
}
.search-field{
  width:100%;
  font-size: 12px;
  padding-left: 10px;
  font-family: 'Open Sans';
}
.search-field::-webkit-input-placeholder { /* Edge */
  font-style: normal;
}
.search-field:-ms-input-placeholder { /* Internet Explorer */
  font-style: normal;
}
.search-field::placeholder {
  font-style: normal;
  font-family: 'Open Sans';
  
}
.input-group{
  position: relative;
}
.input-group .deposit-icon {
    margin-bottom: 4px;
    position: absolute;
    left: 18px;
    top: 2px;
    color: #CCCCCC;
}

</style>
