<template>
  <fragment>
    
   
    <div class="grid space-between">
       <div class="col-12"  v-if="cDisplayToast"><Message class="toast" @close="closeToast" :severity="toastSeverity"> {{toastMessage}}</Message></div>
      <div class="col-6">
        <p class="label">Filters</p>
        <location-filter name="zones" :options="locationsFilterOptions" @onFilter="fetchFilteredCandidates('locations', $event)" />
        <base-filter name="stages" :options="FunnelStages" type="radio" @onFilter="fetchFilteredCandidates('stages', $event)"/>
        <div :key="stepRefreshKey">
          <base-filter name="steps"  :options="FunnelSteps" type="radio" @onFilter="fetchFilteredCandidates('steps', $event)"/>
          <base-filter name="status" :key="statusRefreshKey" :options="FunnelStatus" type="radio" @onFilter="fetchFilteredCandidates('status', $event)"/>
        </div>
        <base-filter name="sub status" :options="FunnelSubStatus" :key="subStatusRefreshKey" type="radio" @onFilter="fetchFilteredCandidates('subStatus', $event)"/>
        <button class="button w-button-primary" @click="fetchFilteredCandidates('search', $event)">Search</button>
      </div>
      <div class="col-6 flex inherit-flex">
        <div class="col-0.5" style="padding-right:0px" @click="wOpenCommsFlyout('SMS')">
         <img src="@/assets/images/logos/walmart-sms.svg"/>
        </div>
        <div class="col-0.5" style="padding-right:0px" @click="wOpenCommsFlyout('EMAIL')">
         <img src="@/assets/images/logos/walmart-email.svg"/>
        </div>
        <div class="col-0.5" style="padding-right:0px">
          <meatball id="onboarding-maintenance" right :changeIcon="true">
              <img src="@/assets/images/logos/walmart-mvr.svg"/>
              <menu-item  style="display:inline-block" label="Move to MVR" :on-click="wRunMvr"/>
          </meatball>
        </div>
      <div class="col-2" style="padding-right:0px">
        <div class="search-container">
          <input v-model="searchTerm" class="deposit-search" type="search" placeholder="Candidate Search" title="Candidate: Name, Phone, Email" />
          <button type="submit"><span v-if="searchIcon()" class="icon-x deposit-icon" @click="resetSearch"></span> <span v-else class="icon-search deposit-icon"></span></button>
        </div>
      </div>
      </div>
    </div>
    <div class="onboarding-candidates">
      <div v-if="hasCandidates" style="margin:0px" class="module location-header" :class="{'paginator-align': totalCount > perPage }">
      <div>
        <div ><label for="total-candidates"><span class="label-container"><b>Showing ({{ totalCount}} Candidates)</b></span></label></div>
        <div class="checkbox-custom checkAlign" style="margin:0">
          <input id="select-all" v-model="wSelectAllCandidates" @click="hideToast" type="checkbox" @change="selectAllPartners"/>
          <label for="select-all"><span class="label-container"><b>Select All ({{ selectedPartners.length }} Candidates)</b></span></label>
      </div>
      <div class="checkbox-custom checkAlign" style="margin:0">
          <input id="select-all-pages" v-model="wSelectAllPages"  type="checkbox" @change="selectAllPages"/>
          <label for="select-all-pages"><span class="label-container"><b>Select All Pages ({{ totalCount}} Candidates)</b></span></label>
      </div>
    </div>
      </div>
      <data-table
        :sticky-header="true"
        :loading="onboardingCandidateViewLoading"
        :headers="headers"
        :entries="filteredCandidates"
        :row-class-function="rowClass"
        :dispaly-row-selector="false"
        :pagination-total-count="totalCount"
        :pagination-per-page="perPage"
        :pagination-current-page="currentPage"
        @onPaginate="fetchData($event)"
        @onChangePerPage="onChangeCount($event)"
      >
      <template #select="{ item }"> <input type="checkbox" class="checkbox-input-deposit" @click="multiSelect(item, $event)" v-model="selectedPartners" :value="item.id" ></template>

        <template #name="{ item }">
          <a @click="openFlyout(item)">{{ item.name }}</a>
        </template>

        <template #action="{ item }">
          <a v-if="item.status !== 'INVALID'">N/A</a>
          <a v-if="item.status == 'INVALID'" @click="deleteCandidate(item.partnerID)">Delete</a>
        </template>
      </data-table>

      <div id="delete-candidate-modal" class="modal" aria-hidden="true">
        <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
          <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby" style="min-height: 300px;">
            <div style="margin: 15% 0px auto;" v-if="!deletingLoader">
              <vue-simple-spinner />
              <h6 style="text-align: center;padding-top: 10px;">Deleting Please Wait</h6>
            </div>
            <div class="modal-content" style="text-align: center" v-if="deletingLoader">
              <div class="module">
                <span><i class="icon icon-alert-triangle" style="color: #c47d2b; font-size: 60px"></i></span>
              </div>
              <div class="module">
                <h4>Are you sure you want to delete</h4>
              </div>
              <div class="button-group close-top">
                <a class="button secondary" data-micromodal-close>Cancel</a>
                <a class="button secondary" style="background: #c2272c; color: #ffffff; margin-left: 20px" @click="confirmationModal">Delete</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="bulk-delete-candidate-modal" class="modal" aria-hidden="true">
        <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
          <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby" style="min-height: 300px;">
            <div class="modal-content" style="text-align: center">
              <div class="module">
                <span><img src="@/assets/images/logos/walmart-trash.svg"/></span>
              </div>
              <div class="module">
                <h4>Are you sure you want to delete</h4>
                <p>You've selected <b> {{ totalCount}} candidates</b> to be deleted, and they can't be recovered</p>
              </div>
              <div class="button-group close-top">
                <a class="button secondary" style="background: #c2272c; color: #ffffff;" @click="deleteBulkCandidates">Yes,Delete</a>
                <a class="button secondary" data-micromodal-close>No,Cancel</a>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bulk-communications-flyout 
      :bulk-obj="candidateObj" 
      :on-close="closeBulkComms" 
      :display-editor="displayEmailEditor" 
      :comms-type="commsFlyoutType" 
      @sendEmail="sendEmail" 
      @sendText="sendSMS"/>
    <candidate-flyout 
      :candidate="candidate" 
      :eligibility-questions="eligibilityQuestions"  
      :partner-application="partnerApplication" 
      :personal-details="personalDetails" 
      :on-close="closeComms" 
      :cid="cid"
      :isCandidateFlyoutDataLoading="isCandidateFlyoutDataLoading"
      @refresh="refresh" 
      @onRemoveInsurance="removeInsuranceImage"
      @insuranceImageUploaded="insuranceImageUploaded"
      @invalidateCandidate="refetchFlyout"
      @refetchPII="getPersonalDetails"
    />
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import {
  BaseFilter,
  DataTable,
  LocationFilter,
  TableHeader,
  //Direction,
  Meatball,
  MenuItem
} from "@/components";
import { mapActions, mapGetters } from "vuex";
import CandidateFlyout from "@/modules/admin/onboarding/flyouts/CandidateFlyout";
import { DateTime } from "luxon";
import { OnboardingStatus } from "@/modules/admin/partners/model";
import { BulkCommunicationsFlyout } from "@/modules/shared/communications";
import micromodal from "micromodal";
import VueSimpleSpinner from "vue-simple-spinner";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";
import Message from 'primevue/message';
import {FunnelProgress,FunnelStatus} from "@/util/funnelProgress";
import { decode, decrypt } from "@/filters";
import { convertBufferToContent } from "@/util/BufferContentConverter";
import axios from 'axios'
import { isPDFFile } from "../../../../util/validator";
export default {
  components: {
    CandidateFlyout,
    Fragment,
    BaseFilter,
    LocationFilter,
    DataTable,
    VueSimpleSpinner,
    BulkCommunicationsFlyout,
    Meatball,
    MenuItem,
    Message
  },
  data: () => ({
    fp: new FunnelProgress(),
    selectedStage: "",
    selectedSteps: [],
    selectedStep:"",
    selectedStatus:[],
    selectedSubStatus: [],
    cid:"",
    displayEmailEditor: true,
    deletingLoader:true,
    selectedLocation: "",
    candidate: {},
    searchTerm: "",
    deleteCandidateId: "",
    currentCandidate: "",
    selectedStages: [],
    selectedLocations: [],
    selectAllLocationsFlag:false,
    selectedCandidate: {},
    locations: [],
    displayBulkMenu: false,
    candidateObj: [],
    disablebtn: true,
    singleChecks: [],
    bulkActionPartners: [],
    bulkActionCount: 0,
    totalCount: 0,
    currentPage: 0,
    perPage: 50,
    offset: 0,
    updating: 0,
    startDayWeek: 6,
    allCandidates: false,
    isFiltered: false,
    isLoading: true,
    isSubscriptionLoading: true,
    onboardingCandidateView: [],
    onboardingCandidateViewLoading: false,
    partnerSteps: [],
    eligibilityQuestions: [],
    partnerApplication: [],
    personalDetails: {},
    wSelectAllCandidates: false,
    commsFlyoutType: '',
    toastMessage: '',
    displayToast: false,
    toastSeverity:'',
    clickedStatus:"",
    clickedStep:"",
    clickedSubStatus: "",
    cancelCandidateViewSource:null,
    stepRefreshKey:1000,
    statusRefreshKey: 2000,
    subStatusRefreshKey: 3000,
    isCandidateFlyoutDataLoading: false,
    lastSearchedStatus: null,
    selectedPartners:[],
    selectedCandidatesList:[],
    commstoselected:[],
    wSelectAllPages:false,
  }),
  computed: {
    ...mapGetters(["isFlyoutShowing", "getSelectedEmployee","getUserID","getLocationFilterData","getAllLocationsData"]),
    hash() {
      return this.$route.hash === "#candidates";
    },
    cDisplayToast() {
      return this.displayToast;
    },
    FunnelStages () {
      return this.fp.getAllStages()
    },
    FunnelSteps() {
      return this.selectedSteps;
    },
    FunnelStatus () {
      return this.selectedStatus;
    },
    FunnelSubStatus () {
      return this.selectedSubStatus;
    },
    locationsFilterOptions() {
      return [...this.$store.state.onboarding.candidatesTab.locationsFilterOptions];
    },
    filteredCandidates() {
      const candidates = this.onboardingCandidateView.map((candidate) => {
        let timeInStep = "";
        const timeDifference = DateTime.utc().diff(DateTime.fromISO(candidate.date), ["minutes", "hours", "days"]);
        timeInStep =
          timeDifference.values.days > 0
            ? `${timeDifference.values.days}d`
            : timeDifference.values.hours > 0
            ? `${Math.round(timeDifference.values.hours)}h`
            : `${Math.round(timeDifference.values.minutes)}m`;
        return {
          id: candidate.partnerLocationID,
          partnerID: candidate.partnerID,
          name: `${candidate.firstName || ""} ${candidate.lastName || ""}`,
          location: candidate.location,
          locationID: candidate.locationID,
          email: candidate?.email ? candidate?.email : "",
          phone: candidate?.phone ? candidate?.phone : "",
          time: timeInStep,
          date: candidate.date,
          stage: candidate.stage ? candidate.stage : "",
          step: candidate.step ? candidate.step :"",
          status: candidate.status,
          alertTime: candidate.alertTime ? candidate.alertTime : null,
          userID: candidate?.partnerID ? candidate.partnerID : "",
          os: candidate?.assignedOS
        };
      });
      return candidates
    },
    hasCandidates() {
      return this.filteredCandidates && this.filteredCandidates.length > 0;
    },
    headers() {
      return [
        // new TableHeader({ cellClass: "bulk-checkbox-actions", name: "checkbox" }),
        new TableHeader({ name: "select", label: "", cellContents: "select", headerClass: "cell2"}),
        new TableHeader({
          name: "name",
          label: "Candidate Name",
          cellContents: "name",
          headerClass: "cell20",
          sortable: true,
        }),
        new TableHeader({ name: "phone", label: "Phone", cellContents: "phone", headerClass: "cell5", sortable: false }),
        new TableHeader({
          name: "email",
          label: "Email",
          cellContents: "email",
          headerClass: "cell10",
          sortable: true,
        }),
        new TableHeader({
          name: "location",
          label: "Location",
          cellContents: "location",
          headerClass: "cell20",
          sortable: true,
        }),
        new TableHeader({
          name: "time",
          label: "Time In",
          cellContents: "time",
          headerClass: "cell10",
          sortable: false,
          cellClass: "time",
        }),
        new TableHeader({
          name: "stage",
          label: "Stage",
          cellContents: (item) => this.getStageLabel(item.stage),
          headerClass: "cell15",
          sortable: false,
          sorted: false,
          cellClass: "stage",
        }),
        new TableHeader({ 
          name: "step", 
          label: "Step", 
          cellContents: (item) =>this.getStepLabel(item.step), 
          headerClass: "cell15", 
          sortable: false,
          sorted: false, 
          cellClass: "step" 
        }),
        new TableHeader({
          name: "status",
          label: "Status",
          headerClass: "cell20",
          cellContents: (item) => this.getStatusLabel(item.status),
          sortable: false,
         // sortFunction: this.sortStatus,
          sorted: false,
         // direction: Direction.DESC,
          cellClass: "status",
        }),
        new TableHeader({ name: "os", label: "OS", cellContents: "os", headerClass: "cell45", sortable: false, cellClass: "name" }),
      ];
    },
    getRole() {
      return localStorage.getItem("role");
    }
  },
  watch: {
    isFlyoutShowing(newData) {
      if (newData === false) {
        this.candidate = {};
      }
    },
    getSelectedEmployee(newData, oldData) {
      if (newData.name !== oldData.name) {
        this.displayBulkMenu = false;
      }
      if (this.$refs.stageFilter) {
        this.$refs.stageFilter.reset();
      }
    },
    searchTerm(newData, oldData) {
      if (newData != oldData) {
        this.newOnboardingCandidateView();
      }
    }
  },
  methods: {
    ...mapActions(["showFlyout","updateSelectedLocations"]),
    searchIcon() {
      return this.searchTerm !== "";
    },
    hideToast() {
      if(!this.wSelectAllCandidates) {
        this.displayToast = false;
      }
    },
    closeToast() {
      this.displayToast=false;
    },
    resetSearch() {
      this.selectedCandidatesList=[];
      this.wSelectAllCandidates=false;
      this.selectedPartners=[];
      this.searchTerm = "";
      this.currentPage = 1;
      this.newOnboardingCandidateView();
    },
    resetPaginator() {
      this.currentPage = 1;
      this.offset = 0;
    },
    async openFlyout(candidate) {
      this.selectedCandidate = {};
      if (candidate) {
        this.$log.info("Opening flyout for candidate with ID", candidate.id);
        this.selectedCandidate = this.onboardingCandidateView.filter((origCandidate) => origCandidate.partnerLocationID === candidate.id)[0];
        this.cid=this.selectedCandidate.partnerLocationID;
        this.getCandidateDetails(this.selectedCandidate.partnerID);
      
        this.showFlyout("candidate-flyout");
      }
    },
    refetchFlyout() {
      this.getCandidateDetails(this.selectedCandidate.partnerLocationID);
      this.showFlyout("candidate-flyout");
    },
    getPartnerSteps(id) {
        restApi.post('onboard/getFunnelProgress',encodeWithParam({candidateID: id})).then((data) =>{
        this.partnerSteps = data.data.result.map((partnerStep) => {
            return {
              id: partnerStep.id,
              step: partnerStep.name,
              displayName: partnerStep.description,
              stage: partnerStep.stage,
              status: partnerStep.candidateStatus,
              date: partnerStep.date,
              stepDetail: partnerStep.candidateStatusDetail,
            };
          });
  })
    },
    getCandidateDetails(id) {
      this.isCandidateFlyoutDataLoading = true;
      return restApi.post('onboard/getCandidateDetails',encodeWithParam({candidateID: id})).then(async (data)=>{
          if (data) {
          const candidate = data.data;
          const candidateProgress = candidate.candidateProgress.length > 0 ? candidate.candidateProgress[0] : null;
          let timeInStep = "";
          if (candidateProgress) {
            const timeDifference = DateTime.utc().diff(DateTime.fromISO(candidateProgress.date), ["minutes", "hours", "days"]);
            timeInStep =
              timeDifference.values.days > 0
                ? `${timeDifference.values.days}d`
                : timeDifference.values.hours > 0
                ? `${Math.round(timeDifference.values.hours)}h`
                : `${Math.round(timeDifference.values.minutes)}m`;
          }
          this.currentCandidate = "";
          this.candidate = {
            id: candidate.id,
            electronicSignature: candidate.electronicSignature,
            partnerID: candidate.partner.id,
            name: `${candidate.partner.firstName || ""} ${candidate.partner.lastName || ""}`.trim(),
            location: candidate.location.name,
            locationID: candidate.location.id,
            locationCountCurrent: candidate.location.stageCounts?.length > 0 ? await this.approvedCount(candidate.location.stageCounts) : 0,
            locationCountTotal: candidate.location.headCountRequests?.length > 0 ? await this.totalApprovedCount(candidate.location.headCountRequests) : 0,
            email: candidate.partner.email,
            phone: candidate.partner.phone,
            time: timeInStep,
            stage: candidateProgress ? candidateProgress.step.stage : "",
            step: candidateProgress ? candidateProgress.step.name : "",
            status: candidateProgress ? candidateProgress.candidateStatus : "",
            candidateReason: candidateProgress.candidateStatusDetail ? candidateProgress.candidateStatusDetail : "",
            candidateProgressId: candidateProgress.id,
            alertTime: candidateProgress ? candidateProgress.alertTime : null,
            specialist: candidate?.location?.teamSpecialist ? this.computeEmployeeName(candidate.location.teamSpecialist.employee) : "",
            userID: candidate?.partner?.userID ? candidate.partner.userID : "",
            externalID: candidate?.partner?.ids[0]?.externalID ? candidate.partner.ids[0].externalID : null,
            DMSID:null,
            username: candidate?.partner?.user?.username,
            userId: candidate?.partner?.user?.id,
            approvalDate: candidate?.approvalDate ? candidate.approvalDate : "",
            notes: candidate.notes,
            notesList: candidate.notesList,
            clientID: candidate?.location?.client?.id,
            insuranceExpiry: candidate.partner.insuranceExpiry ? candidate.partner.insuranceExpiry : "",
            newInsuranceImageUploaded: false,
            personaOCRInsuranceFailureReasons: candidate.personaOCRInsuranceFailureReasons ?? [],
          };
          const checkDocumentsComplete = await restApi.post('onboard/getFunnelProgress',encodeWithParam({candidateID: this.candidate.id}));
          const documentComplete = checkDocumentsComplete.data.result.filter((item)=>item.candidateStatus === FunnelStatus.DOCUMENTS_COMPLETE)
          if(documentComplete.length > 0) {
            this.getDocuments()
          } else {
            this.isCandidateFlyoutDataLoading = false;
          }
          this.currentCandidate = this.candidate.id;
          this.getFunnelQuestions(this.candidate?.partnerID,this.candidate?.locationID);
          this.getPersonalDetails(this.candidate.id)
        }  
        })
    },
    insuranceImageUploaded() {
      this.candidate.newInsuranceImageUploaded = true;
      this.getDocuments();
    },
    getDocuments() {
      restApi.post('/onboard/getDocuments',encodeWithParam({
        clientLocationPartnerID: this.candidate.id,
        clientID: this.candidate.clientID,
        clientLocationID: this.candidate.locationID,
        userID: this.candidate.userID
      })).then((docs) => {
        restApi.post('/onboard/getInsuranceData', encodeWithParam({ partnerID: this.candidate.partnerID })).then((res) => {
          let insuranceFileID;
          this.candidate = {...this.candidate, personaOCRInsuranceFailureReasons: res.data?.result?.length ? res.data.result[0]?.personaOCRInsuranceFailureReasons : [] }
          if (this.candidate.newInsuranceImageUploaded || !(res.data?.result?.length)) {
            insuranceFileID = (docs.data.result.filter((item) => item.title.toLowerCase() === "insurance"))[0].fileID;
            this.candidate = { ...this.candidate, insuranceFileId: insuranceFileID };
          } else {
            insuranceFileID = res.data.result[0].file_id;
            this.candidate = { ...this.candidate, insuranceExpiry: res.data.result[0].insurance_expiry, insuranceFileId: insuranceFileID };
          }

          restApi.post('/employee/getUploadedFile', encodeWithParam({ fileID: insuranceFileID }))
            .then((data) => {
              let imageContent = data.data?.bufferInHex ? convertBufferToContent(data.data.bufferInHex, data.data.contentType) : null
              this.candidate = { ...this.candidate, allDocs: docs.data.result, insuranceImageURL: imageContent };
              this.candidate.insuranceFileFormat = isPDFFile(data?.data?.fileName);
              this.candidate.isInsuranceFileLoading = false;
              this.isCandidateFlyoutDataLoading = false;
            }).catch(() => {
              this.isCandidateFlyoutDataLoading = false;
            })
        })
      })
    },
    removeInsuranceImage() {
      this.candidate.insuranceImageURL =null;
      this.candidate.insuranceExpiry=null;
    },
    getFunnelQuestions(partnerId,locationId) {
      restApi.post('/onboard/getFunnelQuestions',encodeWithParam({partnerID:partnerId,clientLocationID:locationId})).then((data)=>{
        this.eligibilityQuestions =data.data.eligibilityQuestions;
        this.partnerApplication = data.data.eligibilityAnswers;
      })
    },
    getFunnelAnswers(parnerId) {
      restApi.post('/onboard/getFunnelAnswers',encodeWithParam({partnerID: parnerId})).then((data)=>{
        this.partnerApplication = data.data?.result
      })
    },
    getPersonalDetails(id) {
      restApi.post('/onboard/getPersonalDetails',encodeWithParam({candidateID: id})).then(async(data)=>{
            const decodedSsn = decode(data.data.personalDetails?.encodedData)
            const decryptedSnn = await decrypt(decodedSsn.dssn,this.candidate?.partnerID)
            this.personalDetails = {
              ssn: '*****'+decryptedSnn?.substring(5),
              ein: data.data.personalDetails.ein,
              fullSsn: data.data.personalDetails.ssn ? data.data.personalDetails.ssn : null,
              fullEin: data.data.personalDetails.ein ? data.data.personalDetails.ein : null,
              dob: data?.data?.personalDetails?.dateOfBirth ? data.data.personalDetails.dateOfBirth:"",
              licenseNumber: data.data?.driversLicenses?.number ? data.data?.driversLicenses?.number:"",
              licenseExpiry: data?.data?.driversLicenses?.expiration ? data.data.driversLicenses.expiration:"",
              licenseState: data.data.driversLicenses.state ? data.data.driversLicenses.state:"",
              insuranceExpiry: data.data.personalDetails.insuranceExpiry ? data.data.personalDetails.insuranceExpiry:"",
              streetAddress: data.data.personalDetails.address1 ? data.data.personalDetails.address1:"",
              city: data.data.personalDetails.city ? data.data.personalDetails.city:"",
              state: data.data.personalDetails.state ? data.data.personalDetails.state:"",
              zip: data.data.personalDetails.postalCode ?data.data.personalDetails.postalCode:"",
              wasConvicted: data.data.criminalHistory?.wasConvicted ? data.data.criminalHistory.wasConvicted:false,
              convictionDescription: data.data.criminalHistory?.convictionDescription ? data.data.criminalHistory.convictionDescription:"",
              hasPendingCharges: data.data.criminalHistory?.hasPendingCharges ? data.data.criminalHistory.hasPendingCharges:false,
              pendingChargesDescription: data.data.criminalHistory?.pendingChargesDescription ? data.data.criminalHistory.pendingChargesDescription:"",
              isSexOffender: data.data.criminalHistory?.isSexOffender ? data.data.criminalHistory.isSexOffender:false,
              stateRegisteredYear: data.data.criminalHistory?.stateRegisteredYear ? data.data.criminalHistory.stateRegisteredYear:"",
              stateRegistered: data.data.criminalHistory?.stateRegistered ?data.data.criminalHistory.stateRegistered:"",
              countyRegistered: data.data.criminalHistory?.countyRegistered ? data.data.criminalHistory.countyRegistered:"",
              decoded: data.data.personalDetails.encodedData ? decode(data.data.personalDetails.encodedData) : ""
            };
            this.personalDetails.decoded.dssn = await decrypt(this.personalDetails.decoded.dssn,this.candidate?.partnerID)
      })
    },
        maskNumber(value){
      if(value){
        if(value.length < 8){
          return `****${value.replace(/\d(?=\d{4})/g, "*")}`
        }
        else{
          return value.replace(/\d(?=\d{4})/g, "*");
        }
        
      }
      else{
        return "";
      }
      
    },
    getStageLabel(stageName) {
      return stageName ? this.fp.getStageLabel(stageName): "Unknown Stage";
    },
    getStepLabel(stepName) {
      return stepName ? this.fp.getStepLabel(stepName): "Unknown Step"
    },
    getStatusLabel(statusName) {
      return this.lastSearchedStatus === "Waitlist Not Eligible" ? "Waitlist Not Eligible" : (statusName ? this.fp.getStatusLabel(statusName) : "Unknown Status")
    },
    rowClass(candidate) {
      if (OnboardingStatus.isWithdrawn(candidate.status)) {
        return "withdrawn";
      }
      if (OnboardingStatus.isWarningCandidateStatus(candidate.status)) {
        return "status-alert";
      }
      if (candidate.alertTime && DateTime.fromISO(candidate.alertTime) < DateTime.utc()) {
        return "time-alert";
      }
      return null;
    },
    sortStage() {
      return (candidate1, candidate2) => {
        const candidate1Urgency = this.computeStage(candidate1);
        const candidate2Urgency = this.computeStage(candidate2);
        return candidate1Urgency - candidate2Urgency;
      };
    },
    computeStage(candidate) {
      return this.fp.getStageID(candidate.stage);
    },
    computeEmployeeName(employee) {
      if (employee) {
        return `${employee.firstName} ${employee.lastName}`.trim();
      }
      return "none";
    },
    fetchData(event) {
      this.currentPage = Number(event.pageNumber);
      this.offset = event.offset;
      this.wSelectAllCandidates=false;
      this.selectedPartners=[];
      this.newOnboardingCandidateView();
    },
    onChangeCount(event) {
      this.perPage = event.perPage;
    },
    fetchFilteredCandidates(type, event) {
      this.selectedCandidatesList=[];
      this.wSelectAllCandidates=false;
      this.selectedPartners=[];
      switch(type) {
        case "stages":
          this.stepRefreshKey++;
          this.subStatusRefreshKey++;
          this.clickedStep = "";
          this.clickedStatus = "";
          this.clickedSubStatus = "",
          this.setFunnelSteps(event);
          this.setFunnelStatus(event);
          break;
        case "steps":
          this.statusRefreshKey++; 
          this.subStatusRefreshKey++;
          this.clickedStatus = "";
          this.clickedSubStatus = "";
          event.length ? this.clickedStep = event[1].name : this.clickedStep = "";
          this.setFunnelStatus(event);
          break;
        case "status":
          this.subStatusRefreshKey++;
          this.clickedSubStatus = "";
          event.length ? this.clickedStatus = event[1].name: this.clickedStatus = "";
          this.setFunnelSubStatus(event);
          break;
        case "subStatus":
          event.length ? this.clickedSubStatus = event[1].name: this.clickedSubStatus = "";
          break;
        case "locations":
          this.updateSelectedLocations(event);
          this.selectedLocations = event
          break;
        case "search":
          if (this.selectedLocations.length === 0) {
            this.selectAllLocationsFlag=true;
          } else {
            this.selectAllLocationsFlag=false;
          }
          this.newOnboardingCandidateView();
          break;
        default:
          break;
      }
      this.resetPaginator();
    },
    selectCandidate() {
      this.allCandidates = false;
      if (this.singleChecks.length > 0) {
        this.disablebtn = false;
      } else {
        this.disablebtn = true;
      }
      if (this.bulkActionPartners.length == this.singleChecks.length) {
        this.allCandidates = true;
      }
      this.bulkActionCount = this.singleChecks.length;
    },
    createBulkObj(data) {
      this.bulkActionPartners = data.map((partner) => {
        return {
          name: `${partner.firstName} ${partner.lastName}`.trim(),
          email: partner.email,
          phone: partner.phone ? partner.phone : "",
          locations: [partner.location],
          partnerID: partner.partnerID,
          userID: this.getSelectedEmployee.id,
          externalID: partner.externalID ? partner.externalID : null,
        };
      });
      this.isLoading = false;
    },
    sendMessage() {
      this.displayEmailEditor = true;
      this.candidateObj = [];
      this.bulkActionPartners.forEach((item) => {
        if (this.singleChecks.includes(item.partnerID)) {
          let customObj = {
            name: item?.name ? item.name : "",
            email: item?.email ? item.email : "",
            partnerID: item?.partnerID ? item.partnerID : "",
            externalID: item?.externalID ? item.externalID : "",
            phone: item?.phone ? item.phone : "",
            userID: item?.userID ? item.userID : "",
          };
          this.candidateObj.push(customObj);
        }
      });
      document.body.classList.add("no-scroll");
      this.showFlyout("bulk-comms-flyout");
    },
    wOpenCommsFlyout(type) {
    if(this.selectedPartners.length>0 || this.wSelectAllPages) {
      this.commsFlyoutType =type;
      this.displayEmailEditor = true;
      this.candidateObj = [];
      let filtered=[]
      if(this.wSelectAllPages){
        filtered  = this.filteredCandidates;;
      }
      else{
        filtered  = this.selectedCandidatesList;
      }
      filtered.forEach((item)=>{
          let obj = {
            name: item.name,
            partnerID: item.partnerID,
          }
          this.candidateObj.push(obj)
      })
      this.showFlyout('bulk-comms-flyout')
      } else {
       this.errorToast();
      }
      
    },
    sendEmail(event) {
      let payload={};
      if(this.wSelectAllPages){
      payload = {
        html :event.html,
        subject: event.subject,
        action: 'EMAIL',
        userID: this.getUserID,
        filterData: JSON.parse(window.atob(this.candidateCountPayload())),
        type: 'CANDIDATE',
        sendCommForAllPages:true
      }
    }
    else{
      const candidatesSend = this.selectedCandidatesList;
      candidatesSend.forEach((item)=>{
      let obj = {
            email: item?.email ? item.email : "",
            partnerID: item?.partnerID ? item.partnerID : "",
            name:item?.name ? item.name : "",
            phone:item?.phone ? item.phone : "",
            userid:item?.userID ? item.userID : ""
          }
          this.commstoselected.push(obj)
        })
      payload = {
        html :event.html,
        subject: event.subject,
        action: 'EMAIL',
        userID: this.getUserID,
        filterData: this.commstoselected,//JSON.parse(window.atob(this.candidateCountPayload())),
        type: 'CANDIDATE',
        sendCommForAllPages:false
      }
    }
      restApi.post("/onboard/bulkAction",encodeWithParam(payload)).then((data)=>{
        this.successToast(data)
      })
      this.displayEmailEditor = false;
    },
    deleteBulkCandidates() {
      const payload = {
        action: 'DELETE',
        type: 'CANDIDATE'
      }
      restApi.post("/onboard/bulkAction",encodeWithParam(payload)).then((data)=>{
        console.log(data);
        micromodal.close("bulk-delete-candidate-modal");
        this.newOnboardingCandidateView();
      })
    },
    sendSMS(event) {
      let payload={};
      if(this.wSelectAllPages){
        payload = {
          body: event.body,
          sendDate: event.sendDate,
          action:'SMS',
          userID:this.getUserID,
          filterData: JSON.parse(window.atob(this.candidateCountPayload())),
          type: 'CANDIDATE',
          sendCommForAllPages:true
      }
    }
    else{
      const candidatesSendSMS = this.selectedCandidatesList;
      candidatesSendSMS.forEach((item)=>{
          let obj = {
            email: item?.email ? item.email : "",
            partnerID: item?.partnerID ? item.partnerID : "",
            name:item?.name ? item.name : "",
            phone:item?.phone ? item.phone : "",
            userid:item?.userID ? item.userID : ""
          }
          this.commstoselected.push(obj)
        })
        payload = {
        body: event.body,
        sendDate: event.sendDate,
        action:'SMS',
        userID:this.getUserID,
        filterData: this.commstoselected,//JSON.parse(window.atob(this.candidateCountPayload())),
        type: 'CANDIDATE',
        sendCommForAllPages:false
      }
    }
      restApi.post("/onboard/bulkAction",encodeWithParam(payload)).then((data)=>{
        this.successToast(data)
      })
      this.displayEmailEditor = false;
    },
    wRunMvr() {
      if(this.wSelectAllCandidates) {
        const payload = {
        action: 'MVR',
        type:'CANDIDATE',
        filterData: JSON.parse(window.atob(this.candidateCountPayload()))
      }
      restApi.post("/onboard/bulkAction",encodeWithParam(payload)).then((data)=>{
        this.successToast(data)
        this.newOnboardingCandidateView();
      })
      } else {
       this.errorToast();
      }    
    },
    successToast(data) {
      this.displayToast=true;
      this.toastSeverity="success";
      this.toastMessage = data.data.message;
      this.wSelectAllPartners = false;
    },
    errorToast() {
      this.toastMessage="Click on select all "
      this.displayToast = true;
      this.toastSeverity="error"
    },
    closeBulkComms() {
      this.candidateObj = [];
      this.wSelectAllCandidates = false;
      this.selectedPartners=[],
      this.selectedCandidatesList=[],
      this.commstoselected=[]
      this.showFlyout("");
    },
    closeComms() {
      this.perPage = 50;
    },
    refresh() {
      this.perPage = 50;
      this.newOnboardingCandidateView();
    },
    async approvedCount(stageCounts) {
      const data = await restApi.get(`/location/getPendingHCR`)
      let count = stageCounts.filter((item)=>item.weekly === data.data.pendingHCR[0]?.startDate);
      return count.length > 0 ? Number(count[0].count) : 0
    },
    
    getRecruitingStartDate (day) {
        var currentDate = new Date();
        var startDate = 1;
        switch (day) {
            case "MONDAY":
                startDate = 6;
                break;
            case "TUESDAY":
                startDate = 5;
                break;
            case "WEDNESDAY":
                startDate = 4;
                break;
            case "THURSDAY":
                startDate = 3;
                break;
            case "FRIDAY":
                startDate = 2;
                break;
            case "SATURDAY":
                startDate = 1;
                break;
            case "SUNDAY":
                startDate = 0;
                break;
        }
        return new Date(currentDate.setDate(currentDate.getDate() - (currentDate.getDay() + startDate) % 7)).toISOString().split("T")[0];
    },
    async totalApprovedCount(totalCandidates) {
      const data = await restApi.get(`/location/getPendingHCR`)
      const approvedCandidates = totalCandidates.filter((item) => item.startDate === data.data.pendingHCR[0]?.startDate)
      return approvedCandidates && approvedCandidates?.length > 0 ? approvedCandidates[0].count : 0;
    },
    deleteCandidate(parnerId) {
      this.deleteCandidateId = "";
      this.deleteCandidateId = parnerId;
      micromodal.show("delete-candidate-modal", {});
    },
    confirmationModal() {
      this.deletingLoader=false;
      restApi.post("/onboard/deletePartner",encodeWithParam({partnerID:this.deleteCandidateId})).then(() => {
        this.deletingLoader=true;
        this.newOnboardingCandidateView();
        micromodal.close("delete-candidate-modal");
      });
    },
  selectAllLocations(event){
    if(event.target.checked){
      this.totalCount=0;
      this.selectAllLocationsFlag=true;
      this.newOnboardingCandidateView(); 
    }
    if(!event.target.checked){
      this.totalCount=0;
      this.selectAllLocationsFlag=false;
      this.newOnboardingCandidateView();
    }
  },
  getFilteredLocations(){
    let locations=[];
    if(this.selectAllLocationsFlag){
      locations=this.getAllLocationsData;
    }
    else{
      locations=this.getLocationFilterData;
    }
    return locations;
  }, 
  searchMatch(filter){
    let searchData="";
    let isPhone = /^[0-9]*$/;
    let isEmail = /\S+@\S+\.\S+/;
    if(filter === "email"){
      if(isEmail.test(this.searchTerm)){
        searchData=this.searchTerm;
      }
      else{
        searchData = "";
      }
    }
    if(filter === "phone"){
      if(isPhone.test(this.searchTerm)){
        searchData=this.searchTerm;
      }
      else{
        searchData = "";
      }
    }
    if(filter === "fullName"){
    if(!isEmail.test(this.searchTerm) && !isPhone.test(this.searchTerm)){
      
      searchData=this.searchTerm
    }
    else{
      searchData = "";
    }
    }
     return searchData
  },
  stepsPayload() {
    if(this.selectedStage && this.clickedStep) {
      return [this.clickedStep]
    } else if(this.selectedStage) {
      return this.fp.getStepsByStage(this.selectedStage).map((item)=>item.name)
    } else {
      return this.fp.getAllSteps().map((item)=>item.name)
    }
  },
  statusPayload() {
    if(this.selectedStage && this.clickedStep && this.clickedStatus) {
      return [this.clickedStatus]
    } else {
      let statuses = this.selectedStage && this.clickedStep ? this.fp.getStatusByStep(this.clickedStep) : this.fp.getAllStatus();
      return statuses.filter((item)=> !["MVR Denied", "CBC Denied"].includes(item.name)).map(item => item.name);
    }

  },
  candidatePayload(){
    if(this.searchTerm !== ""){
      this.resetPaginator();
    }
    let data={
        userID: this.getSelectedEmployee.id,
        locationIds:this.getFilteredLocations(),
        stages: this.selectedStage ? [this.selectedStage ] : this.fp.getAllStageNames(),
        steps:  this.stepsPayload(),
        status:this.statusPayload(),
        subStatus: this.clickedSubStatus?.trim().length ? this.clickedSubStatus : null,
        phone:this.searchMatch("phone"),
        email:this.searchMatch("email"),
        fullName:this.searchMatch("fullName"),
        limit: this.perPage,
        offset: this.offset,
    }
      let payload=JSON.stringify(data)
      return window.btoa(payload)
    },
    candidateCountPayload(){
      let data={
          userID: this.getSelectedEmployee.id,
          locationIds:this.getFilteredLocations(),
          stages:this.selectedStage ? [this.selectedStage ] : this.fp.getAllStageNames(),
          steps: this.stepsPayload(),
          status:this.statusPayload(),
          subStatus: this.clickedSubStatus?.length ? this.clickedSubStatus : null,
          phone:this.searchMatch("phone"),
          email:this.searchMatch("email"),
          fullName:this.searchMatch("fullName"),
          limit:0,
          offset: 0
      }
      let payload=JSON.stringify(data)
      return window.btoa(payload)
    },
    cancelOnboardingAggregate() {
      if(this.cancelCandidateViewSource) {
        this.cancelCandidateViewSource.cancel('cancel previous call');
      }
    },
    async newOnboardingCandidateView(){
      this.lastSearchedStatus = this.clickedStatus;
      this.onboardingCandidateView=[];
      this.onboardingCandidateViewLoading=true;
      this.cancelOnboardingAggregate();
      this.cancelCandidateViewSource = axios.CancelToken.source();
      
      restApi.post(`/employee/onboardingCandidateViewCount`,{"param":this.candidateCountPayload()},
        {cancelToken:this.cancelCandidateViewSource.token}).then((data)=>{
          if (!["Waitlist Not Eligible", "Application Waitlist"].includes(this.clickedStatus)) {
            this.totalCount=Number(data?.data?.result[0]?.total_count);
          }
          if (((this.getLocationFilterData.length > 0 || this.selectAllLocationsFlag) && this.totalCount) || ["Waitlist Not Eligible", "Application Waitlist"].includes(this.clickedStatus)) {
            restApi.post(`/employee/onboardingCandidateViewV2`,{"param":this.candidatePayload()},
            {cancelToken:this.cancelCandidateViewSource.token}).then((data)=>{
            this.cancelCandidateViewSource= null;
            this.onboardingCandidateView = data?.data?.result;
            this.onboardingCandidateViewLoading=false;
            this.totalCount = ["Waitlist Not Eligible", "Application Waitlist"].includes(this.clickedStatus) ? data?.data.rowCount : this.totalCount;
            });
          } else {
            this.onboardingCandidateView=[];
            this.onboardingCandidateViewLoading=false;
          }
          
      })
    },
    convertDate(date){
      let fullDate=date.split('T');
      return fullDate[0]
    },
    setFunnelSteps(event) {
      if(event.length) {
        this.selectedStage = event[1].name;
        this.selectedSteps = this.fp.getStepsByStage(this.selectedStage);
      } else {
        this.selectedStage ="";
        this.selectedSteps = []
      }
    },
    setFunnelStatus(event) {
      if(event.length) {
        this.selectedStep = event[1].name;
        this.selectedStatus = this.fp.getStatusByStep(this.selectedStep);
      } else {
        this.selectedStep = "";
        this.selectedStatus =[];
      }
    },
    setFunnelSubStatus(event) {
      if(event.length) {
        this.selectedSubStatus = this.fp.getSubStatusByStatus(this.clickedStatus);
      } 
      else {
        this.selectedSubStatus = null;
      }
    },
    

    selectAllPartners() {
    
      if (this.wSelectAllCandidates) {
        this.wSelectAllPages=false
        this.selectedPartners = [];
        this.selectedCandidatesList=[];
        this.selectedPartners = this.filteredCandidates.map(partner => partner.id);
        this.selectedCandidatesList=this.filteredCandidates.slice();
      } else {
        this.selectedPartners = [];
        this.selectedCandidatesList=[];
      }
    },

    selectAllPages(){
      this.wSelectAllCandidates=false
      this.selectedPartners = [];
      this.selectedCandidatesList=[];
    },

    multiSelect(val, e){
      if(e.target.checked){
            if (val.id != this.selectedPartners.map((item) => item.id) && this.selectedPartners.length > 0) {
                this.selectedPartners.push(val.id)
                this.selectedCandidatesList.push(val);
                this.wSelectAllCandidates = this.selectedPartners.length === this.filteredCandidates.length;
                
            }
            else{
              this.selectedPartners.push(val.id)
              this.selectedCandidatesList.push(val);
              this.wSelectAllCandidates = this.selectedPartners.length === this.filteredCandidates.length;
            }
      }
      else{
        let index=this.selectedPartners.indexOf(val.id);
        this.selectedPartners.splice(index, 1)
        this.selectedCandidatesList.splice(index, 1)
        this.wSelectAllCandidates = this.selectedPartners.length === this.filteredCandidates.length;
      }
    },
  },
  
};
</script>
<style scoped>
.toast {
  width: 40%;
  margin: -18px auto 0 auto;
}
.p-message.p-message-error {
  background: #c2272c;
  color: #ffffff;
}
.p-message.p-message-success {
  background: #2A8703;
  color: #ffffff;
}
.inherit-flex {
  flex: inherit !important;
}
.paginator-align {
  margin-bottom: -40px !important;
}
.checkbox-input-deposit{
  width: 16px;
    cursor: pointer;
    height: 15px;
    margin-top: 2px;
}
.show-count{
  display: flex
}
.checkAlign{
  margin-top: 7% !important;
  margin-left: 0% !important;
}
</style>
