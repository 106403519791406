<template>
  <div id="image-viewer" class="modal" aria-hidden="true">
    <div class="modal-window_overlay" tabindex="-1" @click="closeModal">
      <div class="modal-window_container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby" @click.stop>
        <header class="modal-header">
          <div class="close-modal close-modal-header" aria-label="Close modal">
          </div>
        </header>
        <div class="modal-content">
          <div class="closeBut">
            <span :class="['xbutton']" @click="closeModal">
              <span class="icon-x"></span>
            </span>
          </div>
          <slot>
            <div>&nbsp;</div>
            <div class='image-container'>
              <span v-if="isLoading" class="loading">
                <Loader />
              </span>
              <div v-else>
                <div v-if="isError">
                  Error while fetching the file. Please Retry!.
                </div>
                <div v-else>
                  <div v-if="!isPDF && insuranceFileUrl">
                    <img :src="insuranceFileUrl" alt="Image Preview" />
                  </div>
                  <div v-else-if="isPDF && insuranceFileUrl">
                    <vue-pdf-embed :source="insuranceFileUrl" />
                  </div>
                  <div v-else>
                    File Not Found
                  </div>
                </div>
              </div>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/filters";
import { convertBufferToContent } from "@/util/BufferContentConverter";
import { Loader } from "@/components";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import { isPDFFile } from "../../util/validator";

export default {
  name: "ImageViewer",
  components: { 
    Loader, 
    VuePdfEmbed 
  },
  props: {
    fileId: {
      type: String,
      required: true,
      default: () => { },
    },
  },
  data: function () {
    return {
      isPDF : false,
      isLoading: true,
      insuranceFileUrl: null,
      isError:false
    };
  },
  watch: {
    fileId: {
      immediate: true,
      handler: function (newVal) {
        this.insuranceFileUrl = null;
        newVal && this.getInsuranceData()
      }
    },
  },
  methods: {
    async getInsuranceData() {
      this.isLoading = true;
      try {
        let data = await restApi.post('/employee/getUploadedFile', encodeWithParam({ fileID: this.$props.fileId }));
        this.isLoading = false;
        if(!data.data.success){
          this.isError = true;
          return;
        };
        this.isPDF = isPDFFile(data?.data?.fileName);
        this.insuranceFileUrl = convertBufferToContent(data.data.bufferInHex, data.data.contentType);
        
      } catch (err) {
        this.isLoading = false;
        this.isError = true;
      }   
    },
    resetData() {
      this.isError = false;
      this.isLoading = true;
      this.insuranceFileUrl = null;
    },
    closeModal() {
      this.resetData();
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.modal-window_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0000007a;
  backdrop-filter: blur(10px);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  /* CHANGED */
  align-items: center;
  -webkit-align-items: center;
  overflow: hidden;
  z-index: 40;
}

.modal-window_container {
  background-color: #fff;
  padding: 0 16px 16px 16px;
  width: fit-content;
  max-width: 700px;
  max-height: 100%;
  /* CHANGED */
  border-radius: 0px 0px 0px 0px;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;
  font-family: 'Open Sans';
}

.image-container {
  max-height: 500px;
  max-width: 700px;
  overflow: auto;
  min-height: 100px;
  min-width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.xbutton {
  position: absolute;
  right: 11px;
  top: 11px;
  cursor: pointer;
}
</style>
