<template>
  <main class="empty-area">
    <div class="login" v-if="showLoginContent">
      <div class="content grid">
        <div class="login-area col-5">
          <transition name="fade">
            <div v-if="!forgot" class="login-form">
              <h2 class="text-center">Login</h2>
              <div class="module-large">
                <form @submit="login">
                  <div v-if="authenticationFailed" class="error-message">{{errorMsg}}</div>
                  <div class="field">
                    <label class="field-label">
                      Username
                      <input v-model="credentials.username" name="username" autocomplete="username" />
                    </label>
                  </div>
                  <div class="field">
                    <label class="field-label">
                      Password
                      <input v-model="credentials.password" name="password" required type="password" autocomplete="current-password" @keyup.enter="login" />
                    </label>
                  </div>
                </form>
              </div>
              <div class="module-large">

                <button v-if="loginLoading"  class="loginBtn button block" :disabled="true"><vue-simple-spinner :size="16" /></button>
                <button v-else  class="loginBtn button block" :disabled="disabledLogin" @click="login">Login</button>
              </div>
              <div class="grid">
                <div class="col-6">
                  <button class="button ghost" :style="{ padding: '0px' }" @click="employeeLogin">Employee Login</button>
                </div>
                <div class="col-6 text-right">
                  <button class="button ghost" :style="{ padding: '0px' }" @click="forgotPassword">Forgot Password</button>
                </div>
              </div>
            </div>
          </transition>
          <transition name="fade">
            <ForgotPassword v-if="forgot" @click="forgotPassword" />
          </transition>
        </div>
        <div class="logo-display col-7">
          <img src="@/assets/images/logos/walmart-logo.svg" alt="" />
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import authenticationService from "@/services/authentication.service";
import { AUTH_SERVER } from "@/modules/login/constants";
import ForgotPassword from "@/modules/login/ForgotPassword";
import VueSimpleSpinner from "vue-simple-spinner";


export default {
  name: "Login",
  components: {  ForgotPassword,VueSimpleSpinner },
  data: () => {
    return {
      forgot: false,
      loginLoading:false,
      credentials: {
        username: "",
        password: "",
      },
      authenticationFailed: false,
      resetPasswordError: "",
      employeeLoginUrl: `${AUTH_SERVER}/authenticate/saml`,
      errorMsg:""
    };
  },
  
  computed: {
    disabledLogin() {
      return !(this.credentials.username.length > 0 && this.credentials.password.length > 0);
    },
    showLoginContent() {
      return config.VUE_APP_ENV === 'local'
    }
  },
  mounted() {
    if (!this.showLoginContent) this.employeeLogin();
  },
  methods: {
    employeeLogin() {
      window.location.href = this.employeeLoginUrl;
    },
    async login() {
      this.loginLoading = true
      this.authenticationFailed = false;
      this.credentials.username = this.credentials.username.toLowerCase();
      let data= await authenticationService.login(this.credentials)
      if(data.data.success) {
        await this.$store.dispatch("userLogin");
        await this.$store.dispatch("fetchPermissions");
        this.loginLoading = false
        const toName = this.$router.redirectedFrom ? this.$router.redirectedFrom : authenticationService.homePageForLoggedInUser();
        return this.$router.push({ name: toName });
      }
      else{
        if(data.data.forgotPassword){
          this.forgotPassword()
        }
        this.credentials.password = "";
        this.authenticationFailed = true;
        this.errorMsg=data.data.message
        this.loginLoading = false
      }
      this.loginLoading = false;
    },
    forgotPassword() {
      this.forgot = !this.forgot;
      // if (this.credentials.username.length > 0) {
      //   await authenticationService.requestPasswordReset(this.credentials.username);
      // } else {
      //   this.resetPasswordError = "Username required";
      // }
    },
  },
};
</script>
