<template>
  <flyout name="errorlog-flyout" :title="'ErrorLog Detail - ' + errorLog.category" full-screen :overlay="false">
    <template #header>
      <sticky-header title="ErrorLog Detail" classes = "margin-zero">
        <div class="errorlog-paginator" :load-data="loadData">
          <!-- <paginator always-show :per-page="1" :sync-page="selectedIndex" :total="total" @onPaginate="onPaginate($event)" />
          <span>{{errorLog.category}}</span> -->
        </div>
        <div class="button-group">
          <button class="button secondary" @click="done">Done</button>
        </div>
      </sticky-header>
    </template>
    <main>
      <div class="container">
        <div class="content">
          <div class="grid module-large">
            <div class="col-9" :class="[errorLog.level]">
              <label>{{ errorLog.type }}</label>
              <p>{{ errorLog.message }}</p>
            </div>
            <div class="col-3">
              <label>Event Time</label>
              <p>{{ errorLog.eventTime | formatDateTimePacific }}</p>
            </div>
            <div class="col-9">
              <label>Server</label>
              <p>{{ errorLog.server }}</p>
            </div>
            <div class="col-3">
              <label>Status</label>
              <p>{{ errorLog.status }}</p>
            </div>
            <div class="col-12">
              <label>Context</label>
              <pre>{{ errorLog.context }}</pre>
            </div>
            <div class="col-12">
              <label>Stacktrace</label>
              <p><pre>{{errorLog.stacktrace}}</pre></p>
            </div>
          </div>
        </div>
      </div>
    </main>
  </flyout>
</template>
<script>
import { Flyout, StickyHeader } from "@/components"; // , Paginator
// import { FIND_ERROR_LOG } from "@/modules/admin/ddi/graph/queries";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";

export default {
  name: "ErrorLogFlyout",
  components: {Flyout, StickyHeader }, // Paginator, 
  props: {
    errorLogId: {
      type: String,
      required: true,
    },
    singleLog:{
      type: String,
      required: false,
      default: "",
    },
    selectedIndex: {
      type: Number,
      required: true
    },
    onClose: {
      type: Function,
      required: false,
      default: () => {},
    },
    total: {
      type: Number,
      required: false,
      default: 1
    }
  },
  data: () => ({
    errorLog: {},
    page: 1
  }),
  computed: {
    loadData() {
      if(this.$props.singleLog) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.errorLog = JSON.parse(this.$props.singleLog);  
      }
      return 0;
    }
  },
  methods: {
    done() {
      this.$props.onClose();
    },
    onPaginate(event) {
      this.$emit("onPage", {...event, selectedIndex: this.$props.selectedIndex});
    },
    async SingleErrorLog(){
      if(this.$props.errorLogId) {
        await restApi.post(`/comm/FilteredErrorLogs`, encodeWithParam({ errorLogID: this.$props.errorLogId})).then((responseData)=>{
          // console.log(responseData.data.data);
          this.errorLog = responseData?.data?.data;
        });
      }
    },
  },
  // apollo: {
  //   errorLog: {
  //     query: FIND_ERROR_LOG,
  //     variables() {
  //       return {
  //         errorLogID: this.$props.errorLogId,
  //       };
  //     },
  //     update: (data) => data.errorLog,
  //     skip() {
  //       return !this.$props.errorLogId;
  //     },
  //   },
  // },
};
</script>
