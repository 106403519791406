<template>
    <div class="vue-simple-spinner" :style="{ height: radius, width: radius }">
    </div>
</template>

<script>
export default {
    name: "CustomSpinLoader",
    props: {
        radius: {
            type: String,
            required: false,
            default: "32px",
        },
    },
}
</script>

<style scoped>
.vue-simple-spinner {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    border-width: 3px;
    border-style: solid;
    border-color: rgb(33, 150, 243) rgb(238, 238, 238) rgb(238, 238, 238);
    border-image: initial;
    animation: 0.8s linear 0s infinite normal none running vue-simple-spinner-spin;
    transition: all .3s linear;
}
@keyframes vue-simple-spinner-spin {
   0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}
</style>
  