<template>
    <base-modal :id="reconStatusModalName">
        <div>Invoice Date: {{ formatDate(invoiceDate) }}</div>
        <div class="option-cont">
            <label for="status">Status:</label>
            <select v-model="currentStatus" class="status-drop-down" name="status">
                <option>ADJUSTED</option>
                <option>PENDING</option>
                <option>RECONCILED</option>
            </select>
        </div>
        <label style="padding-left: 0px">Reason for changing the status</label>
        <label style="float: right;">(Mandatory)</label>
        <div class="notes-user-message">
          <textarea class="notes-display" name="message" cols="2" rows="2" v-model="remark"></textarea>
        </div>
        <div v-if="showError" class="error-message">{{ errorMessage }}</div>
        <div class="button-group bg-margin">
            <button class="button primary" :disabled="enableSubmit" @click="updateData">Save</button>
            <button class="button secondary" @click="closeModal(null)">Cancel</button>
        </div>
    </base-modal>
  </template>
  
  <script>
    import BaseModal from "@/components/modals/BaseModal";
    import micromodal from "micromodal";
    import { reconciliationApi } from "../../../../http/http.config";
    import { encodeWithParam } from "@/filters";
  
    export default {
      name: "ReconciliationStatusModal",
      components: { BaseModal },
      props: {
        reconStatusModalName: {
          type: String,
          required: false,
          default: "reconStatusModalName",
        },
        type: {
          type: String,
          required: false,
          default: "settlement-view",
        },
        rowItem: {
          type: Object,
          required: false,
          default: () => { },
        },
      },
      data() {
        return {
          currentStatus:"",
          remark:"",
          errorMessage:"",
          showError:false
        };
      },
      watch:{
        rowItem(val){
          this.currentStatus = val.status;
          this.remark = val.remarks;
        }
      },
      computed: {
        viewType() {
          return this.$props.type;
        },
        disbursementDate() {
          return this.$props.rowItem?.disbursement_date;
        },
        invoiceDate() {
          return this.$props.rowItem?.invoice_date_pst;
        },
        enableSubmit(){
          return !(this.remark?.toString().length > 50);
        }
      },
      
      methods: {
        formatDate(date) {
          if (date) {
            const [year, month, day] = date.substr(0, 10).split("-");
            return [month, day, year].join("/");
          }
          return date;
        },
       
        async updateData(){
          try {
            let result = await reconciliationApi.post("/setReconInvoiceStatus", encodeWithParam({ view:this.$props.type,id:this.$props.rowItem?.id,status:this.currentStatus,remark:this.remark, provider:this.$props.rowItem?.provider }));
            if(result.data.success){
              this.showError = false;
              this.errorMessage = '';
              this.closeModal({ view:this.$props.type,id:this.$props.rowItem?.id,status:this.currentStatus,remark:this.remark });
            }else{
              this.showError = true;
              this.errorMessage = result.data.message;
            }
          } catch (err) {
              this.showError = true;
              this.errorMessage = err.message;
          }
        },
        closeModal(data){
          this.showError = false;
          this.errorMessage = "";
          micromodal.close(this.$props.reconStatusModalName);
          this.$emit('close',data);
          document.body.classList.remove("no-scroll");
        }
      },
    };
  </script>
  
  <style scoped>
    .option-cont{
        display: flex;
        align-items: center;
        margin: 15px 0;
    }
    .bg-margin{
      margin-top: 30px;
    }
    .status-drop-down{
        width: 200px;
        margin-left: 10px;
    }
  </style>