<template>
  <span>
    <div class="grid">
      <div class="col-6">
        <h2>{{ totalCount }} Total Zones</h2>
        <h3>{{ totalHCR }} Requesting Additional Drivers</h3>
      </div>
      <div class="col-6 btn-container">
        <button v-if="getRole === 'LOCATION_MANAGER'" :disabled="disableEdit" class="button secondary" @click="openEditZone">Edit Zones</button>
        <router-link v-if="getPermission('addZone')" class="button secondary" :to="{ name: 'addClientLocation' }">Add Zone</router-link>
        <button v-if="getPermission('uploadHCR')" class="button secondary" @click="openUploadModal">Upload Partner Requests</button>
        <button v-if="getPermission('uploadHCR')" class="button secondary" @click="openUploadThresholdModal">Upload Thresholds</button>
      </div>

      <!-- <div class="col-12">
        <p class="label">Filters</p>
        <base-filter name="Status" type="radio" :options="statusFilterOptions" @onFilter="filtersAppliedStatuses($event)" :filterToggleOn="true"/>
      </div> -->
      <div class="search-cont">
        <div class="search-container">
          <input v-model="searchTerm" class="deposit-search" type="search" placeholder="Search Location Name" />
          <button type="submit"><span v-if="searchIcon" class="icon-x deposit-icon" @click="resetSearch"></span> <span v-else class="icon-search deposit-icon"></span></button>
        </div>
        <button class="button secondary" :disabled="!searchIcon" @click="searchLocations">Search</button>
      </div>
    </div>
    <data-table :headers="headers" 
      :entries="locationsList" 
      :loading="loader"
      :pagination-total-count="totalCount"
      :pagination-per-page="perPage"
      :pagination-current-page="currentPage"
      :display-rows-per-page-max-selector= "isSelector" 
      @onChangePerPage="onChangePerPage($event)"
      @onPaginate="onPaginate($event)"
      table-id="clientPartnerRequestTable"> 
      <template #location="{ item }">
        <a @click="goToLocationHeadcountRequest(item)">{{ item.name }}</a>
      </template>
      <template #select="{ item }" >
        <div class="flex">
          <input type="checkbox" v-bind:checked="item.selected" @click="zoneSelect(item, $event)" class="checkbox-input-zone" >
        </div>
      </template>
      <template #funnelStatus="{ item }" >
        <div class="flex">
          {{getFunnelStatus(item).status ? 'On' : 'Off'}}
        </div>
      </template>
        <!-- <template #progress="{ item }">
        <location-progress-cell :location="item" :requested="requestedCount(item)" :approved="approvedCount(item)" :week-filter="false" />
      </template> -->
      <template #threshold="{ item }" >
        <div class="flex">
          {{item.threshold}}
        </div>
      </template>
      <template #edit="{item}" >
        <div class="flex">
          <img @click="updateStatus(item)" src="@/assets/images/logos/fi_edit.png" :class="disableEdit ?  '' : 'edit-disabled'"/>
        </div>
      </template>
      <template #thisWeek="{ item,rowIndex }">
        <span v-if="!item.activeRequests[0].hovering && !item.activeRequests[0].editing" style="cursor: pointer" @mouseover="hover(item, item.activeRequests[0])">
          {{ item.activeRequests[0].count }}
          
          <tooltip
            v-if="item.activeRequests[0].locked"
            id="locked-tooltip"
            title="Locked"
            description="Once a week has been locked, you may not edit the head count request again."
            hover
            icon-class="red icon icon-lock"
          />
        </span>
        <div v-else class="spread-number">
          <input
            :ref="`${item.activeRequests[0].key}-${rowIndex}`"
            :value="item.activeRequests[0].count"
            :name="item.activeRequests[0].key"
            type="number"
            min="1"
            max="10"
            @keyup.enter="submit(item, item.activeRequests[0], rowIndex)"
            @keydown.tab="focusNextField(item, item.activeRequests[0].key, rowIndex, $event)"
            @focus="markAsEditing(item, item.activeRequests[0], rowIndex, $event)"
            @mouseleave="stopHover(item, item.activeRequests[0])"
          /></div>
      </template>
      <template v-if="existingHCR[1]" #nextWeek="{ item, rowIndex }">
        <span v-if="!item.activeRequests[1].hovering && !item.activeRequests[1].editing" style="cursor: pointer" @mouseover="hover(item, item.activeRequests[1])">
          {{ item.activeRequests[1].count }}
        </span>
        <div v-else class="spread-number">
          <input
            :ref="`${item.activeRequests[1].key}-${rowIndex}`"
            :value="item.activeRequests[1].count"
            :name="item.activeRequests[1].key"
            type="number"
            min="1"
            max="10"
            @keyup.enter="submit(item, item.activeRequests[1], rowIndex)"
            @keydown.tab="focusNextField(item, item.activeRequests[1].key, rowIndex, $event)"
            @focus="markAsEditing(item, item.activeRequests[1], rowIndex, $event)"
            @mouseleave="stopHover(item, item.activeRequests[1])"
          />
        </div>
      </template>
    </data-table>
    <funnel-status-modal :locationInfo="locationInfo" @cancel="fetch" @save="save"/>
    <upload-partner-request-modal :client-i-d="clientID" @refresh="fetch" />
    <upload-threshold-request-modal :client-i-d="clientID" @refresh="fetch" />
    <edit-zones-modal :edit-zones = "editZonesData" @cancel="fetch" @modified="saveMultipleVals"/>
  </span>
</template>
<script>
import { DataTable, TableHeader,
 // Direction,BaseFilter
 } from "@/components";
import { DateTime } from "luxon";
import Tooltip from "@/components/popups/Tooltip";
import { mapGetters } from "vuex";
import UploadPartnerRequestModal from "@/modules/admin/clients/components/UploadPartnerRequestModal";
import UploadThresholdRequestModal from "@/modules/admin/clients/components/UploadThresholdRequest";
import EditZonesModal from "@/modules/admin/clients/components/EditZonesModal";
import micromodal from "micromodal";
import debounce from "debounce";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";
import FunnelStatusModal from "@/modules/admin/clients/components/FunnelStatusModal";
// import LocationProgressCell from "@/modules/admin/clients/locations/LocationProgressCell";
// import {FunnelStages,FunnelSteps} from "@/util/funnelProgress";
export default {
  name: "PartnerRequests",
  title() {
    if (this.$route.hash === "#partnerRequests") {
      return `Partner Requests`;
    }
  },
  components: { Tooltip, 
    DataTable,
    UploadPartnerRequestModal, 
    UploadThresholdRequestModal,
    EditZonesModal,
    FunnelStatusModal,
    //LocationProgressCell,
    //BaseFilter
  },
  filters: {
    formatDate(date) {
      this.format(date);
    },
  },
  data: () => ({
    locations: [],
    inFocus: "",
    allowedRoles: ["LOCATION_MANAGER"],
    totalCount: 0,
    totalHCR: 0,
    searchTerm: "",
    currentPage: 1,
    perPage: 50,
    restorePerPage: 50,
    isSelector: true,
    offset: 0,
    searchedLocations: [],
    selectedFilters: [],
    loader: true,
    locationId: "",
    existingHCR: [],
    locationInfo: {},
    selectedZones: [],
    editZonesData: [],
    isChecked: false,
    disableEdit: true,
    // searchReset:false,
    // status:""
  }),
  computed: {
    ...mapGetters(["getUsername", "getRole", "getPermission"]),
    headers() {
        if(this.getRole === 'LOCATION_MANAGER'){
          if (this.existingHCR[1] !== undefined) {
          return [
        new TableHeader({ name: "select", label: "", cellContents: "select", headerClass: "cell2"}),
        new TableHeader({ name: "location", label: "Zone", cellContents: "name", headerClass: "cell20", sortable: true }),
        new TableHeader({ label: "Status", headerClass: "cell10", cellContents: "funnelStatus", name: "funnelStatus", sortable: true }),
        // new TableHeader({ label: "Progress", headerClass: "cell20", name: "progress", sortable: true,sortFunction: this.sortProgress ,sorted:true,direction: Direction.DESC}),
        new TableHeader({ label: "Threshold 1", headerClass: "cell10", cellContents: "threshold", name: "threshold", sortable: false }),
        new TableHeader({ name: "thisWeek", label: `Current HCR: ${this.thisWeek}`, headerClass: "cell15" }),
        new TableHeader({ name: "nextWeek", label: `Next HCR: ${this.nextWeek}`, headerClass: "cell15" }),   
        new TableHeader({ name: "edit", label: "", headerClass: "cell5" })    
      ];
          } else {
          return [
        new TableHeader({ name: "select", label: "", cellContents: "select", headerClass: "cell2"}),
        new TableHeader({ name: "location", label: "Zone", cellContents: "name", headerClass: "cell20", sortable: true }),
        new TableHeader({ label: "Status", headerClass: "cell10", cellContents: "funnelStatus", name: "funnelStatus", sortable: false }),
        // new TableHeader({ label: "Progress", headerClass: "cell20", name: "progress", sortable: true,sortFunction: this.sortProgress ,sorted:true,direction: Direction.DESC}),
        new TableHeader({ label: "Threshold", headerClass: "cell10", cellContents: "threshold", name: "threshold", sortable: false }),
        new TableHeader({ name: "thisWeek", label: `Current HCR: ${this.thisWeek}`, headerClass: "cell15" }),
        new TableHeader({ name: "edit", label: "", headerClass: "cell5" })    
        
      ];
          }
        }
        else{
          if (this.existingHCR[1] !== undefined) {
          return [
        new TableHeader({ name: "location", label: "Zone", cellContents: "name", headerClass: "cell20", sortable: true }),
        new TableHeader({ label: "Status", headerClass: "cell10", cellContents: "funnelStatus", name: "funnelStatus", sortable: false }),
        // new TableHeader({ label: "Progress", headerClass: "cell20", name: "progress", sortable: true,sortFunction: this.sortProgress ,sorted:true,direction: Direction.DESC}),
        new TableHeader({ label: "Threshold", headerClass: "cell10", cellContents: "threshold", name: "threshold", sortable: false }),
        new TableHeader({ name: "thisWeek", label: `Current HCR: ${this.thisWeek}`, headerClass: "cell15" }),
        new TableHeader({ name: "nextWeek", label: `Next HCR: ${this.nextWeek}`, headerClass: "cell15" }),     
      ];
          } else {
          return [
        new TableHeader({ name: "location", label: "Zone", cellContents: "name", headerClass: "cell20", sortable: true }),
        new TableHeader({ label: "Status", headerClass: "cell10", cellContents: "funnelStatus", name: "funnelStatus", sortable: false }),
        // new TableHeader({ label: "Progress", headerClass: "cell20", name: "progress", sortable: true,sortFunction: this.sortProgress ,sorted:true,direction: Direction.DESC}),
        new TableHeader({ label: "Threshold", headerClass: "cell10", cellContents: "threshold", name: "threshold", sortable: false }),
        new TableHeader({ name: "thisWeek", label: `Current HCR: ${this.thisWeek}`, headerClass: "cell15" }),
      ];
          }
        }
    },
    // statusFilterOptions() {
    //   return [
    //     { id: "true", name: "true", label: "ON", checked: false },
    //     { id: "false", name: "false", label: "OFF", checked: false},
    //   ];
    // },
    locationsWithRequests: function () {
      return 0;
        },
    
    startOfFirstRange() {
      return DateTime.fromISO(this.existingHCR[0]?.startDate)
    },
    endOfFirstRange() {
      return DateTime.fromISO(this.existingHCR[0]?.endDate)
    },
    startOfNextWeek() {
      return DateTime.fromISO(this.existingHCR[1]?.startDate)
    },
    endOfNextWeek() {
      return DateTime.fromISO(this.existingHCR[1]?.endDate)
    },
    thisWeek() {
      return `${this.format(this.startOfFirstRange)} - ${this.format(this.endOfFirstRange)}`;
    },
    nextWeek() {
      return `${this.format(this.startOfNextWeek)} - ${this.format(this.endOfNextWeek)}`;
    },
    clientID() {
      return config.CLIENT_ID;
    },
    activeWeeks() {
      return [
        { key: "thisWeek", startDate: this.startOfFirstRange, endDate: this.endOfFirstRange },
        { key: "nextweek", startDate: this.startOfNextWeek, endDate: this.endOfNextWeek },
      ];
    },
    ...mapGetters(["getRole"]),
    path() {
      return this.$route.path;
    },
    searchIcon() {
      return this.searchTerm !== "";
    },
    locationsList() {
      return this.locations;
    },
    filteredList: function () {
      const start = this.currentPage * this.perPage - this.perPage;
      const end = start + this.perPage - 1;
      const today = DateTime.utc(DateTime.utc().year, DateTime.utc().month, DateTime.utc().day);
      switch (this.selectedFilters[0]) {
        case "launching":
          return this.locations.filter((location) => new Date(location.startDate) > today).filter((item, index) => index >= start && index <= end);

        case "less than 30 days":
          return this.locations
            .filter((location) => today > new Date(location.startDate) && new Date(location.startDate) >= today.minus({ days: 30 }))
            .filter((item, index) => index >= start && index <= end);
        case "request made":
          return this.locations.filter((location) => location.isRecruiting && location.headCountRequests.length > 0).filter((item, index) => index >= start && index <= end);

        case "unfulfilled requests":
          return this.locations
            .filter((location) => location.headCountRequests.length > 0 && this.approvedCount(location) < location.headCountRequests.length)
            .filter((item, index) => index >= start && index <= end);

        default:
          return this.locations.filter((item, index) => index >= start && index <= end);
      }
    }
    
  },

  mounted(){
    this.fetch = debounce(this.fetch, 500);
    this.fetch();
  },
  methods: {
    // requestedCount(location) {
    //   return location?.activeRequests?.length > 0 ? location.activeRequests[0].count : 0;
    // },
    // approvedCount(location) {
    //    return location?.stageCounts?.filter((stageCount) => stageCount.stage === FunnelStages.APPLICATION && stageCount.step === FunnelSteps.APPLICATION_APPROVED)
    //    .reduce((start, next) => +start + +next.count, 0);
    // },
    // sortProgress(direction) {
    //   return (location1, location2) => {
    //     const notRecruitingSort = direction === Direction.ASC ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER;
    //     const location1Progress = location1.isRecruiting && this.requestedCount(location1) ? this.getHeadCountFulfilledPercent(this.approvedCount(location1), this.requestedCount(location1)) : notRecruitingSort;
    //     const location2Progress = location2.isRecruiting && this.requestedCount(location2) ? this.getHeadCountFulfilledPercent(this.approvedCount(location2), this.requestedCount(location2)) : notRecruitingSort;
    //     return location2Progress - location1Progress;
    //   };
    // },
    // getHeadCountFulfilledPercent(approved, requested) {
    //   return !requested || requested === 0 ? 100 : approved / requested / Math.pow(10, -2);
    // },
    // filtersAppliedStatuses(value) {
    //   if(value[0]=='on' || value[0]=='off'){
    //     this.status=value[0]
    //   }
    //   else{
    //     this.status=''
    //   }
    //   this.searchReset = true;
    //   this.resetPaginator();
    //   this.fetch();
    // },
    // resetPaginator() {
    //   this.currentPage = 1;
    //   this.offset = 0;
    // },
    onPaginate(event) {
      let definedPage = Number(event.pageNumber);
      this.offset = event.offset; 
      if (definedPage <= Math.ceil(this.totalCount / this.perPage) && definedPage > 0) {
        this.currentPage = Number(event.pageNumber);
        this.fetch();  
      }
    },
    onChangePerPage(event){
      if(this.perPage == 50 || this.perPage == 75 || this.perPage == 100)
        this.restorePerPage = this.perPage; 
      this.perPage = Number(event.perPage); 
      this.fetch();  
    },
    searchLocations() {
      this.fetch();  
    },
    zoneSelect(val, e){
      let index = this.locations.findIndex(item=>item.id === val.id)
      if (e.target.checked) {
        const exists = this.selectedZones.some(item => val.id === item.id);
        if (!exists) {
          const obj = {
            id: val.id,
            name: val.name,
            funnelStatus: val.funnel.onboardingFunnelStatus,
            threshold: val.threshold,
            HCR:val.activeRequests[0].count,
            // clrID:val.activeRequests[0].id,
            startDate:DateTime.fromISO(val.activeRequests[0].startDate).toFormat("yyyy-MM-dd"),//val.activeRequests[0].startDate,
            endDate:DateTime.fromISO(val.activeRequests[0].endDate).toFormat("yyyy-MM-dd")//val.activeRequests[0].endDate
          };
          if (val.activeRequests[0].id !== undefined && val.activeRequests[0].id !== null) 
          {
            obj.clrID = val.activeRequests[0].id;
          }
          else{
            obj.clrID="";
          }
          // this.$set(this.selectedZones, this.selectedZones.length, obj);
          this.locations[index].selected = true;
          this.selectedZones.push(obj)
        }
      } else {
        const pos = this.selectedZones.findIndex(item => val.id === item.id);
        if (pos !== -1) {
          this.selectedZones.splice(pos, 1);
        }
        this.locations[index].selected = false;
    }
    this.disableEdit = this.selectedZones.length ? false : true;
    },
    getFunnelStatus(item) {
      return { 
        status: item?.funnel?.onboardingFunnelStatus,
        disable: item.funnel ? false : true
      }
    },
    resetSearch() {
      this.searchTerm = "";
      this.fetch();  
    },
    
    timerDisplay(item) {
      if (item?.funnel?.funnelConfiguration != null) {
        const { snoozeTurnOffDate } = item?.funnel?.funnelConfiguration;
        const diffTime = new Date(snoozeTurnOffDate) - new Date();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays < 0 ? "" : `${diffDays} days status go ON`;
      } else if (!item.funnel) {
        return "No funnel";
      }
      return ""
    },
    async fetch() {
      this.disableEdit = true
      if(this.selectedZones.length){
          this.selectedZones = []
      }
      restApi.get(`/location/getPendingHCR`).then((data) => {
        this.existingHCR = data.data.pendingHCR
      })
      this.loader = true;
      if(this.searchTerm.trim() == '' && (this.perPage != 50 && this.perPage != 75 && this.perPage != 100)) {
        this.perPage = this.restorePerPage; 
        this.offset = 0;
      }
      const param = {
        searchTerm: this.searchTerm.trim() == '' ? '' : `%${this.searchTerm.trim().toLowerCase()}%`,
        clientID: config.CLIENT_ID,
        limit: this.perPage,
        offset: this.offset
      }
      // if(this.status=='on' || this.status=='off'){
      //   param.status = this.status;
      // }
      await restApi.post(`/location/getLocationsWithHCR`, encodeWithParam(param)).then((data)=>{
        if (data.data.success == false || (!data?.data?.client?.locations)) {
          this.loader = false;
          let error = {};
          error.showWarning = true;
          this.locations = [];
          this.totalCount = 0;
          this.totalHCR = 0;
          error.title = "No Locations";
          error.description = "Clients must contain at least one location";
          this.$emit("onDataLoaded", error);
        } else {
          this.totalCount = data?.data?.client?.total?.aggregate?.count ? data.data.client.total.aggregate.count : this.totalCount;
          this.totalHCR = data?.data?.client?.totalHCR ? data.data.client.totalHCR : this.totalHCR;
          const error = {
            showWarning: true,
          };
          if (data?.data?.client?.locations && data.data.client.locations.length > 0) {
            error.showWarning = false;
          } else {
            error.title = "No Locations";
            error.description = "Clients must contain at least one location";
          }
          this.$emit("onDataLoaded", error);
        
          this.locations = data.data.client.locations.map((location, currentIndex) => {
            if (location && location.headCountRequests) {
              const [requestKey, indexString] = this.inFocus ? this.inFocus.split("-") : [];
              const index = indexString ? parseInt(indexString) : -1;
              location.activeRequests = this.activeWeeks.map((week) => {
                if (week.key === "thisWeek") {
                  const local = DateTime.local().setZone("America/Los_Angeles");
                  const rezoned = local.setZone("America/Los_Angeles").set({ weekday: 6, hour: 0, minutes: 0, seconds: 0, millisecond: 0 });
                  const locked = rezoned < local;
                  week.locked = locked;
                }
                const findWeek = location.headCountRequests.findIndex((headCountRequest) => this.format(headCountRequest.startDate) === this.format(week.startDate));
                let editHover = {};
                if (week.key === requestKey && currentIndex === index) {
                  editHover = { editing: true, hovering: true };
                }
                if (findWeek >= 0) {
                  const request = location.headCountRequests[findWeek];
                  return { ...request, ...week, originalCount: request.count, ...editHover };
                }
                return { ...week, count: 0, originalCount: 0, ...editHover };
              });
            } else {
              location.activeRequests = this.activeWeeks;
            }
            this.loader = false;
            location.selected = false;
            return location;
          });
          if (this.searchTerm.trim() != '') {
            this.isSelector = false;
            if(this.perPage == 50 || this.perPage == 75 || this.perPage == 100)
              this.restorePerPage = this.perPage; 
            this.perPage = data?.data?.client?.total?.aggregate?.count ? data.data.client.total.aggregate.count : this.totalCount;
          } else {
            this.isSelector = true;
            if(this.perPage != 50 && this.perPage != 75 && this.perPage != 100) {
              this.perPage = this.restorePerPage;
              this.offset = 0;
            }
          }
        }
      });
    },
    format(date) {
      let dateToFormat = date;
      if (typeof date === "string") {
        dateToFormat = DateTime.fromISO(date);
      }
      return dateToFormat.toFormat("dd LLL");
    },
    goToLocationHeadcountRequest(location) {
      this.$router.push({ name: "location", hash: "#locationPartnerRequests", params: { clientID: this.clientID, locationID: location.id } });
    },
    hover(locationCopy, request) {
      if (!request.hovering) {
        this.locations = this.locations.map((location) => {
          if (location.id === locationCopy.id) {
            location.activeRequests.filter((activeRequest) => activeRequest.key === request.key).forEach((activeRequest) => (activeRequest.hovering = true));
          }
          return { ...location };
        });
      }
    },
    stopHover(locationCopy, request) {
      if (request.hovering) {
        this.locations = this.locations.map((location) => {
          if (location.id === locationCopy.id) {
            location.activeRequests.filter((activeRequest) => activeRequest.key === request.key).forEach((activeRequest) => (activeRequest.hovering = false));
          }
          return { ...location };
        });
      }
      if (request.editing) {
        this.$nextTick(() => {
          this.$refs[this.inFocus].focus();
        });
      }
    },
    markAsEditing(locationCopy, request, index, event) {
      if (!request.editing) {
        this.inFocus = `${request.key}-${index}`;
        this.locations = this.locations.map((location) => {
          if (location.id === locationCopy.id) {
            location.activeRequests.filter((activeRequest) => activeRequest.key === request.key).forEach((activeRequest) => (activeRequest.editing = true));
          }
          return { ...location };
        });
      }
      this.$nextTick(() => {
        this.$refs[this.inFocus].focus();
        event.target.select();
      });
    },
    focusNextField(locationCopy, requestKey, currentIndex, event) {
      event.preventDefault();
      const maxLength = this.locations.length;
      const nextTab = currentIndex + 1;
      const nextTabIndex = nextTab >= maxLength ? 0 : nextTab;
      this.locations = this.locations.map((location, index) => {
        if (locationCopy.id === location.id) {
          location.activeRequests
            .filter((activeRequest) => activeRequest.key === requestKey)
            .forEach((activeRequest) => {
              activeRequest.editing = false;
              activeRequest.hovering = false;
            });
        }
        if (index === nextTabIndex) {
          location.activeRequests
            .filter((activeRequest) => activeRequest.key === requestKey)
            .forEach((activeRequest) => {
              activeRequest.hovering = true;
              activeRequest.editing = true;

              this.inFocus = `${requestKey}-${index}`;
            });
        }
        return { ...location };
      });

      this.$nextTick(() => {
        this.$refs[this.inFocus].focus();
      });
    },
    submit(location, request, index) {
      const count = this.$refs[`${request.key}-${index}`].value;
      if (count && parseInt(count) !== request.count) {
        const headCountRequest = {
          ...request,
          count,
          startDate: DateTime.fromISO(request.startDate).toFormat("yyyy-MM-dd"),
          endDate: DateTime.fromISO(request.endDate).toFormat("yyyy-MM-dd"),
          requestedBy: this.getUsername,
          requestDate: new Date(),
          clientLocationID: location.id,
        };
        delete headCountRequest.locked;
        delete headCountRequest.key;
        delete headCountRequest.editing;
        delete headCountRequest.hovering;
        delete headCountRequest["__typename"];
        delete headCountRequest.originalCount;
        restApi.post('/location/saveHeadCountRequest',encodeWithParam(headCountRequest)).finally(()=>{
          
          this.$nextTick(() => {
            this.$refs[this.inFocus].focus();
            this.resetSearch();
            this.fetch();
          })
        })
      }
    },
    hash() {
      return this.$route.hash === "partnerRequests";
    },
    openUploadModal() {

      micromodal.show("upload-partner-requests");
    },
    openUploadThresholdModal() {
      micromodal.show("upload-threshold-request");
    },
    openEditZone(){
      this.editZonesData = this.selectedZones
      micromodal.show("edit-zones");

    },
    updateStatus(item) {
      this.locationInfo = item
        micromodal.show("funnel-status");
    },

    
    save(data) {
      const locationID = data.locationId;
      let clrID="";
      if(this.locationInfo.activeRequests[0].id !== undefined && this.locationInfo.activeRequests[0].id !== null)
      {
        clrID= this.locationInfo.activeRequests[0].id;
      }
      let startDate=DateTime.fromISO(this.locationInfo.activeRequests[0].startDate).toFormat("yyyy-MM-dd");
      let endDate= DateTime.fromISO(this.locationInfo.activeRequests[0].endDate).toFormat("yyyy-MM-dd");
      let requestedBy= this.getUsername;
      restApi.post('/location/updateLocationFunnelStatus',encodeWithParam({locationID: locationID, onboardingFunnelStatus: data.statusChange, changeThreshold: data.thresholdChange, funnelConfiguration: data?.info,hcrChange:data.hcrChange,clrID:clrID,startDate:startDate,endDate:endDate,requestedBy:requestedBy })).then(()=>{
        this.resetSearch();
        this.fetch();
        });
    },

    saveMultipleVals(data){
      let requestedBy= this.getUsername;
      restApi.post('/location/updateMultipleThresholds',encodeWithParam({locationData: data,requestedBy:requestedBy})).then(()=>{
        this.resetSearch();
        this.fetch();
        });
    }
  }
};
</script>

<style scoped>
.marign-left-auto {
  margin-left: auto;
}
.search-cont{
  display: flex;
}
.search-cont .secondary{
  margin-bottom: 8px;
}
.btn-container {
  grid-gap: 5px;
  grid-auto-flow: column;
  width: 100%;
  height: 100%;
  display: grid !important;
  justify-content: end;
}

.checkbox-input-zone{
  width: 16px;
    cursor: pointer;
    height: 15px;
    margin-top: 2px;
}

.edit-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
</style>
