<template>
    <div class="tax-container">

        <!--  -->

        <div class="tax-info" v-if="!loader">
            <h5>Driver Tax Info</h5>
            <div class="flex">
                <div class="info-left flex">
                    <div class="details-group flex personal-info">
                        <div class="info-left-row r-one">
                            <p class="header">Legal Full Name</p>
                            <!-- <p>{{ partner1099InfoData.name }}</p> -->
                             {{ $props.partner.firstName }} {{ $props.partner.lastName }}
                        </div>
                        <div class="info-left-row r-two">
                            <p class="header">SSN</p>
                            <!-- <p> {{ partner1099InfoData.ssn }}</p> -->
                             {{ $props.partner.ssn }}
                        </div>
                        <div class="info-left-row r-three ">
                            <p class="header">Address</p>
                            <p v-if="homeAddress.address1">
                                {{ homeAddress.address1 }},{{ homeAddress.address2 }} <br /> 
                                {{ homeAddress.city }}, {{ homeAddress.state }}, {{ homeAddress.postalCode }}, {{ homeAddress.country }}
                            </p>
                        </div>
                    </div>
                    <div class="right">
                        <i v-if="this.getPermission('editTaxPckt')" class="icon-edit" @click="openEdit1099InfoModal('driverTaxEdit')"></i>
                    </div>
                </div>

                <h3 class="id-theft" v-if="!theftAccess">
            <div class="bold flex div-container">
              <div class="margin-top-2 id-title">ID THEFT</div> 
              <InputSwitch v-model="idTheft" :disabled="true"/> 
            </div>
          </h3>
            </div>
        </div>
<!--  -->

          <!-- Modal -->

          <div id="show-theft-modal" class="modal" aria-hidden="true">
            <div class="modal-overlay" tabindex="-1">
              <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby" >
                <div class="modal-content">
                  <div class="module">
                    <p class="pclass center">Do you want to turn {{ idTheft ? 'on':'off' }} ID theft Flag?</p>
                  </div>
                  <div class="button-group close-top center">
                    <a class="button w-button-primary yesBut"
                      >OK</a>
                    <a class="button secondary w-button-secondary noBut" style="margin-left: 20px"
                    @click="closeTheftModal">Cancel</a>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div id="mail-documents" class="modal" aria-hidden="true">
            <div class="modal-overlay" tabindex="-1">
              <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby" >
                <div class="modal-content">
                  <div class="module">
                    <p class="pclass center">Physical Delivery</p>
                    <p class="pclass">Select Tax Years </p>

                    <div
                    class="checkbox-items"
                    v-for="item in driverTaxYears"
                    :key="item"
                    >
                    <input
                        class="checkbox-input"
                        type="checkbox"
                        :value="item"
                        :checked="selectedYearsforDocs.includes(item)"
                        @change="handelCheckboxChange(item)"
                    /> 
                    <label class="checkbox-label">{{ item}}</label>
                    </div>

                  </div>
                  <div class="button-group close-top center">
                    <a class="button w-button-primary yesBut" @click="updatePhysicalDelivery"
                      >Submit</a>
                    <a class="button secondary w-button-secondary noBut" style="margin-left: 20px"
                    @click="hideMailDocModal">Cancel</a>
                  </div>
                </div>
              </div>
            </div>
          </div>


        <label class="filter-label">Filters</label> 
        <div class="filters-n-download flex">
            <div class="left-sec-m">
                <div class="select-container filters flex" v-if="taxYears.length">
                    <div class="filter-toggle">
                        Tax Year
                        <i class="icon-dropdown-right"></i>
                      </div>
                      <select v-model="selectedYear" @change="changeYear($event)">
                        <!-- <option :value="selectedYear" selected> {{ selectedYear }} </option> -->
                        <option v-for="year in taxYears" :key="year" :value="year">{{ year }}</option>
                    </select>
                </div>
            </div>
            <div class="right-sec">
                <div v-if="downloadTaxData" style="color: red; margin-bottom: 5px">Tax Packet not available</div>
                <button v-if="this.getPermission('dwnldTaxPckt')" class="button tax-btn" @click="downloadTaxPacket">Download Tax Document</button>
            </div>
        </div>
        <div v-if="loader">
            <vue-simple-spinner />
        </div>
        <!-- <div v-else-if="disableAccess()" class="info-2022">
            Please download tax document using above button
        </div> -->
        <div v-else-if="!isTaxablePartnerInformationAvailable" class="margin-top-14 text-center">
            No tax data available for this partner in year - {{ selectedYear }}
        </div>
        <div v-else>
        <div class="delivery-opt-n-earnings flex">
            <div class="left-sec del-methods">
                <h6>1099 Delivery Method</h6>
                <div class="flex switch-cls">
                    <InputSwitch class="input-switch" v-model="electronicDelivery" :disabled="true" />
                    <label> Electronic Delivery</label>
                    <p v-if="electronicDelivery" class="e-conset-info"><i class="icon-check"></i> Accepted by driver on {{formatDate(econsentDate)}}</p>
                </div>
                <!-- <div class="flex switch-cls">
                    <InputSwitch class="input-switch" v-model="physicalDelivery" @click="changePhysicalDelivery" />
                    <label>Physical Delivery</label>
                </div>
                <div class="flex switch-cls">
                    <InputSwitch class="input-switch" v-model="refileIRS" @click="changeRefileIRS" />
                    <label>Refile IRS</label>
                </div> -->
            </div>
            <!-- <div class="right-sec total-earnings-main">
                <div class="total-earnings flex">
                    <div class="amount">{{ totalEarnings | formatMoney("s") }}</div>
                    <div class="title">
                        <div>Total</div>
                        <div>Earnings</div>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="mt-25">
            <button @click="showMailDocModal">
                Mail 1099
            </button>
        </div>
        <div class="tax-info">
            <h5>1099 INFO</h5>
            <div class="flex">
                <div class="info-left flex">
                    <div class="details-group flex personal-info">
                        <div class="info-left-row r-one">
                            <p class="header">Legal Full Name</p>
                            <p>{{ partner1099InfoData.name }}</p>
                        </div>
                        <div class="info-left-row r-two">
                            <p class="header">SSN</p>
                            <p> {{ partner1099InfoData.ssn }}</p>
                        </div>
                        <div class="info-left-row r-three ">
                            <p class="header">Address</p>
                            <p v-if="partner1099InfoData.tax1099Address.address1">
                                {{ partner1099InfoData.tax1099Address.address1 }},{{ partner1099InfoData.tax1099Address.address2 }} <br /> 
                                {{ partner1099InfoData.tax1099Address.city }}, {{ partner1099InfoData.tax1099Address.state }},{{ partner1099InfoData.tax1099Address.postalCode }}, {{ partner1099InfoData.tax1099Address.country }}
                            </p>
                        </div>
                    </div>
                    <div class="right">
                        <i v-if="this.getPermission('editTaxPckt')" class="icon-edit" @click="openEdit1099InfoModal('1099Info')"></i>
                    </div>
                </div>
                <div>
                    <div class="total-earnings-main">
                        <div class="total-earnings flex">
                            <div class="amount amount-total-earnings">{{ totalEarnings | formatMoney("s") }}</div>
                            <div class="title">
                                <div>Total</div>
                                <div>Earnings</div>
                            </div>
                        </div>
                    </div>

                
                <div class="mt-2">
                    <div class="total-earnings flex">
                        <div class="amount">{{ partner1099InfoData.totalTaxableAmount | formatMoney("s") }}</div>
                        <div class="title">
                            <div>Total Taxable</div>
                            <div>Amount</div>
                        </div>
                    </div>
                </div>
                </div>

            </div>
        </div>

        <Accordion :multiple="true">
            <AccordionTab header="PERSONAL INFO - CHANGE HISTORY">
                <p>Based on 1099 NEC document of the selected period</p>
                <p class="m-0">
                    <data-table :entries="transformPersonalInfoEditHistory" :headers="personalInfoEditHistoryHeaders"
                        :loading="loader">
                        <template #changedOn="{ item }"> {{ formatDate(item.changed_on) }}</template>
                        <template #changedFrom="{ item }">
                            <span>
                                <span v-if="item.changed_from.firstName">
                                    {{ item.changed_from.firstName }}  {{ item.changed_from.lastName }}
                                    <br>
                                </span>

                                <span v-if="item.changed_from.address">
                                    {{ item.changed_from.address.address1 }},{{ item.changed_from.address.address2 }}<br />
                                    {{ item.changed_from.address.city }}, {{ item.changed_from.address.state }},
                                    {{ item.changed_from.address.postalCode }}, US
                                    <br>
                                </span>
                                <span v-if="item.changed_from.homeAddress">
                                    {{ item.changed_from.homeAddress.address1 }},{{ item.changed_from.homeAddress.address2 }}<br />
                                    {{ item.changed_from.homeAddress.city }}, {{ item.changed_from.homeAddress.state }},
                                    {{ item.changed_from.homeAddress.postalCode }}, US
                                    <br>
                                </span>
                                <span v-if="item.changed_from.totalTaxableAmount"><br v-if="item.changed_from.address1" />{{
                                    item.changed_from.totalTaxableAmount | formatMoney("s") }}
                                <br>    
                                </span>
                                <span v-if="item.changed_from.ssn">SSN:&nbsp;{{
                                    decodeSSN(item.changed_from.ssn) }}</span>
                            </span>
                            
                        </template>
                        <template #changedTo="{ item }">
                            <span>
                                <span v-if="item.changed_to.firstName">
                                    {{ item.changed_to.firstName }}  {{ item.changed_to.lastName }}
                                    <br>
                                </span>
                                <span v-if="item.changed_to.address">
                                    {{ item.changed_to.address.address1 }},{{ item.changed_to.address.address2 }}<br />
                                    {{ item.changed_to.address.city }}, {{ item.changed_to.address.state }},
                                    {{ item.changed_to.address.postalCode }}, US
                                    <br>
                                </span>
                                <span v-if="item.changed_to.homeAddress">
                                    {{ item.changed_to.homeAddress.address1 }},{{ item.changed_to.homeAddress.address2 }}<br />
                                    {{ item.changed_to.homeAddress.city }}, {{ item.changed_to.homeAddress.state }},
                                    {{ item.changed_to.homeAddress.postalCode }}, US
                                    <br>
                                </span>
                                <span v-if="item.changed_from.totalTaxableAmount"><br v-if="item.changed_from.address" />{{
                                    item.changed_to.totalTaxableAmount | formatMoney("s") }}
                                    <br>
                                </span>
                                <span v-if="item.changed_to.ssn">SSN:&nbsp;{{
                                    decodeSSN(item.changed_to.ssn) }}</span>
                            </span>
                        </template>
                        <template #approvedOn="{ item }">{{ formatDate(item.approved_on) }}</template>
                    </data-table>
                </p>
            </AccordionTab>
            <AccordionTab header="TAX DOCUMENTS HISTORY">
                <p class="m-0">
                    <data-table :entries="necTaxDocumentsHistory" :headers="taxDocumentsHeader" :loading="loader">
                        <template #doc-name="{item}"><span @click="openFileViewerModal(item.file_id)"> {{ getDocName(item.document_name) }} </span></template>
                        <template #last-mailedon="{ item }"> {{ formatDate(item.last_mailed_on) }}</template>
                        <template #action="{ item }"> 
                            <span v-if="item.index == 0" class="flex align-center link-text download-enabled" @click="handleMailTaxDocument">
                                    <img style="min-height: 20px; min-width: 20px; margin-right: 5px;"  src="@/assets/images/svg/mail.svg"/>
                                    Mail Tax Document
                            </span>
                        </template>
                    </data-table>
                </p>
            </AccordionTab>
            <AccordionTab header="MAILED TAX DOCUMENTS">
                <p class="m-0">
                    <data-table :entries="necTaxPhysicalDocumentsHistory" :headers="taxDocumentsPhysicalHeader" :loading="loader">
                        <template #doc-name="{item}"><span @click="openFileViewerModal(item.file_id)"> {{ getDocName(item.document_name) }} </span></template>
                        <template #last-mailedon="{ item }"> {{ formatDate(item.last_mailed_on) }}</template>
                    </data-table>
                </p>
            </AccordionTab>
            <AccordionTab>
                <template #header>
                    <div class="flex space-between deposit-summary-section-header">
                        <div>DEPOSIT SUMMARIES</div>
                        <div @click.stop style="display: flex; align-items: center; gap: 20px;">
                            <div class="search-container">
                                <input v-model="depositSummarySearchValue" class="deposit-search" type="search" placeholder="" title="Deposit Summary: Date, Type, Status" />
                                <button type="submit"><span v-if="searchIcon()" class="icon-x deposit-icon" @click="resetSearch"></span> <span v-else class="icon-search deposit-icon"></span></button>
                            </div>
                            <span @click="downloadPartnerTaxDepositSummaries">
                                <img style="min-height: 40px; min-width: 40px;" :class="downloadDepositSummaryButtonStyle"  src="@/assets/images/logos/download.svg" />
                            </span>
                        </div>
                    </div>
                </template>
                <p class="m-0">
                    <data-table v-if="depositSummarySearchValue.length > 0" :entries="despositSummarySearchResult" :headers="depositSummariesHeader">
                    </data-table>
                    <data-table v-else :entries="depositSummaries" :headers="depositSummariesHeader">
                    </data-table>
                </p>
            </AccordionTab>
        </Accordion>
        </div>
        <edit1099-info v-if="!loader" @click="close1099InfoModal" :data="partner1099InfoData" :address="homeAddress" :userID="userID" @refetchData="refetchData" :driverTaxPage ="isDriverTaxEdit" :idTheftFlag=idTheft :driverTaxYears ="driverTaxYears" :partnerMainInfo="partnerMainInfo"/>
        <tax-file-preview @click="closeFileViewerModal" :fileId="selectedFileId"/>
    </div>
</template>
<style scoped>
.info-left-row {
    width: 30%;
}

.info-2022{
    margin-top: 20px;
    text-align: center;
}

.r-three {
    width: 40%;
}

.details-group {
    width: 80%;
}

.info-left {
    width: 70%;
    padding: 20px;
    background: #f4f4f4;
}

.info-right {
    width: 30%;
}

.left-sec-m {
    width: 75%;
}

.select-container select {
    padding: 4px 24px 4px 8px;
    height: 32px;
    width: 270px;
}

.total-earnings {
    padding: 8px;
    background: #f4f4f4;
    margin-left: 25px;
}

.total-earnings .amount,
.total-earnings .title {
    font-weight: 600;
    line-height: 150%;
    font-family: 'Open Sans';
    padding: 8px 16px;
}

.total-earnings .amount {
    min-width: 178px;
    background-color: #FFF;
    font-size: 32px;
    text-align: center;
}

.delivery-opt-n-earnings .amount {
    color: #2A8703;
}

.tax-info {
    margin-top: 64px;
}

.tax-info .amount {
    color: #333;
}

.total-earnings .title {
    font-size: 16px;
}

.tax-container .left-sec {
    width: 70%;
}

.personal-info p {
    color: #46474A;
    font-family: 'Open Sans';
    font-size: 16px;
    margin-bottom: 3px;
    line-height: normal;
}

.personal-info .header {
    opacity: 0.75;
}

.tax-container .right-sec {
    width: 30%;

}

.tax-container .right-sec .tax-btn {
    float: right;
}

.filter-label {
    display: inline-block;
    margin: 5px 0;
}

.select-container {
    width: 50%;
}

.tax-year-label {
    width: 15%
}

.delivery-opt-n-earnings {
    margin-top: 36px;
}

.input-switch {
    margin-right: 15px;
}

.switch-cls {
    margin-top: 12px;
}

.switch-cls label {
    margin-top: 5px;
}

.info-left .right {
    width: 20%;
    text-align: end;
}

.p-accordion-tab {
    margin-top: 20px;
}

.deposit-summary-section-header {
    width: 100%;
    align-items: center;
}

.download-enabled {
    cursor: pointer;
}

.download-disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
.e-conset-info {
    margin: 4px;
    font-size: 14px;
    color: #2A8703;
    padding-left: 10px;
}
.id-theft{
    padding: 20px;
  }
  .id-theft .id-title{
    margin-right: 10px;
  }
  .id-theft .div-container{
    float: right
  }
  .amount-total-earnings {
    color: #2A8703 !important;
}
 .mt-25 {
    margin-top: 25px;
 }
 .mt-2 {
    margin-top: 2px;
 }
</style>

<script>
import InputSwitch from 'primevue/inputswitch';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import micromodal from "micromodal";
import { saveAs } from 'file-saver';
import Edit1099Info from "@/components/modals/Edit1099Info";
import DataTable from "@/components/table/DataTable";
import TableHeader from "@/components/table/TableHeader";
import { DateTime } from "luxon";
import { restApi } from "@/http/http.config";
import { encodeWithParam,decode } from "@/filters";
// import { BaseFilter } from "@/components";
import VueSimpleSpinner from "vue-simple-spinner";
import { downloadFile } from "../../../../filters";
import { mapGetters } from "vuex";
import TaxFilePreview from "@/components/modals/TaxFilePreview";
import { decrypt } from "@/filters";

export default {
    name: "PartnerTaxes",
    components: {
        InputSwitch,
        Accordion,
        AccordionTab,
        Edit1099Info,
        // BaseFilter,
        DataTable,
        VueSimpleSpinner,
        TaxFilePreview,
    },
    props: {
        partner: {
            type: Object,
            required: true,
            default: () => { }
        }
    },
    data: function () {
        return {
            loader: false,
            electronicDelivery: false,
            // physicalDelivery: false,
            // refileIRS: false,
            econsentDate:'',
            userID:"",
            partner1099InfoData: {
                name: '',
                firstName:'',
                lastName:'',
                ssn: '',
                tax1099Address: {
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    country: '',
                    postalCode: '',
                },
                isSame1099AndDeliveryAddress: true,
                totalTaxableAmount: 0,
                year: ""
            },
            partnerMainInfo: {
                name: `${this.$props.partner.firstName} ${this.$props.partner.lastName}`,
                firstName:this.$props.partner.firstName,
                lastName:this.$props.partner.lastName,
                ssn: this.$props.partner.ssn
            },
            homeAddress: {
                address1: '',
                address2: '',
                city: '',
                state: '',
                country: '',
                postalCode: '',
            },
            totalEarnings: 0,
            selectedYear: new Date().getFullYear() - 1,
            personalInfoEditHistory: [],
            transformPersonalInfoEditHistory:[],
            depositSummaries: [],
            selectedYearsforDocs:[],
            necTaxDocumentsHistory: [],
            necTaxPhysicalDocumentsHistory:[],
            taxData: false,
            downloadTaxData: false,
            depositSummarySearchValue: '',
            despositSummarySearchResult: [],
            isTaxablePartnerInformationAvailable: false,
            selectedFileId: "",
            taxYears:[],
            idTheft:false,
            theftAccessRoles:["ACCOUNTING_ANALYST","ACCOUNTING_SPECIALIST","ACCOUNTING_SUPERVISOR"],
            isDriverTaxEdit:false,
            driverTaxYears:[]
        }
    },
    computed: {
    ...mapGetters(["getPermission","getRole"]),
        partnerID() {
            return this.$route.params.id;
        },
        personalInfoEditHistoryHeaders() {
            return [
                new TableHeader({ label: "Changed On", headerClass: "cell15 text-left", name: "changedOn", cellContents: "changed_on" }),
                new TableHeader({ label: "Tax Year", headerClass: "cell15", name: "taxYear", cellContents: "tax_year" }),
                new TableHeader({ label: "Changed From", headerClass: "cell15", name: "changedFrom", cellContents: "changed_from" }),
                new TableHeader({ label: "Changed To", headerClass: "cell15", name: "changedTo", cellContents: "changed_to" }),
                new TableHeader({ label: "Changed By", headerClass: "cell20", name: "changedBy", cellContents: "changed_by", cellClass:"link-text" }),
                new TableHeader({ label: "Approved By", headerClass: "cell20", name: "approvedBy", cellContents: "approved_by", cellClass:"link-text" }),
                new TableHeader({ label: "Approved On", headerClass: "cell15", name: "approvedOn", cellContents: "approved_on" }),
                new TableHeader({ label: "Description", headerClass: "cell15", name: "description", cellContents: "description" }),
            ];
        },
        taxDocumentsHeader() {
            return [
                new TableHeader({ label: "Document Name", headerClass: "cell10 text-left", name: "doc-name", cellContents: "document_name", cellClass:"link-text pointer" }),
                new TableHeader({ label: "Last Mailed On", headerClass: "cell15", name: "last-mailedon", cellContents: "last_mailed_on" }),
                new TableHeader({ label: "Mailed By", headerClass: "cell15", name: "mailed-by", cellContents: "mailed_by", cellClass:"link-text" }),
                new TableHeader({ label: "", headerClass: "cell20", name: "action", cellContents: "" }),
            ];
        },

        taxDocumentsPhysicalHeader() {
            return [
                new TableHeader({ label: "Document Name", headerClass: "cell15 text-left", name: "doc-name", cellContents: "document_name", cellClass:"link-text pointer" }),
                new TableHeader({ label: "Last Mailed On", headerClass: "cell15", name: "last-mailedon", cellContents: "last_mailed_on" }),
                new TableHeader({ label: "Mailing Address", headerClass: "cell20", name: "mailing-address", cellContents: "mailing_address" }),
                new TableHeader({ label: "No. of time tax document mailed", headerClass: "cell20", name: "times-mailed", cellContents: "times_mailed" }),
            ];
        },
        depositSummariesHeader() {
            return [
                new TableHeader({ label: "Earning Period", headerClass: "cell25 text-left", name: "earningPeriod", cellContents: "startDate" }),
                new TableHeader({ label: "Earning Provider", headerClass: "cell20 ", name: "gross", cellContents: "payment_mode" }),
                new TableHeader({ label: "Total Earnings (Net)", headerClass: "cell20", name: "net", cellContents: "total_earnings" }),
                new TableHeader({ label: "Status", headerClass: "cell20", name: "status", cellContents: "status" }),
            ];
        },
        downloadDepositSummaryButtonStyle() {
            return this.depositSummaries.length > 0 ? 'download-enabled' : 'download-disabled';
        },
        theftAccess(){
            this.theftAccessRoles.includes(this.getRole)
            console.log(this.theftAccessRoles.includes(this.getRole))
            return this.theftAccessRoles.includes(this.getRole)
        }
    },
    watch: {
        depositSummarySearchValue: function(newVal) {
            if (newVal.trim().length) {
                let result =  this.depositSummaries.filter((item) => (
                    item.payment_mode.toLowerCase().includes(newVal.toLowerCase()) || 
                    item.status.toLowerCase().includes(newVal.toLowerCase()) || 
                    item.startDate.toLowerCase().includes(newVal.toLowerCase())
                ))
                this.despositSummarySearchResult =  result;
            } else {
                this.despositSummarySearchResult = [];
            }
        }
    },
    mounted() {
        if(this.$props.partner?.idTheft[0]?.id_theft){
        this.idTheft = this.$props.partner?.idTheft[0]?.id_theft;
      }
        this.getTaxYears();
        this.getPartnerTaxInfo();
    },
    methods: {
        getTaxYears(){
            let year = new Date().getFullYear()-1;
            for(let i = year;i > 2021; i-- ){
                this.taxYears.push(i.toString())
        }
        },
        handelCheckboxChange(item){
            if(!this.selectedYearsforDocs.includes(item)){
                this.selectedYearsforDocs.push(item);
            }
            else{
                this.selectedYearsforDocs = this.selectedYearsforDocs.filter(function(val) {
                return val !== item
            })
            }
        },

        updatePhysicalDelivery(){
            if(this.selectedYearsforDocs.length){
                let param = {
                partnerID: this.$route.params.id,
                year: this.selectedYearsforDocs,
                update: 'nec_physical_delivery',
                nec_physical_delivery: true
            };
             restApi.post(`/tax/updateTaxablePartnerFlags`, encodeWithParam(param)).then(()=>{
                this.selectedYearsforDocs = [];
            })
            this.hideMailDocModal();
            }
        },
        showMailDocModal(){
            micromodal.show("mail-documents")
        },
        hideMailDocModal(){
            this.selectedYearsforDocs=[];
            micromodal.close("mail-documents")
        },
        showTheftModal(){
            micromodal.show("show-theft-modal")
        },
        closeTheftModal(){
            this.idTheft=false;
            micromodal.close("show-theft-modal")
        },
    //     toggleIdtheft(){
    //         // this.idTheft = !this.idTheft
    //         restApi.post(`/partner/add-IDtheft`, encodeWithParam({ partnerID:this.$route.params.id,id_theft:this.idTheft,reason:null })).then(()=>micromodal.close("show-theft-modal"))
    // },
        disableAccess(){
            return Number(this.selectedYear) <= 2022 
        },
        changeYear(){
            this.taxData = false
            if(Number(this.selectedYear) > 2021 ){
                this.getPartnerTaxInfo();
            }
        },
        getDocName(val){
            if(val){
                return val
            }
            return `${this.partnerID}_${this.selectedYear}_1099Nec.pdf`
        },
        formatDate(date) {
            return DateTime.fromISO(date).setZone("America/Los_Angeles").toFormat('hh:mm a, LL/dd/y')
        },

        formatDateToUTC(date) {
            return DateTime.fromISO(date, { zone: 'utc' }).toFormat("dd LLL yyyy");
        },

        async isSSN(value) {
        const ssnPattern = /^(?:\d{3}-\d{2}-\d{4}|\d{9})$/;
        if(ssnPattern.test(value)){
            return value
        }
        return await decrypt(value,this.partnerID)
        },
        decodeSSN(value){
        const ssnPattern = /^(?:\d{3}-\d{2}-\d{4}|\d{9})$/;
        if(ssnPattern.test(value)){
            return value
        }
        return  decode(value)
        },

        resetValues(){
            this.homeAddress ={
                address1: '',
                address2: '',
                city: '',
                state: '',
                country: '',
                postalCode: '',
            };
            this.necTaxDocumentsHistory=[];
            this.necTaxPhysicalDocumentsHistory=[];
            this.personalInfoEditHistory=[];
            this.depositSummaries=[];
            this.transformPersonalInfoEditHistory=[];
            this.partner1099InfoData = {
                name: '',
                firstName:'',
                lastName:'',
                ssn: '',
                tax1099Address: {
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    country: '',
                    postalCode: '',
                },
                isSame1099AndDeliveryAddress: true,
                totalTaxableAmount: 0,
                year: ""
            }

        },

        async getPartnerTaxInfo() {
            this.loader = true;
            this.taxData = false
            this.resetValues();
            await restApi.post(`/tax/getPartnerTaxDetails`, encodeWithParam({ partnerID: this.partnerID, year: this.selectedYear }))
                .then(async ({ data }) => {
                    if (data.success) {
                        if (data.totalEarnings?.length) {
                            this.totalEarnings = data?.totalEarnings[0]?.totalearnings;
                        }
                        else if(data.totalEarnings?.length==0){
                            this.totalEarnings = 0;
                        }
                        if (data.homeAddress?.length) {
                            this.homeAddress = data?.homeAddress[0]?.homeAddress;
                        }
                        if (data.personalInfo?.length) {
                            this.isTaxablePartnerInformationAvailable = true;
                            // this.physicalDelivery = data.personalInfo[0]?.nec_physical_delivery;
                            // this.refileIRS = data.personalInfo[0]?.nec_refile_irs;
                            this.userID = data.personalInfo[0]?.userID
                            this.partner1099InfoData = {
                                totalTaxableAmount: data.taxableAmount?.length ? data.taxableAmount[0]?.total_taxable_amount : 0,
                                name: data.personalInfo[0]?.fullname,
                                firstName:data.personalInfo[0]?.firstName,
                                lastName:data.personalInfo[0]?.lastName,
                                ssn: await this.isSSN(data.personalInfo[0]?.ssn),
                                tax1099Address: data.personalInfo[0]?.tax1099Address,
                                year: this.selectedYear,
                                isSame1099AndDeliveryAddress: data.personalInfo[0]?.is_same_mailing_address,                     
                            }
                            if(data?.driverTaxYears?.length>0){
                            this.driverTaxYears =data.driverTaxYears.map(item => item?.year?.toString());
                            }
                        }
                        if (data.editHistory?.length) {
                            this.personalInfoEditHistory = data.editHistory;

                            this.personalInfoEditHistory.forEach(item =>{
                                if(item.changed_from?.firstName){
                                    this.transformPersonalInfoEditHistory.push(
                                    {
                                        changed_on:item?.changed_on,
                                        changed_from:{firstName:item.changed_from?.firstName,lastName:item.changed_from?.lastName},
                                        changed_to:{firstName:item.changed_to?.firstName,lastName:item.changed_to?.lastName}, 
                                        approved_on:item.approved_on,
                                        changed_by:item.changed_by,
                                        approved_by:item.approved_by,
                                        description:item.description,
                                        tax_year:item.tax_year
                                    }
                                )
                                }
                                if(item.changed_from?.ssn){
                                    this.transformPersonalInfoEditHistory.push(
                                    {
                                        changed_on:item.changed_on,
                                        changed_from:{ssn:item.changed_from?.ssn},
                                        changed_to:{ssn:item.changed_to?.ssn},
                                        approved_on:item.approved_on,
                                        changed_by:item.changed_by,
                                        approved_by:item.approved_by,
                                        description:item.description,
                                        tax_year:item.tax_year
                                    }
                                ) 
                                }
                                if(item.changed_from?.address){
                                    this.transformPersonalInfoEditHistory.push(
                                    {
                                        changed_on:item.changed_on,
                                        changed_from:{address: {
                                        city: item.changed_from?.address?.city,
                                        state: item.changed_from?.address?.state,
                                        address1: item.changed_from?.address?.address1,
                                        address2: item.changed_from?.address?.address2,
                                        postalCode: item.changed_from?.address?.postalCode
                                        }
                                        },
                                        changed_to:{
                                        address: {
                                        city: item.changed_to?.address?.city,
                                        state: item.changed_to?.address?.state,
                                        address1: item.changed_to?.address?.address1,
                                        address2: item.changed_to?.address?.address2,
                                        postalCode: item.changed_to?.address?.postalCode
                                        }
                                        },
                                        approved_on:item.approved_on,
                                        changed_by:item.changed_by,
                                        approved_by:item.approved_by,
                                        description:item.description,
                                        tax_year:item.tax_year
                                    }
                                ) 
                                }
                                if(item.changed_from?.homeAddress){
                                    this.transformPersonalInfoEditHistory.push(
                                    {
                                        changed_on:item.changed_on,
                                        changed_from:{address: {
                                        city: item.changed_from?.homeAddress?.city,
                                        state: item.changed_from?.homeAddress?.state,
                                        address1: item.changed_from?.homeAddress?.address1,
                                        address2: item.changed_from?.homeAddress?.address2,
                                        postalCode: item.changed_from?.homeAddress?.postalCode
                                        }
                                        },
                                        changed_to:{
                                        address: {
                                        city: item.changed_to?.homeAddress?.city,
                                        state: item.changed_to?.homeAddress?.state,
                                        address1: item.changed_to?.homeAddress?.address1,
                                        address2: item.changed_to?.homeAddress?.address2,
                                        postalCode: item.changed_to?.homeAddress?.postalCode
                                        }
                                        },
                                        approved_on:item.approved_on,
                                        changed_by:item.changed_by,
                                        approved_by:item.approved_by,
                                        description:item.description,
                                        tax_year:item.tax_year
                                    }
                                ) 
                                }
                                if(item.changed_from?.totalTaxableAmount){
                                    this.transformPersonalInfoEditHistory.push(
                                    {
                                        changed_on:item.changed_on,
                                        changed_from:{totalTaxableAmount:item.changed_from?.totalTaxableAmount},
                                        changed_to:{totalTaxableAmount:item.changed_to?.totalTaxableAmount},
                                        approved_on:item.approved_on,
                                        changed_by:item.changed_by,
                                        approved_by:item.approved_by,
                                        description:item.description,
                                        tax_year:item.tax_year
                                    }
                                )
                                }
                            })
                        }
                        if (data.depositSummaries?.length) {
                            this.depositSummaries = data.depositSummaries;
                        }
                        if (data.necTaxDocumentsHistory?.length) {
                            this.necTaxDocumentsHistory = data.necTaxDocumentsHistory;
                        }
                        if (data.necTaxPhysicalDocumentsHistory?.length) {
                            this.necTaxPhysicalDocumentsHistory = data.necTaxPhysicalDocumentsHistory;
                        }
                        if (data.eConsent?.length) {
                            this.electronicDelivery=data.eConsent[0].tax_consent
                            this.econsentDate = data.eConsent[0].last_updated_on
                        }
                        else{
                            this.electronicDelivery = false; 
                            // this.physicalDelivery=true;
                        }
                    }

                    // this.depositSummaries = data?.result.depositSummaries;
                    // this.totalCount = Number(data?.result.depositSummaries_aggregate);
                    // this.locationName = data?.result?.depositSummaries[0]?.clientLocation.name
                    this.loader = false;
                    this.taxData = true;
                })
        },

        formatEarningPeriod(item) {
            return `${this.formatDateToUTC(item.startDate)} - ${this.formatDateToUTC(item.endDate)}`;
        },

        downloadTaxPacket() {
            this.downloadTaxData = false
            restApi.post(`/tax/downloadTaxDocs`, encodeWithParam({ partnerID: this.partnerID, year: this.selectedYear, userID:this.userID }))
                .then(({ data }) => {
                    if (data.length > 0) {
                        restApi.post('employee/getUploadedFile', encodeWithParam({ fileID: data[0] })).then((content) => {
                            let buffer = Buffer.from(content.data.bufferInHex, "hex");
                            let buf64 = buffer.toString("base64");
                            let bufferData = "data:application/pdf;base64," + buf64;

                            saveAs(bufferData, `${this.partnerID}_${this.selectedYear}.pdf`)
                        })
                    }
                    else {
                        this.downloadTaxData = true
                    }
                })
        },

        downloadPartnerTaxDepositSummaries() {
            if (this.depositSummaries.length) {
                restApi.post(`/tax/downloadPartnerTaxDepositSummaries`, encodeWithParam({ partnerID: this.partnerID, year: this.selectedYear }))
                    .then(({ data }) => {
                        downloadFile(data, `Deposit Summaries.csv`);
                        this.disableDownloadButton = false;
                    })
            }
        },

        updateTaxablePartnerFlags(param) {
            this.loader = true;
            restApi.post(`/tax/updateTaxablePartnerFlags`, encodeWithParam(param)).then(()=>this.refetchData())
        },

        // changePhysicalDelivery() {
        //     this.physicalDelivery = !this.physicalDelivery;
        //     let param = {
        //         partnerID: this.partnerID,
        //         year: this.selectedYear,
        //         update: 'nec_physical_delivery',
        //         nec_physical_delivery: this.physicalDelivery
        //     };
        //     this.updateTaxablePartnerFlags(param);
        // },

        // changeRefileIRS() {
        //     this.refileIRS = !this.refileIRS;
        //     let param = {
        //         partnerID: this.partnerID,
        //         year: this.selectedYear,
        //         update: 'nec_refile_irs',
        //         nec_refile_irs: this.refileIRS
        //     };
        //     this.updateTaxablePartnerFlags(param);
        // },

        handleMailTaxDocument() {
            this.physicalDelivery = true;
            let param = {
                partnerID: this.partnerID,
                year: this.selectedYear,
                update: 'nec_physical_delivery',
                nec_physical_delivery: this.physicalDelivery
            };
            this.updateTaxablePartnerFlags(param);
        },

        openModal(modalName) {
            micromodal.show(modalName);
        },

        closeModal(modalName) {
            micromodal.close(modalName);
        },

        openEdit1099InfoModal(data) {
            if(data=="driverTaxEdit"){
                if(!this.driverTaxYears.length>0) return;
                this.isDriverTaxEdit= data == "driverTaxEdit" ? true : false
                this.openModal('edit-1099-info-modal')
            }
            else{
                if(!this.partner1099InfoData.name) return;
                this.isDriverTaxEdit= data == "driverTaxEdit" ? true : false
                this.openModal('edit-1099-info-modal')
            }
        },

        close1099InfoModal() {
            this.closeModal("edit-1099-info-modal");
        },

        openFileViewerModal(fileId) { 
            this.selectedFileId = fileId;
            this.openModal('tax-file-viewer');
        },

        closeFileViewerModal() {
            this.selectedFileId = "";
            this.closeModal("tax-file-viewer");
        },

        refetchData() {
            this.$emit("refetch");
            this.getPartnerTaxInfo();
        },

        searchIcon() {
            return this.depositSummarySearchValue !== "";
        },

        resetSearch() {
            this.depositSummarySearchValue = "";
        }
    }
}
</script>