<template>
    <fragment>
        <flyout :name="$props.name" :title="getName">
            <div class="deposit-flyout-content deposit-flyout">
                <div class="flex module margin-top-40">
                    <div class="cell70">
                        <h3>DEPOSIT SUMMARY</h3>
                    </div>
                    <div class="flyout-icon-download cell40">
                        <a v-if="getPermission('dwnldDeposits')" :class="{ 'pointer-event': !downloadEnable }" @click="generateReport"> <i
                                class="icon-download"></i>Download PDF</a>
                        <vue-html2pdf ref="html2Pdf" :show-layout="false" :float-layout="true" :enable-download="true"
                            :preview-modal="false" :paginate-elements-by-height="1500" :pdf-quality="2"
                            :manual-pagination="false" pdf-format="letter" filename="" pdf-orientation="portrait"
                            pdf-content-width="100%" :html-to-pdf-options="htmlToPdfOptions"
                            @beforeDownload="generateFileName" @hasGenerated="hasGenerated($event)">
                            <section slot="pdf-content">
                                <one-day-summary :details="dayDeposits" @enableDownload="enableDownload" />
                            </section>
                        </vue-html2pdf>
                    </div>
                </div>
                <div v-if="loader" class="loader-cls">
                    <loader />
                </div>
                <!-- <div v-else>
                    <div class="module-m" v-for="(item, index) in holdDeposits" :key="index">
                        <h5 class="bottom-border">
                             {{ item.count }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ item.pay_type }}<span>{{ item.sum | formatMoney("s") }}</span>
                        </h5>
                    </div>
                </div>
                <div class="gray-bg module-large">
                    <h5 class="net-total">
                        Total<span>{{ totalAmount| formatMoney("s") }}</span>
                    </h5>
                </div> -->

                <div v-else>
                    <div v-if="($props.details && $props.details.pay_type == 'ADHOC')">
                        <div class="module-m deposit-summary-line-item" v-for="(item, index) in dayDeposits.credits" :key="index">
                            <h5 :class="isTaxExempted(item)">
                                {{ item.count }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ item.name }}<span>{{ item.amount | formatMoney("s") }}</span>
                            </h5>
                            <h5 class="bottom-border">
                                Remarks: {{ item.remarks }}
                            </h5>
                        </div>
                    </div>
                    <div v-else>
                        <div class="module-m" v-for="(item, index) in dayDeposits.credits" :key="index">
                            <h5 class="bottom-border">
                                {{ item.count }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ item.name }}<span>{{ item.amount | formatMoney("s") }}</span>
                            </h5>
                        </div>
                    </div>
                    <div class="gray-bg module-large">
                        <h5 class="net-total">
                            Total<span>{{ dayDeposits.netAmount | formatMoney("s") }}</span>
                        </h5>
                    </div>
                </div>
            </div>
        </flyout>
    </fragment>
</template>

<script>
    import Flyout from "@/components/flyouts/Flyout";
    import OneDaySummary from "@/modules/admin/partners/DownloadOneDeposits";
    import { Fragment } from "vue-fragment";
    import VueHtml2pdf from "vue-html2pdf";
    import { restApi } from "@/http/http.config";
    import { encodeWithParam } from "@/filters";
    import { Loader } from "@/components";
    import { mapGetters } from "vuex";

    export default {
        name: "HoldDepositSummaryFlyout",
        components: {
            Flyout, 
            Fragment, 
            VueHtml2pdf, 
            OneDaySummary, 
            Loader
        },
        props: {
            name: {
                type: String,
                required: true,
                default: "default",
            },
            details: {
                type: Object,
                required: true,
                default: () => { }
            },
            partnerID: {
                type: String,
                required: true,
                default: ""
            }
        },
        data() {
            return {
                htmlToPdfOptions: {
                    filename: "",
                    html2canvas: { useCORS: true, height: 1024, logging: false },
                },
                downloadEnable: false,
                loader: false,
                holdDeposits: [],
                totalAmount: 0,
                dayDeposits: {}

            };
        },
        watch: {
            details() {
                this.fetchDetails();
            }
        },
        computed: {
            ...mapGetters(["getPermission"]),
            getName() {
                return this.dayDeposits?.partner?.name;
            },
            getLocation() {
                return this.$props.details?.locationName;
            },
            depositRemarks() {
                return this.$props.details?.remarks ?? '';
            },
        },
        methods: {
            fetchDetails() {
                this.holdDeposits = []
                this.loader = true;
                restApi.post(`/employee/getHoldEarnings`, encodeWithParam({ partnerID: this.$props.partnerID, startDate: this.$props.details.startDate, endDate: this.$props.details.endDate, pay_type: this.$props.details?.pay_type, status: this.$props.details?.status })).then(({ data }) => {
                    this.depositSummaryData(data.result, this.$props.details?.date, data.partnerInfo)
                })
            },

            depositSummaryData(depositResult, date, partner) {
                let totalAmount = depositResult.reduce((acc, currentValue) => parseFloat(acc) + parseFloat(currentValue.totalAmount), 0);
                
                let credits = depositResult.map((item, index) => {
                    return {
                        id: index,
                        name: item.mappingKey,
                        amount: item.totalAmount,
                        count: item.count,
                        reason_code: item?.reason_code,
                        tax_exempted: item?.tax_exempted,
                        remarks: item?.remarks,
                    }
                })

                // this.totalAmount = data.result.reduce((acc, currentValue) => parseFloat(acc) + parseFloat(currentValue.amount), 0);

                // this.loader = false;
                // let MappingKey = {
                //     1: 'Trip Earnings',
                //     2: 'Incentives',
                //     3: 'Confirmed Tips',
                //     4: 'Earning Adjustment',
                //     5: 'Healthcare Subsidy',
                //     6: 'Tip Coverage'
                // }
                // let credits = depositResult.map((item, index) => {
                //     return {
                //         id: index,
                //         name: MappingKey[item.mappingKey],
                //         amount: item.totalAmount,
                //         count: item.count
                //     }
                // })
                depositResult.forEach(() => {
                    let customObj = {
                        netAmount: totalAmount,
                        credits: credits,
                        checkDate: date,
                        startDate: this.$props.details.startDate,
                        endDate: this.$props.details.endDate,
                        clientLocation: this.getLocation,
                        payPeriod: date,
                        firstName: partner.firstName,
                        lastName: partner.lastName,
                        partner: {
                            name: `${partner.firstName} ${partner.lastName}`,
                            email: partner.email,
                        },
                        isAdhocPayment: this.$props?.details.pay_type == 'ADHOC',
                    };
                    this.dayDeposits = customObj;
                });
                this.loader = false;
            },
            generateFileName() {
                this.htmlToPdfOptions.filename = `HOLD-WEEKLY-${this.dayDeposits.firstName}_${this.dayDeposits.lastName}_DATE_[${this.formatdate(this.dayDeposits.startDate)}to${this.formatdate(this.dayDeposits.endDate)}]`;
            },
            hasGenerated() {
                this.$emit("pdfGenerationComplete", "");
            },
            async generateReport() {
                await this.$refs.html2Pdf.generatePdf();
            },
            enableDownload() {
                this.downloadEnable = true;
            },
            formatdate(date){
                return date.split("T")[0];
            },
            isTaxExempted(item) {
              return item.tax_exempted?.toLowerCase() === 'y' ? 'tax-exempted' : '';
            },
        }
    };
</script>

<style scoped>
    .loader-cls {
        min-height: 200px;
        text-align: center;
        padding-top: 40px;
    }
    .margin-top-40 {
        margin-top: 40px;
    }
    .check-dt {
        margin-left: 0;
        margin-top: 40px;
    }
</style>