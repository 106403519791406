<template>
  <add-edit-page>
    <template #header="{}">
      <sticky-header :title="$props.title" classes = "margin-zero">
        <h4>{{ $props.entityName }}</h4>
        <save-cancel :loader="true" @onSave="handleSubmit" />
      </sticky-header>
    </template>
    <div id="contactadd">
      <div class="grid module">
        <div class="col-7">
          <slot name="description"></slot>
        </div>
        <div v-if="editing" class="col-5 flex-right">
          <div class="spread-aside">
            <a class="warning" @click="openDeleteModal"> <i class="icon-plus-circle"></i>Delete This Contact </a>
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="spread col-4">
          <div class="module-form">
            <h3>Basic Info</h3>
            <form-input :value="value.firstName" name="First Name" label="First Name" required rules="required" @input="update('firstName', $event)" />
            <form-input :value="value.lastName" name="Last Name" label="Last Name" required rules="required" @input="update('lastName', $event)" />
            <form-input :value="value.phone" :mask="true" name="Phone" label="Phone" required rules="required|phone" type="phone" @input="update('phone', $event)" />
            <form-input :value="value.email" name="Email" label="Email" :disabled="emailDisabled" required :rules="getEmailRules" type="email" @input="update('email', $event)" />
          </div>
          <div class="module-form">
            <h3>Role</h3>
            <form-select
              key="code"
              :value="value.role"
              label="Role"
              name="Role"
              default-choice="Choose Role"
              :options="roles"
              required
              option-label="label"
              option-value="name"
              @input="update('role', $event)"
            />
          </div>
          <div class="module-form">
            <h3>Address (Optional)</h3>
            <p v-if="addressInUse">
              <a @click="clearAddress">Clear This Address<i class="icon-delete right-icon"></i></a>
            </p>
            <form-input :value="value.address1" name="address" label="Street Address" @input="update('address1', $event)" />
            <form-input :value="value.address2" name="address2" label="Street Address" @input="update('address2', $event)" />
            <form-input :value="value.city" name="email" label="City" @input="update('city', $event)" />
            <div v-if="value.address" class="field">
              <label>
                State
                <region-select :value="value.state" :region="value.state" :country="value.country" country-name autocomplete placeholder="Select State" @change="update('state', $event)" />
              </label>
            </div>
            <form-input :value="value.postalCode" name="zip" label="Zip" @input="update('postalCode', $event)" />
            <div class="field">
              <label>
                Country
                <country-select :value="value.country" :country="value.country" country-name autocomplete top-country="United States" @change="update('country', $event)" />
              </label>
            </div>
          </div>
        </div>
        <div class="col-2"></div>
        <div v-if="$props.clientContact" class="col-6">
          <!-- <validation-provider ref="validationProvider" name="locationAssignments" slim> -->
          <assignment-component :list="value.client.locations" :assignments="locationAssignments" @change="onChangeLocations" />
          <!-- </validation-provider> -->
        </div>
      </div>
    </div>
    <template #bottomContent="{}">
      <save-cancel no-margin :loader="true" @onSave="handleSubmit" />
    </template>

    <contact-delete-modal v-if="editing" :contact="value" :client="value.client" @delete-contact="deleteContact" />
  </add-edit-page>
</template>

<script>
import { SaveCancel, FormInput, StickyHeader, AddEditPage, FormSelect, AssignmentComponent } from "@/components";
import {
  extend,
  //ValidationProvider
} from "vee-validate";
import { USER_EXISTS } from "@/modules/admin/employees/individuals/queries";
import micromodal from "micromodal";
import { DELETE_CLIENT_CONTACT, DELETE_CONTACT_FROM_USER } from "@/modules/shared/contacts/graph/mutations";
import ContactDeleteModal from "@/modules/shared/contacts/ContactDeleteModal";

export default {
  name: "ContactsAddEdit",
  components: { AddEditPage, FormInput, StickyHeader, SaveCancel, FormSelect, ContactDeleteModal, AssignmentComponent },
  props: {
    title: {
      type: String,
      required: true,
      default: "Add Contact",
    },
    entityName: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: Object,
      required: true,
      default: () => {},
    },
    clientContact: {
      type: Boolean,
      required: false,
      default: false,
    },
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      //locationAssignmentCount: this.$props.value.locations ? this.$props.value.locations.length : 0,
    };
  },
  computed: {
    locationAssignmentCount() {
      return this.$props.value.locations ? this.$props.value.locations.length : 0;
    },
    roles() {
      return []
    },
    userID() {
      if (this.$props.value && this.$props.value.userID) {
        return this.$props.value.userID;
      }
      return null;
    },
    contactID() {
      return this.$props.value.id || this.$route.params.contactID;
    },
    editing() {
      return !!this.contactID;
    },
    locationAssignments() {
      if (this.$props.value && this.$props.value.locations) {
        return this.$props.value.locations.map((location) => location.clientLocationID);
      }
      return [];
    },
    address() {
      if (this.$props.value) {
        return { ...this.$props.value.address };
      }
      return {};
    },
    addressInUse: function () {
      let arr = [];
      Object.entries(this.$props.value.address).forEach(([, value]) => {
        arr.push(value);
      });
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].length > 0) {
          return true;
        }
      }
      return false;
    },
    emailDisabled() {
      return this.$props.value.user && !!this.$props.value.user.lastLogin;
    },
    getEmailRules() {
      return this.$props.edit ? `required|email` : `required|email|emailExists:${this.userID}`;
    },
  },
  mounted() {
    const apolloClient = this.$apollo;
    extend("emailExists", {
      message: (field) => `${field} already exists`,
      validate(value, args) {
        let id = args[0];
        if (id && id.toLowerCase() === "null") {
          id = null;
        }
        return apolloClient
          .query({
            fetchPolicy: "no-cache",
            query: USER_EXISTS,
            variables: { username: value, id },
          })
          .then(({ data }) => {
            return Promise.resolve({
              valid: !(data.users && data.users.length > 0),
            });
          });
      },
    });
    micromodal.init({
      onShow: function () {
        document.body.classList.add("no-scroll");
      },
      onClose: function () {
        document.body.classList.remove("no-scroll");
      },
    });
  },
  methods: {
    handleSubmit() {
      this.$emit("onSave", this.$props.value);
    },
    openDeleteModal() {
      micromodal.show("modal-delete-contact", {});
    },
    onChangeLocations(data) {
      // if (this.$refs.validationProvider && data.length == 0 && this.$props.clientContact) {
      //   this.$refs.validationProvider.setFlags({ dirty: true, pristine: false, touched: false, untouched: true, valid: false, invalid: true });
      // } else if (this.$refs.validationProvider && data.length !== this.locationAssignmentCount) {
      //   this.$refs.validationProvider.setFlags({ dirty: true, pristine: false, touched: true, untouched: false, valid: true, invalid: false });
      // } else {
      //   this.$refs.validationProvider.setFlags({ dirty: false, pristine: true, touched: false, untouched: true, valid: true, invalid: false });
      // }
      this.$emit("onLocationsChanged", data);
    },
    deleteContact(id) {
      this.$apollo
        .mutate({
          mutation: DELETE_CLIENT_CONTACT,
          variables: {
            id,
          },
        })
        .then(({ data }) => {
          this.$apollo.mutate({
            mutation: DELETE_CONTACT_FROM_USER,
            variables: {
              id: data?.delete_contacts_by_pk?.userID,
            },
          });
        })
        .finally(() => {
          micromodal.close("modal-delete-contact");
          this.$router.back();
        });
    },
    clearAddress() {
      this.$props.value.address = {
        ...this.$props.value.address,
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
      };
    },
    update(key, value) {
      this.$emit("input", { ...this.$props.value, [key]: value });
    },
  },
};
</script>
