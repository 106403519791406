<template>
  <div>
    <data-table :sticky-header="$props.stickyHeader" :loading="locationLoader" :headers="headers" :entries="$props.entries" min-height="300px">
      <template #name="{ item }">
        <router-link :to="{ name: 'location', params: { locationID: item.id, id: clientID } }">{{ item.name }}</router-link>
      </template>
      <template #progress="{ item }">
        <location-progress-cell :location="item" :requested="requestedCount(item)" :approved="approvedCount(item)" :week-filter="false" />
      </template>
      <template #noContents>No Locations</template>
    </data-table>
  </div>
</template>

<script>
import LocationProgressCell from "@/modules/admin/clients/locations/LocationProgressCell";
import { mapGetters } from "vuex";
import { DataTable, TableHeader, Direction } from "@/components";
import {FunnelStages,FunnelProgress,FunnelSteps} from "@/util/funnelProgress";
import { waitlistEligible } from "../util/funnelProgress";

export default {
  name: "LocationEnrollmentStatus",
  components: { LocationProgressCell, DataTable },
  props: {
    stickyHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    locationSpecific:{
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    entries: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      selectedFilters: [],
      dataLocations: [],
      user: {},
      fp: new FunnelProgress()
    };
  },

  methods: {
    sortProgress(direction) {
      return (location1, location2) => {
        const notRecruitingSort = direction === Direction.ASC ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER; // always at the bottom
        const location1Progress = location1.isRecruiting && this.requestedCount(location1) ? this.getHeadCountFulfilledPercent(this.approvedCount(location1), this.requestedCount(location1)) : notRecruitingSort;
        const location2Progress = location2.isRecruiting && this.requestedCount(location2) ? this.getHeadCountFulfilledPercent(this.approvedCount(location2), this.requestedCount(location2)) : notRecruitingSort;
        return location2Progress - location1Progress;
      };
    },
    sortOnboarding() {
      return (location1, location2) => {
        return location2?.onboardingPartners?.aggregate?.count - location1?.onboardingPartners?.aggregate?.count;
      };
    },
    sortApproved() {
      return (location1, location2) => {
        return this.approvedCount(location2) - this.approvedCount(location1);
      };
    },
    enrollmentCount(location) {
      return this.stageCount(location, FunnelStages.ENROLLMENT);
    },
    earningsMethodCount(location) {
      return this.stageCount(location, FunnelStages.EARNINGS_METHOD);
    },
    insuranceCount(location) {
      return this.stageCount(location, FunnelStages.INSURANCE);
    },
    riskMitigationCount(location) {
      return this.stageCount(location, FunnelStages.RISK_MITIGATION);
    },
    applicationCount(location) {
      return location?.stageCounts?.filter((stageCount) => stageCount.stage === FunnelStages.APPLICATION && stageCount.step === FunnelSteps.APPLICATION_COMPLETE)
       .reduce((start, next) => +start + +next.count, 0);
    },
    waitlistEligibleCount(location) {
      return location?.stageCounts?.filter((stageCount) => stageCount.stage === FunnelStages.APPLICATION && stageCount.step === waitlistEligible)
       .reduce((start, next) => +start + +next.count, 0);
    },
    withdrawnCount(location) {
      return this.stageCount(location, FunnelStages.WITHDRAWN);
    },
    approvedCount(location) {
       return location?.stageCounts?.filter((stageCount) => stageCount.stage === FunnelStages.APPLICATION && stageCount.step === FunnelSteps.APPLICATION_APPROVED)
       .reduce((start, next) => +start + +next.count, 0);
    },
    stageCount(location, stage) {
      return location?.stageCounts?.filter((stageCount) => stageCount.stage === stage).reduce((start, next) => +start + +next.count, 0);
    },
    requestedCount(location) {
      return location?.headCountRequests?.length > 0 ? location.headCountRequests[0].count : 0;
    },
    getHeadCountFulfilledPercent(approved, requested) {
      return !requested || requested === 0 ? 100 : approved / requested / Math.pow(10, -2);
    },
  },
  computed: {
    ...mapGetters(["getUserID", "getSelectedEmployee", "getStartDate", "getEndDate"]),
    headers() {
      if(this.locationSpecific) {
        return [
          new TableHeader({ label: "Progress", headerClass: "cell15", name: "progress", sortable: true, sortFunction: this.sortProgress, sorted: true, direction: Direction.DESC }),
          new TableHeader({ label: "# Requested", headerClass: "cell0", cellContents: this.requestedCount, sortable: true }),
          new TableHeader({ label: "Approved", headerClass: "cell10", cellContents: this.approvedCount, sortable: true, sortFunction: this.sortApproved }),
          new TableHeader({ label: this.fp.getStageLabel(FunnelStages.ENROLLMENT), headerClass: "cell10", cellContents: this.enrollmentCount, sortable: true, sortFunction: this.sortOnboarding }),
          new TableHeader({ label: this.fp.getStageLabel(FunnelStages.EARNINGS_METHOD), headerClass: "cell10", cellContents: this.earningsMethodCount, sortable: true, sortFunction: this.sortOnboarding }),
          new TableHeader({ label: this.fp.getStageLabel(FunnelStages.INSURANCE), headerClass: "cell10", cellContents: this.insuranceCount, sortable: true, sortFunction: this.sortOnboarding }),
          new TableHeader({ label: this.fp.getStageLabel(FunnelStages.RISK_MITIGATION), headerClass: "cell10", cellContents: this.riskMitigationCount, sortable: true, sortFunction: this.sortOnboarding }),
          new TableHeader({ label: "Application-Waitlist", headerClass: "cell10", cellContents: this.applicationCount, sortable: true, sortFunction: this.sortOnboarding }),
          new TableHeader({ label: "Waitlist Eligible", headerClass: "cell10", cellContents: this.waitlistEligibleCount, sortable: true, sortFunction: this.sortOnboarding }),
          new TableHeader({ label: this.fp.getStageLabel(FunnelStages.WITHDRAWN), headerClass: "cell10", cellContents: this.withdrawnCount, sortable: true, sortFunction: this.sortApproved })
        ]
      }else{
        return [
          new TableHeader({ label: "Location", headerClass: "cell15", cellContents: "name", name: "name", sortable: true }),
          new TableHeader({ label: "Progress", headerClass: "cell15", name: "progress", sortable: true, sortFunction: this.sortProgress, sorted: true, direction: Direction.DESC }),
          new TableHeader({ label: "# Requested", headerClass: "cell0", cellContents: this.requestedCount, sortable: true }),
          new TableHeader({ label: "Approved", headerClass: "cell10", cellContents: this.approvedCount, sortable: true, sortFunction: this.sortApproved }),
          new TableHeader({ label: this.fp.getStageLabel(FunnelStages.ENROLLMENT), headerClass: "cell10", cellContents: this.enrollmentCount, sortable: true, sortFunction: this.sortOnboarding }),
          new TableHeader({ label: this.fp.getStageLabel(FunnelStages.EARNINGS_METHOD), headerClass: "cell10", cellContents: this.earningsMethodCount, sortable: true, sortFunction: this.sortOnboarding }),
          new TableHeader({ label: this.fp.getStageLabel(FunnelStages.INSURANCE), headerClass: "cell10", cellContents: this.insuranceCount, sortable: true, sortFunction: this.sortOnboarding }),
          new TableHeader({ label: this.fp.getStageLabel(FunnelStages.RISK_MITIGATION), headerClass: "cell10", cellContents: this.riskMitigationCount, sortable: true, sortFunction: this.sortOnboarding }),
          new TableHeader({ label: "Application-Waitlist", headerClass: "cell10", cellContents: this.applicationCount, sortable: true, sortFunction: this.sortOnboarding }),
          new TableHeader({ label: "Waitlist Eligible", headerClass: "cell10", cellContents: this.waitlistEligibleCount, sortable: true, sortFunction: this.sortOnboarding }),
          new TableHeader({ label: this.fp.getStageLabel(FunnelStages.WITHDRAWN), headerClass: "cell10", cellContents: this.withdrawnCount, sortable: true, sortFunction: this.sortApproved })
        ]

      }
    },
    locationsRecruiting: function () {
      return this.locations.filter((location) => location.isRecruiting).length;
    },
    locationsWithRequests: function () {
      return this.locations.filter((location) => location.isRecruiting && this.requestedCount(location) > 0).length;
    },
    totalComplete: function () {
      return this.locations.filter((location) => location.progress === 100).length;
    },
    totalHeadCount: function () {
      return this.locations.reduce((total, location) => total + this.requestedCount(location), 0);
    },
    totalHeadCountFulfilled: function () {
      return this.locations.reduce((total, location) => {
        const approvedCount = this.approvedCount(location);
        if (location.isRecruiting) {
          const toAdd = approvedCount > location.recruitingGoal ? location.recruitingGoal : approvedCount;
          return total + toAdd;
        }
        return total;
      }, 0);
    },
    locations() {
      if (this.user && this.user.employees && this.user.employees.length > 0) {
        const employee = this.user.employees[0];
        if (employee.specialist) {
          return employee.specialist.locations;
        }
        if (employee.accountManager) {
          return employee.accountManager.locations;
        }
      }
      return [];
    },
    clientID() {
      return config.CLIENT_ID;
    },
    locationLoader() {
      return this.$props.loading
    },
  },
};
</script>
