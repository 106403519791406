<template>
  <div>
    <hr />
    <expansion-panel ref="personalFormPanel" label="View Personal Details">
      <form class="personal-details-form">
        <div class="module">
          <a v-if="getPermission('editCandidPII') && !editingPersonalDetails" class="button secondary" @click="editPersonalDetails">Edit Personal Details</a>
          <save-cancel v-if="editingPersonalDetails" emit-cancel @onSave="savePersonalDetails" @onCancel="cancelPersonalDetails" :saveEnabled="!ssnError && !licNumberError"/>
        </div>
        <div class="module">
          <form-input v-model="piiDetails.dob" label="Date of Birth" required :disabled="!editingPersonalDetails || isRoleOS" />
          <form-input v-model="piiDetails.ein" label="EIN" :disabled="!editingPersonalDetails || isRoleOS" />
          <form-input v-model="piiDetails.ssn" label="Social Security Number" required :disabled="!editingPersonalDetails || isRoleOS" @input="checkSsn" />
          <p class="error-message" v-if="ssnError">{{ssnErrorMsg}}</p>
          <h2>Drivers License</h2>
          <form-input v-model="piiDetails.licenseState" label="Drivers License State" required :disabled="!editingPersonalDetails || !getPermission('editDL')" @input="inputLicenseState" />
          <form-input v-model="piiDetails.licenseNumber" label="Drivers License Number" required :disabled="!editingPersonalDetails || !getPermission('editDL')" @input="inputLicense"/>
          <p class="error-message" v-if="licNumberError">{{errorLicense}}</p>
          <form-input v-model="piiDetails.licenseExpiry" label="Drivers License Expiration" required :disabled="!editingPersonalDetails || !getPermission('editDL')" />
          <h2>Address</h2>
          <form-input v-model="piiDetails.streetAddress" :disabled="!editingPersonalDetails" name="address" label="Address1" @input="getAddressSuggestions($event)" required  />
          <div class="box" v-if="addressSuggestions.length > 0">
            <div v-for="(item, index) in addressSuggestions" :key="index">
              <p class="suggestion-item" @click="setAddress(item.item)">{{ item.name }}</p>
            </div>
          </div>
          <form-input v-model="piiDetails.city" name="city" label="City" required  :disabled="!editingPersonalDetails || disableAddress" />
          <div class="field">
            <label 
              >State*
              <!-- <form-input  name="state" /> -->
              <region-select  v-model="piiDetails.state"  :region="piiDetails.state" placeholder="*****" autocomplete  :disabled="disablestate"/>
            </label>
          </div>
          <form-input v-model="piiDetails.zip" label="Zipcode" required :disabled="disablezipcode" />
        </div>
        <div class="module">
          <save-cancel v-if="editingPersonalDetails" emit-cancel @onSave="savePersonalDetails" @onCancel="cancelPersonalDetails" :saveEnabled="!ssnError && !licNumberError"/>
        </div>
      </form>
    </expansion-panel>
  </div>
</template>
<script>
import { ExpansionPanel, FormInput, SaveCancel } from "@/components";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";
import { encrypt } from "@/filters";
import { mapGetters } from "vuex";


export default {
  components: { FormInput, ExpansionPanel, SaveCancel },
  props: {
    candidate: {
      type: Object,
      required: true,
      default: () => {},
    },
    personalDetails: {
      type: Object,
      required: true,
      default: () =>{}
    }
  },
  data: () => ({
    editingPersonalDetails: false,
    clientName:"",
    disableAddress: false,
    disablestate: true,
    disablezipcode: true,
    addressSuggestions: [],
    licNumberError:false,
    errorLicense:'',
    ssnError: false,
    ssnErrorMsg: '',

    piiDetails: {
      ssn: "",
      dob: "",
      ein: "",
      licenseNumber: "",
      licenseExpiry: "",
      licenseState: "",
      streetAddress: "",
      state: "",
      city: "",
      zip: "",
     

    }
  }),
  watch: {
    personalDetails(value) {
      Object.assign(this.piiDetails, value);
    },
    searchInput(newText, oldText) {
      if (oldText !== "") {
        if (newText !== oldText) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          // const value = newText;
          // this.getAddressSuggestions(value);
        }, 1000);
      }
    }
  },
  mounted() {
    this.clientName=config.CLIENT_NAME;
  },
  computed: {
    ...mapGetters(["getPermission"]),
    candidateID() {
      return this.$props.candidate ? this.$props.candidate.id : null;
    },
    isRoleCoordinator() {
      return localStorage.getItem("role") == "COORDINATOR";
    },
    isRoleOS() {
      return localStorage.getItem("role") == "ONBOARDING_SPECIALIST";
    },
    searchInput() {
      return this.piiDetails.streetAddress;
    },
  },
  methods: {
    getAddressSuggestions(searchText) {
      const searchParams = encodeWithParam({search: searchText});
      restApi.post("/employee/getAddressSuggestions", searchParams)
        .then(({ data }) => {
          if (data.result.length > 0) {
            this.addressSuggestions = data.result.map((item) => {
              return {
                name: `${item.streetLine}, ${item.city}, ${item.state}, ${item.zipcode}`,
                item,
              };
            });
            this.disableAddress = true;
          } else {
            this.addressSuggestions = [
              {
                name: "Unknown address, click here to enter manually",
                item: {},
              },
            ];
            this.disableAddress = false
          }
        })
    },
    
    async inputLicense(value) {
      // console.log(value);
       await restApi.post(`/partner/checkValueExists`, { 
      "param": window.btoa(JSON.stringify({ type: 'license', value: value, dlState : this.piiDetails.licenseState }))
  }).then(({ data }) => {
    // console.log("data", data);
    if(data.result.exists && value.length > 1){
            
            this.licNumberError = true;
            this.errorLicense = "Drivers License Number already exists."
           }
           else{
            this.licNumberError = false;
            this.errorLicense = ""
           }
  })},
  
    setAddress(data) {
      if (Object.keys(data).length > 0) {
        this.piiDetails.streetAddress = data.streetLine;
        this.piiDetails.city = data.city;
        //this.form.address.county = data.county;
        this.piiDetails.state = data.state;
        this.piiDetails.zip = data.zipcode;
        this.addressSuggestions = [];
      }
      
    },
    editPersonalDetails() {
      this.editingPersonalDetails = true;
      this.toggleMaskDetails(false);
      this.disableAddress = false;

    },
    async toggleMaskDetails(mask) {
      const decoded = !mask ? this.$props.personalDetails.decoded : this.$props.personalDetails;
      if (!this.isRoleOS) {
        this.piiDetails.dob = mask ? decoded.dob : decoded.ddateOfBirth;
        this.piiDetails.ein = mask ? decoded.ein : decoded.dein;
      }
      this.piiDetails.ssn = mask ? decoded.ssn :decoded.dssn;
      this.piiDetails.licenseNumber = mask ? decoded.licenseNumber : decoded.dnumber;
      this.piiDetails.licenseExpiry = mask ? decoded.licenseExpiry : decoded.dexpiration;
      this.piiDetails.licenseState = mask ? decoded.licenseState : decoded.dlicenseState
      this.piiDetails.streetAddress = mask ? decoded.streetAddress : decoded.daddress1;
      this.piiDetails.city = mask ? decoded.city : decoded.dcity;
      this.piiDetails.state = mask ? decoded.state : decoded.dstate;
      this.piiDetails.zip = mask ? decoded.zip : decoded.dpostalCode;
    },
    savePersonalDetails() {
      this.piiDetails.licenseState = this.piiDetails.licenseState.trim()
      this.piiDetails.licenseNumber = this.piiDetails.licenseNumber.trim()
      this.piiDetails.licenseExpiry = this.piiDetails.licenseExpiry.trim()
      this.piiDetails.streetAddress = this.piiDetails.streetAddress.trim()
      this.piiDetails.city = this.piiDetails.city.trim()
      this.piiDetails.state = this.piiDetails.state.trim()
      this.piiDetails.zip = this.piiDetails.zip.trim()
      const obj = Object.assign({}, this.piiDetails);
      this.$emit("onSavePersonalDetail", obj);
      this.cancelPersonalDetails();
    },
    cancelPersonalDetails() {
      this.editingPersonalDetails = false;
      this.toggleMaskDetails(true);
      this.disableAddress = false;
      this.licNumberError = false;
      this.addressSuggestions = []
    },
    isMasked(value){
      return value.includes("*")
    },
    async checkSsn(val) {
      let sanitizedSSN = val.replace(/\D/g, '');
      const maxLength = 11;
      const maxLengthWithoutHyphen = 9;

      if (val.length <= maxLength) {
        this.ssnError=true;
        this.ssnErrorMsg='';
        if (sanitizedSSN.length > 3 && sanitizedSSN.length <= 5) {
          this.piiDetails.ssn = `${sanitizedSSN.slice(0, 3)}-${sanitizedSSN.slice(3)}`;
        } else if (sanitizedSSN.length > 5) {
          this.piiDetails.ssn = `${sanitizedSSN.slice(0, 3)}-${sanitizedSSN.slice(3, 5)}-${sanitizedSSN.slice(5)}`;
        } else {
          this.piiDetails.ssn = sanitizedSSN;
        }
      }
      else {
        this.piiDetails.ssn = `${sanitizedSSN.slice(0, 3)}-${sanitizedSSN.slice(3, 5)}-${sanitizedSSN.slice(5)}`;
        this.ssnError=true;
        this.ssnErrorMsg='Please Enter Valid SSN';
      }
      if (val.length === maxLength && sanitizedSSN.length === maxLengthWithoutHyphen) {
        let ssnCheck = await encrypt(val.replace(/\D/g, ''))
        const data = await restApi.post("/employee/checkSSN", encodeWithParam({ ssn: ssnCheck }));
         this.ssnError=false;
        if(Number(data.data?.result?.count) > 0){
          this.ssnError=true;
          this.ssnErrorMsg='Duplicate SSN'
        }
      }
    },

    async inputLicenseState(value) {
      console.log(value);
       await restApi.post(`/partner/checkValueExists`, { 
      "param": window.btoa(JSON.stringify({ type: 'license', value: this.piiDetails.licenseNumber , dlState : value }))
      }).then(({ data }) => {
          if(data.result.exists && value.length > 1){
            
            this.licNumberError = true;
            this.errorLicense = "Drivers License Number already exists."
           }
           else{
            this.licNumberError = false;
            this.errorLicense = ""
           }
  })},


  },
};
</script>
<style scoped>
.box {
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  font-family: "Open Sans";
  position: absolute;
  background-color: white;
  margin-top: -15px;
  z-index: 5;
  cursor: pointer;
}
.suggestion-item {
  padding: 5px;
  cursor: pointer;
  margin-bottom: 0;
}
.suggestion-item:hover {
  background-color: lightgray;
}
</style>