<template>
  <add-edit-page className="full-white">
    <template #header="{ validation }">
      <sticky-header :title="title" classes = "margin-zero">
        <save-cancel :save-enabled="!validation.pristine && !validation.invalid" on-can @onSave="saveLocation" />
      </sticky-header>
    </template>
    <location-form v-model="location" :accountManagers="accountManagers" :specialists="specialists" @onSpecialistChanged="specialistChanged" @onAMChanged="amChanged" />
    <template #bottomContent="{ validation }">
      <save-cancel :save-enabled="!validation.pristine && !validation.invalid" on-can @onSave="saveLocation" />
    </template>
  </add-edit-page>
</template>

<script>
import LocationForm from "@/modules/admin/clients/components/LocationForm";
// import { CREATE_CLIENT_LOCATION, CREATE_ONBOARDING_SPECIALIST, UPDATE_CLIENT_LOCATION_DETAILS } from "@/modules/admin/clients/locations/mutations";
// import { FIND_AVAILABLE_SPECIALISTS } from "@/modules/admin/clients/graph/queries"; // , GET_CLIENT_DETAILS_BY_PK
import { AddEditPage, SaveCancel, StickyHeader } from "@/components";
import { mapGetters } from "vuex";
// import { CREATE_FINANCE_TEMPLATE } from "@/modules/admin/clients/graph/mutations";
// import { CREATE_LOCATION_FUNNEL } from "@/modules/admin/clients/locations/mutations";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";

export default {
  name: "AddClientLocation",
  components: { LocationForm, AddEditPage, StickyHeader, SaveCancel },
  data() {
    return {
      location: {
        clientID: config.CLIENT_ID,
        name: "",
        addressID: "",
        address: {
          data: {
            address1: "",
            address2: "",
            address3: "",
            city: "",
            state: "",
            postalCode: "",
            country: "",
          },
        },
        startDate: "",
        timeZone: "",
        phone: "",
        ageRequirement: "",
        federalTaxID: "",
        isRecruiting: "",
        taxProgram: "",
        mvrPackageName: "",
        screeningPackageName: "",
      },
      teams: [],
      specialists: [],
      accountManagers: [],
      selectedTeam: null,
      selectedSpecialist: null,
      selectedAM: null,
      roles: [
        "ONBOARDING_ACCOUNT_MANAGER",
        "ONBOARDING_ANALYST"
      ],
      statuses: ["ACTIVE"],
    };
  },
  computed: {
    ...mapGetters(["getUsername"]),
    title() {
      return "New Location";
    },
    clientID() {
      return config.CLIENT_ID;
    },
  },
  mounted(){
    this.fetchClientDetails();
    this.fetchEmployees();
  },
  methods: {
    async fetchClientDetails() {
      let clientName = process.env.CLIENT_NAME || "Walmart";
      await restApi.post(`/location/getClientDetails`, encodeWithParam({clientName: clientName})).then((data)=>{
        this.client = data.data.data;
        this.location = {
          ...this.location,
          addressID: this.client.addressID, 
          timeZone: this.client.timeZone,
          startDate: this.client.startDate,
          phone: this.client.phone,
          taxProgram: this.client.taxProgram,
          isRecruiting: this.client.isRecruiting,
          ageRequirement: this.client.ageRequirement,
          insuranceSetting: this.client.insuranceSetting,
          businessType: this.client.businessType,
          federalTaxID: this.client.federalTaxID,
          mvrPackageName: this.client.clientConfiguration?.mvrPackageName ? this.client.clientConfiguration.mvrPackageName : "",
          screeningPackageName: this.client.clientConfiguration?.screeningPackageName ? this.client.clientConfiguration.screeningPackageName : "",
        };
        this.location.address.data.address1 = this.client.address1;
        this.location.address.data.address2 = this.client.address2;
        this.location.address.data.city = this.client.city;
        this.location.address.data.state = this.client.state;
        this.location.address.data.postalCode = this.client.postalCode;
        this.location.address.data.country = this.client.country;
      });
    },
    async fetchEmployees() {
      const param = {
        roles: this.roles,
        statuses: this.statuses,
        limit: 90000,
        offset: 0,
        searchTerm: "%"
      }
      await restApi.post('employee/getEmployees', encodeWithParam(param))
        .then(data => {
          let employees = data.data.result;
          // console.log(employees);
          if(employees && employees.length > 0) { 
            for(let i = 0; i < employees.length; i++){
              if(employees[i].role_name == "ONBOARDING_ACCOUNT_MANAGER") {
                this.accountManagers.push({ id: employees[i].id, name: `${employees[i].firstName} ${employees[i].lastName}`.trim(), specialistID: employees[i].id });
              } else if(employees[i].role_name == "ONBOARDING_ANALYST") {
                this.specialists.push({ id: employees[i].id, name: `${employees[i].firstName} ${employees[i].lastName}`.trim(), specialistID: employees[i].id });
              }
            }
            // console.log("this.accountManagers");
            // console.log(this.accountManagers);
          }
        });
    },
    async saveLocation() {
      // console.log("this.selectedSpecialist.specialistID");
      // console.log(this.selectedSpecialist.specialistID);
        // this.location.userID = this.$store.getters.getUserID;
        this.location.accountManagerID = this.selectedAM?.id;
        this.location.specialistID = this.selectedSpecialist?.specialistID;
        let locationConfiguration = {};
        if (this.location.mvrPackageName && this.location.mvrPackageName.length > 0) {
          locationConfiguration.mvrPackageName = this.location.mvrPackageName;
        }
        if (this.location.screeningPackageName && this.location.screeningPackageName.length > 0) {
          locationConfiguration.screeningPackageName = this.location.screeningPackageName;
        }
        this.location.locationConfiguration = locationConfiguration;
        this.location.clientID = config.CLIENT_ID;
        let finalLocation = this.location;
      delete finalLocation.mvrPackageName;
      delete finalLocation.screeningPackageName;
      finalLocation.funnelFiles = this.createFunnel();
      // console.log("before save finalLocation", finalLocation);
      await restApi.post('location/UpdateClientLocationDetails', encodeWithParam(finalLocation))
      .then(() => {
        // console.log("data");
        // console.log(data);
        this.$router.push({ name: "client", params: { id: config.CLIENT_ID }});
      });
    },
    // async saveLocation() {
    //   if (this.selectedTeam) {
    //     this.location.teamID = this.selectedTeam.id;
    //     if (this.selectedSpecialist) {
    //       let specialistID = this.selectedSpecialist.specialistID;
    //       if (!specialistID) {
    //         const newSpecialist = { employeeID: this.selectedSpecialist.id, teamID: this.selectedTeam.id, assignedBy: this.getUsername };
    //         await this.$apollo.mutate({ mutation: CREATE_ONBOARDING_SPECIALIST, variables: { teamSpecialist: newSpecialist } }).then(({ data }) => {
    //           specialistID = data.specialist.id;
    //         });
    //       }
    //       this.location.specialistID = specialistID;
    //     }
    //   }
    //   let locationConfiguration = {};
    //   if (this.location.mvrPackageName && this.location.mvrPackageName.length > 0) {
    //     locationConfiguration.mvrPackageName = this.location.mvrPackageName;
    //   }
    //   if (this.location.screeningPackageName && this.location.screeningPackageName.length > 0) {
    //     locationConfiguration.screeningPackageName = this.location.screeningPackageName;
    //   }
    //   this.location.locationConfiguration = locationConfiguration;
    //   let finalLocation = this.location;
    //   delete finalLocation.mvrPackageName;
    //   delete finalLocation.screeningPackageName;
    //   const mutationOptions = {};
    //   mutationOptions.mutation = this.id ? UPDATE_CLIENT_LOCATION_DETAILS : CREATE_CLIENT_LOCATION;
    //   mutationOptions.variables = this.id ? { id: this.id, location: finalLocation } : { location: finalLocation };
    //   await this.$apollo
    //     .mutate(mutationOptions)
    //     .then(({ data }) => {
    //       const clientID = data.insert_clientLocations_one.clientID;
    //       const locationID = data.insert_clientLocations_one.id;
    //       this.createFunnel(locationID);
    //       this.$apollo.mutate({
    //         mutation: CREATE_FINANCE_TEMPLATE,
    //         variables: {
    //           financeTemplate: { clientID, locationID },
    //         },
    //       });
    //       return locationID;
    //     })
    //     .finally(() => this.$router.push({ name: "client", params: { id: this.clientID } }));
    // },
    amChanged(amChangedEvent) {
      this.selectedAM = amChangedEvent.itemAdded;
    },
    specialistChanged(specialistChangedEvent) {
      this.selectedSpecialist = specialistChangedEvent.itemAdded;
    },
    createFunnel() {
      let param = {
        steps: [
            { displayName: "Signup", step: "SIGNUP", index: 0 },
            { displayName: "Verification", step: "VERIFICATION", index: 1 },
            { displayName: "Eligibility", step: "ELIGIBILITY", index: 2 },
            { displayName: "Personal Details", step: "PERSONAL_DETAILS", index: 3 },
            { displayName: "Documents", step: "DOCUMENTS", index: 4 },
            { displayName: "Payment Agreement", step: "PAYMENT_AGREEMENT", index: 5 },
            { displayName: "Screening", step: "SCREENING", index: 6 },
            { displayName: "Completed", step: "COMPLETED", index: 7 },
            { displayName: "Approved", step: "APPROVED", index: 8 },
        ],
        funnelFiles: { data: [{ label: "insurance", order: 0 }] },
        questions: [
          {
            step: "ELIGIBILITY",
            questionText: "Do you have a clean driving record?",
            type: "radio",
            required: true,
            qualifier: false,
            infoText: null,
            index: 0,
            key: "hasCleanRecord",
            options: [
              {
                key: "YES",
                qualifies: true,
                optionText: "Yes",
              },
              {
                key: "NO",
                qualifies: false,
                optionText: "No",
              },
            ],
          },
          {
            step: "ELIGIBILITY",
            questionText: "Are you over 18 years of age?",
            type: "radio",
            required: true,
            qualifier: true,
            infoText: null,
            index: 1,
            key: "isOver18",
            options: [
              {
                key: "YES",
                qualifies: true,
                optionText: "Yes",
              },
              {
                key: "NO",
                qualifies: false,
                optionText: "No",
              },
            ],
          },
          {
            step: "ELIGIBILITY",
            questionText: "Vehicle Model",
            type: "text",
            required: true,
            qualifier: true,
            infoText: null,
            index: 4,
            key: "vehicleModel",
            options: {
              url: "/vehicles/GetModelsForMakeIdYear/makeId/{{vehicleMake}}/modelyear/{{vehicleYear}}?format=json",
              dependencies: ["vehicleYear", "vehicleMake"],
            },
          },
          {
            step: "ELIGIBILITY",
            questionText: "Vehicle Year",
            type: "text",
            required: true,
            qualifier: true,
            infoText: null,
            index: 2,
            key: "vehicleYear",
            options: {
              type: "intrange",
              lower: 1950,
              upper: null,
            },
          },
          {
            step: "ELIGIBILITY",
            questionText: "Vehicle Make",
            type: "text",
            required: true,
            qualifier: true,
            infoText: null,
            index: 3,
            key: "vehicleMake",
            options: {
              url: "/vehicles/GetAllMakes?format=json",
            },
          },
          {
            step: "ELIGIBILITY",
            questionText: "Do you have an Android or iOS smartphone?",
            type: "radio",
            required: true,
            qualifier: true,
            infoText: null,
            index: 5,
            key: "hasSmartphone",
            options: [
              {
                key: "YES",
                qualifies: true,
                optionText: "Yes",
              },
              {
                key: "NO",
                qualifies: true,
                optionText: "No",
              },
            ],
          },
          {
            step: "ELIGIBILITY",
            questionText: "Are you currently a Walmart Associate?",
            type: "radio",
            required: true,
            qualifier: true,
            infoText:
              "Walmart associates (an associate of Walmart Inc. or one of its subsidiary companies in the United States) are not eligible to provide services through the Spark App. Any Walmart associate who provides false information regarding their status as a Walmart associate may be subject to disciplinary action up to, and including, termination.",
            index: 6,
            key: "isWalmartAssociate",
            options: [
              {
                key: "YES",
                qualifies: false,
                optionText: "Yes",
              },
              {
                key: "NO",
                qualifies: true,
                optionText: "No",
              },
            ],
          },
            {
              step: "ELIGIBILITY",
              questionText: "How did you hear about this opportunity?",
              type: "dropdown",
              required: true,
              qualifier: true,
              index: 7,
              key: "howDidYouHearAboutOpportunity",
              options: [
                {
                  key: "social-media",
                  qualifies: true,
                  optionText:"Social Media"
                },
                {
                  key: "job-advertisement",
                  optionText: "Job Advertisement",
                  qualifies: true,
                },
                {
                  key: "friend-family",
                  optionText: "Friend or Family",
                  qualifies: true,
                },
                {
                  key: "walmart-store",
                  optionText: "Local Walmart Store",
                  qualifies: true,
                },
                {
                  key: "other",
                  optionText: "Other",
                  qualifies: true,
                },
              ],
            },
        ],
      };
      return param;
    },
  },
  apollo: {
    // client: {
    //   query: GET_CLIENT_DETAILS_BY_PK,
    //   variables() {
    //     return {
    //       id: this.clientID,
    //     };
    //   },
    //   result({ data }) {
    //     this.client = data.client;
    //     this.location = {
    //       ...this.location,
    //       timeZone: this.client.timeZone,
    //       startDate: this.client.startDate,
    //       phone: this.client.phone,
    //       taxProgram: this.client.taxProgram,
    //       isRecruiting: this.client.isRecruiting,
    //       ageRequirement: this.client.ageRequirement,
    //       insuranceSetting: this.client.insuranceSetting,
    //       businessType: this.client.businessType,
    //       federalTaxID: this.client.federalTaxID,
    //       mvrPackageName: this.client.clientConfiguration.mvrPackageName,
    //       screeningPackageName: this.client.clientConfiguration.screeningPackageName,
    //     };
    //     this.location.address.data.address1 = this.client.address1;
    //     this.location.address.data.address2 = this.client.address2;
    //     this.location.address.data.city = this.client.city;
    //     this.location.address.data.state = this.client.state;
    //     this.location.address.data.postalCode = this.client.postalCode;
    //     this.location.address.data.country = this.client.country;

    //     this.location.address.data.postalCode = this.client.postalCode;
    //     // this.teams = this.client.departmentManagers
    //     //   .flatMap((departmentManager) => departmentManager.manager)
    //     //   .flatMap((manager) => manager.teamsManaged)
    //     //   .flatMap((teamsManaged) => teamsManaged.team)
    //     //   .map((team) => {
    //     //     const firstName = team.accountManager ? team.accountManager.firstName : "";
    //     //     const lastName = team.accountManager ? team.accountManager.lastName : "";
    //     //     const name = firstName || lastName ? `${firstName} ${lastName}`.trim() : "none";
    //     //     return { id: team.id, name: `${team.name} - ${name}` };
    //     //   })
    //     //   .sort((team1, team2) => team1.name.localeCompare(team2.name));
    //   },
    //   skip() {
    //     return !this.clientID;
    //   },
    // },
    // employees: {
    //   fetchPolicy: "no-cache",
    //   query: FIND_AVAILABLE_SPECIALISTS,
    //   variables() {
    //     return {
    //       teamID: this.selectedTeam.id,
    //     };
    //   },
    //   result({ data }) {
    //     this.specialists = data.employees.map((employee) => {
    //       return { id: employee.id, name: `${employee.firstName} ${employee.lastName}`.trim(), specialistID: employee.specialist ? employee.specialist.id : null };
    //     });
    //   },
    //   skip() {
    //     return !this.selectedTeam;
    //   },
    // },
  },
};
</script>
