<template>
  <th :class="headerClass" @click="sort(index); toggle()">{{ headerText }}<i v-if="isSorting || header.display" :class="arrow" /><i v-if="isAccordian" :class="accordian" /></th>
</template>

<script>
import TableHeader from "@/components/table/TableHeader";
import Direction from "@/components/table/direction";

export default {
  name: "DataTableHeader",
  data: () => ({
    toggleAccordian: false
  }),
  props: {
    header: {
      type: [String, TableHeader],
      required: true,
      default: "",
    },
    index: {
      type: Number,
      required: true,
      default: -1,
    },
  },
  computed: {
    headerText: function () {
      return typeof this.header === "string" ? this.header : this.header.label;
    },
    headerClass: function () {
      if (typeof this.header === "string") {
        return "";
      }

      return this.header.headerClass;
    },
    arrow: function () {
      if (this.isSorting || this.header.display) {
        return this.header.direction === Direction.DESC ? "icon-arrow-down" : "icon-arrow-up";
      }

      return "";
    },
    accordian: function () {
      return this.toggleAccordian ? "icon-chevron-up" : "icon-chevron-down";
    },
    isAccordian: function () {
      return this.header.accordian;
    },
    isSorting() {
      if (typeof this.header === "string") {
        return false;
      }
      return this.header.sortable && this.header.sorted;
    },
  },
  methods: {
    sort(index) {
      this.$emit("onSort", index);
    },
    toggle() {
      if (this.header.accordian) {
        this.toggleAccordian = !this.toggleAccordian;
        this.$emit("onToggle", this.toggleAccordian);
      }
    }
  },
};
</script>
