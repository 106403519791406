<template>
  <base-modal id="modal-add-document">
    <div class="module">
      <h4>Add a Document to <span v-if="$props.location">Location</span><span v-else>{{ name }}</span></h4>
    </div>
    <div class="module-large">
      <h5>New Document</h5>
      <p>
        <a class="button secondary" @click="createDocument">Create a Document</a>
      </p>
    </div>

    <div class="module">
      <!-- <h5>Existing Document</h5>
      <div class="grid">
        <div class="col-6">
          <form-select :value="selected" :options="clients" option-label="name" label="" @change="onChange" />
        </div>
      </div> -->
      <div class="grid">
        <div class="col-6">
          <form class="search-container">
            <label><input v-model="findField" type="search" placeholder="Find Existing Document" /></label>
            <button type="submit"><span v-if="searchIcon" class="icon-x deposit-icon" @click="() => (findField = '')"></span> <span v-else class="icon-search deposit-icon"></span></button>
          </form>
        </div>
        <div class="col-6 inline-offset check-box-display"  >
          <div class="checkbox-custom" :style="{'margin-right': '24px'}">
            <input id="show-active" v-model="showInactive" type="checkbox" @click="showInactiveDocs" />
            <label for="show-active"><span class="label-container">Show Inactive</span> </label>
          </div>

          <!-- <div class="checkbox-custom checkbox-custom-card" >
          <input class="checkbox-custom-input" id="optional" v-model="optional" type="checkbox" @click="makeDocOptional" />
          <label class="checkbox-custom-label" for="optional"><span class="label-container">Optional</span> </label>
        </div> -->
        </div>
      </div>
      <div class="search-results well small">
        <div v-if="filteredTemplates.length == 0" class="doc-center">
          <p>No records found</p>
        </div>
        <ul v-for="template in filteredTemplates" :key="template.id" class="list-unstyled">
          <li>
            <a @click="selectTemplate(template)">
              <div class="name">
                <p>{{ template.title }} <span v-if="template.status === 'inactive'"> (Inactive)</span><span v-if="template.locationID !== null"> (Location)</span></p>
              </div>
              <div class="date">
                <p>{{ template.updated_at | formatDateTime }}</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>

 
    
    <p>
      <a class="button secondary" data-micromodal-close>Cancel</a>
    </p>
    <create-edit-document modal-id="create-new-document" title="Create New Document" @documentModified="documentModified" />
  </base-modal>
</template>
<script>
import BaseModal from "@/components/modals/BaseModal";
import { ENABLE_TEMPLATE } from "@/modules/shared/documents/graph/mutations";
import micromodal from "micromodal";
//import FormSelect from "@/components/forms/fields/FormSelect";
import CreateEditDocument from "@/modules/shared/documents/CreateEditDocument";
import documentService from "@/services/document.service";
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
export default {
  name: "AddDocumentModal",
  components: { CreateEditDocument, BaseModal },
  props: {
    ddi: { type: Boolean, required: false, default: false },
    location: { type: Boolean, required: false, default: false },
    partner: { type: Boolean, required: false, default: false },
    selectedTemplates: {type: Array,required: true,default:()=>[]}
  },
  data: function () {
    return {
      clients: [],
      selected: config.CLIENT_ID,
      name: config.CLIENT_NAME,
      showInactive: false,
      optional: false,
      findField: "",
      locationID: this.$route.params.locationID,
      status: "published",
      ddiTemplates: [],
      
    };
  },

  computed: {
    ...mapGetters(["getFullName"]),
    filteredTemplates() {
      let templates = [];
        templates = this.sortTemplates(this.$props.selectedTemplates);
      if (!this.showInactive) {
        templates = templates.filter((template) => template.status !== "inactive");
      }
      if (this.findField === "") {
        return templates;
      } else {
        return this.findTemplate(templates);
      }
    },
    isClient() {
      return !!this.$route.params.id && !this.$route.params.locationID;
    },
    isLocation() {
      return !!this.$route.params.locationID;
    },
    searchIcon() {
      return this.findField !== "";
    },
  },
  methods: {
    createDocument() {
      micromodal.show("create-new-document", {});
      // micromodal.close("modal-add-document")
      // if (this.isLocation) {
      //   this.$router.push({ name: "createLocationTemplate", query: { clientID: this.$route.params.id, locationID: this.$route.params.locationID } });
      // }
      // // else if (this.$route.params.id && this.$props.partner) {
      // //   this.$router.push({ name: "createDdiTemplate", query: { partnerId: this.$route.params.id } });
      // // }
      // else if (this.isClient) {
      //   this.$router.push({ name: "createClientTemplate", query: { clientID: this.$route.params.id } });
      // } else {
      //   this.$router.push({ name: "createDdiTemplate" });
      // }
    },
    documentModified() {
      this.$emit('documentModified');
    },
    onChange(e) {
      this.$props.selectedTemplates = [];
      this.$props.selected = e;
    },
    removeInheritance: function (inheritance, templates) {
      let arr = templates;
      inheritance.forEach((item) => {
        let index = arr.findIndex((template) => {
          return template.id === item.documentTemplate.id;
        });
        if (index !== -1) {
          arr.splice(index, 1);
        }
      });
      return arr;
    },
    findTemplate: function (array) {
      const regex = new RegExp(this.findField, "i");
      return array.filter((item) => regex.test(item.title));
    },
    sortTemplates: function (array) {
      return array.sort((a, b) => {
        let nameA = a.title.toUpperCase();
        let nameB = b.title.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    },
    closeModal: function () {
      this.selected = this.$route.params.id;
      micromodal.close("modal-add-document");
    },
    enableTemplate: function (id) {
      this.$apollo
        .mutate({
          mutation: ENABLE_TEMPLATE,
          variables: {
            id,
            disabled: false,
          },
        })
        .then(({ data }) => {
          if (!this.isLocation) {
            if (data.update_documentTemplates?.returning[0]?.client?.locations.length > 0) {
              data.update_documentTemplates.returning[0].client.locations.forEach((location) => this.inheritClientToLocation(id, location.id));
            }
          }
          this.closeModal("modal-add-document");
        });
    },
    createTemplateBasedOn: function (template) {
      return this.$router.push({
        path: `${this.$route.path}/documents/create`,
        query: { documentID: template.id },
      });
    },
    selectTemplate: async function (template) {
      // DDI Level
      let docTemplate;
      if (!this.isClient && !this.isLocation) {
        //this.$router.push({ name: "createDdiTemplate", query: { documentID: template.id, inherited: "true" } });
        docTemplate = this.createDDIObject(template);
      }
      // Client Level
      else if (this.isClient) {
        //this.$router.push({ name: "createClientTemplate", query: { documentID: template.id, clientID: this.$route.params.id } });
        docTemplate = this.createClientObject(template);
      }
      // Location Level
      else if (this.isLocation) {
        //this.$router.push({ name: "createLocationTemplate", query: { documentID: template.id, clientID: this.$route.params.id, locationID: this.$route.params.locationID } });
        docTemplate = this.createLocationObject(template);
      }
      await documentService.createDocumentTemplate(docTemplate);
      micromodal.close("modal-add-document");
    },
    showInactiveDocs($event) {
      if ($event.target.checked === true) {
        this.showInactive = true;
        this.status = "inactive";
      } else {
        this.showInactive = false;
        this.status = "published";
      }
    },
    makeDocOptional($event) {
      this.optional = $event.target.checked === true ? true : false;
    },
    createDDIObject(template) {
      const uuid = uuidv4();
      const obj = {
        version: template.isDDI ? Number(template?.version) + 1 : 1,
        isDDI: true,
        clientID: null,
        locationID: null,
        originalID: template.isDDI ? template.originalID : uuid,
        previousID: template.isDDI ? template.id : null,
      };
      return { ...this.createDocTemplateObject(template, uuid), ...obj };
    },
    createClientObject(template) {
      const uuid = uuidv4();
      const obj = {
        version: this.validateClientDocScenario(template) ? Number(template?.version) + 1 : 1,
        isDDI: false,
        clientID: this.$route.params.id,
        locationID: null,
        originalID: this.validateClientDocScenario(template) ? template.originalID : uuid,
        previousID: this.validateClientDocScenario(template) ? template.id : null,
      };
      return { ...this.createDocTemplateObject(template, uuid), ...obj };
    },
    validateClientDocScenario(template) {
      return template.clientID == this.$route.params.id && template.locationID == null;
    },
    createLocationObject(template) {
      const uuid = uuidv4();
      const obj = {
        version: this.validateLocationDocScenario(template) ? Number(template?.version) + 1 : 1,
        isDDI: false,
        clientID: this.$route.params.id,
        locationID: this.$route.params.locationID,
        originalID: this.validateLocationDocScenario(template) ? template.originalID : uuid,
        previousID: this.validateLocationDocScenario(template) ? template.id : null,
      };
      return { ...this.createDocTemplateObject(template, uuid), ...obj };
    },
    validateLocationDocScenario(template) {
      return template.locationID == this.$route.params.locationID;
    },
    createDocTemplateObject(template, uuid) {
      return {
        id: uuid,
        text: template?.text,
        title: template.title,
        updatedBy: this.getFullName,
        html: template.html,
        templateStatus: "draft",
        optional: this.optional
      };
    },
  },
  apollo: {
    $subscribe: {
      // documentTemplates: {
      //   fetchPolicy: "no-cache",
      //   query: DOCUMENT_TEMPLATE_ORIGINAL_SUBSCRIPTION,
      //   variables() {
      //     return {
      //       id: this.selected,
      //       locationID: this.locationID ? this.locationID : "00000000-0000-0000-0000-000000000000",
      //     };
      //   },
      //   result({ data }) {
      //     if (this.isClient) {
      //       this.selectedTemplates = data.documentTemplates.filter((template) => {
      //         if (template.clientID !== null) {
      //           return template;
      //         }
      //       });
      //     } else {
      //       this.selectedTemplates = data.documentTemplates.filter((template) => {
      //         if (template.clientID !== null || template.locationID !== null) {
      //           return template;
      //         }
      //       });
      //     }
      //   },
      //   skip() {
      //     return this.selected === "0";
      //   },
      // },
      // ddiTemplates: {
      //   fetchPolicy: "no-cache",
      //   query: DDI_DOCUMENT_TEMPLATE_ORIGINAL_SUBSCRIPTION,
      //   result({ data }) {
      //     this.ddiTemplates = data.documentTemplates;
      //   },
      //   skip() {
      //     return this.selected !== "0";
      //   },
      // },
    },
    // clients: {
    //   query: GET_CLIENTS_DETAILS,
    //   result({ data }) {
    //     this.clients = [{ name: "DDI", id: "0" }, ...data.clients];
    //   },
    // },
  },
};
</script>
<style>
.checkbox-custom-input{
  width: 20px !important;
  height: 20px !important;
  position: unset !important;

}
.checkbox-custom-input[type="checkbox"]:checked + label::before {
    display: none !important;
}
.checkbox-custom-input[type="checkbox"]:checked + label::after {
    display: none !important;
}

.checkbox-custom-label {
    position: unset !important;
    padding: 3px 0 0 6px !important;
}

.checkbox-custom-label:before {
  display: none !important;

}
.checkbox-custom-label::after {
  display: none !important;

}

.check-box-display 
  {
    display: inline-flex; 
    flex-flow: wrap-reverse
  }

</style>
