import Vue from "vue";
import VueRouter from "vue-router";
import { LoginRoutes } from "@/modules/login";
import { AdminRoutes } from "@/modules/admin";
import authGuard from "@/security/authGuard";

Vue.use(VueRouter);

const routes = [
  ...LoginRoutes,
  ...AdminRoutes,
  {
    path: "/",
    name: "home",
    redirect: { name: "onboarding" },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach(authGuard);

export default router;
