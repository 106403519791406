var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('span',[_vm._v(_vm._s(_vm.restAPI))]),_c('h2',[_vm._v("Onboarding")]),_c('div',{staticClass:"module-xlarge"},[_c('h3',[_vm._v("Current Active Partners")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"current-active-partners"},[_c('div',{staticClass:"active-partners"},[_vm._v(_vm._s(_vm.activePartners))]),_c('div',{staticClass:"title"},[_vm._v("Current Active Roster")])]),_c('tooltip',{attrs:{"id":"active-partners-tooltip","title":"","description":"Active partners are partners who have rendered services for this location in the latest completed pay cycle and newly approved partners.","hover":""}}),_c('location-enrollment-status',{attrs:{"entries":_vm.currentWeek,"location-specific":true}})],1)]),_c('div',{staticClass:"module-xlarge"},[_c('h3',[_vm._v("New and Active Requests")]),_c('data-table',{attrs:{"headers":_vm.activeRequestHeaders,"entries":_vm.activeRequests},scopedSlots:_vm._u([{key:"week",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDate")(item.startDate))+" - "+_vm._s(_vm._f("formatDate")(item.endDate)))]}},{key:"count",fn:function(ref){
var item = ref.item;
return [(item.count == 0 && !item.editing)?_c('strong',[_vm._v("no request")]):_vm._e(),(item.count != 0 && !item.editing)?_c('fragment',[_vm._v(_vm._s(item.count))]):_vm._e(),(item.editing)?_c('div',{staticClass:"spread-number"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.count),expression:"item.count"}],ref:item.key,attrs:{"name":item.key,"type":"number","min":"1","max":"10"},domProps:{"value":(item.count)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(item, "count", $event.target.value)},function($event){return _vm.updateCount(item, $event)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit(item)},"focus":function($event){return $event.target.select()}}})]):_vm._e()]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [(!item.count && !item.editing && !item.locked && _vm.getPermission('editHCR'))?_c('a',{on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("Make Request")]):_vm._e(),(item.count && !item.editing && !item.locked && _vm.getPermission('editHCR'))?_c('a',{on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("Edit")]):_vm._e(),(item.locked)?_c('fragment',[_vm._v("locked")]):_vm._e(),(item.editing)?_c('a',{staticClass:"button secondary small",on:{"click":function($event){return _vm.submit(item)}}},[_vm._v("Submit")]):_vm._e()]}}])})],1),_c('div',{staticClass:"module-xlarge"},[_c('h3',[_vm._v("Past Requests")]),_c('p',{staticClass:"label"},[_vm._v("Filters")]),_c('base-filter',{attrs:{"name":"Show the Last","options":_vm.filterOptions,"type":"radio"},on:{"onFilter":function($event){_vm.selectedFilters = $event}}}),_c('data-table',{attrs:{"headers":_vm.headers,"entries":_vm.filteredList},scopedSlots:_vm._u([{key:"week",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDate")(item.startDate))+" - "+_vm._s(_vm._f("formatDate")(item.endDate)))]}},{key:"fulfilled",fn:function(ref){
var item = ref.item;
return [_c('small-progress-bar',{attrs:{"complete":item.approved,"total":item.total}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }