<template>
    <fragment>
        <vue-html2pdf 
            v-for="(item, index) in items" 
            :key="index" 
            ref="hello" 
            :show-layout="false" 
            :float-layout="true"
            :enable-download="true" 
            :preview-modal="false" 
            :paginate-elements-by-height="1500" 
            :pdf-quality="2"
            :manual-pagination="false" 
            pdf-format="letter" 
            pdf-orientation="portrait"
            pdf-content-width="100%" 
            :html-to-pdf-options="htmlToPdfOptions"
        >
            <section slot="pdf-content">
                <download-deposit :details="item" />
            </section>
        </vue-html2pdf>
    </fragment>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import html2pdf from 'html2pdf.js'
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import { Fragment } from "vue-fragment";
import DownloadDeposit from "@/modules/admin/accounting/invoicing/DownloadDeposit";
export default {
    name: "DownloadMultipleDepositSummary",
    components: { Fragment, VueHtml2pdf, DownloadDeposit },
    props: {
        downloadDepositSummary: {
            type: Boolean,
            required: true,
            default: false,
        },
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    computed: {
        partnerID() {
            return this.$route.params.id;
        },
    },
    watch: {
        downloadDepositSummary: function(newVal) {
            if(newVal) {
                this.generateReport();
            }
        }
    },
    data() {
        return {
            htmlToPdfOptions: {
                filename: "Test_DepositSummary",
                html2canvas: { useCORS: true, height: 1024, logging: false },
                showLayout: "false",
                floatLayout: "true",
                enableDownload: false,
                previewModal: "false",
                paginateElementsByHeight: "1500",
                pdfQuality: "2",
                manualPagination: "false",
                pdfFormat: "letter",
            },
            pdfBlobs: [],
        };
    },
    methods: {
        async generateReport() {
            this.pdfBlobs = [];
            let options = this.htmlToPdfOptions;
            const zip = new JSZip();

            let totalItems = this.$refs['hello']?.length ?? 0;
            for (let i = 0; i < totalItems; i++) {

                const pdfContent = this.$refs['hello'][i]?.$refs?.pdfContent;
                const html2PdfSetup = html2pdf().set(options).from(pdfContent);

                const pdfData = await html2PdfSetup.output('blob');

                const fileName = `${this.$props.items[i]?.partner?.firstName}_${this.$props.items[i]?.partner?.lastName}_${this.$props.items[i]?.payPeriod}-${new Date().getTime()}.pdf`;
                zip.file(fileName, pdfData); 
            }               

            if(totalItems) {
                const zipBlob = await zip.generateAsync({ type: "blob" });
                saveAs(zipBlob, `${this.partnerID}.zip`, { automatic: true });
            }

            this.depositSummaryDownloaded();

        },
        depositSummaryDownloaded() {
            this.$emit("depositSummaryDownloaded");
        },
    },
};
</script>