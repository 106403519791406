import dotenv from "dotenv";
import Vue from "vue";
import router from "./router";
import { store } from "./store";
import { apolloProvider } from "@/apollo";
import VueLogger from "vuejs-logger";
import loggingOptions from "@/util/logging";
import App from "./App.vue";
import VuePapaParse from "vue-papa-parse";
import VueCookies from "vue-cookies";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import "vue-date-pick/dist/vueDatePick.css";
import "vue2-datepicker/index.css";
import "./assets/css/quill.snow.css";
import "@trevoreyre/autocomplete-vue/dist/style.css";
import "viewerjs/dist/viewer.css";
import "./assets/css/style.css";
import "./assets/css/overrides.css";
import titleMixin from "@/mixins/titleMixin";
import vueCountryRegionSelect from "vue-country-region-select";
import {
  formatDateTime,
  formatDate,
  formatPhone,
  formatSSN,
  privateSSN,
  titleCase,
  formatMoney,
  pretty,
  formatTime,
  formatNumber,
  formatDatePacific,
  formatDateTimePacific,
  formatDateDDMMM,
  formatDateDDMMMYYYY
} from "@/filters";
import VueViewer from "v-viewer";
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/nova/theme.css';
import 'primeicons/primeicons.css';
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";
dotenv.config();
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export const appInsights = new ApplicationInsights({ config: {
    instrumentationKey: config.VUE_APP_AZURE_INSIGHTS || process.env.VUE_APP_AZURE_INSIGHTS,
    /* ...Other Configuration Options... */
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
    disableFetchTracking:false,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderExcludedDomains:['admin.ddiwork.com']
  } });
//appInsights.loadAppInsights();

//appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

// Sentry.init({
//   Vue,
//   dsn: "https://8366d7baa86c49f6927a5d60a0359232@o251564.ingest.sentry.io/5623777",
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: [process.env.VUE_APP_ENV, "ddiwork.com.com", /^\//],
//     }),
//   ],
//   logErrors: process.env.VUE_APP_ENV !== "production",
//   trackComponents: true,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   // tracesSampleRate: process.env.VUE_APP_ENV === "production" ? 0.5 : 1.0,
//   environment: config.VUE_APP_ENV || process.env.VUE_APP_ENV,
// });

Vue.config.productionTip = false;

Vue.use(Toast, {
  timeout: 3000,
  position: POSITION.BOTTOM_RIGHT,
  maxToasts: 5,
});
Vue.use(VueLogger, loggingOptions);
Vue.use(vueCountryRegionSelect);
Vue.use(VuePapaParse);
Vue.use(VueCookies);
Vue.use(VueViewer);
Vue.use(PrimeVue);
Vue.mixin(titleMixin);
Vue.mixin({
  created() {
    this.$log.debug(this.$options.name);
  },
});

Vue.filter("privateSSN", privateSSN);
Vue.filter("formatSSN", formatSSN);
Vue.filter("formatDate", formatDate);
Vue.filter("formatDatePacific", formatDatePacific);
Vue.filter("formatDateTime", formatDateTime);
Vue.filter("formatDateTimePacific", formatDateTimePacific);
Vue.filter("formatPhone", formatPhone);
Vue.filter("titleCase", titleCase);
Vue.filter("formatMoney", formatMoney);
Vue.filter("pretty", pretty);
Vue.filter("formatTime", formatTime);
Vue.filter("formatNumber", formatNumber);
Vue.filter("formatDateDDMMM", formatDateDDMMM);
Vue.filter("formatDateDDMMMYYYY",formatDateDDMMMYYYY)

Vue.$cookies.config(config.VUE_APP_COOKIE_EXPIRATION || process.env.VUE_APP_COOKIE_EXPIRATION | "7d");

const vm = new Vue({
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");

export default vm;
