<template>
  <fragment>
    <div class="location">
      <a class="location-font" @click="() => (showSearch = !showSearch)">Change Location</a>
      <div v-if="showSearch" class="searchResult">
        <div class="search-container">
          <form>
            <label>
              <input v-model="searchTerm" @input="searchLocation($event)" type="search" placeholder="Search" />
            </label>
            <button v-if="searchTerm.length === 0" disabled><span class="icon-search padding-bottom-6"></span></button>
            <button v-else @click.prevent="clearSearch"><span class="icon-x-circle padding-bottom-6"></span></button>
          </form>
        </div>
        <ul v-if="searchResults.total > 0 && searchTerm.length !== 0" @click.prevent="clearSearch">
          <li v-for="result in searchResults.locations" :key="result.id">
            <div class="searchResultRow">
              <p v-if="result.type === 'location'" class="gray-light" @click="displayConfirmModal(result.id)">{{ result.name }}</p>
            </div>
          </li>
        </ul>
        <ul v-if="searchResults.total === 0 && searchTerm.length > 0">
            <p class="text-left">No Locations found</p>
        </ul>
        <!-- <ul v-if="searchResults.total === 0 && searchTerm.length > 0">
            <table-loader min-height="20px" min-width="240px" />
        </ul> -->
      </div>
    </div>

    <div id="modal-confirm-change-location" class="modal" aria-hidden="true">
      <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
        <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby">
          <div class="modal-content">
            <div class="module">
              <h4 class="text-left">Location Change</h4>
              <p class="small gray-light text-left">This will change location</p>
            </div>
            <div class="button-group close-top">
              <a class="button secondary" data-micromodal-close @click="() => (showSearch = false)">Cancel</a>
              <a class="button" @click="changeLocation()">Change</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";
// import { SEARCH_CLIENT_LOCATIONS } from "@/components/graph/queries";
import micromodal from "micromodal";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";
// import TableLoader from "@/components/loader/TableLoader";
// import {UPDATE_CANDIDATE_LOCATION, UPDATE_CANDIDATE_FILES} from "../graph/mutations"
// import {GET_FUNNEL_ID} from "../graph/queries"
// import { validate as isValidUUID } from 'uuid';
export default {
  name: "ChangeLocation",
  components: {
    Fragment,
    // TableLoader
  },
  data() {
    return {
      showSearch: false,
      searchTerm: "",
      clientLocations: [],
      locationID: ""
    };
  },
  props: {
      candidateID: {
        type: String,
        required: false
      }
  },
  computed: {
    searchResults() {
      const locations = this.clientLocations.map((item) => {
        item.type = "location";
        return item;
      });
      const total = locations.length;
      return { locations, total };
    },
  },
  methods: {
    searchLocation(event){
        const locationParam = {searchTerm : `%${event.target.value}%`}
        restApi.post('onboard/locationSearch', encodeWithParam(locationParam))
        .then(data => {
          this.clientLocations = data.data.result;
        });

    },
    clearSearch() {
      this.searchTerm = "";
    },
    displayConfirmModal(id) {
      this.locationID = id
      micromodal.show("modal-confirm-change-location", {});
    },

    changeLocation(){
      this.showSearch = false
        micromodal.close("modal-confirm-change-location");
      let param = {
          clientLocationID: this.locationID,
          candidateID: this.$props.candidateID
        }
        restApi.post('/onboard/changeLocation',encodeWithParam(param))
        .then((data)=>{
            console.log('this is result data', data)
            this.$emit("locationChanged")
        })
    }
    // async changeLocation() {
    //     this.showSearch = false
    //     micromodal.close("modal-confirm-change-location");
    //     // const {data} = await this.$apollo.query({query: GET_FUNNEL_ID, variables: {clientLocationID: this.locationID}})
    //     // const funnelFilesID = data?.clientLocationFunnels[0]?.funnelFiles[0]?.id;
    //     // console.log('funnelFilesID',funnelFilesID)
    //     console.log('candidateID',this.$props.candidateID)
    //     console.log('locationID', this.locationID)

    //     let param = {
    //       clientLocationID: this.locationID,
    //       candidateID: this.$props.candidateID
    //     }

    //     // if (isValidUUID(this.$props.candidateID) && isValidUUID(this.locationID) && isValidUUID(funnelFilesID)) {
    //     //     await this.$apollo.mutate({mutation: UPDATE_CANDIDATE_LOCATION,
    //     //     variables: {clientLocationID: this.locationID, candidateID: this.$props.candidateID}})
    //     //     await this.$apollo.mutate({mutation: UPDATE_CANDIDATE_FILES, variables: {funnelFilesID: funnelFilesID, locationPartnerID: this.$props.candidateID}})
    //     // }
    //     //console.log("location changed for the candidate", this.$props.candidateID, this.locationID)
    // }
  },
  // apollo: {
  //   clientLocations: {
  //     query: SEARCH_CLIENT_LOCATIONS,
  //     debounce: 500,
  //     variables() {
  //       return { likeQuery: `%${this.searchTerm}%` };
  //     },
  //     update: (data) => [...data.clientLocations],
  //     result({ data }) {
  //       this.clientLocations = data.clientLocations;
  //     },
  //     skip() {
  //       return this.searchTerm.length === 0;
  //     },
  //   },
  // },
};
</script>

<style scoped>
.location-font {
  color: var(--link);
  text-decoration: none;
}
.location {
  float: right;
}
.search-container {
  padding: 5px;
  margin-bottom: 0;
  width: 320px;
}
.search-container input {
  width: 310px;
}
.searchResult {
  position: absolute;
  right: 24px;
  max-height: 320px;
  z-index: 100;
  list-style: none;
  min-width: 320px;
  width: auto;
  padding-left: 0;
  background-color: #fdfdfd;
  margin-top: 8px;
  -webkit-box-shadow: 0 0 2px 1px rgba(189, 189, 189, 1);
  -moz-box-shadow: 0 0 2px 1px rgba(189, 189, 189, 1);
  box-shadow: 0 0 2px 1px rgba(189, 189, 189, 1);
}
.padding-bottom-6 {
  padding-bottom: 6px;
}
.searchResultRow {
  align-items: center;
  text-align: left;
  padding-left: 0;
  cursor: pointer;
}
.searchResultRow:hover {
  background-color: #eaeaea;
}
input:focus {
  box-shadow: none;
  outline: none;
}
ul {
  list-style-type: none;
  padding-left: 8px;
  padding-right: 6px;
}
.searchResultRow p {
  padding: 6px 0;
}
.modal-container {
    max-width: 320px;
}
.modal-content {
    float: left;
    margin-left: 16px;
}
.modal-overlay {
    bottom: -24em;
}
</style>
