<template>
  <BaseModal :id="$props.modalId" :full="true">
    <sticky-header :title="title" classes = "margin-zero">
      <div class="document-toolbar">
        <div class="document-actions">
          <save-cancel :save-enabled="!isCreateButtonDisabled" :save-label="$props.saveLabel" :emit-cancel="true" @onSave="saveDocument" @onCancel="closeModal" />
        </div>
      </div>
    </sticky-header>
    <main class="off-white">
      <div class="container">
        <div class="content">
          <div class="grid">
            <div class="col-12">
              <div class="field">
                <label for="templateName">Document Name</label>
                <input id="templateName" v-model="template.title" type="text" />
              </div>
              <div class="checkbox-custom checkbox-custom-card" >
                <input class="checkbox-custom-input" id="optional" v-model="optionalDoc" type="checkbox" @click="makeDocOptional" />
                <label class="checkbox-custom-label" for="optional"><span class="label-container">Optional</span> </label>
              </div>
              <new-editor :content="textData" @json="saveJSON" @html="saveHTML" />
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer />
    <DocumentUsedModal :template="template" />
  </BaseModal>
</template>
<script>
import { SaveCancel, StickyHeader } from "@/components";
import DocumentUsedModal from "@/modules/shared/documents/DocumentUsedModal";
import micromodal from "micromodal";
import NewEditor from "@/components/editor/NewEditor";
import BaseModal from "@/components/modals/BaseModal";
import documentService from "@/services/document.service";
import { v4 as uuidv4 } from "uuid";
import { mapGetters } from "vuex";
import {encodeWithParam} from "@/util/Base64Encoding";

export default {
  name: "CreateEditDocument",
  components: { BaseModal, NewEditor, SaveCancel, StickyHeader, DocumentUsedModal },
  props: {
    modalId: {
      type: String,
      required: false,
      default: "create-edit-document",
    },
    edit: {
      //! if edit is true then update template, else create new draft an increment version and set previousID
      type: Boolean,
      required: false,
      default: false,
    },
    saveLabel: {
      type: String,
      required: false,
      default: "Save",
    },
    clientId: {
      type: String,
      required: false,
      default: "",
    },
    locationId: {
      type: String,
      required: false,
      default: "",
    },
    templateId: {
      type: String,
      required: false,
      default: null,
    },
    optional: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["onSave"],
  data() {
    return {
      template: {
        title: "",
        text: {},
        status: "draft",
        version: 0,
        html: "",
      },
      optionalDoc: false,
      textData: {}
    };
  },
  computed: {
    ...mapGetters(["getFullName"]),
    version() {
      return this.template.version + 1;
    },
    isDDI() {
      return !this.$route.params.id && !this.$route.params.locationID;
    },
    isLocation() {
      return !!this.$route.params.locationID;
    },
    isClient() {
      return !!this.$route.params.id && !this.$route.params.locationID;
    },
    isCreateButtonDisabled() {
      return !(this.template.title.length > 0 && this.template.text && this.template.status);
    },
    originalTemplateID() {
      return this.template && this.template.original ? this.template.id : this.template.originalID;
    },
    title() {
      return this.edit ? "Edit Document" : "Create Document";
    },
  },
  watch: {
    optional(newval) {
      this.optionalDoc = newval
    }
  },
  mounted() {
    this.$nextTick(
      micromodal.init({
        onShow: function () {
          document.body.classList.add("no-scroll");
        },
        onClose: function () {
          document.body.classList.remove("no-scroll");
        },
      })
    );
  },
  methods: {
    closeModal() {
      if (!this.edit) {
        this.template.title = "";
        this.template.status = "draft";
        this.template.version = 1;
        this.template.html = "";
        this.template.text = {};
      }
      micromodal.close(this.$props.modalId);
      micromodal.close("modal-add-document");
    },
    saveJSON(data) {
      this.template.text = data;
    },
    saveHTML(data) {
      this.template.html = data;
    },
    saveDocument() {
      if (this.edit) {
        const variables = {
          id: this.template.id,
          title: this.template.title,
          updatedBy: this.getFullName,
          text: this.template.text,
          html: this.template.html,
          optional: this.optionalDoc
        };
        documentService.updateDocumentTemplate(variables);
      } else {
        const id = uuidv4();

        const variables = encodeWithParam({
          id: id,
          title: this.template.title,
          originalID: this.template?.id ? this.template.id : id,
          version: this.version,
          updatedBy: this.getFullName,
          text: this.template.text,
          html: this.template.html,
          isDDI: false,
          previousID: this.template?.id,
          clientID: config.CLIENT_ID,
          locationID: this.isLocation ? this.$route.params.locationID : null,
          templateStatus: "draft",
          optional: this.optionalDoc
        });
        documentService.createDocumentTemplate(variables,this.isLocation).then(()=>{
          this.$emit('documentModified');
        })
      }
      this.closeModal();
    },
    makeDocOptional($event) {
      this.optionalDoc = $event.target.checked === true ? true : false
    }
  },
  apollo: {
    // template: {
    //   query: GET_DOCUMENT_BY_ID,
    //   fetchPolicy: "no-cache",
    //   variables() {
    //     return {
    //       id: this.templateId,
    //     };
    //   },
    //   skip() {
    //     return !this.templateId;
    //   },
    //   result({ data }) {
    //     this.textData = data.template.text;
    //   },
    // },
  },
};
</script>

<style>
.checkbox-custom-input{
  width: 20px !important;
  height: 20px !important;
  position: unset !important;

}
.checkbox-custom-input[type="checkbox"]:checked + label::before {
    display: none !important;
}
.checkbox-custom-input[type="checkbox"]:checked + label::after {
    display: none !important;
}

.checkbox-custom-label {
    position: unset !important;
    padding: 3px 0 0 6px !important;
}

.checkbox-custom-label:before {
  display: none !important;

}
.checkbox-custom-label::after {
  display: none !important;

}
</style>
