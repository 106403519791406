<template>
  <div class="module-form">
    <h3>DDI Assignments</h3>
    <div class="module">
      <label>Assign Onboarding Account Manager (OAM)</label>
      <item-picker
        ref="accountManagers"
        :search-results="$props.accountManagers"
        :selected="selectedAM"
        :max-size="1"
        placeholder="Type onboarding Account Mananger name"
        auto-select
        no-title
        @itemsChanged="updateAM"
      />
    </div>
    <div class="module">
      <label>Assign Onboarding Analyst (OA)</label>
      <item-picker
        ref="specialist"
        :search-results="$props.specialists"
        :selected="selectedSpecialist"
        :max-size="1"
        placeholder="Type onboarding analyst name"
        auto-select
        no-title
        @itemsChanged="updateSpecialist"
      />
    </div>
    <!-- <div class="module">
      <h4>Team Assignment</h4>
      <item-picker :search-results="$props.teams" :selected="selectedTeam" :max-size="1" placeholder="Type team name" auto-select no-title @itemsChanged="updateTeam" />
    </div> -->
    <!-- <div v-if="teamSelected" class="module">
      <h4>OS Assignment</h4>
      <item-picker
        ref="specialist"
        :search-results="$props.specialists"
        :selected="selectedSpecialist"
        :max-size="1"
        placeholder="Type onboarding specialist name"
        auto-select
        no-title
        @itemsChanged="updateSpecialist"
      />
    </div> -->
  </div>
</template>
<script>
import { ItemPicker } from "@/components";

export default {
  components: { ItemPicker },
  props: {
    teams: { type: Array, required: false, default: () => [] },
    selectedTeam: { type: Array, required: false, default: () => [] },
    accountManagers: { type: Array, required: false, default: () => [] },
    specialists: { type: Array, required: false, default: () => [] },
    selectedSpecialist: { type: Array, required: false, default: () => [] },
    selectedAM: { type: Array, required: false, default: () => [] },
  },
  data: () => ({
    teamSelected: false,
  }),
  methods: {
    // updateTeam(itemChangedEvent) {
    //   this.teamSelected = !!itemChangedEvent.itemAdded;
    //   if (!this.teamSelected) {
    //     this.$refs.specialist.reset();
    //   }
    //   this.$emit("onTeamChanged", itemChangedEvent);
    // },
    updateSpecialist(itemChangedEvent) {
      this.$emit("onSpecialistChanged", itemChangedEvent);
    },
    updateAM(itemChangedEvent) {
      this.$emit("onAMChanged", itemChangedEvent);
    },
  },
};
</script>
