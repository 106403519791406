<template>
  <fragment>
      <div class="flex space-between margin-btm-24">
          <h2>Checkr History</h2>
      </div>
      <data-table :entries="checkrHistroy" :headers="auditNotesHeader" :loading="loader">
          <template #date="{ item }"> {{ formatDate(item.created_on) }} </template>
      </data-table>
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";
import DataTable from "@/components/table/DataTable";
import TableHeader from "@/components/table/TableHeader";
import { DateTime } from "luxon";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/filters";

export default {
  name: "PartnerCheckrHistory",
  components: {
      Fragment,
      DataTable,
  },
  data() {
      return {
          loader: true,
          checkrHistroy: [],
      };
  },
  computed: {
      partnerID() {
          return this.$route.params.id;
      },
      auditNotesHeader() {
          return [
              new TableHeader({ label: "Date", headerClass: "cell25 text-left", name: "date", cellContents: "created_on" }),
              new TableHeader({ label: "Screening Package", headerClass: "cell25 text-left", name: "screeningPackage", cellContents: "screeningPackage" }),
              new TableHeader({ label: "Report Status", headerClass: "cell25 text-left", name: "reportStatus", cellContents: "reportStatus", cellClass: "capitalize" }),
              new TableHeader({ label: "Assessment", headerClass: "cell20 text-left", name: "assessment", cellContents: "assessment" }),
          ]
      }
  },
  mounted() {
    this.getDriverCheckrHistory();
  },
  methods: {
      async getDriverCheckrHistory() {
          this.loader = true;
          await restApi.post(`/partner/getDriverCheckrHistory`, encodeWithParam({ partnerID: this.partnerID }))
              .then(({ data }) => {
                  this.checkrHistroy = data?.result ?? [];
                  this.loader = false;
              }).catch(() => {
                  this.loader = false;
              })
      },
      formatDate(date) {
          return DateTime.fromISO(date).setZone("America/Los_Angeles").toFormat("hh:mm a, LL/dd/yyyy");
      },
  },
};
</script>
<style scoped>
.capitalize {
  text-transform: capitalize;
}
.custom-btn {
  padding: 9px 10px;
  border-radius: 4px;
  border: 1px solid #CCC;
  height: 40px;
  cursor: pointer;
  background-color: #FFF;
}
</style>
